import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { iif, map, mergeMap, of, tap } from 'rxjs';
import { Observable } from 'rxjs';
import { GeoLocation } from '../interfaces/geo-location.interface';

@Injectable({
  providedIn: 'root',
})
export class GeoLocationService {
  constructor(private http: HttpClient) {}

  initGeoLocation$(): Observable<string> {
    return of(null).pipe(
      mergeMap(() =>
        iif(
          () => localStorage.getItem('userCountry') === null,
          this.getCountry$().pipe(tap((country: string) => localStorage.setItem('userCountry', country))),
          of(localStorage.getItem('userCountry'))
        )
      )
    );
  }

  getCountry$(): Observable<string> {
    return this.http
      .get<string>('https://us-central1-shelfdesigner-bbbcb.cloudfunctions.net/getGeoLocation', { responseType: 'text' as 'json' })
      .pipe(map((rawGeoLocation: string) => (JSON.parse(rawGeoLocation) as GeoLocation).country));
  }
}
