import { Component, OnInit } from '@angular/core';
import { tap } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  rightPanelActive = false;

  submitted = false;
  signingIn = false;

  signInModel = { userName: '', userPassword: '' };

  constructor(public authService: AuthService) {}

  ngOnInit() {}

  handleShowSignUp() {
    this.rightPanelActive = true;
  }

  handleSignUp(userEmail, userPassword) {
    this.authService.signUp(userEmail.value, userPassword.value);
  }

  handleSignIn() {
    this.rightPanelActive = false;
  }

  handleSignInWithGoogle() {
    this.authService.googleAuth$().subscribe();
  }

  handleSignInWithTwitter() {
    this.authService.twitterAuth$().subscribe();
  }

  handleSignInWithFacebook() {
    this.authService.facebookAuth$().subscribe();
  }

  onSubmit() {
    this.submitted = true;
    this.signingIn = true;
    this.authService
      .signIn$(this.signInModel.userName, this.signInModel.userPassword)
      .pipe(tap(() => (this.signingIn = false)))
      .subscribe();
    // return false;
  }
}
