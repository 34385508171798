<div class="header">
  <!-- Price -->
  <div class="price-section">
    <div class="price" [ngClass]="{ 'do-not-buy': !(totalPrice$ | async) }"
      [innerHTML]="totalPrice$ | async | currencyFormat: 'EUR':'de'" (click)="handleCheckout()"></div>
  </div>

  <!-- Button for there is a price equals to 0 -->
  <ion-button class="show-product-list-button" (click)="handleToggleProductList()" fill="solid">
    <!-- TODO: >#< Is this a Reference, or can it be removed? -->
    <!--
    <ng-container *ngIf="(totalPrice$ | async) == 0">
      {{ 'shopping-cart.go-to' | transloco: { affiliate: 'affiliates.amazon' | transloco } }}
    </ng-container>
    <ng-container *ngIf="(totalPrice$ | async) > 0">
      {{ 'shopping-cart.title' | transloco }}
    </ng-container>
    //-->
    {{ 'shopping-cart.title' | transloco }}
    <ion-icon *ngIf="!(isProductListOpen$ | async)" icon="chevron-down-circle-outline" slot="end"></ion-icon>
    <ion-icon *ngIf="isProductListOpen$ | async" icon="chevron-up-circle-outline" slot="end"></ion-icon>
  </ion-button>
</div>

<app-shelves-scenes-hacks-picker [sidebar]="true"></app-shelves-scenes-hacks-picker>

<div id="product-list-cupertino-pane" class="cupertino-pane">
  <app-product-list hide-on-bottom></app-product-list>
</div>
