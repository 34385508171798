<div class="left">
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <!-- TODO: Check if Burger button is shown even without autohide parameter -->
        <ion-button (click)="handleShowAppMenu()" class="app-menu">
          <ion-icon name="menu" slot="icon-only"></ion-icon>
        </ion-button>
        <img class="app-logo" [ngClass]="{ link: !isNativePlatform }" (click)="handleLogoClick()" [src]="logoPath" />
      </ion-buttons>
      <ion-title> </ion-title>
      <!-- TODO: >#< Can THIS variable removed? -->
      <ion-buttons slot="end" *ngLet="(totalPrice$ | async) > 0 as price">
        <!-- TODO: >#< Is this a Reference, or can it be removed? -->
        <div class="designer-options-bar" *ngIf="false">
          <!-- New Design -->
          <div class="option" (click)="handleCreateNewDesign()">
            <img src="./assets/imgs/plus-icon.svg" />
            <ion-label class="option-text">{{ 'general.new' | transloco }}</ion-label>
          </div>

          <!-- Share -->
          <div class="option" (click)="handleShare()">
            <img src="./assets/imgs/share-icon.svg" />
            <ion-label class="option-text">{{ 'app-menu.share' | transloco }}</ion-label>
          </div>
        </div>

        <!-- Only for mobile -->
        <!-- Productlist -->
        <ion-button class="go-to-shopping-cart" (click)="onGotoProductListClick()" *ngIf="!(splitMode$ | async)">
          {{ 'shopping-cart.title' | transloco }}
        </ion-button>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>

  <ion-content [scrollY]="false">
    <div class="root">
      <div style="display: block;" class="shelf-configurator" *ngLet="rootHeight$ | async as rootHeight"
        [ngStyle]="{ height: rootHeight, minHeight: rootHeight }">
        <div class="container" #shelfConfigurator>
          <div class="shelf-and-actions">
            <div #shelfImage class="shelf" *ngLet="{
                currentShelf: currentShelf$ | async,
                shelfImageBounds: shelfImageBounds$ | async,
                currentSceneIndex: currentSceneIndex$ | async
              } as observables">
              <!-- TODO: >#< Is this a Reference, or can it be removed? -->
              <app-item-image *ngIf="false" class="shelf-reflection"
                [src]="observables.currentShelf?.scenes[observables.currentSceneIndex].imageUrl" size="2000x2000"
                [style.left.px]="observables.shelfImageBounds.x"
                [style.top.px]="observables.shelfImageBounds.y + observables.shelfImageBounds.height"
                [style.width.px]="observables.shelfImageBounds.width"
                [style.height.px]="observables.shelfImageBounds.height">
                <!--
                [style.margin-top.px]="observables.currentShelf?.reflectionYOffset"
                [style.transform]="'skewY(' + currentShelf?.rotation + ')'"
             -->
              </app-item-image>

              <div class="shelf-loader" *ngIf="isShelfLoading$ | async">
                <div class="loader">
                  <img src="../../../assets/imgs/shelfy-logo-artwork.png" height="100" />
                </div>
              </div>
              <div id="shelfy-screenshot-container" [style.left.px]="observables.shelfImageBounds.x"
                [style.top.px]="observables.shelfImageBounds.y" [style.width.px]="observables.shelfImageBounds.width"
                [style.height.px]="observables.shelfImageBounds.height">

                <app-loading-image [ngClass]="{ 'shelf-image': true, 'fade-out': isShelfLoading$ | async }"
                  #shelfImageImg [src]="observables.currentShelf?.scenes[observables.currentSceneIndex]?.imageUrl"
                  size="2000x2000" [style.width.px]="observables.shelfImageBounds.width"
                  [style.height.px]="observables.shelfImageBounds.height"
                  (loaded)="handleShelfImageLoaded($event)"></app-loading-image>

                <!-- TODO: Does we need this as a reference? Or can we throw it away? -->
                <!--
                <app-item-image
                  class="shelf-image"
                  #shelfImageImg
                  [src]="observables.currentShelf?.scenes[observables.currentSceneIndex]?.imageUrl"
                  size="2000x2000"
                  [style.left.px]="observables.shelfImageBounds.x"
                  [style.top.px]="observables.shelfImageBounds.y"
                  [style.width.px]="observables.shelfImageBounds.width"
                  [style.height.px]="observables.shelfImageBounds.height"
                >
                </app-item-image>
                -->
                <!--
                srcset="{{observables.currentShelf?.image3dViewPath | imageSrcSet}}"
                sizes="300px"
                srcset="{{observables.currentShelf?.image3dViewPath | imageSrcSet}}"
                sizes="300px"
                -->
                <!--
                <img
                  default-image="{{ environment.basePath }}{{ currentShelf.imagePlaceholder }}"
                  img-preloader="{{ observables.currentShelf.image3dViewPath }}"
                  srcset="{{observables.currentShelf.image3dViewPath | imageSrcSet}}"
                  sizes="80vw"
                />
                -->

                <div class="slots" [style.width.px]="observables.shelfImageBounds.width"
                  [style.height.px]="observables.shelfImageBounds.height">
                  <ng-container *ngLet="shelfZoomFactor$ | async as shelfZoomFactor">
                    <div *ngFor="let slot of observables.currentShelf?.scenes[observables.currentSceneIndex]?.slots"
                      class="shelf-item" [style.left.px]="slot.slotData.ul.x * shelfZoomFactor"
                      [style.top.px]="slot.slotData.ul.y * shelfZoomFactor" [style.width.px]="slot.slotData.width"
                      [style.height.px]="slot.slotData.height" [style.transform]="'scale(' + shelfZoomFactor + ')'"
                      [style.transformOrigin]="'top left'">
                      <app-shelf-item [shelf]="observables.currentShelf" [slot]="slot"
                        [isOwnDesign]="currentDesignIsOwnDesign$ | async"></app-shelf-item>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
            <app-designer-options-bar *ngIf="!(splitMode$ | async)"></app-designer-options-bar>
          </div>
        </div>
      </div>

      <!--
      <img id="shelfy-screenshot2" width="300" height="300"
        src="https://firebasestorage.googleapis.com/v0/b/shelfdesigner-bbbcb.appspot.com/o/products%2Fshelfs%2Fimages%2F70309840-180_250x250.webp?alt=media" />
      -->
    </div>

    <div id="cupertino-pane" class="cupertino-pane">
      <app-shelves-scenes-hacks-picker [sidebar]="false"
        *ngIf="!(splitMode$ | async)"></app-shelves-scenes-hacks-picker>
    </div>

  </ion-content>
</div>

<!-- <app-product-list [sideBar]="true"></app-product-list> -->
<app-sidebar *ngIf="(splitMode$ | async)" class="right"></app-sidebar>

<div *ngIf="splitMode$ | async" class="buttons">
  <div class="button new-button" (click)="handleCreateNewDesign()">
    <ion-icon name="add-outline"></ion-icon>
    <span>{{ 'general.new' | transloco }}</span>
  </div>
  <div class="hr"></div>
  <div class="button my-designs-button" (click)="handleShowMyDesigns()">
    <ion-icon name="apps-outline"></ion-icon>
    <span>{{ 'shelfdesigner.my-designs-button' | transloco }}</span>
  </div>
  <div class="hr"></div>
  <!--
  <div class="button share-button" (click)="handleShare()">
    <ion-icon name="share-social"></ion-icon>
    <span>{{ 'general.share' | transloco }}</span>
  </div>
  -->

  <app-social-share-buttons class="button share-button"></app-social-share-buttons>
</div>
