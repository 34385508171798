<!--   sizes="10vw"
    srcset="{{slot.slotImage | imageSrcSet}}" -->

<div class="shelf-item" [style]="transformStyle$ | async">
  <div class="position" [ngClass]="{
      left: imgStyling.position === 'left',
      center: imgStyling.position === 'center',
      right: imgStyling.position === 'right'
    }">
    <img #img [style.width.px]="imgStyling!.width" [style.height.px]="imgStyling!.height"
      [ngClass]="{'not-fit': !(doesItemFitInSlot$ | async)}" />
  </div>

  <div class="shelfy-item-warning" *ngIf="!(doesItemFitInSlot$ | async)" #shelfyWarning>
    <ion-icon name="warning"></ion-icon>
    <p [ngClass]="{ 'smaler-box': (shelItemWidth$ | async) < 140 }">
      {{ 'shelfdesigner.item-does-not-fit-slot' | transloco }}
    </p>
  </div>

  <div #placeholder class="placeholder" [style.opacity]="placeholderOpacity$ | async">
    <img [src]="placeholderImagePath$ | async" [ngClass]="{ animated: slot.id === 0 && slotAnimationActive }"
      [style.width.px]="slot.slotData.width" [style.height.px]="slot.slotData.height"
      style="transform-origin: left top" />
  </div>
</div>
