import { Directive, EventEmitter, HostListener, Input, Output, SimpleChanges } from '@angular/core';

/**
 * All (ionChange) events will be checked for ui interactions triggered by the user.
 * This avoids infinite action dispatch loops caused by state updates and two-way bindings.
 */
@Directive({
  selector: '[appUiChanges]',
})
export class UiChangesDirective {
  @Input() behavior?: 'click' | 'drag';
  @Output() uiChange: EventEmitter<CustomEvent> = new EventEmitter();

  elementHasBeenTriggered!: boolean;

  constructor() {
    this.elementHasBeenTriggered = false;
  }

  @HostListener('window:mousedown', ['$event'])
  onClickingElement(): void {
    if (this.behavior === 'click') {
      this.elementHasBeenTriggered = true;
    }
  }

  // Mouse Up: Desktop Listener
  @HostListener('window:mouseup', ['$event'])
  onReleasingElement(): void {
    if (this.behavior === 'drag') {
      this.elementHasBeenTriggered = true;
    }
  }

  // Touch End: Mobile Listener
  @HostListener('window:touchend', ['$event'])
  onStopDraggingElement(): void {
    if (this.behavior === 'drag') {
      this.elementHasBeenTriggered = true;
    }
  }

  @HostListener('ionChange', ['$event'])
  public onUiChange(event: CustomEvent): void {
    if (this.elementHasBeenTriggered) {
      // reset event trigger for next time
      this.elementHasBeenTriggered = false;
      this.uiChange.emit(event);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.behavior) {
      this.behavior = changes.behavior.currentValue;
    }
  }
}
