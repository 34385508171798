import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyFormat',
})
export class CurrencyFormatPipe implements PipeTransform {
  transform(
    value: number,
    currency: string,
    currentLocale: string,
    minimumFractionDigits: number = 2,
    strikeThrough: boolean = false
  ): any {
    if ((!value || !currency) && value !== 0) {
      return '';
    }
    const formatter = new Intl.NumberFormat(currentLocale, {
      style: 'currency',
      currency,
      minimumFractionDigits,
    });

    const displayValue = formatter
      .formatToParts(value)
      .map(({ type, value }) => {
        switch (type) {
          case 'fraction':
            return `<sup>${value}</sup>`;
          default:
            return value;
        }
      })
      .reduce((string, part) => string + part);

    return strikeThrough
      ? `<div class="currency-container"><div class="strike-through"></div><span>${displayValue}</span></div>`
      : displayValue;
  }
}
