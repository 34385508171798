import { Pipe, PipeTransform } from '@angular/core';
import { AffiliatesEnum } from '../enums/affiliates.enum';
import { getAffiliateData, hasShoppingCart } from '../functions/affiliates';
import { getPriceOfProduct } from '../functions/price';
import { AffiliateProductData } from '../interfaces/affiliate-product-data.interface';
import { AffiliateInformation, MetaAffiliate } from '../interfaces/affiliate.interface';
import { Item } from '../interfaces/item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import * as Affiliates from '../../../assets/json/affiliate-informations.json';

type AffiliateProperty =
  | 'price'
  | 'higherPrice'
  | 'currentPrice'
  | 'hasSalePrice'
  | 'fullStars'
  | 'halfStars'
  | 'emptyStars'
  | 'totalRatings'
  | 'checkIfHasRating'
  | 'seller'
  | 'name'
  | 'url'
  | 'hasShoppingCart';

@Pipe({
  name: 'affiliate',
})
export class AffiliatePipe implements PipeTransform {
  affiliateInformations: { [key: string]: AffiliateInformation } = Affiliates;

  transform(product: Item | ShelfScenes, property: AffiliateProperty): number | number[] | null | boolean | string {
    if (!product) {
      return this.defaultReturnings(property);
    }
    // get correct affiliate
    const affiliate = getAffiliateData(product);

    if (!affiliate) {
      return this.defaultReturnings(property);
    }

    if (['price', 'higherPrice', 'currentPrice', 'hasSalePrice'].indexOf(property) > -1) {
      return getPriceOfProduct(affiliate.data, property);
    } else if (['fullStars', 'halfStars', 'emptyStars', 'totalRatings', 'checkIfHasRating'].indexOf(property) > -1) {
      let rating: string;
      let starAmount: number;

      if (property === 'checkIfHasRating') {
        let ratingRaw = affiliate.data.rating;
        return ratingRaw && ratingRaw > 0 ? true : false;
      }

      rating = affiliate.data.rating.toString();

      if (property === 'totalRatings') {
        return Number(affiliate?.data?.ratingNumber);
      }

      if (property === 'fullStars' || property === 'halfStars' || property === 'emptyStars') {
        return this.getRating(affiliate?.data, property);
      }
    } else if (property === 'seller') {
      return affiliate.viewName;
    } else if (property === 'url') {
      if (product.id == 'iHoJsK25sbY9nRfQSFfR') {
        return 'https://www.amazon.de/GL%C3%9CCKSWOLKE-Kinder-Aufbewahrungsboxen-Spielzeugkiste-Aufbewahrungsbox/dp/B08K97CXJV?_encoding=UTF8&pd_rd_w=NGFPy&pf_rd_p=07a6ea8f-9559-4d3e-86b6-bade19b6ee8e&pf_rd_r=JZD1JGMBYRHXFSX85D4A&pd_rd_r=2db35dcc-8eb3-4b06-b70f-5ec3efed2e8e&pd_rd_wg=RTiR4&linkCode=ll1&tag=hello-shelfy-21&linkId=99556c9cd5d2e47996264bf8f856932f&language=de_DE&ref_=as_li_ss_tl';
      } else {
        return this.getAffiliateLink(affiliate);
      }
    } else if (property === 'name') {
      return affiliate.name;
    } else if (property === 'hasShoppingCart') {
      return hasShoppingCart(affiliate.name);
    }
  }

  private defaultReturnings(property: string): number | number[] | null | boolean | string {
    if (property === 'fullStars' || property === 'halfStars' || property === 'emptyStars') {
      return [];
    } else if (property === 'checkIfHasRating' || property === 'hasSalePrice' || property === 'hasShoppingCart') {
      return false;
    } else if (property === 'higherPrice') {
      return null;
    } else if (property === 'currentPrice' || property === 'price' || property === 'totalRatings') {
      return 0;
    } else {
      // For 'seller', 'name', 'url'
      return '?';
    }
  }

  private getRating(affiliateProductData: AffiliateProductData, property: string): number[] {
    const rating = affiliateProductData.rating.toFixed(1);

    const delimiterIndex = rating.indexOf('.');
    const fullStarAmount = Number(rating.substring(0, delimiterIndex));
    const halfStarAmount = Math.round(Number(rating.substring(delimiterIndex + 1)) / 10);
    const emptyStarAmount = 5 - fullStarAmount - halfStarAmount;

    let starAmount = 0;

    if (property === 'fullStars') {
      starAmount = fullStarAmount;
    } else if (property === 'halfStars') {
      starAmount = halfStarAmount;
    } else if (property === 'emptyStars') {
      starAmount = emptyStarAmount;
    }

    return Array.from(Array(starAmount).keys());
  }

  getAffiliateLink(affiliate: MetaAffiliate) {
    let link = affiliate.data?.sellerUrl;

    switch (affiliate.name) {
      case AffiliatesEnum.AMAZON_US:
        link = this.getAffiliateLinkForAmazonUS(link);
        break;
      case AffiliatesEnum.AMAZON_DE:
        link = this.getAffiliateLinkForAmazonDE(link);
        break;
      case AffiliatesEnum.IKEA_DE:
        link = this.getAffiliateLinkForIkeaDE(link);
        break;
      case AffiliatesEnum.POCO_DE:
        link = this.getAffiliateLinkForPocoDE(link);
        break;
      case AffiliatesEnum.OTTO_DE:
        link = this.getAffiliateLinkForOttoDE(link);
        break;
      case AffiliatesEnum.WALMART_US:
        link = this.getAffiliateLinkForWalmartUS(link);
        break;
    }
    return link;
  }

  private getAffiliateLinkForAmazonDE(link: any): any {
    if (!link || link.length <= 0) {
      link = this.affiliateInformations.amazonDE.productFallbackUrl;
    }
    return `${link}?tag=${this.affiliateInformations.amazonDE.affiliateId}`;
  }

  private getAffiliateLinkForAmazonUS(link: any): any {
    if (!link || link.length <= 0) {
      link = this.affiliateInformations.amazonUS.productFallbackUrl;
    }
    return `${link}?tag=${this.affiliateInformations.amazonUS.affiliateId}`;
  }

  private getAffiliateLinkForIkeaDE(link: any): any {
    if (!link || link.length <= 0) {
      link = this.affiliateInformations.ikeaDE.productFallbackUrl;
    }
    return `${link}?tag=${this.affiliateInformations.ikeaDE.affiliateId}`;
  }

  private getAffiliateLinkForPocoDE(link: string) {
    if (!link || link.length <= 0) {
      link = this.affiliateInformations.pocoDE.productFallbackUrl;
    }
    return this.affiliateInformations.pocoDE.trackingUrl.replace('{{productUrl}}', encodeURIComponent(link));
  }

  private getAffiliateLinkForOttoDE(link: string) {
    if (!link || link.length <= 0) {
      link = this.affiliateInformations.ottoDE.productFallbackUrl;
    }
    return this.affiliateInformations.ottoDE.trackingUrl.replace('{{productUrl}}', encodeURIComponent(link));
  }

  private getAffiliateLinkForWalmartUS(link: any): any {
    if (!link || link.length <= 0) {
      link = this.affiliateInformations.walmartUS.productFallbackUrl;
    }
    return `${link}?tag=${this.affiliateInformations.walmartUS.affiliateId}`;
  }
}
