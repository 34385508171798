import { environment } from '../../../environments/environment';
import { AffiliatesEnum } from '../enums/affiliates.enum';
import { AffiliateInformation, MetaAffiliate } from '../interfaces/affiliate.interface';
import { Item } from '../interfaces/item.interface';
import { Product } from '../interfaces/product.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import * as Affiliates from '../../../assets/json/affiliate-informations.json';

export const getAffiliateData = (product: Product): MetaAffiliate => {
  const result: MetaAffiliate = { name: '', viewName: '', data: null };
  let bestAffiliate: string;

  // in case only one affiliate is available: use it
  if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] !== AffiliatesEnum.ALL) {
    return {
      name: environment.allowedAffiliates[0],
      viewName: parseAffiliateViewName(environment.allowedAffiliates[0]),
      data: product.affiliates[environment.allowedAffiliates[0]],
    };
  }

  // define all available affiliate meta data (TODO: could be moved to environment file later)
  const activeAffiliates: { value: AffiliatesEnum; active: boolean }[] = [
    { value: AffiliatesEnum.AMAZON_DE, active: product?.affiliates?.amazonDE?.active },
    { value: AffiliatesEnum.AMAZON_US, active: product?.affiliates?.amazonUS?.active },
    { value: AffiliatesEnum.IKEA_DE, active: product?.affiliates?.ikeaDE?.active },
    { value: AffiliatesEnum.POCO_DE, active: product?.affiliates?.pocoDE?.active },
    { value: AffiliatesEnum.OTTO_DE, active: product?.affiliates?.ottoDE?.active },
    { value: AffiliatesEnum.WALMART_US, active: product?.affiliates?.walmartUS?.active },
    { value: AffiliatesEnum.DEPOT_DE, active: product?.affiliates?.depotDE?.active },
  ]
    .filter((affiliate) => affiliate.active === true)
    .filter((affiliate) => {
      // for affiliate shops: only use their specific affiliate data
      if (environment.allowedAffiliates.includes(affiliate.value) || environment.allowedAffiliates[0] === AffiliatesEnum.ALL) {
        return true;
      }

      return false;
    });

  // otherwise, select the "best" affiliate of all available
  if (activeAffiliates.length < 1) {
    // TODO: Fix with #274
    console.warn('There is no active affiliate for this product', product);
    return null;
  } else if (activeAffiliates.length === 1) {
    bestAffiliate = activeAffiliates[0].value;
  } else {
    // If more than one affiliate is active
    // select active (!) affiliate with lowest product price
    let lowestPrice: number = Number.MAX_SAFE_INTEGER;
    for (const [affiliateName, affiliateData] of Object.entries(product.affiliates)) {
      if (!affiliateData.active || product.activeAffiliates.indexOf(affiliateName) === -1) {
        continue;
      }

      let currentPrice: number;
      if (affiliateData.priceSale && typeof affiliateData.priceSale === 'number') {
        currentPrice = affiliateData.priceSale;
      } else if (affiliateData.price && typeof affiliateData.price === 'number') {
        currentPrice = affiliateData.price;
      } else {
        console.error('Product has no valid price', product);
        currentPrice = -1;
      }

      if (currentPrice < lowestPrice) {
        lowestPrice = currentPrice;
        bestAffiliate = affiliateName;
      }
    }
  }

  result.data = product.affiliates[bestAffiliate];
  result.name = bestAffiliate;
  result.viewName = parseAffiliateViewName(bestAffiliate);

  return result;
};

export const hasShoppingCart = (affiliateId: AffiliatesEnum | string) => {
  const affiliateInformations: { [key: string]: AffiliateInformation } = Affiliates;

  switch (environment.allowedAffiliates[0]) {
    case AffiliatesEnum.ALL:
      return !!affiliateInformations[affiliateId].addToCartUrl;
    case AffiliatesEnum.OTTO_DE:
      return !!affiliateInformations[AffiliatesEnum.OTTO_DE].addToCartUrl;
  }
};

function parseAffiliateViewName(name: string): string {
  return name.toUpperCase().slice(0, -2);
}

export const isThereAnActiveAffiliate = (product: ShelfScenes | Item) => {
  for (const [key, value] of Object.entries(product.affiliates)) {
    if (value.active) {
      return value.active;
    }
  }
  return false;
};
