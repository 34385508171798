import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'affiliateLogoUrl',
})
export class AffiliateLogoUrlPipe implements PipeTransform {
  private affiliateLogos: any = {
    ikeaDE: './assets/imgs/ikea-logo.png',
    amazonDE: './assets/imgs/amazon-logo.png',
    amazonUS: './assets/imgs/amazon-logo.png',
    pocoDE: './assets/imgs/poco-logo.png',
    ottoDE: './assets/imgs/otto-logo.svg',
    walmartUS: './assets/imgs/walmart-logo.png',
    depotDE: './assets/imgs/depot-logo.png',
  };

  transform(affiliateName: string, ...args: unknown[]): unknown {
    return this.affiliateLogos[affiliateName];
  }
}
