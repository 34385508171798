import { Injectable } from '@angular/core';
import { collection, collectionData, Firestore } from '@angular/fire/firestore';
import { from, map, Observable } from 'rxjs';
import { AffiliatesEnum } from '../enums/affiliates.enum';
import { Affiliate } from '../interfaces/affiliate.interface';

@Injectable({
  providedIn: 'root',
})
export class AffiliateService {
  constructor(private firestore: Firestore) {}

  convertFromString(affiliateCode: string): AffiliatesEnum {
    switch (affiliateCode) {
      case 'amazonDE':
        return AffiliatesEnum.AMAZON_DE;
      case 'ikeaDE':
        return AffiliatesEnum.IKEA_DE;
      case 'pocoDE':
        return AffiliatesEnum.POCO_DE;
      case 'ottoDE':
        return AffiliatesEnum.OTTO_DE;
      case 'walmartUS':
        return AffiliatesEnum.WALMART_US;
      case 'depotDE':
        return AffiliatesEnum.DEPOT_DE;
    }
  }

  convertFromStringArray(affiliateCodes: string[]): AffiliatesEnum[] {
    return affiliateCodes.map((affiliateCode: string) => this.convertFromString(affiliateCode));
  }

  getAffiliates$(): Observable<Affiliate[]> {
    return from(collectionData(collection(this.firestore, 'affiliates'))).pipe(map((affiliates: Affiliate[]) => affiliates));
  }
}
