import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, Subject, takeUntil, withLatestFrom } from 'rxjs';
import { loginAction } from '../../core/actions/authentication.actions';
import { getOriginalUrl, isAuthenticated } from '../../core/reducers/authentication.reducer';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-anonymous-login',
  templateUrl: './anonymous-login.component.html',
  styleUrls: ['./anonymous-login.component.scss'],
})
export class AnonymousLoginComponent implements OnInit, OnDestroy {
  destroy$ = new Subject();

  constructor(public authService: AuthService, private router: Router, private store: Store) {}

  ngOnInit(): void {
    this.store.dispatch(loginAction({ originalUrl: this.router.getCurrentNavigation()?.extras?.state?.originalUrl }));

    this.store
      .select(isAuthenticated)
      .pipe(
        takeUntil(this.destroy$),
        filter((isAuthenticated: boolean) => isAuthenticated),
        withLatestFrom(this.store.select(getOriginalUrl))
      )
      .subscribe(([isAuthenticated, originalUrl]: [boolean, string | null]) => {
        this.router.navigateByUrl(originalUrl);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }
}
