<ion-header>
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="start" *ngIf="context === pickerContext">
      <ion-button (click)="handleClose()">
        <ion-icon name="arrow-back-outline" class="grey-icon arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="product-title">{{ product?.name }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="desktop-container">
    <div class="detail-container">
      <!--
        Content in the order of:
        Manufacturer, Badges,
        Product-image,
        Meta-Content,
        Description,
        Open Details Site in New Tab
      -->
      <!-- Manufacturer -->
      <div class="subtitle">{{ product?.manufacturer }}</div>
      <!-- Badges -->
      <div class="badge-wrapper">
        <app-product-badge *ngIf="product | isNew" [text]="'general.new' | transloco"
          priority="primary"></app-product-badge>
        <app-product-badge *ngIf="product | affiliate: 'hasSalePrice'" [text]="'general.sale' | transloco"
          priority="secondary"></app-product-badge>
      </div>

      <!-- Product-image -->
      <ion-img class="product-image"
        src="{{ product | product: productCategory:'image' | imageSize: '250x250' }}"></ion-img>

      <!--
        Meta-Content:
        Left-side,
        Right-side
      -->
      <div class="meta-content">
        <!--
          Left-side:
          Seller,
          Sizes,
          Color Manufacturer,
          Materials,
          Rating
        -->
        <div class="left-content">
          <!-- Seller -->
          <div class="seller">
            <div class="seller-label">{{ 'product.seller' | transloco }}</div>
            <a [href]="product | affiliate: 'url'" target="_blank">
              <img [src]="product | affiliate: 'name' | affiliateLogoUrl" class="affiliate-thumbnail" />
            </a>
          </div>
          <!-- Sizes -->
          <div class="sizes" *ngIf="product?.width && product?.height">
            <div class="sizes-label">
              {{ 'product.sizes' | transloco }}
            </div>
            <div class="sizes-content">
              <div *ngIf="product?.outer !== null && product?.outer !== undefined;else normalSizes" class="outer">
                <span *ngIf="product?.outer.height > 0;else normalHeight">
                  {{ product?.outer.height | amountConverter }}
                </span>
                <ng-template #normalHeight>
                  {{ product?.height | amountConverter }}
                </ng-template>
                <span *ngIf="product?.outer.width > 0;else normalWidth">
                  x {{ product?.outer.width | amountConverter }}
                </span>
                <ng-template #normalWidth>
                  x {{ product?.width | amountConverter }}
                </ng-template>
                <span *ngIf="product?.depth"> x {{ product?.depth | amountConverter }}</span> cm
              </div>
              <ng-template #normalSizes>
                {{ product?.height | amountConverter }} x {{ product?.width | amountConverter }}
                <span *ngIf="product?.depth"> x {{ product?.depth | amountConverter }}</span> cm
              </ng-template>
            </div>
          </div>
          <!-- Color Manufacturer -->
          <div class="color-manufacturer" *ngIf="product?.colorManufacturer">
            <div class="color-manufacturer-label">
              {{ 'product.color-manufacturer' | transloco }}
            </div>
            <div class="materials-content">
              <span>
                {{ product?.colorManufacturer }}
              </span>
            </div>
          </div>
          <!-- Materials -->
          <div class="materials" *ngIf="product | product: 'hasMaterial'">
            <div class="materials-label">
              {{ 'product.materials' | transloco }}
            </div>
            <div class="materials-content">
              <div *ngFor="let material of product?.materials">
                <span>
                  {{ 'materials.' + material | transloco }}
                </span>
              </div>
            </div>
          </div>
          <!-- Rating -->
          <app-product-rating [product]="product" *ngIf="product | affiliate: 'checkIfHasRating'"></app-product-rating>
        </div>
        <div class="right-content">
          <div *ngIf="product | affiliate: 'hasSalePrice'" class="higher-price"
            [innerHTML]="product | affiliate: 'higherPrice' | currencyFormat: 'EUR':'de'"></div>
          <div class="current-price" [innerHTML]="product | affiliate: 'currentPrice' | currencyFormat: 'EUR':'de'">
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="description">
        <div class="description-label">{{ 'product.description' | transloco }}</div>
        <div class="description-content" [innerHTML]="product?.description"></div>
      </div>

      <!-- Go to Affiliate Product Page -->
      <div class="additional-information">
        <a [href]="product | affiliate: 'url'" target="_blank">{{ 'shelf-item-picker.details.additional-information' |
          transloco }}</a>
      </div>

      <!-- Open Details Site in New Tab -->
      <div class="additional-information">
        <a [href]="product | product: productCategory:'url'" target="_blank">{{ 'product.open-in-new-tab' | transloco
          }}</a>
      </div>
    </div>
  </div>

  <div class="design-now" *ngIf="!(context === pickerContext)">
    <ion-button class="design-now" [routerLink]="'/shelfdesigner'">
      {{ 'landing-page.design-now' | transloco }}
    </ion-button>
  </div>

  <ion-fab slot="fixed" vertical="bottom" horizontal="center">
    <ion-button *ngIf="context === pickerContext" (click)="handleChoose()" fill="solid" shape="round"
      class="choose-button">
      {{ 'general.choose' | transloco }}
    </ion-button>
  </ion-fab>
</ion-content>
