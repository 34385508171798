import { environment } from '../../../environments/environment';
import { AffiliateProductData } from '../interfaces/affiliate-product-data.interface';
import { Item } from '../interfaces/item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';

export const getPriceOfProduct = (product: AffiliateProductData, property: string): any => {
  // check if sale price exists
  let normalPrice: number = product.price;
  let specialPrice: number = product.priceSale;

  if (property === 'higherPrice') {
    return typeof specialPrice === 'number' && specialPrice < normalPrice ? normalPrice : null;
  } else if (property === 'currentPrice') {
    return typeof specialPrice === 'number' ? specialPrice : normalPrice;
  } else if (property === 'hasSalePrice') {
    return typeof specialPrice === 'number' ? true : false;
  } else if (property === 'price') {
    return typeof specialPrice === 'number' ? specialPrice : normalPrice;
  }
  return -1;
};

/**
 * Returns a specific product price. Currently the lowest price is used. Only regard active affiliates.
 * TODO: Define a better metric which price to use
 * @param product A shelf or item
 * @returns The lowest available price of all active affiliates
 */
export const getBestProductPrice = (product: ShelfScenes | Item): number => {
  let lowestPrice: number = Number.MAX_SAFE_INTEGER;

  if (product.activeAffiliates.length > 0) {
    product.activeAffiliates.forEach((affiliateName: string) => {
      const affiliateData: AffiliateProductData = product.affiliates[affiliateName];
      if (typeof affiliateData.priceSale === 'number' && affiliateData.priceSale < lowestPrice) {
        lowestPrice = affiliateData.priceSale;
      } else if (typeof affiliateData.price === 'number' && affiliateData.price < lowestPrice) {
        lowestPrice = affiliateData.price;
      }
    });
  } else {
    console.error('[Shelfy] Product has no valid affiliate (%s)', environment.allowedAffiliates, product);
    return 0;
  }

  if (lowestPrice === Number.MAX_SAFE_INTEGER) {
    console.error('[Shelfy] Product has no valid price', product);
    return 0;
  }

  return lowestPrice;
};

// internal functions to calculate lowest / highest price
export const getLowestPrice = (products: ShelfScenes[] | Item[], noRounding?: boolean): number | undefined => {
  if (products.length === 0) {
    return undefined;
  }

  let lowestPrice = Number.MAX_SAFE_INTEGER;
  products.forEach((product: ShelfScenes | Item) => {
    const currentPrice: number = getBestProductPrice(product);
    if (currentPrice < lowestPrice) {
      lowestPrice = currentPrice;
    }
  });
  return noRounding ? lowestPrice : Math.ceil(lowestPrice);
};

export const getHighestPrice = (products: ShelfScenes[] | Item[], noRounding?: boolean): number => {
  if (products.length === 0) {
    return undefined;
  }

  let highestPrice = 0;
  products.forEach((product: ShelfScenes | Item) => {
    const currentPrice: number = getBestProductPrice(product);
    if (currentPrice > highestPrice) {
      highestPrice = currentPrice;
    }
  });
  return noRounding ? highestPrice : Math.ceil(highestPrice);
};
