import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  loadChooseHacksItems,
  loadHacksItemsSucceededAction,
  toggleChooseHacksItem,
  toggleHacksTypeAction,
} from '../actions/hacks-picker.actions';
import { TypesEnum } from '../enums/types.enum';
import { Item } from '../interfaces/item.interface';

export interface HacksState {
  items: Item[];
  activeTypes: TypesEnum[];
  hacksToBuyIds: string[];
}

export const initialState: HacksState = {
  items: [],
  activeTypes: [],
  hacksToBuyIds: [],
};

export const shelfPickerReducer = createReducer(
  initialState,
  on(toggleHacksTypeAction, (state: HacksState, action) => {
    const activeTypes = [...state.activeTypes];

    if (!activeTypes.includes(action.hacksType)) {
      activeTypes.push(action.hacksType);
    } else {
      activeTypes.splice(activeTypes.indexOf(action.hacksType), 1);
    }

    return { ...state, activeTypes };
  }),

  on(loadHacksItemsSucceededAction, (state: HacksState, action) => {
    return { ...state, items: action.items };
  }),

  on(toggleChooseHacksItem, (state: HacksState, action) => {
    const hacksToBuy_Id = [...state.hacksToBuyIds];

    if (!hacksToBuy_Id.includes(action.item.id)) {
      hacksToBuy_Id.push(action.item.id);
    } else {
      hacksToBuy_Id.splice(hacksToBuy_Id.indexOf(action.item.id), 1);
    }
    return { ...state, hacksToBuyIds: hacksToBuy_Id };
  }),

  on(loadChooseHacksItems, (state: HacksState, action) => {
    return { ...state, hacksToBuyIds: action.items };
  })
);

export function reducer(state: HacksState = initialState, action: Action): HacksState {
  return shelfPickerReducer(state, action);
}

export const hacksPickerState = createFeatureSelector<HacksState>('hacks-picker');
export const getActiveTypesHacks = createSelector(hacksPickerState, (state: HacksState) => state.activeTypes);
export const getHacksItems = createSelector(hacksPickerState, (state: HacksState) => state.items);
export const getHacksItemsToBuyIds = createSelector(hacksPickerState, (state: HacksState) => state.hacksToBuyIds);
