import { createAction, props } from '@ngrx/store';
import { AppUser } from '../../login/entities/user.interface';

const actionType = '[Authentication]';

export const loginAction = createAction(`${actionType} Login`, props<{ originalUrl?: string }>());
export const loginSucceededAction = createAction(`${actionType} Login succeeded`, props<{ user: AppUser }>());
export const loginFailedAction = createAction(`${actionType} Login failed`, props<{ error: Error }>());

export const logoutAction = createAction(`${actionType} Logging out`);
