import { Component, Input, OnInit } from '@angular/core';
import { PickerTypes } from '../../core/enums/picker-types.enum';
import { ProductCategories } from '../../core/enums/product-categories.enum';
import { LooseObject } from '../../core/interfaces/loose-object.interface';
import { ShelfItemPickerComponent } from '../../shelf-designer/shelf-item-picker/shelf-item-picker.component';
import { ShelfPickerComponent } from '../../shelf-designer/shelves-scenes-hacks-picker/shelf-picker/shelf-picker.component';

@Component({
  selector: 'app-modal-nav-container',
  templateUrl: './modal-nav-container.component.html',
  styleUrls: ['./modal-nav-container.component.scss'],
})
export class ModalNavContainerComponent implements OnInit {
  @Input() pickerType?: PickerTypes;

  rootPage: unknown;
  rootParams: LooseObject;
  productCategory: ProductCategories;

  constructor() {}

  ngOnInit(): void {
    if (this.pickerType) {
      switch (this.pickerType) {
        case PickerTypes.ShelfColor:
        case PickerTypes.ShelfType:
          this.rootPage = ShelfPickerComponent;
          this.productCategory = ProductCategories.Shelf;

          this.rootParams = {
            pickerType: this.pickerType,
          };
          break;
        case PickerTypes.Item:
          this.rootPage = ShelfItemPickerComponent;
          this.productCategory = ProductCategories.Item;
          this.rootParams = {};
          break;
      }
    }
  }
}
