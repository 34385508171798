import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppUser } from '../../login/entities/user.interface';
import { AuthService } from '../../login/services/auth.service';
import { loginAction, loginFailedAction, loginSucceededAction, logoutAction } from '../actions/authentication.actions';
import { hideAppMenuAction } from '../actions/general.actions';
import { loadSettingsAndDesignForUserAction } from '../actions/shelf-designer.actions';

@Injectable()
export class AuthenticationEffects {
  constructor(private actions$: Actions, private store: Store, private authService: AuthService) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginAction),
      switchMap(() => this.authService.signInAnonymously$()),
      map((user: AppUser) => loginSucceededAction({ user })),
      catchError((error, caught) => {
        console.error('Error while logging in:', error);
        this.store.dispatch(loginFailedAction({ error }));
        return caught;
      })
    )
  );

  loginSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginSucceededAction),
      map((action) => loadSettingsAndDesignForUserAction({ user: action.user }))
    )
  );

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutAction),
      switchMap(() => this.authService.signOut$()),
      map(() => hideAppMenuAction())
    )
  );
}
