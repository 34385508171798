<ion-app #ionapp [ngClass]="{ 'is-desktop-plaform': desktopPlatform$ | async, 'split-mode': splitMode$ | async }">
  <ion-router-outlet main *ngIf="!showLandscapeWarning"></ion-router-outlet>

  <div *ngIf="showLandscapeWarning" class="landscape-warning">
    <img class="app-logo" src="../assets/imgs/shelfy-logo.png" />
    <div class="warn-message">
      <span class="row-bold">{{ 'landscape-warning.warning-row-1-bold' | transloco }}</span>
      <span class="row-normal">{{ 'landscape-warning.warning-row-1-normal' | transloco }}</span>
      <br />
      <span class="row-normal padding-to-row1">{{ 'landscape-warning.warning-row-2' | transloco }}</span>
    </div>
    <div class="question">
      <span>{{ 'landscape-warning.question' | transloco }}</span>
    </div>
    <img class="shelfy" src="../assets/imgs/shelfy-cat.webp" />
  </div>
</ion-app>
