<div class="designer-options-bar">
  <div class="option create-design-button" (click)="handleCreateNewDesign()">
    <ng-container [ngSwitch]="environment.allowedAffiliates[0]">
      <img *ngSwitchCase="affiliatesEnum.ALL" src="./assets/imgs/plus-icon.svg" />
      <img *ngSwitchCase="affiliatesEnum.OTTO_DE" src="./assets/imgs/plus-icon-otto.svg" />
    </ng-container>
    <ion-label class="option-text">{{ 'general.new' | transloco }}</ion-label>
  </div>

  <div class="option price" (click)="handleShowProductList()">
    <ion-label class="price-tag" [innerHTML]="totalPrice$ | async | currencyFormat: 'EUR':'de'"
      [ngClass]="{ 'do-not-buy': !(totalPrice$ | async) }"></ion-label>
    <ion-label class="option-text">{{ 'general.go-to-productlist' | transloco }}</ion-label>
  </div>

  <div class="option share-button">
    <app-social-share-buttons></app-social-share-buttons>
  </div>
</div>
