import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { Card } from '../../core/classes/card.class';
import { OrientationEnum } from '../../core/enums/orientation.enum';
import { SkeletonItem } from '../../core/interfaces/skeleton-item.interface';

@Component({
  selector: 'app-card-picker',
  templateUrl: './card-picker.component.html',
  styleUrls: ['./card-picker.component.scss'],
})
export class CardPickerComponent implements OnInit {
  @Input() cards!: Array<Card>;
  @Input() orientation?: OrientationEnum;
  @Input() cardContentTemplateRef?: TemplateRef<unknown>;
  @Output() onSelectQuickFilter: EventEmitter<{ name: string; optionId: string }> = new EventEmitter();
  @Output() onSelectCard: EventEmitter<unknown> = new EventEmitter();

  // virtual scroller
  private _virtualScroller: VirtualScrollerComponent;
  skeletonItems: Array<SkeletonItem>;
  Orientation = OrientationEnum;

  @ViewChild('scroll')
  set virtualScroller(virtualScroller: VirtualScrollerComponent) {
    const firstInit: boolean = virtualScroller !== undefined && (this._virtualScroller === undefined || this._virtualScroller === null);

    this._virtualScroller = virtualScroller;

    if (firstInit) {
      this.recalculateVirtualScroller();
    }
  }
  get virtualScroller(): VirtualScrollerComponent {
    return this._virtualScroller;
  }

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    // generate skeleton items
    this.skeletonItems = new Array<SkeletonItem>();
    for (let i = 0; i < 50; i++) {
      const frame = { skeletonItem: true } as any;
      this.skeletonItems.push(frame);
    }

    // set default orientation if not exists
    if (this.orientation === undefined) {
      this.orientation = OrientationEnum.Vertical;
    }
  }

  handleSelect(card: Card): void {
    console.log('>>> handle select', card);
    // this.onSelectCard.emit(card);
  }

  // virtual scroller stuff
  private recalculateVirtualScroller() {
    if (this.virtualScroller) {
      this.virtualScroller.invalidateAllCachedMeasurements();
    }
  }

  handleVirtualScrollerUpdate(): void {
    this.changeDetectorRef.detectChanges();
  }

  handleScrollToTop(): void {
    this.virtualScroller.scrollToIndex(0);
  }
}
