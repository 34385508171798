import { createAction, props } from '@ngrx/store';
import { BottomPanePositionsEnum } from '../enums/bottom-pane-positions.enum';
import { PickerTypes } from '../enums/picker-types.enum';
import { ShelfColorsEnum } from '../enums/shelf-colors.enum';
import { ShelfTypesEnum } from '../enums/shelf-types.enum';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { ShelfCategory } from '../interfaces/shelf-category.interface';

const actionType = '[Shelf-Picker]';

export const loadShelfTypesAction = createAction(`${actionType} Load shelf types`);
export const loadShelfTypesSucceededAction = createAction(`${actionType} Load shelf types succedded`, props<{ shelves: ShelfScenes[] }>());
export const loadShelfTypesFailedAction = createAction(`${actionType} Load shelf types failed`, props<{ error: any }>());

export const bottomPanelPositionChangedAction = createAction(
  `${actionType} Bottom Panel Position changed`,
  props<{ position: BottomPanePositionsEnum }>()
);

export const setBottomPanelPositionAction = createAction(
  `${actionType} Set Bottom Panel Position`,
  props<{ position: BottomPanePositionsEnum }>()
);

export const setSelectedCategoryAction = createAction(`${actionType} Set selected category`, props<{ selectedCategory: ShelfCategory }>());

export const loadShelfColorsAction = createAction(`${actionType} Load shelf colors by shelf type`, props<{ shelfType: string }>());
export const loadShelfColorsSucceededAction = createAction(
  `${actionType} Load shelf colors by shelf type succeeded`,
  props<{ shelves: ShelfScenes[] }>()
);
export const loadShelfColorsFailedAction = createAction(`${actionType} Load shelf colors by shelf type failed`, props<{ error: Error }>());

export const showShelfDetailsAction = createAction(`${actionType} Show shelf details`, props<{ shelf: ShelfScenes }>());
export const leaveShelfDetailsAction = createAction(`${actionType} Leave shelf details`, props<{ pickerType: PickerTypes }>());

export const toggleShelfTypeAction = createAction(`${actionType} Toggle shelf type`, props<{ shelfType: ShelfTypesEnum }>());
export const toggleShelfColorAction = createAction(`${actionType} Toggle shelf color`, props<{ shelfColor: ShelfColorsEnum }>());
