import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  doc,
  docData,
  Firestore,
  getDocs,
  limit,
  query,
  QueryConstraint,
  QueryDocumentSnapshot,
  QuerySnapshot,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { forkJoin, from, iif, of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, delay, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AffiliatesEnum } from '../enums/affiliates.enum';
import { HacksTypesEnum } from '../enums/hacks-types.enum';
import { MaterialsEnum } from '../enums/materials.enum';
import { SortCriteriaEnum } from '../enums/sort.enum';
import { TypesEnum } from '../enums/types.enum';
import { getAffiliateData } from '../functions/affiliates';
import { getBestProductPrice, getHighestPrice, getLowestPrice } from '../functions/price';
import { isNewProduct } from '../helper/products.helper';
import { AffiliateProductData } from '../interfaces/affiliate-product-data.interface';
import { AsinResult2 } from '../interfaces/asin-result2.interface';
import { Color } from '../interfaces/color.interface';
import { Design, ExtendedDesign } from '../interfaces/design.interface';
import { FilteredItems, Item } from '../interfaces/item.interface';
import { FilterAdminItems, LoadItemsConfiguration } from '../interfaces/load-items-configuration.interface';
import { ProductFilterOptions } from '../interfaces/product-filter-options.interface';
import { Product } from '../interfaces/product.interface';
import { ExtendedShoppingCartItem, ShoppingCartItem } from '../interfaces/shopping-cart-item.interface';
import { AffiliateService } from './affiliate.service';
import { ProductService } from './product.service';

@Injectable({
  providedIn: 'root',
})
export class ItemService {
  private cachedItems: Item[];
  private cachedFilteredItems: Item[];
  private cachedFilteredHacksItems: Item[];
  private cachedFilterOptions: ProductFilterOptions;
  private lastItemIndex: number = 0;
  private lastItemSnapshot: QueryDocumentSnapshot<any | Item>;
  affiliatesEnum = AffiliatesEnum;

  constructor(
    private firestore: Firestore,
    private http: HttpClient,
    private productService: ProductService,
    private affiliateService: AffiliateService,
  ) { }

  hasFilterChanged(filterOptions: ProductFilterOptions): boolean {
    return JSON.stringify(this.cachedFilterOptions) !== JSON.stringify(filterOptions);
  }

  getItem$(id: string): Observable<Item> {
    return docData(doc(this.firestore, 'items', id), { idField: 'id' }).pipe(
      take(1),
      map((item) => item as Item)
    );
  }

  getAllActiveItems$(): Observable<Item[]> {
    return of(null).pipe(
      mergeMap(() =>
        iif(
          () => this.cachedItems !== undefined,
          of(this.cachedItems),
          from(getDocs(query(collection(this.firestore, 'items'), where('active', '==', true)))).pipe(
            map((querySnapshot: QuerySnapshot) => {
              const items: Item[] = querySnapshot.docs.map((doc) => {
                const item: Item = doc.data() as Item;
                item.activeAffiliates = [];
                for (const [affiliateName, affiliateData] of Object.entries(item.affiliates)) {
                  if (affiliateData.active) {
                    if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] === this.affiliatesEnum.ALL) {
                      item.activeAffiliates.push(affiliateName);
                    } else {
                      // Only for specific affiliate-build
                      if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] && environment.allowedAffiliates[0] === this.affiliateService.convertFromString(affiliateName)) {
                        item.activeAffiliates.push(affiliateName);
                      }
                    }
                  }
                }
                return item;
              });
              this.cachedItems = items.filter((item: Item) => item.activeAffiliates.length > 0 && getBestProductPrice(item) > 0);
              return items;
            })
          )
        )
      )
    );
  }

  // ! Only used in Shelf-Item-Picker !!!
  getFilteredItems$(filterOptions: ProductFilterOptions, numberOfItems?: number): Observable<FilteredItems> {
    return this.getAllActiveItems$().pipe(
      delay(0), // necessary to trigger the infinite-scroll directive
      switchMap((items: Item[]) =>
        JSON.stringify(filterOptions) === JSON.stringify(this.cachedFilterOptions) ? of(this.cachedFilteredItems) : of(items)
      ),
      map((items: Item[]) => {
        if (!this.hasFilterChanged(filterOptions)) {
          // load the next subset of items (for lazy loading)
          this.lastItemIndex += numberOfItems;
          return {
            items: numberOfItems ? items.slice(this.lastItemIndex, this.lastItemIndex + numberOfItems) : items,
            total: items.length,
            lowestPrice: getLowestPrice(this.cachedItems),
            highestPrice: getHighestPrice(this.cachedItems),
          };
        }

        this.cachedFilterOptions = { ...filterOptions };
        const filteredItems: Item[] = [];

        // general loop over all items
        for (let i = 0; i < items.length; i++) {
          const currentItem: Item = { ...items[i] };

          // check for partner shops
          if (filterOptions.affiliates.length === 0) {
            // abort item filtering, because no partner shop is active
            return {
              items: [],
              total: 0,
              lowestPrice: undefined,
              highestPrice: undefined,
            };
          } else {
            // update active affiliates
            currentItem.activeAffiliates = currentItem.activeAffiliates.filter((affiliateName: string) =>
              filterOptions.affiliates.includes(affiliateName)
            );

            // check for filtered affiliates
            if (currentItem.activeAffiliates.length === 0) {
              continue;
            }
          }

          // check for type
          if (filterOptions.types.length > 0 && filterOptions.types.indexOf(currentItem.type as TypesEnum) === -1) {
            continue;
          }

          // check for themes
          if (filterOptions.theme && currentItem.themes.indexOf(filterOptions.theme) === -1) {
            continue;
          }

          // check for sale price
          if (filterOptions.sale) {
            let hasPriceSale: boolean = false;
            const affiliate = getAffiliateData(currentItem);
            if (typeof affiliate.data.priceSale === 'number') {
              hasPriceSale = true;
            }

            if (!hasPriceSale) {
              continue;
            }
          }

          // check for min / max price (if exist)
          if (filterOptions.prices[0] !== undefined && filterOptions.prices[1] !== undefined) {
            let priceFound: boolean = false;
            currentItem.activeAffiliates.forEach((affiliateName: string) => {
              const affiliateData: AffiliateProductData = currentItem.affiliates[affiliateName];
              let currentPrice: number;
              if (affiliateData.priceSale && typeof affiliateData.priceSale === 'number') {
                currentPrice = affiliateData.priceSale;
              } else {
                currentPrice = affiliateData.price;
              }

              if (Math.ceil(currentPrice) >= filterOptions.prices[0] && Math.ceil(currentPrice) <= filterOptions.prices[1]) {
                priceFound = true;
                return;
              }
            });

            if (!priceFound) {
              continue;
            }

            // check for colors
            if (filterOptions.hasOwnProperty('colors') && Array.isArray(filterOptions.colors) && filterOptions.colors.length > 0) {
              if (currentItem.hasOwnProperty('color') && !filterOptions.colors.find((color: Color) => color.name === currentItem.color)) {
                continue;
              }
            }

            // check for materials
            if (
              filterOptions.materials.length > 0 &&
              !currentItem.materials?.some((material: MaterialsEnum) => filterOptions.materials.includes(material))
            ) {
              continue;
            }
          }

          // Filter Hacks-Items out
          const hacksTypes: HacksTypesEnum[] = Object.values(HacksTypesEnum) as HacksTypesEnum[];
          let foundHacks: boolean = false;
          for (let i: number = 0; i < hacksTypes.length; i++) {
            if (currentItem.type === hacksTypes[i]) {
              foundHacks = true;
              break;
            }
          }

          if (!foundHacks) {
            // add current item to final items array
            filteredItems.push(currentItem);
          }
        }

        // sort final items
        switch (filterOptions.sortCriterion) {
          case SortCriteriaEnum.New:
            filteredItems.sort((a, b) => {
              const dateA = a.lastModified ? a.lastModified.seconds : a.dateAdded.seconds;
              const dateB = b.lastModified ? b.lastModified.seconds : b.dateAdded.seconds;

              return dateA < dateB ? 1 : -1;
            });
            break;
          case SortCriteriaEnum.Popularity:
            filteredItems.sort((a, b) => {
              const aIsNewProduct = isNewProduct(a);
              const bIsNewProduct = isNewProduct(b);
              if (aIsNewProduct && !bIsNewProduct) return 1;
              else if (!aIsNewProduct && bIsNewProduct) return -1;
              else return a.usageCount < b.usageCount ? 1 : -1;
            });
            break;
          case SortCriteriaEnum.PriceAscending:
            filteredItems.sort((a, b) => (this.productService.getPrice(a) > this.productService.getPrice(b) ? 1 : -1));
            break;
          case SortCriteriaEnum.PriceDescending:
            filteredItems.sort((a, b) => (this.productService.getPrice(a) > this.productService.getPrice(b) ? -1 : 1));
            break;
          case SortCriteriaEnum.CustomerRating:
            filteredItems.sort((a, b) => (this.productService.getCustomerRating(a) > this.productService.getCustomerRating(b) ? -1 : 1));
            break;
          default:
            console.error(`Products could not be sorted by '${filterOptions.sortCriterion}'`);
        }

        this.lastItemIndex = 0;
        this.cachedFilteredItems = filteredItems;

        return {
          items: this.cachedFilteredItems.slice(0, numberOfItems),
          total: this.cachedFilteredItems.length,
          lowestPrice: getLowestPrice(this.cachedItems),
          highestPrice: getHighestPrice(this.cachedItems),
        };
      })
    );
  }

  getFilteredHacks$(filterOptions: TypesEnum[], numberOfItems?: number): Observable<Item[]> {
    return this.getAllActiveItems$().pipe(
      delay(0), // necessary to trigger the infinite-scroll directive
      // switchMap((items: Item[]) =>
      //   JSON.stringify(filterOptions) === JSON.stringify(this.cachedFilterOptions) ? of(this.cachedFilteredItems) : of(items)
      // ),
      map((items: Item[]) => {
        let filteredItems: Item[] = [];

        if (filterOptions.length === 0) {
          // Filter for all hacks

          // Filter Hacks-Items out
          // general loop over all items
          const hacksTypes: HacksTypesEnum[] = Object.values(HacksTypesEnum) as HacksTypesEnum[];
          for (let i = 0; i < items.length; i++) {
            const currentItem: Item = { ...items[i] };

            // check for type
            if (hacksTypes.indexOf(currentItem.type as HacksTypesEnum) === -1) {
              continue;
            }
            filteredItems.push(currentItem);
          }
        } else {
          // Filter for only the choosen once
          // general loop over all items
          for (let i = 0; i < items.length; i++) {
            const currentItem: Item = { ...items[i] };

            // check for type
            if (filterOptions.indexOf(currentItem.type as TypesEnum) === -1) {
              continue;
            }
            filteredItems.push(currentItem);
          }
        }

        this.lastItemIndex = 0;
        this.cachedFilteredHacksItems = filteredItems;

        return this.cachedFilteredHacksItems;
      })
    );
  }

  // ! Only used in ADMIN-TOOL
  getItemsForAdmin$(config: LoadItemsConfiguration, cachedItems: Item[], filterAdminItems?: FilterAdminItems): Observable<Item[]> {

    // Only on reset = true or on init
    if ((config.reset !== undefined && config.reset) || cachedItems.length <= 0) {

      let queryConstraints: QueryConstraint[] = [];
      let queryRef = query(collection(this.firestore, 'items'), ...queryConstraints);

      return from(getDocs(queryRef)).pipe(
        map((querySnapshot: QuerySnapshot) => {
          if (querySnapshot.docs.length > 0) {
            this.lastItemSnapshot = querySnapshot.docs[querySnapshot.docs.length - 1];
          }
          return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        }),
        map((items: Item[]) =>
          items.filter((item) => {
            let succ: boolean = true;

            if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] !== this.affiliatesEnum.ALL) {
              // For a specific affiliate
              succ = succ && item.affiliates[environment.allowedAffiliates[0]]?.active;
            }

            return succ;
          })
        )
      );
    } else {
      // Do the normal filter
      return of(cachedItems).pipe(
        map((items: Item[]) =>
          items.filter((item) => {
            let succ: boolean = true;

            if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] !== this.affiliatesEnum.ALL) {
              // For a specific affiliate
              succ = succ && item.affiliates[environment.allowedAffiliates[0]]?.active;
            }

            if (filterAdminItems!.color !== undefined && filterAdminItems!.color.length > 0) {
              succ = succ && item.color === filterAdminItems!.color;
            }

            if (filterAdminItems!.affiliateId !== undefined && filterAdminItems!.affiliateId.length > 0) {
              succ = succ && item.affiliates[filterAdminItems!.affiliateId]?.active == true;
            }

            if (filterAdminItems!.onlyActiveItems !== undefined && filterAdminItems!.onlyActiveItems) {
              succ = succ && item.active;
            }

            return succ;
          })
        )
      )
    }
  }

  getSubsetOfItems$(itemIds: string[]): Observable<Item[]> {
    if (itemIds?.length > 0) {
      // split query in chunks of size <=10
      let queries: Observable<Item[]>[] = [];
      const loops = Math.ceil(itemIds.length / 10);
      for (let i = 0; i < loops; i++) {
        queries.push(
          collectionData(query(collection(this.firestore, 'items'), where('id', 'in', itemIds.splice(0, 10))), { idField: 'id' }).pipe(
            take(1),
            map((items) => items as Item[])
          )
        );
      }
      return forkJoin(queries).pipe(map((items: Array<Item[]>) => items.flat()));
    } else return of([]);
  }

  getSubsetOfItems$_(itemIds: string[]): Observable<Item[]> {
    let finalItems: Item[] = [];
    return of(itemIds).pipe(
      switchMap((itemIds: string[]) => {
        let obs: Observable<unknown>[] = [];

        itemIds.forEach((itemId: string) => {
          obs.push(
            this.getItem$(itemId).pipe(
              map((item: Item) => item),
              tap((item: Item) => {
                finalItems.push(item);
              })
            )
          );
        });
        return forkJoin(obs);
      }),
      map((res) => {
        return finalItems;
      }),

      catchError((error: Error) => {
        console.error('Error while loading subset of items', error);
        throw error;
      })
    );
  }

  getExtendedShoppingCartItems$(cartItems: ShoppingCartItem[]): Observable<ExtendedShoppingCartItem[]> {
    // TODO: remove this if statement, just needed for old designs with extendedshoppingcartitems in firestore
    return this.getSubsetOfItems$(
      cartItems.slice().map((cartItem) => (cartItem.itemId ? cartItem.itemId : (cartItem as any).item.id))
    ).pipe(
      map((items: Item[]) => {
        const extendedShoppingCartItems: ExtendedShoppingCartItem[] = cartItems.slice().map(
          (cartItem) =>
          ({
            item: items.find((item) => item.id === cartItem.itemId),
            slotnumber: cartItem.slotnumber,
            slotsToBuy: cartItem.slotsToBuy,
          } as ExtendedShoppingCartItem)
        );

        return extendedShoppingCartItems;
      }),
      catchError((error: Error) => {
        console.error('Error while getting extendedShoppingCartItems', error);
        throw error;
      })
    );
  }

  getShoppingCartItems$(cartItems: ExtendedShoppingCartItem[]): Observable<ShoppingCartItem[]> {
    return of(cartItems).pipe(
      map((cartItems: ExtendedShoppingCartItem[]) =>
        cartItems.slice().map((extendedItem: ExtendedShoppingCartItem) => ({
          itemId: extendedItem.item.id,
          slotnumber: extendedItem.slotnumber,
          slotsToBuy: extendedItem.slotsToBuy,
        }))
      )
    );
  }

  getExtendedSavedConfiguration$(config: Design): Observable<ExtendedDesign> {
    return of(null).pipe(
      switchMap(() =>
        iif(
          () => config.shoppingCartItems != null && config.shoppingCartItems?.length > 0,
          this.getExtendedShoppingCartItems$(config.shoppingCartItems).pipe(
            map((extendedShoppingCartItems: ExtendedShoppingCartItem[]) => {
              const extendedSavedShelfConfig: ExtendedDesign = {
                ...config,
                shelfColor: config.shelfColor ?? '',
                shoppingCartItems: extendedShoppingCartItems,
              };
              return extendedSavedShelfConfig;
            })
          ),
          of({ ...config, shelfColor: config.shelfColor ?? '', shoppingCartItems: [] })
        ).pipe(
          catchError((error: Error) => {
            console.error('Error while getting extendedSavedShelfConfig', error);
            throw error;
          })
        )
      )
    );
  }

  getExtendedSavedConfigurations$(design: Design[]): Observable<ExtendedDesign[]> {
    return of(design).pipe(
      map((design: Design[]) => {
        let extendedConfigurationsObs: Observable<ExtendedDesign>[] = [];
        design.forEach((config: Design) => {
          extendedConfigurationsObs.push(this.getExtendedSavedConfiguration$(config));
        });
        return extendedConfigurationsObs;
      }),
      switchMap((obs: Observable<ExtendedDesign>[]) => {
        if (obs.length > 0) {
          return forkJoin(obs);
        } else return of([]);
      }),
      catchError((error: Error) => {
        console.error('Error while getting extendedSavedShelfConfig array: ', error);
        throw error;
      })
    );
  }

  getItemFromAffiliate$(config: { productId?: string; productUrl?: string; affiliateId: string }): Observable<AsinResult2> {
    const remoteFunctionHostUrl = 'https://europe-west1-shelfdesigner-bbbcb.cloudfunctions.net';
    const localFunctionHostUrl = 'http://localhost:5001'; // us-central1/shelfdesigner-bbbcb
    let functionHostUrl = remoteFunctionHostUrl;
    // let functionHostUrl = localFunctionHostUrl;
    /*
    if (window.location.href.includes('localhost')) {
      functionHostUrl = localFunctionHostUrl;
    } else {
      functionHostUrl = remoteFunctionHostUrl;
    }
    */
    let url = `${functionHostUrl}/getProduct?`;

    let queryParams = new HttpParams();
    if (config.productId) {
      queryParams = queryParams.append('productId', config.productId);
    }
    if (config.productUrl) {
      queryParams = queryParams.append('productUrl', config.productUrl);
    }
    if (config.productUrl) {
      queryParams = queryParams.append('affiliateId', config.affiliateId);
    }

    console.log('>>> getItemFromAffiliate: url', url);
    return this.http.get<AsinResult2>(url, { params: queryParams });
  }

  getAllItemColors$(): Observable<string[]> {
    const groupBy = (array, key) => {
      return array.reduce((result: Array<string>, currentValue) => {
        if (currentValue[key] && !result.includes(currentValue[key])) {
          result.push(currentValue[key]);
        }
        return result;
      }, new Array<string>()); // empty object is the initial value for result object
    };

    return collectionData(collection(this.firestore, 'items')).pipe(map((items: Item[]) => groupBy(items, 'color')));
  }

  incrementUsageCount$(item: Product): Observable<any> {
    return of(item.usageCount ? item.usageCount + 1 : 1).pipe(
      mergeMap((count: number) =>
        iif(
          () => item?.usageCount !== null,
          from(
            updateDoc(doc(this.firestore, 'items', item.id), {
              usageCount: count,
            })
          ),
          from(setDoc(doc(this.firestore, 'items', item.id), item)).pipe()
        ).pipe(map(() => count))
      ),
      tap((count) => {
        item.usageCount = count;
      }),
      catchError((error) => {
        console.error('Failed updating usage count on ' + item.id, error);
        throw error;
      })
    );
  }
}
