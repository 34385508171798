import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
//import {of} from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ItemImage } from '../interfaces/item-image.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';
import { ExtendedShoppingCartItem, ShoppingCartItem } from '../interfaces/shopping-cart-item.interface';

@Injectable({
  providedIn: 'root',
})
export class ShelfItemsService {
  private basePath: string = environment.basePath;
  private serverUrl: string = environment.serverUrl;
  itemImages: ItemImage[] = [];
  itemImages$: Observable<ItemImage[]>;

  constructor(private http: HttpClient) { }

  buildSlotItemImage(itemImages: ItemImage[], slot: SlotScenes): ItemImage[] {
    if (itemImages != null) {
      this.itemImages = itemImages.slice();
    } else {
      this.itemImages = [];
      this.itemImages.concat(itemImages);
    }
    const item: ItemImage = { slotNumber: slot.id, slotImage: null };
    this.itemImages.push(item);

    return this.itemImages;
  }

  clearItemImages(itemImages: ItemImage[], backbutton?: boolean): ItemImage[] {
    if (backbutton) {
      this.itemImages = null;
    } else {
      if (itemImages != null) {
        this.itemImages = itemImages.slice();
        this.itemImages.forEach((itemImage: ItemImage) => {
          this.itemImages.splice(this.itemImages.indexOf(itemImage), 1, { slotNumber: itemImage.slotNumber, slotImage: null });
        });
      }
    }

    return this.itemImages;
  }

  // TODO: Wieso observable??
  removeItemImage(itemImages: ItemImage[], item: ShoppingCartItem): ItemImage[] {
    if (itemImages != null) {
      this.itemImages = itemImages.slice();

      item.slotnumber.forEach((slotNumber: number) => {
        const index = this.itemImages.map((itemImage: ItemImage) => itemImage.slotNumber).indexOf(slotNumber);
        this.itemImages.splice(index, 1, { slotNumber: slotNumber, slotImage: null });
      });
    }
    return this.itemImages;
  }
}
