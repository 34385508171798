import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  loadItemAction,
  loadItemFailedAction,
  loadItemSucceededAction,
  loadShelfAction,
  loadShelfFailedAction,
  loadShelfSucceededAction,
} from '../actions/product-details.actions';
import { Item } from '../interfaces/item.interface';
import { Product } from '../interfaces/product.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';

export interface ProductDetailsState {
  product: Product;
}

export const initialState: ProductDetailsState = {
  product: null,
};

export const productDetailsReducer = createReducer(
  initialState,
  on(loadShelfAction, loadItemAction, (state) => {
    return { ...state, product: null };
  }),
  on(loadShelfSucceededAction, loadItemSucceededAction, (state, action) => {
    return { ...state, product: (action as any).shelf ?? (action as any).item };
  }),
  on(loadShelfFailedAction, loadItemFailedAction, (state) => {
    return { ...state, product: null };
  })
);

export function reducer(state: ProductDetailsState = initialState, action: Action): ProductDetailsState {
  return productDetailsReducer(state, action);
}

export const productDetailsState = createFeatureSelector<ProductDetailsState>('product-details');
export const getProduct = createSelector(productDetailsState, (state: ProductDetailsState) => state.product as (ShelfScenes | Item));
