import { Component, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonAccordionGroup, IonContent, Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { getCurrentAccordionStateOnProductList } from '../../core/reducers/shoppingcart.reducer';
import { environment } from '../../../environments/environment';
import { openDesignCloneDialogAction } from '../../core/actions/shelf-designer.actions';
import {
  clearItemListAction,
  hideProductListAction,
  setBuyingItemAction,
  setBuyingShelfAction,
  setCurrentAccordionStateAction,
} from '../../core/actions/shopping-cart.actions';
import { AnalyticLogs } from '../../core/enums/analytic-logs.enum';
import { ProductListProduct } from '../../core/interfaces/shopping-cart-item.interface';
import {
  getShopingCartItems,
  getShoppingCartItemsInactive,
  isBuyingCurrentShelf,
  isCurrentDesignOwnDesign,
} from '../../core/reducers/my-designs.reducer';
import { AnalyticsService } from '../../core/services/analytics.service';
import {
  ProductListForShoppingCart,
  SortedShoppingCartInactiveProducts,
  SortedShoppingCartProducts,
} from '../../core/interfaces/shopping-cart.interface';
import { AffiliatesEnum } from '../../core/enums/affiliates.enum';
import { hasShoppingCart } from '../../core/functions/affiliates';
import { startCheckoutAction, startCheckoutAllAction } from '../../core/actions/checkout.actions';
import { Product } from '../../core/interfaces/product.interface';
import { ItemService } from '../../core/services/item.service';
import { Item } from '../../core/interfaces/item.interface';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss'],
})
export class ProductListComponent implements OnInit, OnDestroy {
  environment = environment;
  affiliatesEnum = AffiliatesEnum;

  @Input('sideBar') sideBar = false;

  @ViewChild('root') root: IonContent;
  @ViewChild('accordionList') accordionList: IonAccordionGroup;

  /* Observables */
  isBuyingShelf$: Observable<boolean> = this.store.select(isBuyingCurrentShelf);
  currentDesignIsOwnDesign$: Observable<boolean> = this.store.select(isCurrentDesignOwnDesign);
  destroy$ = new Subject<void>();
  desktopMode$ = new BehaviorSubject(false); // TODO: per media query lösen
  currentAccordionStateOnProductList$: Observable<string> = this.store.select(getCurrentAccordionStateOnProductList);

  /* Subscriptions */
  getShopingCartItemsSubscription: Subscription;
  getShoppingCartItemsInactiveSubscription: Subscription;

  shoppingCartItems: SortedShoppingCartProducts;
  shoppingCartItemsInactive: SortedShoppingCartInactiveProducts;

  constructor(
    private store: Store,
    private analyticsService: AnalyticsService,
    private platform: Platform,
    private itemService: ItemService,
  ) { }

  ngOnInit(): void {
    this.checkDesktopMode(window.innerWidth);
    this.getShopingCartItemsSubscription = this.store.select(getShopingCartItems).subscribe((groupedItems: SortedShoppingCartProducts) => {
      // fix jumping issue
      if (this.root) {
        const mainScrollContainerDOM: HTMLElement = (this.root as any).el.shadowRoot.children[1];
        const scrollTop: number = mainScrollContainerDOM.scrollTop;
        this.shoppingCartItems = groupedItems;

        setTimeout(() => {
          mainScrollContainerDOM.scrollTop = scrollTop;
        }, 0);
      } else {
        this.shoppingCartItems = groupedItems;
      }
    });
    this.getShoppingCartItemsInactiveSubscription = this.store
      .select(getShoppingCartItemsInactive)
      .subscribe((items: SortedShoppingCartInactiveProducts) => {
        this.shoppingCartItemsInactive = items;
      });
  }

  ngOnDestroy(): void {
    this.getShopingCartItemsSubscription.unsubscribe();
    this.getShoppingCartItemsInactiveSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    const w = event.target.innerWidth;
    this.checkDesktopMode(w);
  }

  private checkDesktopMode(width: number): void {
    this.desktopMode$.next(width > environment.mobilWidthBreakpoint);
  }

  clearItemList(): void {
    this.store.dispatch(clearItemListAction({}));
    this.store.dispatch(setBuyingShelfAction({ buyShelf: false }));
  }

  handleClose(): void {
    this.store.dispatch(hideProductListAction());
  }

  handleItemToggle(item: ProductListProduct): void {
    if (this.accordionList.value === undefined) {
      this.store.dispatch(setCurrentAccordionStateAction({ id: '' }));
    } else {
      this.store.dispatch(setCurrentAccordionStateAction({ id: item.item.id }));
    }
    this.store.dispatch(setBuyingItemAction({ item }));
  }

  handleShelfToggle(checked: boolean): void {
    this.store.dispatch(setBuyingShelfAction({ buyShelf: checked }));
  }

  handleCloneDialog(event: CustomEvent): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.store.dispatch(openDesignCloneDialogAction());
  }

  openUrl(url: string) {
    window.open(url, '_blank');
    this.analyticsService.log(AnalyticLogs.PRODUCT_LIST_SHOW_ITEM_DETAILS);
  }

  goToAffiliate(product: Product, url: string, affiliate: AffiliatesEnum) {
    this.analyticsService.log(AnalyticLogs.PRODUCT_LIST_CONFIRM_GOTO_AFFILIATE, { affiliate: affiliate });
    if (hasShoppingCart(affiliate)) {
      this.store.dispatch(startCheckoutAction({ affiliate, product }));
    } else {
      window.open(url, '_blank');
    }
  }

  /**
   * Both function are used for the accordion
   */
  toggleAccordionState(): void {
    if (this.accordionList) {
      let id: string = this.accordionList.value != undefined ? this.accordionList.value.toString() : '';
      this.store.dispatch(setCurrentAccordionStateAction({ id }));
    }
  }

  hasShoppingCartFunctionality(affiliate: AffiliatesEnum): boolean {
    return hasShoppingCart(affiliate);
  }

  isDesktop(): boolean {
    return this.platform.is('desktop');
  }

  handleShoppingCart(affiliate: AffiliatesEnum): void {
    this.store.dispatch(startCheckoutAllAction({ affiliate }));
  }

  // Tragging
  trackByAffiliate(index: number, affiliateGroup: any): string {
    return affiliateGroup.key;
  }

  trackByItemGroup(index: number, itemGroup: ProductListForShoppingCart): string {
    return itemGroup.products[0].item.id;
  }

  /**
   * use those function in the html for debuging
   */
  debugWithValue(value: any): void {
    console.log('>>> DEBUG', value);
  }

  debug(): void {
    console.log('>>> DEBUG', this.accordionList.value);
  }
}
