import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CardPickerTestComponent } from './card-picker-test/card-picker-test.component';
import { AuthGuard } from './core/guards/auth.guard';
import { LandingPageComponent } from './landingpage/landing-page/landing-page.component';
import { AnonymousLoginComponent } from './login/anonymous-login/anonymous-login.component';
import { ForgotPasswordComponent } from './login/forgot-password/forgot-password.component';
import { SignInComponent } from './login/sign-in/sign-in.component';
import { SignUpComponent } from './login/sign-up/sign-up.component';
import { VerifyEmailComponent } from './login/verify-email/verify-email.component';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { ProductDetailContainerComponent } from './product-detail-container/product-detail-container.component';
import { ShelfDesignerComponent } from './shelf-designer/shelf-designer-component/shelf-designer.component';
import { AmazonLinkerComponent } from './stuff/amazon-linker/amazon-linker.component';

const routes = [
  /*
  {
    // the redirect happens in auth guard to keep #admin fragment in url

    path: '',
    canActivate: [AuthGuard],
    component: ShelfDesignerComponent
  },
  */
  {
    path: '',
    // canActivate: [AuthGuard],
    component: LandingPageComponent,
  },
  {
    path: 'shelfdesigner',
    canActivate: [AuthGuard],
    component: ShelfDesignerComponent,
  },
  {
    path: 'shelfdesigner/:id',
    canActivate: [AuthGuard],
    component: ShelfDesignerComponent,
  },
  {
    path: 'shelfdesigner/:id/:scene',
    canActivate: [AuthGuard],
    component: ShelfDesignerComponent,
  },
  {
    path: 'product/:category/:id',
    canActivate: [AuthGuard],
    component: ProductDetailContainerComponent,
  },
  {
    path: 'admin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
  },
  { path: 'card-picker-test', component: CardPickerTestComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'sign-in-a', component: AnonymousLoginComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'register-user', component: SignUpComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-email-address', component: VerifyEmailComponent },
  { path: 'amazon', component: AmazonLinkerComponent },
  { path: 'maintenance', component: MaintenancePageComponent },
  { path: '**', redirectTo: '/' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
