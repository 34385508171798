import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageSrcSet',
})
export class ImageSrcSetPipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    if (!value) return value;
    const imageSrc = value as string;

    const srcSet = `${imageSrc} 1500w, ${imageSrc.replace('.png', '_62x62.png')} 62w, ${imageSrc.replace(
      '.png',
      '_1000x1000.png'
    )} 1000w, ${imageSrc.replace('.png', '_500x500.png')} 500w, ${imageSrc.replace('.png', '_250x250.png')} 250w, ${imageSrc.replace(
      '.png',
      '_125x125.png'
    )} 125w, `;

    return srcSet;
  }
}
