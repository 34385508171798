import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-loading-image',
  templateUrl: './loading-image.component.html',
  styleUrls: ['./loading-image.component.scss'],
})
export class LoadingImageComponent implements OnInit {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input('src') src: string;
  @Input('size') size: string;
  @Output('loaded') loaded = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}

  handleImageLoaded(event: Event) {
    // console.log('>>> LoadingImageComponent: handleImageLoaded', event);
    this.loaded.emit(event);
  }
}
