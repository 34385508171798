<div class="virtual-scroller-container">
  <virtual-scroller
    #scroll
    [items]="cards.length > 0 ? cards : skeletonItems"
    [executeRefreshOutsideAngularZone]="false"
    [checkResizeInterval]="1000"
    (vsUpdate)="handleVirtualScrollerUpdate()"
    [enableUnequalChildrenSizes]="true"
    [horizontal]="orientation === Orientation.Horizontal"
  >
    <div *ngFor="let card of scroll.viewPortItems">
      <div *ngIf="card.skeletonItem" class="skeleton-item">
        <ion-skeleton-text animated></ion-skeleton-text>
      </div>

      <ng-container *ngTemplateOutlet="cardContentTemplateRef; context: { card: this.card }"></ng-container>
    </div>
  </virtual-scroller>
</div>
