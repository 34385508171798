import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, Observable } from 'rxjs';
import { BottomPanePositionsEnum } from '../../core/enums/bottom-pane-positions.enum';
import {
  chooseTabAction,
  setShelfScenesHacksPickerSizeMobileAction,
  toggleHacksPickerDesktopAction,
  toggleScenePickerDesktopAction,
  toggleShelfPickerDesktopAction,
} from '../../core/actions/shelf-designer.actions';
import { setBottomPanelPositionAction } from '../../core/actions/shelf-picker.actions';
import { getShelvesScenesHacksPickerCurrentTab, isHacksPickerOpen, isScenesPickerOpen, isShelfPickerOpen } from '../../core/reducers/shelf-designer.reducer';
import { getBottomPanelPosition } from '../../core/reducers/shelf-picker.reducer';
import { ShelfSceneHacksPickerTabEnum } from '../../core/enums/shelf-scenes-hacks-picker-tab.enum';

@Component({
  selector: 'app-shelves-scenes-hacks-picker',
  templateUrl: './shelves-scenes-hacks-picker.component.html',
  styleUrls: ['./shelves-scenes-hacks-picker.component.scss'],
})
export class ShelvesScenesHacksPickerComponent implements OnInit {
  activeTab$: Observable<string> = this.store.select(getShelvesScenesHacksPickerCurrentTab);
  bottomPanelPosition$: Observable<string> = this.store.select(getBottomPanelPosition);
  isShelfPickerOpen$: Observable<boolean> = this.store.select(isShelfPickerOpen);
  isScenesPickerOpen$: Observable<boolean> = this.store.select(isScenesPickerOpen);
  isHacksPickerOpen$: Observable<boolean> = this.store.select(isHacksPickerOpen);

  bottomPanePositions: BottomPanePositionsEnum[] = Object.values(BottomPanePositionsEnum);
  shelfsceneHacksPickerTabs: ShelfSceneHacksPickerTabEnum[] = Object.values(ShelfSceneHacksPickerTabEnum);

  scrollHorizontal$: Observable<boolean>;

  @Input('sidebar') sidebar = true;

  constructor(private store: Store) {}

  ngOnInit(): void {
    if (!this.sidebar) {
      this.scrollHorizontal$ = this.bottomPanelPosition$.pipe(
        map((position: BottomPanePositionsEnum) => {
          if (position == BottomPanePositionsEnum.TOP) {
            this.store.dispatch(setShelfScenesHacksPickerSizeMobileAction({ open: true }));
          } else {
            this.store.dispatch(setShelfScenesHacksPickerSizeMobileAction({ open: false }));
          }
          return position != BottomPanePositionsEnum.TOP && !this.sidebar;
        })
      );
    } else {
      this.store.dispatch(chooseTabAction({ tab: ShelfSceneHacksPickerTabEnum.SHELF }));
    }
  }

  onSegmentChange(tab: ShelfSceneHacksPickerTabEnum): void {
    this.store.dispatch(chooseTabAction({ tab }));
  }

  onClickSegment(position: BottomPanePositionsEnum): void {
    if (position == BottomPanePositionsEnum.BOTTOM) {
      this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.MIDDLE }));
    }
  }

  onSegmentChangeDesktop(tab: ShelfSceneHacksPickerTabEnum, $shelfPicker, $scenePicker, $hacksPicker): void {
    this.store.dispatch(chooseTabAction({ tab }));
    switch (tab) {
      case ShelfSceneHacksPickerTabEnum.SHELF:
        $shelfPicker.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        break;
      case ShelfSceneHacksPickerTabEnum.SCENE:
        $scenePicker.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        break;
      case ShelfSceneHacksPickerTabEnum.HACKS:
        $hacksPicker.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        break;
    }
  }

  handleMoveSheetTo(position: BottomPanePositionsEnum, event): void {
    event.stopPropagation();
    this.store.dispatch(setBottomPanelPositionAction({ position }));
    if (position == BottomPanePositionsEnum.TOP) {
      this.store.dispatch(setShelfScenesHacksPickerSizeMobileAction({ open: true }));
    } else {
      this.store.dispatch(setShelfScenesHacksPickerSizeMobileAction({ open: false }));
    }
  }

  onClickToggleShelfPicker(): void {
    this.store.dispatch(toggleShelfPickerDesktopAction());
  }

  onClickToggleScenesPicker(): void {
    this.store.dispatch(toggleScenePickerDesktopAction());
  }

  onClickToggleHacksPicker(): void {
    this.store.dispatch(toggleHacksPickerDesktopAction());
  }

  debug(): void {}
}
