import { createAction, props } from '@ngrx/store';
import { TypesEnum } from '../enums/types.enum';
import { ExtendedDesign } from '../interfaces/design.interface';
import { Item } from '../interfaces/item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { ExtendedShoppingCartItem, ProductListProduct, ShoppingCartItem } from '../interfaces/shopping-cart-item.interface';

const actionType = '[ShoppingCard]';

export const addShelfAction = createAction(`${actionType} Adding shelf`, props<{ shelf: ShelfScenes }>());

export const addItemToCartAction = createAction(`${actionType} Adding item to cart`, props<{ item: Item; designId: string }>());
export const addItemToCartSucceededAction = createAction(
  `${actionType} Adding item to cart succeeded`,
  props<{ designId: string; shoppingCartItems: ShoppingCartItem[], extendedShoppingCartItems: ExtendedShoppingCartItem[] }>()
);

export const removeItemGroupAction = createAction(`${actionType} Remove item group`, props<{ item: ExtendedShoppingCartItem }>());

export const removeItemGroupSucceededAction = createAction(
  `${actionType} Succeeded to removed item group`,
  props<{ shoppingCartItems: ExtendedShoppingCartItem[] }>()
);

export const removeItemAction = createAction(`${actionType} Remove item`, props<{ item: ExtendedShoppingCartItem; slotNumber: number }>());
export const removeItemSucceededAction = createAction(
  `${actionType} Remove item succeeded`,
  props<{ shoppingCartItems: ExtendedShoppingCartItem[] }>()
);

export const clearItemListAction = createAction(`${actionType} Clear itemlist`, props<{ back?: boolean }>());

export const calculatePriceAction = createAction(
  `${actionType} Calculate price`,
  props<{ shoppingCardItems: ExtendedShoppingCartItem[] }>()
);

export const calculatePriceSucceededAction = createAction(`${actionType} Succeeded to calcuated price`, props<{ totalPrice: number }>());

export const setBuyingShelfAction = createAction(`${actionType} Set buying shelf`, props<{ buyShelf: boolean }>());
export const setBuyingShelfSucceededAction = createAction(
  `${actionType} Set buying shelf successfully`,
  props<{ isBuyingShelf: boolean }>()
);
export const setBuyingShelfFailedAction = createAction(`${actionType} Set buying shelf failed successfully`, props<{ err: any }>());

export const shoppingcardLoadSavedConfSucceededAction = createAction(
  `${actionType} Succeeded to load shoppingcard saved config`,
  props<{ config: ExtendedDesign; shelf: ShelfScenes }>()
);

export const checkUpdateChangesAction = createAction(
  `${actionType} Check update changes`,
  props<{ types: TypesEnum[]; configId: string }>()
);

export const checkUpdateChangesSucceededAction = createAction(
  `${actionType} Succeeded to check update changes`,
  props<{ extendedShoppingCartItems: ExtendedShoppingCartItem[] }>()
);

export const showProductListAction = createAction(`${actionType} Show product list`);
export const hideProductListAction = createAction(`${actionType} Hide product list`);

export const updateShoppingCartItemsAfterShelfChangeAction = createAction(
  `${actionType} Update shopping cart items after shelf change`,
  props<{ shoppingCartItems: ExtendedShoppingCartItem[] }>()
);

export const setBuyingItemAction = createAction(`${actionType} Set buying item`, props<{ item: ProductListProduct }>());
export const setBuyingItemSucceededAction = createAction(
  `${actionType} Set buying item successfully`,
  props<{ shoppingCartItems: ExtendedShoppingCartItem[] }>()
);
export const setBuyingItemFailedAction = createAction(`${actionType} Set buying item failed`, props<{ error: any }>());

export const setCurrentAccordionStateAction = createAction(`${actionType} Set current accordion state`, props<{ id: string }>());
