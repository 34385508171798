export enum AnalyticLogs {
  APP_MENU_SHOW_CONTACT_INFO = 'appmenu__show_contact_info',
  APP_MENU_SHOW_IMPRINT = 'appmenu__show_imprint',
  APP_MENU_SHOW_PRIVACY_POLICY = 'appmenu__show_privacy_policy',

  GENERAL_SHOW_APP_MENU = 'general__show_app_menu',

  SHELF_DESIGNER_COMPONENT_OPEN_SHELF_PICKER = 'shelfdesigner__open_shelf_picker',
  SHELF_DESIGNER_COMPONENT_CHANGE_SHELF_COLOR = 'shelfdesigner__change_shelf_color',
  SHELF_DESIGNER_COMPONENT_SHARE_DESIGN = 'shelfdesigner__share_design',
  SHELF_DESIGNER_COMPONENT_SHARE_DESIGN_ON_PINTEREST = 'shelfdesigner__share_design_on_pinterest',
  SHELF_DESIGNER_COMPONENT_SHARE_DESIGN_ON_TWITTER = 'shelfdesigner__share_design_on_twitter',
  SHELF_DESIGNER_COMPONENT_SHARE_DESIGN_ON_FACEBOOK = 'shelfdesigner__share_design_on_facebook',
  SHELF_DESIGNER_COMPONENT_CREATE_NEW_DESIGN = 'shelfdesigner__create_new_design',
  SHELF_DESIGNER_COMPONENT_OPEN_SHELF_ITEM_PICKER = 'shelfdesigner__open_shelf_item_picker',
  SHELF_DESIGNER_COMPONENT_SHOW_PRODUCT_LIST = 'shelfdesigner__show_product_list',
  SHELF_DESIGNER_COMPONENT_HIDE_PRODUCT_LIST = 'shelfdesigner__hide_product_list',

  SHELF_PICKER_COMPONENT_SHOW_SHELF_DETAILS = 'shelfpicker__show_shelf_details',
  SHELF_PICKER_COMPONENT_CHOOSE_SHELF = 'shelfpicker__choose_shelf',

  SHELF_ITEM_PICKER_COMPONENT_SHOW_MORE = 'shelfitempicker__show_item_more',
  SHELF_ITEM_PICKER_COMPONENT_SHOW_ITEM_DETAILS = 'shelfitempicker__show_item_details',
  SHELF_ITEM_PICKER_COMPONENT_SELECT_COLOR = 'shelfitempicker__filter_by_color',
  SHELF_ITEM_PICKER_COMPONENT_CHOOSE_ITEM = 'shelfitempicker_choose_item',
  SHELF_ITEM_PICKER_COMPONENT_CLEAR_ITEM = 'shelfitempicker__clear_item',
  SHELF_ITEM_PICKER_COMPONENT_SHOW_PRODUCT_FILTER = 'shelfitempicker__show_product_filter',

  PRODUCT_LIST_COMPONENT_REMOVE_ITEM = 'productlist__remove_item',
  PRODUCT_LIST_COMPONENT_GO_TO_SHOPPING_CART = 'productlist__go_to_shopping_cart',
  PRODUCT_LIST_COMPONENT_CLOSE_SHOPPING_CART = 'productlist__close_shopping_cart',
  PRODUCT_LIST_COMPONENT_TOGGLE_ITEM_CHECKED = 'productlist__toggle_item_checked',
  PRODUCT_LIST_COMPONENT_TOGGLE_SHELF_CHECKED = 'productlist__toggle_shelf_checked',
  PRODUCT_LIST_SHOW_ITEM_DETAILS = 'productlist__show_item_details',
  PRODUCT_LIST_SHOW_SHELF_DETAILS = 'productlist__show_shelf_details',
  PRODUCT_LIST_CONFIRM_CANCEL_AFFILIATE = 'productlist__cancel_goto_affiliate',
  PRODUCT_LIST_CONFIRM_GOTO_AFFILIATE = 'productlist__confirm_goto_affiliate',
}
