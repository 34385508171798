import { Pipe, PipeTransform } from '@angular/core';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';

@Pipe({
  name: 'isEmpty',
})
export class IsEmptyPipe implements PipeTransform {
  transform(shelf: ShelfScenes): boolean {
    if (shelf === undefined || shelf === null) {
      return true;
    }

    return !shelf.scenes[0].slots.some((slot: SlotScenes) => slot.itemId);
  }
}
