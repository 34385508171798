import { Pipe, PipeTransform } from '@angular/core';
import { isNewProduct } from '../helper/products.helper';
import { Item } from '../interfaces/item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';

@Pipe({
  name: 'isNew',
})
export class IsNewPipe implements PipeTransform {
  transform(product: ShelfScenes | Item): boolean {
    if (!product) {
      return false;
    }

    if (product.dateAdded) {
      return isNewProduct(product);
    }

    return false;
  }
}
