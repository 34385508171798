import { MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';

// console.log all actions
// export function logger(reducer: ActionReducer<State>): ActionReducer<State> {
//   return function (state: State, action: any): State {
//     return reducer(state, action);
//   };
// }

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
 export const metaReducers: Array<MetaReducer<any, any>> = environment.production ? [] : [];
