import { createAction, props } from '@ngrx/store';
import { AppUser } from '../../login/entities/user.interface';
import { ShelfSceneHacksPickerTabEnum } from '../enums/shelf-scenes-hacks-picker-tab.enum';
import { ExtendedDesign } from '../interfaces/design.interface';
import { Item } from '../interfaces/item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';
import { Settings } from '../interfaces/settings.interface';
import { ExtendedShoppingCartItem } from '../interfaces/shopping-cart-item.interface';

const actionType = '[Shelf-Designer]';

export const openDesignCloneDialogAction = createAction(`${actionType} Open clone design dialog`);

export const enterShelfPickerAction = createAction(`${actionType} Enter shelf picker`);
export const enterShelfPickerSucceededAction = createAction(
  `${actionType} Succeeded to enter shelf picker`,
  props<{ shelves: ShelfScenes[] }>()
);
export const enterShelfPickerFailedAction = createAction(`${actionType} Failed to enter shelf picker`);

export const enterShelfColorPickerAction = createAction(`${actionType} Enter shelf color picker`);
export const enterShelfColorPickerSucceededAction = createAction(
  `${actionType} Succeeded to enter shelf color picker`,
  props<{ shelves: ShelfScenes[] }>()
);
export const enterShelfColorPickerFailedAction = createAction(`${actionType} Failed to enter shelf color picker`);

export const leaveShelfPickerAction = createAction(`${actionType} Leave shelf picker`);

export const chooseShelfAction = createAction(`${actionType} Choose shelf`, props<{ shelfId: string }>());
export const chooseShelfSucceededAction = createAction(
  `${actionType} Choose shelf succeeded`,
  props<{ shelf: ShelfScenes; newCartItems: ExtendedShoppingCartItem[] }>()
);
export const shelfImageLoadedAction = createAction(`${actionType} Shelf Image loaded`, props<{ shelf: ShelfScenes }>());

export const chooseShelfFailedAction = createAction(`${actionType} Choose shelf failed`);

export const chooseShelfColorAction = createAction(`${actionType} Choose shelf color`, props<{ shelfId: string }>());
export const chooseShelfColorSucceededAction = createAction(`${actionType} Choose shelf color succedded`, props<{ shelf: ShelfScenes }>());
export const chooseShelfColorFailedAction = createAction(`${actionType} Choose shelf color failed`);

export const enterItemPickerAction = createAction(`${actionType} Enter item picker`, props<{ shelf: ShelfScenes; slot: SlotScenes }>());
export const leaveItemPickerAction = createAction(`${actionType} Leave item picker`);
export const chooseItemAction = createAction(`${actionType} Choose item`, props<{ item: Item }>());
export const removeItemAction = createAction(`${actionType} Remove item`, props<{ slotNumber: number }>());

export const loadSettingsAndDesignForUserAction = createAction(
  `${actionType} Load settings and designs for user`,
  props<{ user: AppUser }>()
);
export const loadSettingsAndDesignForUserSucceededAction = createAction(
  `${actionType}  Load settings and designs for user succeeded`,
  props<{ settings: Settings; design: ExtendedDesign }>()
);
export const loadSettingsAndDesignForUserFailedAction = createAction(
  `${actionType} Load settings and designs for user failed`,
  props<{ error: Error }>()
);

export const loadLastModifiedDesignOrCreateNewDesignAction = createAction(
  `${actionType} Load last modified design or create new design`,
  props<{ user: AppUser }>()
);
export const loadLastModifiedDesignSucceededAction = createAction(
  `${actionType} Load last modified design succeeded`,
  props<{ designId: string }>()
);
export const loadLastModifiedDesignFailedAction = createAction(
  `${actionType} Load last modified design failed.`,
  props<{ error: Error }>()
);

/**
 * Shows picker where the user can load older saved designs.
 */
export const showMyDesignsAction = createAction(`${actionType} Show my designs`);
export const hideMyDesignsAction = createAction(`${actionType} Hide my designs`);

export const showBottomPickerAction = createAction(`${actionType} Show bottom picker`);
export const hideBottomPickerAction = createAction(`${actionType} Hide bottom picker`);

export const saveDesignAction = createAction(`${actionType} Save my design`);
export const saveDesignSucceededAction = createAction(`${actionType} Saved my design succeeded`, props<{ design: ExtendedDesign }>());
export const saveDesignFailedAction = createAction(`${actionType} Save my design failed`, props<{ error: any }>());

export const showMyDesignAction = createAction(`${actionType} Show my design`, props<{ designId: string }>());

export const loadMyDesignAction = createAction(`${actionType} Load my design`, props<{ designId: string; sceneIndex?: number }>());
export const loadMyDesignSucceededAction = createAction(
  `${actionType} Load my design succeeeded`,
  props<{
    design: ExtendedDesign;
    currentDesignIsOwnDesign: boolean;
    shelf: ShelfScenes;
    extendedShoppingCartItems: ExtendedShoppingCartItem[];
    sceneIndex: number;
  }>()
);
export const loadMyDesignFailedAction = createAction(`${actionType} Load my design failed`, props<{ error: any }>());

export const cloneDesignAction = createAction(`${actionType} Clone design`);
export const cloneDesignSucceededAction = createAction(
  `${actionType} Clone design succeeded`,
  props<{ newDesignId: string; sceneIndex: number }>()
);
export const cloneDesignFailedAction = createAction(`${actionType} Clone design failed`, props<{ error: Error }>());

export const resetSavedConfigStatusAction = createAction(`${actionType} Reset saved configuration status`);

export const resetShelfDataAction = createAction(`${actionType} Reset shelf data`);

export const setSlotItemImageAction = createAction(`${actionType} Set slot item image`, props<{ slotNumber: number; itemImage: string }>());

export const setSlotItemImageSucceededAction = createAction(
  `${actionType} Succeeded to set slot item image`,
  props<{ slotNumber: number; image: String[] }>()
);

export const openEmptyShelfDesignerAction = createAction(`${actionType} Open empty shelf designer`);

export const createNewDesignAction = createAction(`${actionType} Create new design`);
export const createNewDesignByUserAction = createAction(`${actionType} Create new design by user`);
export const createNewDesignSucceededAction = createAction(
  `${actionType} Create new design succeeded`,
  props<{ design: ExtendedDesign }>()
);

export const editCurrentShelfAction = createAction(`${actionType} Edit current shelf`);

export const showIntroductionAnimationAction = createAction(`${actionType} Show introduction animation`);
export const hideIntroductionAnimationAction = createAction(`${actionType} Close introduction animation`);

export const chooseShelfSceneAction = createAction(`${actionType} Choose Shelf Scene`, props<{ sceneIndex: number }>());

export const chooseTabAction = createAction(`${actionType} Choose tab`, props<{ tab: ShelfSceneHacksPickerTabEnum }>());
export const chooseTabSuccededAction = createAction(`${actionType} Choose tab succeeded`);

export const toggleShelfPickerDesktopAction = createAction(`${actionType} Toggle shelf picker (desktop)`);
export const toggleScenePickerDesktopAction = createAction(`${actionType} Toggle scene picker (desktop)`);
export const toggleHacksPickerDesktopAction = createAction(`${actionType} Toggle hacks picker (desktop)`);

export const setShelfScenesHacksPickerSizeMobileAction = createAction(
  `${actionType} Set shelf scenes hack picker (mobile)`,
  props<{ open: boolean }>()
);

export const editItemAction = createAction(`${actionType} Edit item`, props<{ item: Item }>());
