import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ShelfItemsService } from '../services/shelf-items.service';

@Injectable()
export class ShelfitemEffects {
  constructor(private action$: Actions, private shelfItemsService: ShelfItemsService, private store: Store) {}

  /*
  buildItemImage$ = createEffect(() =>
    this.action$.pipe(
      ofType(buildItemImageAction),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(fromRoot.getSlotItemImages)))),
      map(([action, configuredItemImages]: [any, ItemImage[]]) => {
        console.log('>>> configuredItemImages', configuredItemImages);
        return this.shelfItemsService.buildSlotItemImage(configuredItemImages, action.slot);
      }),
      map((itemImages: ItemImage[]) => BuildItemImageSuccessAction({ itemImages: itemImages }))
    )
  );
  */

  /*
  clearItemImages$ = createEffect(() =>
    this.action$.pipe(
      ofType(ClearItemImagesAction),
      withLatestFrom(this.store.select(fromRoot.getSlotItemImages)),
      map(([action, configuredItemImages]: [any, ItemImage[]]) =>
        this.shelfItemsService.clearItemImages(configuredItemImages, action.goBack)
      ),
      map((itemImages: ItemImage[]) => setItemImageSuccessAction({ itemImages: itemImages }))
    )
  );
  */

  /*
  removeItemImage$ = createEffect(() =>
    this.action$.pipe(
      ofType(RemoveItemImageAction),
      withLatestFrom(this.store.select(fromRoot.getSlotItemImages)),
      map(([action, configuredItemImages]: [any, ItemImage[]]) =>
        this.shelfItemsService.removeItemImage(configuredItemImages, action.shoppingCardItem)
      ),
      map((itemImages: ItemImage[]) => SetItemImageSuccessAction({ itemImages: itemImages }))
    )
  );
  */
}
