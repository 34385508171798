import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  chooseShelfSucceededAction,
  hideBottomPickerAction,
  showBottomPickerAction,
} from '../actions/shelf-designer.actions';
import {
  bottomPanelPositionChangedAction,
  leaveShelfDetailsAction,
  loadShelfColorsAction,
  loadShelfColorsFailedAction,
  loadShelfColorsSucceededAction,
  loadShelfTypesAction,
  loadShelfTypesFailedAction,
  setBottomPanelPositionAction,
  showShelfDetailsAction,
} from '../actions/shelf-picker.actions';
import { AnalyticLogs } from '../enums/analytic-logs.enum';
import { ProductCategories } from '../enums/product-categories.enum';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { isSplitMode } from '../reducers/general.reducer';
import { AnalyticsService } from '../services/analytics.service';
import { ShelfDesignerService } from '../services/shelf-designer.service';
import { ShelvesService } from '../services/shelf.service';
import { loadShelfTypesSucceededAction } from './../actions/shelf-picker.actions';

@Injectable()
export class ShelfpickerEffects {
  constructor(
    private actions$: Actions,
    private shelfService: ShelvesService,
    private analyticsService: AnalyticsService,
    private shelfDesignerService: ShelfDesignerService,
    private store: Store,
    private platform: Platform
  ) { }

  loadShelfTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadShelfTypesAction),
      switchMap(() => this.shelfService.getShelves$({})),
      map((shelves) => loadShelfTypesSucceededAction({ shelves: shelves })),
      catchError((error, caught) => {
        console.error('Error while loading shelf types:', error);
        this.store.dispatch(loadShelfTypesFailedAction({ error }));
        return caught;
      })
    )
  );

  loadShelfTypesSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadShelfTypesSucceededAction)
      ),
    { dispatch: false }
  );

  showBottomPicker$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showBottomPickerAction),
        switchMap((action) => this.shelfDesignerService.showBottomSheet$())
      ),
    { dispatch: false }
  );

  hideBottomPicker$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hideBottomPickerAction),
        switchMap((action) => {
          return this.shelfDesignerService.hideBottomSheet$();
        })
      ),
    { dispatch: false }
  );

  chooseShelfSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chooseShelfSucceededAction),
        withLatestFrom(this.store.select(isSplitMode)),
        switchMap(([, splitMode]) =>
          this.shelfDesignerService.setBottomSheetPositionToMiddle$(splitMode)
        )
      ),
    { dispatch: false }
  );

  setBottomPanelPosition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(setBottomPanelPositionAction),
        switchMap((action) => this.shelfDesignerService.setBottomSheetPosition$(action.position).pipe(map(() => action))),
        map((action) => bottomPanelPositionChangedAction({ position: action.position }))
      ),
    { dispatch: true }
  );

  bottomPanelPositionChanged$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(bottomPanelPositionChangedAction),
        tap(() => this.shelfDesignerService.checkBottomSheetBackDrop())
      ),
    { dispatch: false }
  );

  loadShelfColors$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadShelfColorsAction),
      switchMap((action) => this.shelfService.getShelvesForType$(action.shelfType)),
      map((shelves: ShelfScenes[]) => loadShelfColorsSucceededAction({ shelves })),
      catchError((error, caught) => {
        console.error('Error while loading shelf colors:', error);
        this.store.dispatch(loadShelfColorsFailedAction({ error }));
        return caught;
      })
    )
  );

  showShelfDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showShelfDetailsAction),
        tap(() => this.analyticsService.log(AnalyticLogs.SHELF_PICKER_COMPONENT_SHOW_SHELF_DETAILS))

        // TODO: ganzmachen!
        /*
        tap((action) => {
          const nav: HTMLIonNavElement = this.shelfDesignerService.getIonNav(ProductCategories.Shelf);
          nav.push(ProductDetailsComponent, {
            product: action.shelf,
            productCategory: ProductCategories.Shelf,
            context: ContextCategories.Picker,
          });
        })
        */
      ),
    { dispatch: false }
  );

  leaveShelfDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(leaveShelfDetailsAction),
        tap(() => {
          const nav: HTMLIonNavElement = this.shelfDesignerService.getIonNav(ProductCategories.Shelf);
          nav.pop();
        })
      ),
    { dispatch: false }
  );
}
