import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImagePreloader } from './directives/image-preloader.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { PictureDirective } from './directives/picture.directive';
import { AffiliatePipe } from './pipes/affiliate.pipe';
import { AmountConverterPipe } from './pipes/amount-converter.pipe';
import { CurrencyFormatPipe } from './pipes/currency-format.pipe';
import { FirestoreDatePipe } from './pipes/firestore-date.pipe';
import { ImageSizePipe } from './pipes/image-size.pipe';
import { ImageSrcSetPipe } from './pipes/image-src-set.pipe';
import { ShelfItemColorPipe } from './pipes/shelf-item-color.pipe';
import { ProductPipe } from './pipes/product.pipe';
import { IsNewPipe } from './pipes/is-new.pipe';
import { ProductBadgeComponent } from './components/product-badge/product-badge.component';
import { TranslocoModule } from '@ngneat/transloco';
import { IsMyDesignPipe } from './pipes/is-my-design.pipe';
import { InArrayPipe } from './pipes/in-array.pipe';
import { TotalFilteredProductsPipe } from './pipes/total-filtered-products.pipe';
import { ColorCircleComponent } from './components/color-circle/color-circle.component';
import { ColorPipe } from './pipes/color.pipe';
import { ChipComponent } from './components/chip/chip.component';
import { UiChangesDirective } from './directives/ui-changes.directive';
import { AffiliateLogoUrlPipe } from './pipes/affiliate-logo-url.pipe';
import { FavoritesPipe } from './pipes/favorites.pipe';
import { IsEmptyPipe } from './pipes/is-empty.pipe';

@NgModule({
  declarations: [
    CurrencyFormatPipe,
    FirestoreDatePipe,
    AffiliatePipe,
    ProductPipe,
    AmountConverterPipe,
    ImagePreloader,
    ImageSrcSetPipe,
    ImageSizePipe,
    InfiniteScrollDirective,
    PictureDirective,
    ShelfItemColorPipe,
    IsNewPipe,
    IsMyDesignPipe,
    ProductBadgeComponent,
    InArrayPipe,
    TotalFilteredProductsPipe,
    ColorCircleComponent,
    ColorPipe,
    ChipComponent,
    UiChangesDirective,
    AffiliateLogoUrlPipe,
    FavoritesPipe,
    IsEmptyPipe,
  ],
  imports: [CommonModule, TranslocoModule],
  exports: [
    InArrayPipe,
    TotalFilteredProductsPipe,
    CurrencyFormatPipe,
    FirestoreDatePipe,
    AffiliatePipe,
    ProductPipe,
    AmountConverterPipe,
    ImageSrcSetPipe,
    ImageSizePipe,
    InfiniteScrollDirective,
    PictureDirective,
    ShelfItemColorPipe,
    IsNewPipe,
    IsMyDesignPipe,
    ProductBadgeComponent,
    ColorCircleComponent,
    ColorPipe,
    ChipComponent,
    UiChangesDirective,
    AffiliateLogoUrlPipe,
    FavoritesPipe,
  ],
  providers: [AffiliatePipe, IsMyDesignPipe],
})
export class CoreModule {}
