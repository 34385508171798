import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  checkForRequiredFieldsAction,
  deleteShelvesAction,
  hideAdminMenuAction,
  resetUsageCountAction,
  showHomeAction,
} from './../../core/actions/admin.actions';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss'],
})
export class AdminMenuComponent {
  constructor(private store: Store) {}

  handleClose(): void {
    this.store.dispatch(hideAdminMenuAction());
  }

  handleDeleteShelves(): void {
    this.store.dispatch(deleteShelvesAction());
  }

  handleHome(): void {
    this.store.dispatch(showHomeAction());
  }

  handleResetUsageCount() {
    this.store.dispatch(resetUsageCountAction());
  }

  handleCheckForRequiredFields() {
    this.store.dispatch(checkForRequiredFieldsAction());
  }
}
