import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { getDownloadURL, ref, Storage, StorageError, uploadBytesResumable, UploadTaskSnapshot } from '@angular/fire/storage';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, IonInput, IonToggle, NavParams } from '@ionic/angular';
import { ResizeObserver } from '@juggle/resize-observer';
import { Store } from '@ngrx/store';
import '@svgdotjs/svg.draggable.js';
import { Element, Polyline } from '@svgdotjs/svg.js';
import { BehaviorSubject, from, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { SlotDataScenes } from '../../core/interfaces/scenes-slot-data.interface';
import { SlotSizes } from '../../core/interfaces/scenes-slot-sizes.interface';
import { hideSlotsEditorAction, updateShelfAction } from '../../core/actions/admin.actions';
import { ShelfScenes } from '../../core/interfaces/scenes-shelf.interface';
import { SlotScenes } from '../../core/interfaces/scenes-slot.interface';
import { SlotPoint } from '../../core/interfaces/slot-point.interface';
import { ShelvesService } from '../../core/services/shelf.service';
import { ShelfScenesEnum } from '../../core/enums/scenes-shelf.enum';

@Component({
  selector: 'app-slots-editor',
  templateUrl: './slots-editor.component.html',
  styleUrls: ['./slots-editor.component.scss'],
})
export class SlotsEditorComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$ = new Subject();

  circleDiameter = 10;
  zoom = 1;
  zoomFactor$ = new BehaviorSubject(1.0);
  shelfImageWidth: number;
  shelfImageHeight: number;

  magnifierPosition: string = '100px 0px';

  @ViewChild('slots') slots: ElementRef;
  @ViewChild('imageZoom') imageZoom: ElementRef;

  @ViewChild('shelfImage', { static: true }) shelfImage: ElementRef;
  @ViewChild('shelfElement', { static: true }) shelfElement: ElementRef;

  @ViewChild('imagePixelWidth') imagePixelWidth: IonInput;
  @ViewChild('imagePixelHeight') imagePixelHeight: IonInput;
  @ViewChild('activeToggle') activeToggle: IonToggle;
  @ViewChild('uploadImageInput') uploadImageInput: ElementRef;
  readonly SHELVES_IMAGE_STORAGE_LOCATION = 'products/shelves/images';

  currentShelf: ShelfScenes;
  originalShelf: ShelfScenes;
  currentSceneIndex: number;
  nameOfThisScene: string;
  sceneNameToCheck: string = 'Neue Ansicht';
  spacing: number = 50;
  sceneType: ShelfScenesEnum;

  observer: ResizeObserver;

  // slots form group
  slotsFormGroup!: FormGroup;

  constructor(
    private store: Store,
    private navParams: NavParams,
    private storage: Storage,
    private alertController: AlertController,
    private shelvesService: ShelvesService,
    private formBuilder: FormBuilder
  ) {
    this.currentShelf = this.navParams.get('shelf');
    // duplicate shelf for closing
    this.originalShelf = JSON.parse(JSON.stringify(this.currentShelf));

    this.currentSceneIndex = this.navParams.get('sceneIndex');
    this.nameOfThisScene = this.currentShelf.scenes[this.currentSceneIndex].name;

    // Clear name if it is a new scene
    this.nameOfThisScene = this.nameOfThisScene == this.sceneNameToCheck ? '' : this.nameOfThisScene;
  }

  ngOnInit(): void {
    this.addAllSlotsToForm();
  }

  ngAfterViewInit(): void {
    this.observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.shelfImageWidth = entry.contentRect.width;
        this.shelfImageHeight = entry.contentRect.height;
        this.calculateZoomFactor();
      });
    });
    this.observer.observe(this.shelfElement.nativeElement);

    this.activeToggle.checked = this.currentShelf.scenes[this.currentSceneIndex].active;
    this.sceneType = this.currentShelf.scenes[this.currentSceneIndex].type;
  }

  ngOnDestroy(): void {
    this.observer.unobserve(this.shelfElement.nativeElement);
  }

  get slotsArray() {
    return this.slotsFormGroup.controls['slotsArray'] as FormArray;
  }

  updateName(value: any): void {
    console.log(value);
  }

  isUploadDisable(): boolean {
    return this.nameOfThisScene === this.sceneNameToCheck || this.nameOfThisScene.length == 0;
  }

  handleUploadImage(): void {
    if (!(this.nameOfThisScene === this.sceneNameToCheck || this.nameOfThisScene.length == 0)) {
      this.uploadImageInput.nativeElement.click();
    }
  }

  private getFileExtensionFromMimeType(mimeType: string): string {
    switch (mimeType) {
      case 'image/jpeg':
        return 'jpg';
      case 'image/png':
        return 'png';
      default:
        return mimeType.substring(mimeType.lastIndexOf('/'));
    }
  }

  handleUploadFileInput(file: File): void {
    if (file.type !== 'image/png') {
      // alert('Please specify a png file');
      //    return;
    }

    const fileExtension = this.getFileExtensionFromMimeType(file.type);

    // Generating second name part of uploading file
    let secondPartOfName: string;
    if (this.currentSceneIndex == 1 || this.currentSceneIndex == 0) {
      secondPartOfName = this.nameOfThisScene;
    } else {
      secondPartOfName = this.getNewName();
    }

    const filePath = `${this.SHELVES_IMAGE_STORAGE_LOCATION}/${this.currentShelf.id}-${secondPartOfName}.${fileExtension}`;
    const fileRef = ref(this.storage, filePath);
    const task = uploadBytesResumable(fileRef, file);
    task.on(
      'state_changed',
      (snapshot: UploadTaskSnapshot) => {
        switch (snapshot.state) {
          case 'paused':
            console.log('Upload is paused');
            break;
          case 'running':
            console.log('Upload is running');
            break;
        }
      },
      (error: StorageError) => {
        console.error('Error while uploading file input:', error);
      },
      () => {
        getDownloadURL(task.snapshot.ref).then((url: string) => {
          if (url) {
            let newScenes = [...this.currentShelf.scenes];
            newScenes[this.currentSceneIndex].imageUrl = url;
            this.store.dispatch(updateShelfAction({ shelf: { ...this.currentShelf, scenes: newScenes } }));
            this.calculateZoomFactor();
          }
        });
      }
    );
  }

  getNewName(): string {
    let indexS: string = '';
    let indexI: number = 0;

    this.currentShelf.scenes.forEach((scene) => {
      if (scene.name == this.nameOfThisScene) {
        indexI++;
      }
    });

    let newName: string = this.nameOfThisScene + '_' + indexS;
    return newName;
  }

  /**
   * Slot-Editing stuff
   */
  onImportSlots(): void {
    from(
      this.alertController.create({
        message: 'shelfID?',
        inputs: [
          {
            name: 'shelfID',
            type: 'text',
            placeholder: 'Shelf ID',
            value: this.currentSceneIndex == 0 ? '' : this.currentShelf.id,
          },
        ],
        buttons: [
          {
            text: 'Import',
            handler: (alertData) => {
              this.importSlots(alertData.shelfID);
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Confirm Cancel');
            },
          },
        ],
      })
    )
      .pipe(
        switchMap((alert) => {
          return from(alert.present());
        })
      )
      .subscribe();
  }

  importSlots(shelfID: string): void {
    this.shelvesService.getShelf$(shelfID).subscribe((shelf) => {
      this.currentShelf.scenes[this.currentSceneIndex].slots = shelf.scenes[0].slots;
      this.calculateZoomFactor();

      this.addAllSlotsToForm();
    });
  }

  onClose(): void {
    this.store.dispatch(hideSlotsEditorAction({ shelf: this.originalShelf }));
  }

  onUpdate(): void {
    // this.store.dispatch(updateSlotDataAction({ shelf: this.currentShelf, slotData: this.currentShelf.slotsData }));
    this.currentShelf.scenes[this.currentSceneIndex].imageSize.width = +this.imagePixelWidth.value;
    this.currentShelf.scenes[this.currentSceneIndex].imageSize.height = +this.imagePixelHeight.value;
    this.currentShelf.scenes[this.currentSceneIndex].name = this.nameOfThisScene;
    this.currentShelf.scenes[this.currentSceneIndex].active = this.activeToggle.checked;
    this.currentShelf.scenes[this.currentSceneIndex].type = this.sceneType;

    this.updateAllSlotData();

    this.originalShelf = JSON.parse(JSON.stringify(this.currentShelf));

    this.calculateZoomFactor();
    this.store.dispatch(updateShelfAction({ shelf: this.currentShelf }));
  }

  calculateZoomFactor(): void {
    if (this.currentShelf.scenes[this.currentSceneIndex].slots) {
      this.zoomFactor$.next(this.shelfImageWidth / this.currentShelf.scenes[this.currentSceneIndex].imageSize.width);
    }
  }

  getPoints(slot: SlotScenes): string {
    return `${slot.slotData.ul.x},${slot.slotData.ul.y} ${slot.slotData.ur.x},${slot.slotData.ur.y} ${slot.slotData.lr.x},${slot.slotData.lr.y} ${slot.slotData.ll.x},${slot.slotData.ll.y}`;
  }

  getPointsZoomed(slot: SlotScenes): string {
    const zoomFactor = this.zoomFactor$.getValue();
    const s = `${slot.slotData.ul.x * zoomFactor},${slot.slotData.ul.y * zoomFactor} ${slot.slotData.ur.x * zoomFactor},${
      slot.slotData.ur.y * zoomFactor
    } ${slot.slotData.lr.x * zoomFactor},${slot.slotData.lr.y * zoomFactor} ${slot.slotData.ll.x * zoomFactor},${
      slot.slotData.ll.y * zoomFactor
    }`;
    return s;
  }

  // getViewBox(slotData: SlotData): string {
  //   // return `0 0 ${slotData.width*} ${slotData.width}`;
  //   return `0 0 ${this.shelfImageWidth} ${this.shelfImageHeight}`;
  // }

  onInitialize(shape: Element, slotPoint: SlotPoint): void {
    shape.draggable();
    shape.on('dragstart', (event: CustomEvent) => {
      //   this.zoomFactor$.next(this.zoomFactor$.getValue()*5);
    });
    shape.on('dragend', (event: CustomEvent) => {
      //  this.zoomFactor$.next(this.zoomFactor$.getValue()/5);
    });
    shape.on('dragmove', (event: CustomEvent) => {
      this.onDragMove(event, slotPoint);
    });
  }

  onInitializeSlotShape(shape: Polyline, slot: SlotScenes): void {
    shape.draggable();
    shape.on('dragend', (event: CustomEvent) => {
      this.onShapeDragEnd(event, shape, slot);
    });
  }

  onShapeDragEnd(event: CustomEvent, shape: Polyline, slot: SlotScenes): void {
    const zoomFactor = this.zoomFactor$.getValue();

    slot.slotData.ul.x = shape.array()[0][0];
    slot.slotData.ul.y = shape.array()[0][1];

    slot.slotData.ur.x = shape.array()[1][0];
    slot.slotData.ur.y = shape.array()[1][1];

    slot.slotData.lr.x = shape.array()[2][0];
    slot.slotData.lr.y = shape.array()[2][1];

    slot.slotData.ll.x = shape.array()[3][0];
    slot.slotData.ll.y = shape.array()[3][1];
  }

  onDragMove(event: CustomEvent, slotPoint: SlotPoint): void {
    const { handler, box } = event.detail;
    event.preventDefault();

    let { x, y } = box;
    // const zoomFactor = this.zoomFactor$.getValue();

    // x = x / zoomFactor;
    // y = y / zoomFactor;
    // handler.move(x, y);
    slotPoint.x = x;
    slotPoint.y = y;

    // this.magnifierPosition = `${slotPoint.x}px ${slotPoint.y}px`;
    // console.log('>>> this.magnifierPosition', this.magnifierPosition);
  }

  getCircleDiameter(): number {
    return this.circleDiameter / this.zoomFactor$.getValue();
  }

  onAddSlot(): void {
    let newSlot: SlotScenes = null;
    if (!this.currentShelf.scenes[this.currentSceneIndex].slots.length) {
      newSlot = {
        slotData: {
          ul: { x: 0, y: 0, index: 0 } as SlotPoint, //new SlotPoint(0, 0, 0),
          ur: { x: 500, y: 0, index: 1 } as SlotPoint, //new SlotPoint(500, 0, 1),
          lr: { x: 500, y: 500, index: 2 } as SlotPoint, // new SlotPoint(500, 500, 2),
          ll: { x: 0, y: 500, index: 3 } as SlotPoint, // new SlotPoint(0, 500, 3),
          width: 500,
          height: 500,
        } as SlotDataScenes,
        slotSizes: {
          width: 33,
          height: 33,
          depth: 39,
        } as SlotSizes,
      } as SlotScenes;
      newSlot.id = 0;
      this.currentShelf.scenes[this.currentSceneIndex].slots.push(newSlot);

      this.addAllSlotsToForm();
      this.calculateZoomFactor();
    } else {
      let oldSlot = this.currentShelf.scenes[this.currentSceneIndex].slots.slice(-1)[0];
      this.onCloneSlot(oldSlot.id);
    }
  }

  onCloneSlot(slotIndex: number): void {
    this.updateAllSlotData();
    let newSlot: SlotScenes = JSON.parse(JSON.stringify(this.currentShelf.scenes[this.currentSceneIndex].slots[slotIndex]));

    newSlot.id = this.currentShelf.scenes[this.currentSceneIndex].slots.length;
    newSlot.slotData.ul.x += this.spacing;
    newSlot.slotData.ul.y += this.spacing;
    newSlot.slotData.ur.x += this.spacing;
    newSlot.slotData.ur.y += this.spacing;
    newSlot.slotData.ll.x += this.spacing;
    newSlot.slotData.ll.y += this.spacing;
    newSlot.slotData.lr.x += this.spacing;
    newSlot.slotData.lr.y += this.spacing;
    this.currentShelf.scenes[this.currentSceneIndex].slots.push(newSlot);

    this.addAllSlotsToForm();
    this.calculateZoomFactor();
  }

  onDeleteSlot(slotIndex: number): void {
    // TODO: Ask for realy remove
    from(
      this.alertController.create({
        cssClass: 'my-custom-class',
        header: 'Warning',
        message: 'Do you realy want to delete this slot?',
        buttons: [
          {
            text: 'Yes',
            handler: () => {
              this.deleteSlot(slotIndex);
            },
          },
          {
            text: 'Cancel',
            role: 'cancel',
            handler: () => {
              console.log('Confirm Cancel');
            },
          },
        ],
      })
    )
      .pipe(
        switchMap((alert) => {
          return from(alert.present());
        })
      )
      .subscribe();
  }

  deleteSlot(slotIndex: number): void {
    this.updateAllSlotData();

    this.currentShelf.scenes[this.currentSceneIndex].slots.splice(slotIndex, 1);
    let i: number = 0;
    this.currentShelf.scenes[this.currentSceneIndex].slots.forEach((slot) => {
      slot.id = i;
      i++;
    });

    this.addAllSlotsToForm();
  }

  updateAllSlotData(): void {
    let sizesArray: any[] = this.slotsArray.value;
    for (let i: number = 0; i < this.currentShelf.scenes[this.currentSceneIndex].slots.length; i++) {
      this.currentShelf.scenes[this.currentSceneIndex].slots[i].slotSizes.width = parseFloat(('' + sizesArray[i].width).replace(',', '.'));
      this.currentShelf.scenes[this.currentSceneIndex].slots[i].slotSizes.height = parseFloat(
        ('' + sizesArray[i].height).replace(',', '.')
      );
      this.currentShelf.scenes[this.currentSceneIndex].slots[i].slotSizes.depth = parseFloat(('' + sizesArray[i].depth).replace(',', '.'));
    }
  }

  addAllSlotsToForm(): void {
    this.slotsFormGroup = this.formBuilder.group({
      slotsArray: this.formBuilder.array([]),
    });

    this.currentShelf.scenes[this.currentSceneIndex].slots.forEach((slot) => {
      const slotForm = this.formBuilder.group({
        width: [slot.slotSizes.width, Validators.required],
        height: [slot.slotSizes.height, Validators.required],
        depth: [slot.slotSizes.depth, Validators.required],
      });
      this.slotsArray.push(slotForm);
    });
  }
}
