import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { of, tap } from 'rxjs';
import { catchError, concatMap, map, switchMap, withLatestFrom } from 'rxjs/operators';
import {
  startCheckoutAction,
  startCheckoutAllAction,
  startCheckoutAllSucceededAction,
} from '../actions/checkout.actions';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { ExtendedShoppingCartItem } from '../interfaces/shopping-cart-item.interface';
import { isBuyingCurrentShelf } from '../reducers/my-designs.reducer';
import { getCurrentShelf } from '../reducers/shelf-designer.reducer';
import { getShoppingCardItems } from '../reducers/shoppingcart.reducer';
import { CheckoutService } from '../services/checkout.service';
import { AnalyticsService } from '../services/analytics.service';
import { AnalyticLogs } from '../enums/analytic-logs.enum';

@Injectable()
export class CheckoutEffects {
  constructor(
    private actions$: Actions,
    private checkoutService: CheckoutService,
    private store: Store,
    private analyticsService: AnalyticsService
  ) {}

  /*
  @Effect()
  startCheckout$: Observable<StartCheckoutSuccessAction | null> = this.actions$.pipe(
    ofType<StartCheckoutAction>(CheckoutActionTypes.START_CHECKOUT),
    withLatestFrom(this.store.select(getShelfItemSelectedShelf), this.store.select(isBuyingShelf)),
    switchMap(([action, shelf, isBuyingShelf]: [StartCheckoutAction, Shelf, boolean]) =>
      this.checkoutService.checkout(action.payload.shoppingCardItems, shelf, isBuyingShelf)
    ),
    map((affiliateUrl: string) => new StartCheckoutSuccessAction({ affiliateUrl: affiliateUrl })),
    catchError((error, caught) => {
      alert(error);
      return of(null);
    })
  );
  */

  startCheckoutAll$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startCheckoutAllAction),
      tap((action) => {
        this.analyticsService.log(AnalyticLogs.PRODUCT_LIST_CONFIRM_GOTO_AFFILIATE, { affiliate: action.affiliate });
      }),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(
            this.store.select(getCurrentShelf),
            this.store.select(isBuyingCurrentShelf),
            this.store.select(getShoppingCardItems)
          )
        )
      ),
      switchMap(([action, shelf, isBuyingShelf, shoppingCartItems]: [any, ShelfScenes, boolean, ExtendedShoppingCartItem[]]) =>
        this.checkoutService.checkoutAll$(action.affiliate, shoppingCartItems, shelf, isBuyingShelf)
      ),
      map((affiliateUrl: string) => startCheckoutAllSucceededAction({ affiliateUrl: affiliateUrl })),
      catchError((error, caught) => {
        alert(error);
        return of(null);
      })
    )
  );

  startCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(startCheckoutAction),
      switchMap((action: any) => this.checkoutService.checkout$(action.affiliate, action.product)),
      map((affiliateUrl: string) => startCheckoutAllSucceededAction({ affiliateUrl: affiliateUrl })),
      catchError((error, caught) => {
        alert(error);
        return of(null);
      })
    )
  );
}
