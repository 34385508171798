import { Component, OnInit } from '@angular/core';
import { environment } from './../../../environments/environment';
import * as Affiliates from '../../../assets/json/affiliate-informations.json';
import { AffiliateInformation } from '../../core/interfaces/affiliate.interface';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss'],
})
export class LandingPageComponent implements OnInit {
  environment = environment;
  affiliateInformations: { [key: string]: AffiliateInformation } = Affiliates;

  constructor() { }

  ngOnInit(): void { }
}
