export enum MaterialsEnum {
  FiberVegetable = 'fiber-vegetable',
  FiberAnimal = 'fiber-animal',
  FiberChemical = 'fiber-chemical',
  Wood = 'wood',
  Carton = 'carton',
  Plastic = 'plastic',
  Glass = 'glass',
  Metal = 'metal',
  Stone = 'stone',
  Concrete = 'concrete',
  Ceramic = 'ceramic',
}
