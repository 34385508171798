import { Injectable } from '@angular/core';
import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { AnalyticLogs } from '../enums/analytic-logs.enum';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private analytics: Analytics;

  constructor() {
    this.analytics = getAnalytics();
  }

  log(message: AnalyticLogs, params?: { [key: string]: any }): void {
    console.log('>>> event logging: ', message, params);
    if (params) {
      // Google Analytics
      logEvent(this.analytics, message, params);
      //Matomo
      (window as any)._paq?.push(['trackEvent', message, message, JSON.stringify(params)]);
      //MixPanel
      // Track an event. It can be anything, but in this example, we're tracking a Signed Up event.
      // Include a property about the signup, like the Signup Type
      mixpanel.track(message, params);
    } else {
      logEvent(this.analytics, message);
      (window as any)._paq?.push(['trackEvent', message, message]);
      mixpanel.track(message, {});
    }
  }
}
