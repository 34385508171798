import { createAction, props } from '@ngrx/store';

const actionType = '[General]';

export const showAppMenuAction = createAction(`${actionType} Show app menu`);

export const hideAppMenuAction = createAction(`${actionType} hide app menu`);

export const shareAction = createAction(`${actionType} Share`);
export const shareOnPinterestAction = createAction(`${actionType} Share on pinterest`);
export const shareOnTwitterAction = createAction(`${actionType} Share on twitter`);
export const shareOnFacebookAction = createAction(`${actionType} Share on facebook`);

export const initUserInterfaceAction = createAction(
  `${actionType} Init user interface`,
  props<{ splitMode: boolean; screenWidth: number; desktopPlatform: boolean }>()
);

export const gotoSignInAction = createAction(`${actionType} Goto sign in`);

export const openReviewPopupAction = createAction(`${actionType} Open review popup`);
export const openReviewPopupSuccededAction = createAction(`${actionType} Open review popup succeded`);
