import { AffiliatesEnum } from './../enums/affiliates.enum';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AdminToolService } from '../../admin/services/admin-tool.service';
import { SlotsEditorComponent } from '../../admin/slots-editor/slots-editor.component';
import { Affiliate } from '../interfaces/affiliate.interface';
import { Item } from '../interfaces/item.interface';
import { AffiliateService } from '../services/affiliate.service';
import { DebugService } from '../services/debug.service';
import { ItemService } from '../services/item.service';
import { ShelvesService } from '../services/shelf.service';
import { AdminMenuComponent } from './../../admin/admin-menu/admin-menu.component';
import {
  addNewItemAction,
  addNewShelfAction,
  addSceneAction,
  addSceneSuccededAction,
  checkForRequiredFieldsAction,
  cloneItemAction,
  cloneShelfAction,
  deleteItemAction,
  deleteItemFailedAction,
  deleteItemSucceededAction,
  deleteSceneAction,
  deleteSceneFailedAction,
  deleteSceneSucceededAction,
  deleteShelfAction,
  deleteShelfFailedAction,
  deleteShelfSucceededAction,
  deleteShelvesAction,
  hideAdminMenuAction,
  hideSlotsEditorAction as closeSlotsEditorAction,
  importDataFromAffiliateAction,
  importDataFromAffiliateFailedAction,
  importDataFromAffiliateSucceededAction,
  loadAffiliatesAction,
  loadAffiliatesFailedAction,
  loadAffiliatesSucceededAction,
  loadOrFilterAdminItemsAction,
  loadItemsFailedAction,
  loadOrFilterAdminItemsSucceededAction,
  loadShelfAction,
  loadShelfSucceededAction,
  openSlotsEditorAction,
  openSlotsEditorSucceededAction,
  resetUsageCountAction,
  showAdminMenuAction,
  showDeleteItemConfirmationQuestionAction,
  showDeleteSceneConfirmationQuestionAction,
  showDeleteShelfConfirmationQuestionAction,
  showHomeAction,
  showItemsListAction,
  showShelfsListAction,
  updateDataFromAffiliateAction,
  updateDataFromAffiliateFailedAction,
  updateDataFromAffiliateSucceededAction,
  updateItemAction,
  updateItemFailedAction,
  updateItemSucceededAction,
  updateShelfAction,
  updateShelfFailedAction,
  updateShelfSucceededAction,
} from './../actions/admin.actions';
import { AsinResult2 } from '../interfaces/asin-result2.interface';
import { getCachedItems } from '../reducers/admin.reducer';

@Injectable()
export class AdminEffects {
  constructor(
    private actions$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private loadingController: LoadingController,
    private modalController: ModalController,
    private adminToolService: AdminToolService,
    private alertController: AlertController,
    private itemService: ItemService,
    private affiliateService: AffiliateService,
    private translocoService: TranslocoService,
    private shelvesService: ShelvesService,
    private debugService: DebugService
  ) { }

  updateItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateItemAction),
        switchMap((action) =>
          from(this.loadingController.create({ message: this.translocoService.translate('admin-tool.items.uploading') })).pipe(
            switchMap((loader) => loader.present()),
            map(() => action)
          )
        ),
        switchMap((action) => this.adminToolService.updateItem$(action.item)),
        map((item) => updateItemSucceededAction({ item })),
        catchError((error, caught) => {
          console.error('Error while updating data from amazon', error);
          this.store.dispatch(updateItemFailedAction({ error }));
          return of(null);
        })
      ),
    { dispatch: true }
  );

  updateSuccededItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateItemSucceededAction),
        switchMap((action) => this.loadingController.dismiss()),
        map(() => loadOrFilterAdminItemsAction({
          configuration: { reset: true },
          filterAdminItems: {}
        }))
      ),
    { dispatch: true }
  );

  updateFailedItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateItemFailedAction),
        tap((action) => {
          alert(action.error.message);
          this.loadingController.dismiss();
        })
      ),
    { dispatch: false }
  );

  updateShelf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateShelfAction),
        switchMap((action) =>
          from(this.loadingController.create({ message: this.translocoService.translate('admin-tool.shelves.uploading') })).pipe(
            switchMap((loader) => loader.present()),
            map(() => action)
          )
        ),
        switchMap((action) => this.adminToolService.updateShelf$(action.shelf)),
        map((res) => updateShelfSucceededAction()),
        catchError((error, caught) => {
          return of(updateShelfFailedAction({ error }));
        })
      ),
    { dispatch: true }
  );

  updateShelfSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateShelfSucceededAction),
        switchMap((action) => this.loadingController.dismiss())
      ),
    { dispatch: false }
  );

  updateShelfFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateShelfFailedAction),
        tap((action) => {
          console.error(action.error.message);
          alert(action.error.message);
        }),
        switchMap((action) => this.loadingController.dismiss())
      ),
    { dispatch: false }
  );

  showAdminMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showAdminMenuAction),
        switchMap((action) =>
          from(
            this.modalController.create({
              component: AdminMenuComponent,
              cssClass: 'app-menu',
            })
          ).pipe(switchMap((modal) => modal.present()))
        )
      ),
    { dispatch: false }
  );

  hideAdminMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hideAdminMenuAction),
        switchMap((action) => from(this.modalController.dismiss()))
      ),
    { dispatch: false }
  );

  showShelfsList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showShelfsListAction),
        tap(() => this.router.navigate(['/admin/shelves'], { fragment: this.route.snapshot.fragment })),
        map(() => hideAdminMenuAction())
      ),
    { dispatch: true }
  );

  showItemsList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showItemsListAction),
        tap(() => this.router.navigate(['/admin/items'], { fragment: this.route.snapshot.fragment })),
        map(() => hideAdminMenuAction())
      ),
    { dispatch: true }
  );

  showHomeAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showHomeAction),
        tap(() => this.router.navigate(['/'], { fragment: this.route.snapshot.fragment })),
        map(() => hideAdminMenuAction())
      ),
    { dispatch: true }
  );

  addNewSelfAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addNewShelfAction),
        switchMap(() => from(this.loadingController.create({})).pipe(switchMap((loader) => loader.present()))),
        switchMap(() => this.adminToolService.createNewShelf$()),
        tap((res) => this.loadingController.dismiss()),
        tap((res) => this.router.navigate([`/admin/shelves/details/${res.id}`], { fragment: this.route.snapshot.fragment }))
      ),
    { dispatch: false }
  );

  cloneShelf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cloneShelfAction),
        switchMap((action) =>
          from(this.loadingController.create({})).pipe(
            switchMap((loader) => loader.present()),
            map(() => action)
          )
        ),
        switchMap((action) => this.adminToolService.cloneShelf$(action.shelf)),
        tap((res) => this.loadingController.dismiss()),
        tap((res) => this.router.navigate([`/admin/shelves/details/${res.id}`], { fragment: this.route.snapshot.fragment }))
      ),
    { dispatch: false }
  );

  showDeleteShelfConfirmationQuestion$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showDeleteShelfConfirmationQuestionAction),
        switchMap((action) => {
          return from(
            this.alertController.create({
              cssClass: 'my-custom-class',
              header: 'Delete Shelf?',
              message: 'Do you really want to delete this shelf?',
              buttons: ['Yes', 'Cancel'],
            })
          ).pipe(
            tap((alert) => {
              alert.present();
            }),
            switchMap((alert) => from(alert.onDidDismiss()).pipe(withLatestFrom(of(action))))
          );
        }),
        map(([detail, action]) => {
          if (detail.role != 'cancel') {
            return deleteShelfAction({ shelf: action.shelf });
          }
        }),
        filter((action) => !!action)
      ),
    { dispatch: true }
  );

  deleteShelf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteShelfAction),
        switchMap((action) =>
          from(this.loadingController.create({})).pipe(
            switchMap((loader) => loader.present()),
            map(() => action)
          )
        ),
        switchMap((action) => this.adminToolService.deleteShelf$(action.shelf)),
        map(() => deleteShelfSucceededAction()),
        catchError((error, caught) => {
          this.store.dispatch(deleteShelfFailedAction(error));
          return caught;
        })
      ),
    { dispatch: true }
  );

  deleteShelfSucceededAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteShelfSucceededAction),
        switchMap(() => from(this.loadingController.dismiss()))
      ),
    { dispatch: false }
  );

  deleteShelfFailedAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteShelfFailedAction),
        tap((action) => {
          alert(action.error?.message ?? 'Unknown Error');
          this.loadingController.dismiss();
        })
      ),
    { dispatch: false }
  );

  loadItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadOrFilterAdminItemsAction),
      withLatestFrom(this.store.select(getCachedItems)),
      switchMap(([action, cachedItems]: [any, Item[]]) => this.itemService.getItemsForAdmin$(action.configuration, cachedItems, action.filterAdminItems)),
      map((items: Item[]) => loadOrFilterAdminItemsSucceededAction({
        items
      })),
      catchError((error: Error, caught) => {
        console.error(error);
        this.store.dispatch(loadItemsFailedAction({ error }));
        return caught;
      })
    )
  );

  addNewItemAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addNewItemAction),
        switchMap(() => from(this.loadingController.create({})).pipe(switchMap((loader) => loader.present()))),
        switchMap(() => this.adminToolService.createNewItem$()),
        tap((res) => this.loadingController.dismiss()),
        tap((res) => this.router.navigate([`/admin/items/details/${res.id}`], { fragment: this.route.snapshot.fragment })),
        map(() => loadOrFilterAdminItemsAction({
          configuration: { reset: true },
          filterAdminItems: {}
        }))
      ),
    { dispatch: true }
  );

  showDeleteItemConfirmationQuestion$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showDeleteItemConfirmationQuestionAction),
        switchMap((action) => {
          return from(
            this.alertController.create({
              cssClass: 'my-custom-class',
              header: 'Delete Item?',
              message: 'Do you really want to delete this item?',
              buttons: ['Yes', 'Cancel'],
            })
          ).pipe(
            tap((alert) => {
              alert.present();
            }),
            switchMap((alert) => from(alert.onDidDismiss()).pipe(withLatestFrom(of(action))))
          );
        }),
        map(([detail, action]) => {
          if (detail.role != 'cancel') {
            return deleteItemAction({ item: action.item });
          }
        }),
        filter((action) => !!action)
      ),
    { dispatch: true }
  );

  deleteItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteItemAction),
        switchMap((action) =>
          from(this.loadingController.create({})).pipe(
            switchMap((loader) => loader.present()),
            map(() => action)
          )
        ),
        switchMap((action) => this.adminToolService.deleteItem$(action.item)),
        map(() => deleteItemSucceededAction()),
        catchError((error, caught) => {
          this.store.dispatch(deleteItemFailedAction(error));
          return caught;
        })
      ),
    { dispatch: true }
  );

  deleteItemSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteItemSucceededAction),
        switchMap((action) => from(this.loadingController.dismiss())),
        map(() => loadOrFilterAdminItemsAction({
          configuration: { reset: true },
          filterAdminItems: {}
        }))
      ),
    { dispatch: true }
  );

  deleteItemFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteItemFailedAction),
        tap((action) => {
          alert(action.error?.message ?? 'Unknown Error');
          this.loadingController.dismiss();
        })
      ),
    { dispatch: false }
  );

  cloneItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cloneItemAction),
        switchMap((action) =>
          from(this.loadingController.create({})).pipe(
            switchMap((loader) => loader.present()),
            map(() => action)
          )
        ),
        switchMap((action) => this.adminToolService.cloneItem$(action.item)),
        tap((res) => this.loadingController.dismiss()),
        tap((res) => this.router.navigate([`/admin/items/details/${res.id}`], { fragment: this.route.snapshot.fragment })),
        map(() => loadOrFilterAdminItemsAction({
          configuration: { reset: true },
          filterAdminItems: {}
        }))
      ),
    { dispatch: true }
  );

  openSlotsEditor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openSlotsEditorAction),
        switchMap((action) =>
          from(
            this.modalController.create({
              component: SlotsEditorComponent,
              componentProps: {
                shelf: action.shelf,
                sceneIndex: action.sceneIndex,
              },
            })
          ).pipe(
            switchMap((loader) => {
              return loader.present();
            })
          )
        ),
        map(() => openSlotsEditorSucceededAction())
      ),
    { dispatch: true }
  );

  closeSlotsEditor$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeSlotsEditorAction),
        switchMap((action) => from(this.modalController.dismiss()))
      ),
    { dispatch: false }
  );

  updateDataFromAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(updateDataFromAffiliateAction),
      switchMap((action) =>
        this.itemService
          .getItemFromAffiliate$({ productId: action.asin, productUrl: action.productUrl, affiliateId: action.affiliateId })
          .pipe(
            tap((item) => {
              if (item.price?.value) action.priceFormControl.setValue(item.price?.value);
              if (item.priceSale?.value) action.priceSaleFormControl.setValue(item.priceSale?.value);
              action.ratingFormControl.setValue(item.rating);
              action.ratingNumberFormControl.setValue(item.ratingNumber);
              if (item.url) action.sellerUrlFormControl.setValue(item.url);
            })
          )
      ),
      map(() => updateDataFromAffiliateSucceededAction()),
      catchError((error, caught) => {
        this.store.dispatch(updateDataFromAffiliateFailedAction());
        return caught;
      })
    )
  );

  updateDataFromAffiliateFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updateDataFromAffiliateFailedAction),
        tap((e) => {
          console.error(e);
          window.alert('Could not update data from Affiliate: ' + e);
        })
      ),
    { dispatch: false }
  );

  importDataFromAffiliate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(importDataFromAffiliateAction),
      switchMap((action) =>
        this.itemService
          .getItemFromAffiliate$({ productId: action.asin, productUrl: action.productUrl, affiliateId: action.affiliateId })
          .pipe(
            tap((item: AsinResult2) => {
              console.log('>>> item from Affiliate', item);
              action.nameFormControl.setValue(item.title);
              action.descriptionFormControl.setValue(item.description);
              action.priceFormControl.setValue(item.price.value);
              if (item.priceSale) {
                action.priceSaleFormControl.setValue(item.priceSale.value);
              }
              action.sellerUrlFormControl.setValue(item.url);
              action.colorManufacturerFormControl.setValue(item.color);
              // action.materialsFormControl?.setValue(item.material);
              action.ratingFormControl.setValue(item.rating);
              action.ratingNumberFormControl.setValue(item.ratingNumber);
            })
          )
      ),
      map(() => importDataFromAffiliateSucceededAction()),
      catchError((e) => {
        console.error('Error while importing data from amazon', e);
        return of(importDataFromAffiliateFailedAction({ error: e }));
      })
    )
  );

  importDataFromAffiliateFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(importDataFromAffiliateFailedAction),
        tap((action) => {
          console.error(action.error);
          window.alert('Could not import data from Affiliate: ' + action.error);
        })
      ),
    { dispatch: false }
  );

  loadAffiliates$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadAffiliatesAction),
      switchMap(() => this.affiliateService.getAffiliates$()),
      map((affiliates: Affiliate[]) => loadAffiliatesSucceededAction({ affiliates })),
      catchError((error, caught) => {
        console.error(error);
        this.store.dispatch(loadAffiliatesFailedAction({ error }));
        return caught;
      })
    )
  );

  resetUsageCount$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(resetUsageCountAction),
        switchMap(() => this.debugService.resetUsageCountOfProducts$()),
        tap((noItems) => alert(`usage count reset of ${noItems} products`))
      ),
    { dispatch: false }
  );

  checkForRequiredFields$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(checkForRequiredFieldsAction),
        switchMap(() => this.debugService.checkForRequiredFields$()),
        tap((noUpdatedItems) => alert(`required fields checked. ${noUpdatedItems} products updated.`))
      ),
    { dispatch: false }
  );

  loadShelf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadShelfAction),
        switchMap((action) => {
          return this.shelvesService.getShelf$(action.id);
        }),
        map((shelf) => loadShelfSucceededAction({ shelf: shelf }))
      ),
    { dispatch: true }
  );

  addScene$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addSceneAction),
        map((action) => {
          return addSceneSuccededAction({ shelf: this.adminToolService.addNewScene(action.shelf) });
        })
      ),
    { dispatch: true }
  );

  showDeleteSceneConfirmationQuestion$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showDeleteSceneConfirmationQuestionAction),
        switchMap((action) => {
          return from(
            this.alertController.create({
              cssClass: 'my-custom-class',
              header: 'Delete Scene?',
              message: 'Do you really want to delete this scene (' + action.shelf.scenes[action.sceneIndexToDelete].name + ')?',
              buttons: ['Yes', 'Cancel'],
            })
          ).pipe(
            tap((alert) => {
              alert.present();
            }),
            switchMap((alert) => from(alert.onDidDismiss()).pipe(withLatestFrom(of(action))))
          );
        }),
        map(([detail, action]) => {
          if (detail.role != 'cancel') {
            return deleteSceneAction({ shelf: action.shelf, sceneIndexToDelete: action.sceneIndexToDelete });
          }
        }),
        filter((action) => !!action)
      ),
    { dispatch: true }
  );

  deleteScene$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteSceneAction),
        switchMap((action) =>
          from(this.loadingController.create({})).pipe(
            switchMap((loader) => loader.present()),
            map(() => action)
          )
        ),
        map((action) => this.adminToolService.deleteScene(action.shelf, action.sceneIndexToDelete)),
        map(() => deleteSceneSucceededAction()),
        catchError((error, caught) => {
          this.store.dispatch(deleteSceneFailedAction(error));
          return caught;
        })
      ),
    { dispatch: true }
  );

  deleteSceneSucceededAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteSceneSucceededAction),
        switchMap(() => from(this.loadingController.dismiss()))
      ),
    { dispatch: false }
  );

  deleteSceneFailedAction = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteSceneFailedAction),
        tap((action) => {
          alert(action.error?.message ?? 'Unknown Error');
          this.loadingController.dismiss();
        })
      ),
    { dispatch: false }
  );

  deleteShelves$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(deleteShelvesAction),
        tap(() => {
          this.adminToolService.deleteUnnecessaryDesigns$().subscribe();
        })
      ),
    { dispatch: false }
  );
}
