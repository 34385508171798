import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { loadMyDesignsAction, loadMyDesignsFailedAction, loadMyDesignsSucceededAction } from '../actions/my-designs.actions';
import { ExtendedDesign, Design } from '../interfaces/design.interface';
import { DesignService } from '../services/design.service';
import { ItemService } from '../services/item.service';

@Injectable()
export class MyDesignsEffects {
  constructor(private actions$: Actions, private store: Store, private designService: DesignService, private itemService: ItemService) {}

  loadMyDesigns$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadMyDesignsAction),
        switchMap((action) => this.designService.loadMyDesigns$()),
        switchMap((design: Design[]) => this.itemService.getExtendedSavedConfigurations$(design)),
        map((designs: ExtendedDesign[]) => loadMyDesignsSucceededAction({ designs })),
        catchError((error, caught) => {
          this.store.dispatch(loadMyDesignsFailedAction({ error }));
          return caught;
        })
      ),
    { dispatch: true }
  );

  loadMyDesignsFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadMyDesignsFailedAction),
        tap((action) => {
          console.error('Error while loading designs', action.error);
          alert(action.error.message);
        })
      ),
    { dispatch: false }
  );
}
