import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  collectionData,
  doc,
  docData,
  DocumentReference,
  Firestore,
  orderBy,
  query,
  Timestamp,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { getDownloadURL, ref, Storage, StorageError, uploadBytesResumable, UploadTaskSnapshot } from '@angular/fire/storage';
import html2canvas from 'html2canvas';
import { from, Observable, of } from 'rxjs';
import { catchError, delay, map, switchMap, take, tap } from 'rxjs/operators';
import { AppUser } from '../../login/entities/user.interface';
import { AuthService } from '../../login/services/auth.service';
import { Design } from '../interfaces/design.interface';
@Injectable({
  providedIn: 'root',
})
export class DesignService {
  private readonly THUMBNAIL_STORAGE_LOCATION = 'designs/thumbnails';

  constructor(private firestore: Firestore, private authService: AuthService, private storage: Storage) {}

  saveMyDesign$(design: Design): Observable<string> {
    return from(addDoc(collection(this.firestore, 'designs'), design)).pipe(map((res: DocumentReference) => res.id));
  }

  loadLastModifiedDesignIfExists$(user: AppUser): Observable<string | null> {
    return from(
      collectionData(query(collection(this.firestore, 'designs'), where('userId', '==', user.uid), orderBy('date', 'desc')), {
        idField: 'id',
      })
    ).pipe(
      take(1),
      map((designs: Design[]) => (designs.length > 0 ? designs[0].id : null))
    );
  }

  loadMyDesign$(designId: string): Observable<Design> {
    return docData(doc(this.firestore, 'designs', designId), { idField: 'id' }).pipe(
      take(1),
      map((design: Design) => {
        if (!design) {
          throw new Error(`Design not found: ${designId}`);
        }
        return design;
      })
    );
  }

  loadMyDesign$_(configId: string): Observable<Design> {
    return docData(doc(this.firestore, 'users', this.authService.userData.uid, 'designs', configId)).pipe(
      take(1),
      map((design: Design) => {
        if (design) {
          design.id = configId;
        }
        return design;
      })
    );
  }

  loadMyDesigns$(): Observable<Design[]> {
    return of(null).pipe(
      switchMap(() =>
        from(
          collectionData(
            query(collection(this.firestore, 'designs'), where('userId', '==', this.authService.userData.uid), orderBy('date')),
            { idField: 'id' }
          ).pipe(
            take(1),
            map((designs: Design[]) => {
              return designs;
            })
          )
        )
      )
    );
  }

  updateMyDesign$(designId: string, design: Design): Observable<any> {
    return of(null).pipe(
      switchMap(() => from(updateDoc(doc(this.firestore, 'designs', designId), design as any))),
      catchError((error: Error) => {
        console.error('Error while updating design in db:', error);
        return of(error);
      })
    );
  }

  updateMyDesignThumbnail$(designId: string, myDesign: Design): Observable<any> {
    return of(null).pipe(
      delay(1000),
      switchMap(() => this.takeScreenShot$()),
      switchMap((blob: Blob) => this.uploadThumbnail$(designId, blob)),
      tap((thumbnailUrl: string) => (myDesign.thumbnailUrl = thumbnailUrl)),
      switchMap((thumbnailUrl: string) => from(updateDoc(doc(this.firestore, 'designs', designId), myDesign as any))),
      catchError((error: Error) => {
        console.error('Error while updating design in db:', error);
        return of(error);
      })
    );
  }

  takeScreenShot$(): Observable<Blob> {
    return of(null).pipe(
      delay(10),
      tap(() => console.time('take screenshot')),
      switchMap(() =>
        html2canvas(document.querySelector('#shelfy-screenshot-container'), {
          allowTaint: false,
          useCORS: true,
        })
      ),
      tap(() => console.timeEnd('take screenshot')),
      switchMap(
        (canvas: HTMLCanvasElement) =>
          new Observable((observer) => {
            canvas.toBlob((blob) => {
              observer.next(blob);
              observer.complete();
            }, 'image/png');
          })
      ),
      map((data) => data as Blob)
    );
  }

  cloneDesign$(designId: string): Observable<string> {
    return this.loadMyDesign$(designId).pipe(
      switchMap((design: Design) => {
        const newDesign: Design = {
          ...design,
          buyingShelf: false,
          userId: this.authService.userData.uid,
          date: Timestamp.fromDate(new Date()),
        };

        return this.saveMyDesign$(newDesign);
      })
    );
  }

  uploadThumbnail$(designId: string, blob: Blob): Observable<string> {
    return new Observable((observer) => {
      const filePath = `${this.THUMBNAIL_STORAGE_LOCATION}/${designId}-thumbmail.png`;
      const fileRef = ref(this.storage, filePath);
      const task = uploadBytesResumable(fileRef, blob);
      task.on(
        'state_changed',
        (snapshot: UploadTaskSnapshot) => {
          // this.uploadingFrontalFileProgress = snapshot.bytesTransferred / snapshot.totalBytes;
          switch (snapshot.state) {
            case 'paused':
              console.log('Upload is paused');
              break;
            case 'running':
              console.log('Upload is running');
              break;
          }
        },
        (error: StorageError) => {
          console.error('Error while uploading file input:', error);
        },
        () => {
          getDownloadURL(task.snapshot.ref).then((url: string) => {
            if (url) {
              observer.next(url);
              observer.complete();
            }
          });
        }
      );
    });
  }
}
