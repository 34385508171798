import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  collection,
  collectionData,
  deleteDoc,
  doc,
  Firestore,
  getDocs,
  getFirestore,
  query,
  QuerySnapshot,
  setDoc,
  Timestamp,
  updateDoc,
  writeBatch,
} from '@angular/fire/firestore';
import { getApp } from 'firebase/app';
import { forkJoin, from, map, Observable, of, switchMap, take, tap } from 'rxjs';
import { AdminToolService } from '../../admin/services/admin-tool.service';
import { MaterialsEnum } from '../enums/materials.enum';
import { ThemesEnum } from '../enums/themes.enum';
import { Item } from '../interfaces/item.interface';
import { LoadItemsConfiguration } from '../interfaces/load-items-configuration.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { ShoppingCartItem } from '../interfaces/shopping-cart-item.interface';
import { ItemService } from './item.service';
import { ShelvesService } from './shelf.service';
import { LoadShelvesConfiguration } from '../interfaces/load-shelves-configuration.interface';

/**
 * Pseudo interface for debug data in design context
 */
export interface DesignDebug {
  buyingShelf: boolean;
  date: Timestamp;
  id: string;
  shelfId: string;
  shoppingCartItems: ShoppingCartItem[];
  userId: string;
}

/**
 * The purpose of this service is to provide debugging functions during development.
 * I. e. firebase scripts that automatically update existing data sets to fit new configurations.
 *
 * !!! THIS SERVICE MUST NOT BE USED IN PRODUCTIVE VERSIONS !!!
 */
@Injectable({
  providedIn: 'root',
})
export class DebugService {
  constructor(
    private firestore: Firestore,
    private http: HttpClient,
    private itemService: ItemService,
    private shelvesService: ShelvesService,
    private adminToolService: AdminToolService
  ) { }

  /**
   * This method is only useful to update items
   */
  updateItemRederingSizesFrom33To33_5$(): Observable<void> {
    return from(getDocs(query(collection(this.firestore, 'items')))).pipe(
      switchMap((itemsSnapshot) => {
        const items: Item[] = itemsSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Item));
        const batch = writeBatch(this.firestore);

        // update all items
        items.forEach((item: Item) => {
          const ref = doc(this.firestore, 'items', item.id);
          let show = {
            height: 0,
            width: 0
          }
          if (item.show.height === 33) {
            show.height = 33.5;
          }
          if (item.show.width === 33) {
            show.width = 33.5;
          }
          if (show.height > 0 || show.width > 0) {
            batch.update(ref, { show });
          }
        });

        return from(batch.commit());
      })
    );
  }

  /**
   * This method is only useful to convert old Firestore shelves, such that they have 3 different size properties.
   */
  convertShelfSizesToSeparateFields$(): Observable<void> {
    return collectionData(collection(this.firestore, 'shelfs'), { idField: 'id' }).pipe(
      take(1),
      map((oldShelves: ShelfScenes[]) => {
        oldShelves.forEach((oldShelf: ShelfScenes) => {
          // only convert shelves that have a sizes property
          if (oldShelf.hasOwnProperty('sizes')) {
            const id = oldShelf.id;

            // parse sizes string automatically
            const [width, depth, height] = (oldShelf as any).sizes.replace('cm', '').replace(/,/g, '.').split('x');

            const newShelf: ShelfScenes = {
              ...oldShelf,
              height: Number(height),
              width: Number(width),
              depth: Number(depth),
            };
            delete newShelf.id;

            updateDoc(doc(this.firestore, `shelfs/${id}`), newShelf as any);
          }
        });

        return null;
      })
    );
  }

  /**
   * This method is useful to convert old Firestore items, such that they have 3 different size properties.
   */
  convertItemSizesToSeparateFields$(): Observable<void> {
    return collectionData(collection(this.firestore, 'items'), { idField: 'id' }).pipe(
      take(1),
      map((oldItems: Item[]) => {
        oldItems.forEach((oldItem: Item) => {
          // only convert items that have a sizes property
          if (oldItem.hasOwnProperty('sizes')) {
            const id = oldItem.id;

            // parse sizes string automatically
            const [height, width, depth] = (oldItem as any).sizes.replace('cm', '').replace(/,/g, '.').split('x');

            const newItem: Item = {
              ...oldItem,
              height: Number(height),
              width: Number(width),
              depth: Number(depth),
            };
            delete newItem.id;

            const docRef = doc(this.firestore, 'items', id);
            updateDoc(docRef, newItem as any); // TODO: geht nur mit any...weiß nicht warum
          }
        });

        return null;
      })
    );
  }

  setActiveStatusOfItems(): Observable<void> {
    return from(getDocs(query(collection(this.firestore, 'items')))).pipe(
      map((querySnapshot: QuerySnapshot) => {
        return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      }),
      switchMap((items: Item[]) => {
        let itemUpdates: Observable<void>[] = [];

        items.forEach((item: Item) => {
          let active = false;
          if (item.affiliates.amazonDE) {
            active = active || (item.affiliates.amazonDE.active ?? false);
          }
          if (item.affiliates.amazonUS) {
            active = active || (item.affiliates.amazonUS.active ?? false);
          }
          if (item.affiliates.ikeaDE) {
            active = active || (item.affiliates.ikeaDE.active ?? false);
          }

          return itemUpdates.push(
            from(
              setDoc(doc(this.firestore, `items/${item.id}`), {
                ...item,
                active,
              })
            )
          );
        });
        return forkJoin(itemUpdates);
      }),
      map(() => null)
    );
  }

  /**
   * Resets the "usageCount" flag of all products.
   */
  resetUsageCountOfProducts$(): Observable<void> {
    const shelfQuery = from(getDocs(query(collection(this.firestore, 'shelfs'))));
    const itemQuery = from(getDocs(query(collection(this.firestore, 'items'))));

    // Commit the batch
    return forkJoin([shelfQuery, itemQuery]).pipe(
      switchMap(([shelvesSnapshot, itemsSnapshot]) => {
        const shelfIds: string[] = shelvesSnapshot.docs.map((doc) => doc.id);
        const itemIds: string[] = itemsSnapshot.docs.map((doc) => doc.id);

        const batch = writeBatch(this.firestore);

        shelfIds.forEach((shelfId: string) => {
          const ref = doc(this.firestore, 'shelfs', shelfId);
          batch.update(ref, { usageCount: 0 });
        });
        itemIds.forEach((itemId: string) => {
          const ref = doc(this.firestore, 'items', itemId);
          batch.update(ref, { usageCount: 0 });
        });

        return from(batch.commit());
      })
    );
  }

  checkForRequiredFields$(): Observable<number> {
    // Commit the batch
    return of(null).pipe(
      switchMap(() =>
        forkJoin({
          items: this.itemService.getItemsForAdmin$({} as LoadItemsConfiguration, []),
          shelves: this.shelvesService.getShelves$({} as LoadShelvesConfiguration),
        })
      ),
      switchMap((result) => {
        const batch = writeBatch(this.firestore);

        let noUpdatedItems = 0;

        result.items.forEach((item: Item) => {
          if (this.adminToolService.checkRequiredFields(item)) {
            const ref = doc(this.firestore, 'items', item.id);
            batch.set(ref, item);
            noUpdatedItems++;
          }
        });
        result.shelves.forEach((shelf: ShelfScenes) => {
          if (this.adminToolService.checkRequiredFields(shelf)) {
            const ref = doc(this.firestore, 'shelfs', shelf.id);
            batch.set(ref, shelf);
            noUpdatedItems++;
          }
        });

        return from(batch.commit()).pipe(map(() => noUpdatedItems));
      })
    );
  }

  restoreShelves$(): Observable<void> {
    return this.http.get('/assets/backup/shelfs.json').pipe(
      switchMap((shelves) => {
        const batch = writeBatch(this.firestore);

        for (const [key, properties] of Object.entries(shelves)) {
          const ref = doc(this.firestore, 'shelfs', key);
          batch.update(ref, { ...properties });
        }

        return from(batch.commit());
      }),
      map((status) => console.log('Succeeded to restore shelves', status))
    );
  }

  /**
   * BE CAREFUL: This method performs dynamic stuff which can change from time to time.
   * Be sure you know what you are doing.
   */
  quickFixes$(): Observable<void> {
    const shelfQuery = from(getDocs(query(collection(this.firestore, 'shelfs'))));
    const itemQuery = from(getDocs(query(collection(this.firestore, 'items'))));

    const dateAddedPlain = new Date();
    const dateAddedFormatted = new Intl.DateTimeFormat('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false,
      timeZoneName: 'short',
    }).format(dateAddedPlain);

    const affiliates = ['ikeaDE', 'amazonDE', 'amazonUS'];

    // Commit the batch
    return forkJoin([shelfQuery, itemQuery]).pipe(
      switchMap(([shelvesSnapshot, itemsSnapshot]) => {
        const shelves: ShelfScenes[] = shelvesSnapshot.docs.map((doc) => doc.data() as ShelfScenes);
        const items: Item[] = itemsSnapshot.docs.map((doc) => doc.data() as Item);

        const batch = writeBatch(this.firestore);

        shelves.forEach((shelf: ShelfScenes) => {
          if (!shelf.id) {
            return;
          }

          console.log('>>> shelf', shelf);
          const ref = doc(this.firestore, 'shelfs', shelf.id);
          const updatedShelf: ShelfScenes = { ...shelf };

          // 1) Products: active => data type boolean, not string
          if ((updatedShelf.active as any) === 'true') {
            updatedShelf.active = true;
          } else if ((updatedShelf.active as any) === 'false') {
            updatedShelf.active = false;
          }

          // 2) Products: Affiliate fields: price / priceSale / rating / ratingNumber => data type number, not string
          affiliates.forEach((affiliate: string) => {
            if (updatedShelf.affiliates.hasOwnProperty(affiliate)) {
              ['price', 'priceSale', 'rating', 'ratingNumber'].forEach((property: string) => {
                if (typeof updatedShelf.affiliates[affiliate][property] === 'string') {
                  updatedShelf.affiliates[affiliate][property] = Number(updatedShelf.affiliates[affiliate][property]);
                  if (updatedShelf.affiliates[affiliate][property] === 0) {
                    delete updatedShelf.affiliates[affiliate][property];
                  }
                }
              });
            }
          });

          // 3) Products: Insert dateAdded field for all data sets
          if (!updatedShelf.hasOwnProperty('dateAdded')) {
            updatedShelf.dateAdded = Timestamp.fromDate(new Date(dateAddedFormatted));
          }

          // 4) Products: If price and priceSale have same value, remove priceSale
          if (updatedShelf.hasOwnProperty('affiliates')) {
            affiliates.forEach((affiliate: string) => {
              if (updatedShelf.affiliates.hasOwnProperty(affiliate)) {
                if (updatedShelf.affiliates[affiliate].price === updatedShelf.affiliates[affiliate].priceSale) {
                  delete updatedShelf.affiliates[affiliate].priceSale;
                }
              }
            });
          }

          batch.update(ref, { ...updatedShelf });
        });
        items.forEach((item: Item) => {
          if (!item.id) {
            return;
          }

          const ref = doc(this.firestore, 'items', item.id);
          const updatedItem: Item = { ...item };

          // 1) Products: active => data type boolean, not string
          if ((updatedItem.active as any) === 'true') {
            updatedItem.active = true;
          } else if ((updatedItem.active as any) === 'false') {
            updatedItem.active = false;
          }

          // 2) Products: Affiliate fields: price / priceSale / rating / ratingNumber => data type number, not string
          affiliates.forEach((affiliate: string) => {
            if (updatedItem.affiliates.hasOwnProperty(affiliate)) {
              ['price', 'priceSale', 'rating', 'ratingNumber'].forEach((property: string) => {
                if (typeof updatedItem.affiliates[affiliate][property] === 'string') {
                  updatedItem.affiliates[affiliate][property] = Number(updatedItem.affiliates[affiliate][property]);
                  if (updatedItem.affiliates[affiliate][property] === 0) {
                    delete updatedItem.affiliates[affiliate][property];
                  }
                }
              });
            }
          });

          // 3) Products: Insert dateAdded field for all data sets
          if (!updatedItem.hasOwnProperty('dateAdded')) {
            updatedItem.dateAdded = Timestamp.fromDate(new Date(dateAddedFormatted));
          }

          // 4) Products: If price and priceSale have same value, remove priceSale
          if (updatedItem.hasOwnProperty('affiliates')) {
            affiliates.forEach((affiliate: string) => {
              if (updatedItem.affiliates.hasOwnProperty(affiliate)) {
                if (updatedItem.affiliates[affiliate].price === updatedItem.affiliates[affiliate].priceSale) {
                  delete updatedItem.affiliates[affiliate].priceSale;
                }
              }
            });
          }

          batch.update(ref, { ...updatedItem });
        });

        return from(batch.commit());
      })
    );
  }

  /*
  renameCategoriesToTypes$(): Observable<void> {
    return from(getDocs(query(collection(this.firestore, 'items')))).pipe(
      switchMap((itemsSnapshot) => {
        const items: Item[] = itemsSnapshot.docs.map((doc) => doc.data() as Item);

        const batch = writeBatch(this.firestore);

        items.forEach((item: Item) => {
          if (!item.id) {
            return;
          }

          const ref = doc(this.firestore, 'items', item.id);
          const itemCopy = { ...item };

          if ((itemCopy as any).types) {
            itemCopy.type = (itemCopy as any).types[0] ?? null;
            delete (itemCopy as any).types;
          }

          if ((itemCopy as any).category) {
            if (!itemCopy.type) {
              itemCopy.type = (itemCopy as any).category;
            }
            delete (itemCopy as any).category;
          }

          if (!itemCopy.type) {
            itemCopy.type = TypesEnum.Other;
          }

          console.log('>>> new item', itemCopy);

          batch.set(ref, { ...itemCopy });
        });

        return from(batch.commit());
      }),
      map(() => console.log('Succeeded to rename item categories to types and reduce arrays'))
    );
  }
  */

  refactorRoomsToThemes$(): Observable<void> {
    return from(getDocs(query(collection(this.firestore, 'items')))).pipe(
      switchMap((itemsSnapshot) => {
        const items: Item[] = itemsSnapshot.docs.map((doc) => doc.data() as Item);

        const batch = writeBatch(this.firestore);

        items.forEach((item: Item) => {
          if (!item.id) {
            return;
          }

          const ref = doc(this.firestore, 'items', item.id);
          const itemCopy = { ...item };

          if (!itemCopy.themes) {
            itemCopy.themes = [];
          }

          const rooms: string[] = (itemCopy as any).rooms as string[];
          if (rooms) {
            // intelligent mapping
            rooms.forEach((room: string) => {
              switch (room) {
                case 'office':
                  itemCopy.themes.push(ThemesEnum.Office);
                  break;
                case 'childs':
                  itemCopy.themes.push(ThemesEnum.Children);
                  break;
                default:
                  break;
              }
            });

            delete (itemCopy as any).rooms;
          }

          batch.set(ref, { ...itemCopy });
        });

        return from(batch.commit());
      }),
      map(() => console.log('Succeeded to refactor item rooms and reduce arrays'))
    );
  }

  getAllProductColors$(): Observable<void> {
    return forkJoin([
      from(getDocs(query(collection(this.firestore, 'items')))),
      from(getDocs(query(collection(this.firestore, 'shelfs')))),
    ]).pipe(
      switchMap(([itemsSnapshot, shelvesSnapshot]) => {
        const items: Item[] = itemsSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Item));
        const shelves: ShelfScenes[] = shelvesSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as ShelfScenes));

        const batch = writeBatch(this.firestore);

        // const colors: string[] = [];

        // update all items
        items.forEach((item: Item) => {
          const ref = doc(this.firestore, 'items', item.id);
          // batch.update(ref, { color: this.mapColor(item.color) });

          console.log('Color Mapped:', this.mapColor('grün'));

          // if (item.hasOwnProperty('color') && !colors.includes(item.color.toLowerCase())) {
          //   colors.push(item.color.toLowerCase());
          // }
        });

        // update all shelves
        shelves.forEach((shelf: ShelfScenes) => {
          const ref = doc(this.firestore, 'shelfs', shelf.id);
          // batch.update(ref, { color: this.mapColor(shelf.color) });

          // if (shelf.hasOwnProperty('color') && !colors.includes(shelf.color.toLowerCase())) {
          //   colors.push(shelf.color.toLowerCase());
          // }
        });

        // console.log(`>>> colors:`, colors);

        return from(batch.commit());
      })
    );
  }

  addMaterialArraysForAllItems$(): Observable<void> {
    return from(getDocs(query(collection(this.firestore, 'items')))).pipe(
      switchMap((itemsSnapshot) => {
        const items: Item[] = itemsSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id } as Item));
        const batch = writeBatch(this.firestore);

        // update all items
        items.forEach((item: Item) => {
          const ref = doc(this.firestore, 'items', item.id);
          let materials: MaterialsEnum[] = item.materials;
          if (!Array.isArray(materials)) {
            materials = [];
          }
          batch.update(ref, { materials });
        });

        return from(batch.commit());
      })
    );
  }

  mapColor(oldColorName: string): string {
    switch (oldColorName) {
      case 'grau':
        return 'gray';
      case 'blau':
        return 'blue';
      case 'braun':
        return 'brown';
      case 'beige':
        return 'beige';
      case 'gelb':
        return 'yellow';
      case 'schwarz':
        return 'black';
      case 'weiß':
        return 'white';
      case 'bunt':
        return 'colorful';
      case 'orange':
        return 'orange';
      case 'grün':
        return 'green';
      case 'rosa':
        return 'dark-pink';
      case 'türkis':
      case 'türkies':
        return 'turquoise';
      case 'pink':
        return 'pink';
      case 'rot':
        return 'red';
      case 'violett':
        return 'purple';
      case 'glas':
        return 'glas';
      case 'hochglanz weiß':
      case 'hochglanz-weiß':
        return 'white';
      default:
        console.error(`Could not map old color code ${oldColorName}`);
        return '';
    }
  }

  /**
   * Function for migrating data from origin to dev
   */
  migrateAColection$(collectionName: string): Observable<any> {
    const firestoreDev = getFirestore(getApp('dev'));

    return from(getDocs(query(collection(this.firestore, collectionName)))).pipe(
      map((querySnapshot: QuerySnapshot) => {
        return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      }),
      switchMap((documents: any[]) => {
        let updateDocObs: Observable<any>[] = [];
        let batch: any; // = writeBatch(firestoreDev);

        console.log(collectionName, documents.length);
        let checkIfBatchCanCommited: boolean = false;
        documents.forEach((document: any, index: number) => {
          if (index % 500 == 0) {
            if (checkIfBatchCanCommited) {
              updateDocObs.push(from(batch.commit()));
            }
            batch = writeBatch(firestoreDev);
          }
          const ref = doc(firestoreDev, collectionName, document.id);
          batch.set(ref, { ...document });
          checkIfBatchCanCommited = true;
          if (index == documents.length - 1) {
            updateDocObs.push(from(batch.commit()));
          }
        });

        return forkJoin(updateDocObs);
      }),
      tap(() => console.log('>>> ' + collectionName + ' MIGRATION DONE <<<'))
    );
  }

  /**
   * Refactor THIS function for every updating stuff :) !!!
   */
  read$(collectionName: string): Observable<unknown> {
    return from(getDocs(query(collection(this.firestore, collectionName)))).pipe(
      map((querySnapshot: QuerySnapshot) => {
        return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      }),
      tap((docs: any[]) => {
        console.log(`>>> ${collectionName}: ${docs.length} <<<`);
      })
    );
  }

  readDesigns$(collectionName: string): Observable<unknown> {
    return from(getDocs(query(collection(this.firestore, collectionName)))).pipe(
      map((querySnapshot: QuerySnapshot) => {
        return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      }),
      tap((designs: DesignDebug[]) => {
        let month: any = {
          total: 0
        };
        designs.forEach((design) => {
          let date = design.date.toDate();
          if (month[`${date.getFullYear()}-${date.getMonth() + 1}`] !== undefined) {
            month[`${date.getFullYear()}-${date.getMonth() + 1}`]++;
          } else {
            month[`${date.getFullYear()}-${date.getMonth() + 1}`] = 1;
          }
          month.total++;
        });
        console.log(`>>> ${collectionName} <<<`, month);
      })
    );
  }

  /**
   * Refactor THIS function for every updating stuff :) !!!
   */
  update$(): Observable<void> {
    return from(getDocs(query(collection(this.firestore, 'items')))).pipe(
      map((querySnapshot: QuerySnapshot) => {
        return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      }),
      switchMap((items: Item[]) => {
        console.log('start updating items');
        const batch = writeBatch(this.firestore);
        // update the shelvesData
        items.forEach((item: Item) => {
          const ref = doc(this.firestore, 'items', item.id);
          let newItem = { ...item, show: { height: item.height, width: item.width } };
          batch.update(ref, newItem as any);
        });

        return from(batch.commit());
      }),
      tap(() => console.log('>>> UPDATE DONE <<<'))
    );
  }

  /**
   * Refactor THIS function for every deleting stuff :) !!!
   */
  deleteDocuments$(): Observable<any> {
    // const firestoreDev = getFirestore(getApp('dev'));

    return from(getDocs(query(collection(this.firestore, 'designs')))).pipe(
      map((querySnapshot: QuerySnapshot) => {
        return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      }),
      switchMap((designs: DesignDebug[]) => {
        let updateDocObs: Observable<any>[] = [];
        let checkIfBatchCanCommited: boolean = false;
        let batch: any; // = writeBatch(firestoreDev);
        // const batch = writeBatch(this.firestore);
        designs.forEach((design: DesignDebug, index: number) => {
          if (index % 500 == 0) {
            if (checkIfBatchCanCommited) {
              updateDocObs.push(from(batch.commit()));
            }
            batch = writeBatch(this.firestore);
          }
          const ref = doc(this.firestore, 'designs', design.id);

          // must be / are a field in this document
          checkIfBatchCanCommited = true;

          if (design.shoppingCartItems.length === 0) {
            batch.deleteDoc(ref);
          }

          if (index == designs.length - 1) {
            updateDocObs.push(from(batch.commit()));
          }
        });

        // return from(batch.commit());
        return forkJoin(updateDocObs);
      }),
      tap(() => console.log('>>> DELETE DONE <<<'))
    );
  }

  /**
   * This is an example for deleting a field of an document
   */
  /*
  deleteSomeShelfData$(): Observable<any> {
    return from(getDocs(query(collection(this.firestore, 'shelfs')))).pipe(
      map((querySnapshot: QuerySnapshot) => {
        return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      }),
      switchMap((shelves: ShelfScenes[]) => {
        let updateDocObs: Observable<any>[] = [];
        // const batch = writeBatch(this.firestore);
        // update the shelvesData
        shelves.forEach((shelf: ShelfScenes) => {
          const ref = doc(this.firestore, 'shelfs', shelf.id);

          // must be / are a field in this document
          updateDocObs.push(
            from(
              updateDoc(ref, {
                image3dViewPath: deleteField(),
                sizes: deleteField(),
                slotsData: deleteField(),
                slotsPath: deleteField(),
              })
            )
          );
        });

        // return from(batch.commit());
        return forkJoin(updateDocObs);
      }),
      tap(() => console.log('>>> SHELVES UPDATE DONE <<<'))
    );
  }
  */

  /**
   * This is an example for changing an old datamodel to a newer one
   */
  /*
  updateShelfData$(): Observable<void> {
    return from(getDocs(query(collection(this.firestore, 'shelfs')))).pipe(
      map((querySnapshot: QuerySnapshot) => {
        return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      }),
      switchMap((shelves: Shelf[]) => {
        const batch = writeBatch(this.firestore);
        // update the shelvesData
        shelves.forEach((shelf: Shelf) => {
          const ref = doc(this.firestore, 'shelfs', shelf.id);

          let newSlots: SlotScenes[] = [];
          let i: number = 0;

          shelf.slotsData?.slots.forEach((slot: Slot) => {
            let id: number = slot.slotNumber ? slot.slotNumber : i;
            let width: number = 0;
            let height: number = 0;
            if (slot.width) {
              width = slot.width;
            } else {
              width = slot.lr.x - slot.ll.x;
            }
            if (slot.height) {
              height = slot.height;
            } else {
              height = slot.lr.y - slot.ur.y;
            }
            let newSlot = {
              id: id,
              slotSizes: {
                width: 33,
                height: 33,
                depth: 39,
              } as SlotSizes,
              slotData: {
                ul: slot.ul,
                ur: slot.ur,
                ll: slot.ll,
                lr: slot.lr,
                width: width,
                height: height,
              } as SlotDataScenes,
            } as SlotScenes;

            i++;
            newSlots.push(newSlot);
          });

          let imageWidth = shelf.slotsData?.width ? shelf.slotsData.width : 2000;
          let imageHeight = shelf.slotsData?.height ? shelf.slotsData.height : 2000;

          const scene = {
            name: 'Perpektive',
            active: true,
            imageUrl: shelf.image3dViewPath,
            imageSize: {
              width: imageWidth,
              height: imageHeight,
            } as ImageSize,
            type: ShelfScenesEnum.PERSPECTIVE,
            slots: newSlots,
          } as Scene;

          const sceneFrontal = {
            name: 'Frontalansich',
            active: false,
            imageUrl: '',
            imageSize: {
              width: null,
              height: null,
            } as ImageSize,
            type: ShelfScenesEnum.FRONTAL,
            slots: [],
          } as Scene;

          // set defauls-values if it doesn't exists
          let activeAffiliates: string[] = [];
          for (const [key, value] of Object.entries(shelf.affiliates)) {
            if (value.active) {
              activeAffiliates.push(key);
            }
          }

          let active: boolean = shelf.active ? true : false;
          let affiliates = shelf.affiliates ? shelf.affiliates : [];
          let color = shelf.color ? shelf.color : 'colorful';
          let colorManufacturer = shelf.colorManufacturer ? shelf.colorManufacturer : '';
          let dateAdded = shelf.dateAdded ? shelf.dateAdded : null;
          let depth = shelf.depth ? shelf.depth : 0;
          let description = shelf.description ? shelf.description : '';
          let height = shelf.height ? shelf.height : 0;
          let manufacturer = shelf.manufacturer ? shelf.manufacturer : '';
          let name = shelf.name ? shelf.name : '';
          let tags = shelf.tags ? shelf.tags : [''];
          let type = shelf.type ? shelf.type : '';
          let usageCount = shelf.usageCount ? shelf.usageCount : 0;
          let width = shelf.width ? shelf.width : 0;

          let newShelf = {
            id: shelf.id,
            active: active,
            activeAffiliates: activeAffiliates,
            affiliates: affiliates,
            color: color,
            colorManufacturer: colorManufacturer,
            dateAdded: dateAdded,
            depth: depth,
            description: description,
            height: height,
            manufacturer: manufacturer,
            name: name,
            tags: tags,
            type: type,
            usageCount: usageCount,
            width: width,
            scenes: [scene, sceneFrontal],
          } as ShelfScenes;
          // if (shelf.id =='BgdUR1cxN5z4RBrd5H77'){
          //   console.log('Tags', shelf.tags);
          // }
          console.log('*** Shelf: ', shelf.id);
          console.log('Neues Regal', newShelf);
          batch.update(ref, newShelf as any); // TODO: geht nur mit any...weiß nicht warum
        });

        return from(batch.commit());
      }),
      tap(() => console.log('>>> SHELVES UPDATE DONE <<<'))
    );
  }
  */

  /**
   * This is an example for update field in a document
   */
  // renameScenesNames$(): Observable<void> {
  //   return from(getDocs(query(collection(this.firestore, 'shelfs')))).pipe(
  //     map((querySnapshot: QuerySnapshot) => {
  //       return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
  //     }),
  //     switchMap((shelves: ShelfScenes[]) => {
  //       console.log('start refactoring shelf-types');
  //       const batch = writeBatch(this.firestore);
  //       // update the shelvesData
  //       shelves.forEach((shelf: ShelfScenes) => {
  //         const ref = doc(this.firestore, 'shelfs', shelf.id);

  //         let scenePerspective = { ...shelf.scenes[0], name: 'Perspektive' };
  //         let sceneFrontal = { ...shelf.scenes[1], name: 'Frontalansicht' };
  //         sceneFrontal.imageSize.height = 2000;
  //         sceneFrontal.imageSize.width = 2000;
  //         let newShelf = { ...shelf, scenes: [scenePerspective, sceneFrontal] };
  //         console.log(newShelf.id, newShelf);
  //         batch.update(ref, newShelf as any);
  //       });

  //       return from(batch.commit());
  //     }),
  //     tap(() => console.log('>>> SHELVES UPDATE DONE <<<'))
  //   );
  // }
}
