import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, Platform } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { delay, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import {
  gotoSignInAction,
  hideAppMenuAction,
  openReviewPopupAction,
  openReviewPopupSuccededAction,
  shareAction,
  shareOnPinterestAction,
  shareOnTwitterAction,
  showAppMenuAction,
} from '../actions/general.actions';
import { AnalyticLogs } from '../enums/analytic-logs.enum';
import { ExtendedDesign } from '../interfaces/design.interface';
import { getCurrentDesign } from '../reducers/my-designs.reducer';
import { AnalyticsService } from '../services/analytics.service';
import { GeneralService } from '../services/general.service';
import { AppMenuComponent } from './../../app-menu/app-menu.component';
import { canShowReviewPopup } from '../reducers/general.reducer';
import { RateApp } from 'capacitor-rate-app';

@Injectable()
export class GeneralEffects {
  constructor(
    private actions$: Actions,
    private generalService: GeneralService,
    private modalController: ModalController,
    private analyticsService: AnalyticsService,
    private router: Router,
    private store: Store,
    private platform: Platform
  ) { }

  openReviewPopup$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openReviewPopupAction),
        delay(500),
        withLatestFrom(
          // Reeds the flag, that shows, if the popup was already shown or not
          this.store.select(canShowReviewPopup),
        ),
        // tap(() => { console.log('### Open Review') }),
        filter(([action, canShowReviewPopup]: [any, boolean]) => canShowReviewPopup),
        switchMap(() => from(RateApp.requestReview())),
        map(() => openReviewPopupSuccededAction())
      ),
    { dispatch: true }
  );

  share$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(shareAction),
        tap(() => this.analyticsService.log(AnalyticLogs.SHELF_DESIGNER_COMPONENT_SHARE_DESIGN)),
        switchMap(() => this.store.select(getCurrentDesign).pipe(take(1))),
        switchMap((design: ExtendedDesign) => this.generalService.share$(design)),
        filter(() => this.platform.is('ios') || this.platform.is('android')),
        map(() => openReviewPopupAction())
      ),
    { dispatch: true }
  );

  shareOnPinterest$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(shareOnPinterestAction),
        tap(() => this.analyticsService.log(AnalyticLogs.SHELF_DESIGNER_COMPONENT_SHARE_DESIGN_ON_PINTEREST)),
        switchMap(() => this.store.select(getCurrentDesign).pipe(take(1))),
        switchMap((design: ExtendedDesign) => this.generalService.shareOnPinterest$(design)),
        filter(() => this.platform.is('ios') || this.platform.is('android')),
        map(() => openReviewPopupAction())
      ),
    { dispatch: true }
  );

  shareOnTwitter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(shareOnTwitterAction),
        tap(() => this.analyticsService.log(AnalyticLogs.SHELF_DESIGNER_COMPONENT_SHARE_DESIGN_ON_TWITTER)),
        switchMap(() => this.store.select(getCurrentDesign).pipe(take(1))),
        switchMap((design: ExtendedDesign) => this.generalService.shareOnTwitter$(design)),
        filter(() => this.platform.is('ios') || this.platform.is('android')),
        map(() => openReviewPopupAction())
      ),
    { dispatch: true }
  );

  shareOnFacebook$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(shareOnTwitterAction),
        tap(() => this.analyticsService.log(AnalyticLogs.SHELF_DESIGNER_COMPONENT_SHARE_DESIGN_ON_FACEBOOK)),
        switchMap(() => this.store.select(getCurrentDesign).pipe(take(1))),
        switchMap((design: ExtendedDesign) => this.generalService.shareOnFacebook$(design)),
        filter(() => this.platform.is('ios') || this.platform.is('android')),
        map(() => openReviewPopupAction())
      ),
    { dispatch: true }
  );

  showAppMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showAppMenuAction),
        tap(() => this.analyticsService.log(AnalyticLogs.GENERAL_SHOW_APP_MENU)),
        switchMap(() =>
          from(
            this.modalController.create({
              component: AppMenuComponent,
              cssClass: 'app-menu',
            })
          ).pipe(switchMap((modal) => modal.present()))
        )
      ),
    { dispatch: false }
  );

  hideAppMenu$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hideAppMenuAction),
        switchMap(() => from(this.modalController.dismiss()))
      ),
    { dispatch: false }
  );

  gotoSignIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(gotoSignInAction),
        switchMap(() => this.generalService.closeModal$()),
        switchMap(() => from(this.router.navigateByUrl('sign-in')))
      ),
    { dispatch: false }
  );
}
