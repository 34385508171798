export enum ShelfTypesEnum {
  ALL = 'all',
  ST1X1 = '1x1',
  ST1X2 = '1x2',
  ST1X3 = '1x3',
  ST1X4 = '1x4',
  ST2X2 = '2x2',
  ST2X4 = '2x4',
  ST3X3 = '3x3',
  ST3X4 = '3x4',
  ST4X4 = '4x4',
  ST4X6 = '4x6',
  ST5X5 = '5x5',
  STAdditional = 'additional',
}
