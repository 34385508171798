import { ConstantPool } from '@angular/compiler';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class PerspectiveImageService {
  /*
  coeffs: any;
  coeffsInv: any;
  srcPts: any;
  dstPts: any;

  init(srcPts: any, dstPts: any) {
    this.srcPts = srcPts;
    this.dstPts = dstPts;

    this.coeffs = this.getNormalizationCoefficients(this.srcPts, this.dstPts, false);
    this.coeffsInv = this.getNormalizationCoefficients(this.srcPts, this.dstPts, true);
  }
  */

  dim(x) {
    var y, z;
    if (typeof x === 'object') {
      y = x[0];
      if (typeof y === 'object') {
        z = y[0];
        if (typeof z === 'object') {
          return this.dim(x);
        }
        return [x.length, y.length];
      }
      return [x.length];
    }
    return [];
  }

  _foreach2(x, s, k, f) {
    if (k === s.length - 1) {
      return f(x);
    }
    var i,
      n = s[k],
      ret = Array(n);
    for (i = n - 1; i >= 0; i--) {
      ret[i] = this._foreach2(x[i], s, k + 1, f);
    }
    return ret;
  }

  cloneV(x) {
    var _n = x.length;
    var i,
      ret = Array(_n);

    for (i = _n - 1; i !== -1; --i) {
      ret[i] = x[i];
    }
    return ret;
  }

  clone(x) {
    if (typeof x !== 'object') return x;
    var V = this.cloneV;
    var s = this.dim(x);
    return this._foreach2(x, s, 0, V);
  }

  diag(d) {
    var i,
      i1,
      j,
      n = d.length,
      A = Array(n),
      Ai;
    for (i = n - 1; i >= 0; i--) {
      Ai = Array(n);
      i1 = i + 2;
      for (j = n - 1; j >= i1; j -= 2) {
        Ai[j] = 0;
        Ai[j - 1] = 0;
      }
      if (j > i) {
        Ai[j] = 0;
      }
      Ai[i] = d[i];
      for (j = i - 1; j >= 1; j -= 2) {
        Ai[j] = 0;
        Ai[j - 1] = 0;
      }
      if (j === 0) {
        Ai[0] = 0;
      }
      A[i] = Ai;
    }
    return A;
  }

  rep(s, v, k = 0) {
    if (typeof k === 'undefined') {
      k = 0;
    }
    var n = s[k],
      ret = Array(n),
      i;
    if (k === s.length - 1) {
      for (i = n - 2; i >= 0; i -= 2) {
        ret[i + 1] = v;
        ret[i] = v;
      }
      if (i === -1) {
        ret[0] = v;
      }
      return ret;
    }
    for (i = n - 1; i >= 0; i--) {
      ret[i] = this.rep(s, v, k + 1);
    }
    return ret;
  }

  identity(n) {
    return this.diag(this.rep([n], 1));
  }

  dotVV(x, y) {
    var i,
      n = x.length,
      i1,
      ret = x[n - 1] * y[n - 1];
    for (i = n - 2; i >= 1; i -= 2) {
      i1 = i - 1;
      ret += x[i] * y[i] + x[i1] * y[i1];
    }
    if (i === 0) {
      ret += x[0] * y[0];
    }
    return ret;
  }

  round(num) {
    return Math.round(num * 10000000000) / 10000000000;
  }

  /*
  transform(x, y) {
    var coordinates = [];
    coordinates[0] = (this.coeffs[0] * x + this.coeffs[1] * y + this.coeffs[2]) / (this.coeffs[6] * x + this.coeffs[7] * y + 1);
    coordinates[1] = (this.coeffs[3] * x + this.coeffs[4] * y + this.coeffs[5]) / (this.coeffs[6] * x + this.coeffs[7] * y + 1);
    return coordinates;
  }
  */

  // from https://jsfiddle.net/szym/03s5mwjv/
  // Computes the matrix3d that maps src points to dst.
  public computeTransform(src, dst) {


    // console.log(">>> computeTransform:"+dst);
    // src and dst should have length 4 each
    var count = 4;
    var a = []; // (2*count) x 8 matrix
    var b = []; // (2*count) vector

    for (var i = 0; i < 2 * count; ++i) {
      a.push([0, 0, 0, 0, 0, 0, 0, 0]);
      b.push(0);
    }

    for (var i = 0; i < count; ++i) {
      var j = i + count;
      a[i][0] = a[j][3] = src[i][0];
      a[i][1] = a[j][4] = src[i][1];
      a[i][2] = a[j][5] = 1;
      a[i][3] = a[i][4] = a[i][5] = a[j][0] = a[j][1] = a[j][2] = 0;
      a[i][6] = -src[i][0] * dst[i][0];
      a[i][7] = -src[i][1] * dst[i][0];
      a[j][6] = -src[i][0] * dst[i][1];
      a[j][7] = -src[i][1] * dst[i][1];
      b[i] = dst[i][0];
      b[j] = dst[i][1];
    }

    var x = this.solve(a, b, true);
    // matrix3d is homogenous coords in column major!
    // the z coordinate is unused
    var m = [x[0], x[3], 0, x[6], x[1], x[4], 0, x[7], 0, 0, 1, 0, x[2], x[5], 0, 1];
    var transform = 'matrix3d(';
    for (var i = 0; i < m.length - 1; ++i) {
      transform += m[i] + ',';
    }
    transform += m[15] + ')';
    return transform;
  }

  solve(A, b, fast) {
    const luResult: { LU: Array<any>; P: Array<any> } = this.LU(A, fast);
    return this.LUsolve(luResult, b);
  }

  LUsolve(LUP: { LU: Array<any>; P: Array<any> }, b) {
    var i, j;
    var LU = LUP.LU;
    var n = LU.length;
    var x = this.clone(b);
    var P = LUP.P;
    var Pi, LUi, LUii, tmp;

    for (i = n - 1; i !== -1; --i) x[i] = b[i];
    for (i = 0; i < n; ++i) {
      Pi = P[i];
      if (P[i] !== i) {
        tmp = x[i];
        x[i] = x[Pi];
        x[Pi] = tmp;
      }

      LUi = LU[i];
      for (j = 0; j < i; ++j) {
        x[i] -= x[j] * LUi[j];
      }
    }

    for (i = n - 1; i >= 0; --i) {
      LUi = LU[i];
      for (j = i + 1; j < n; ++j) {
        x[i] -= x[j] * LUi[j];
      }

      x[i] /= LUi[i];
    }

    return x;
  }

  // 11. Ax = b
  LU(A, fast = false): { LU: Array<any>; P: Array<any> } {
    // fast = fast || false;

    var abs = Math.abs;
    var i, j, k, absAjk, Akk, Ak, Pk, Ai;
    var max;
    var n = A.length,
      n1 = n - 1;
    var P = new Array(n);
    if (!fast) A = this.clone(A);

    for (k = 0; k < n; ++k) {
      Pk = k;
      Ak = A[k];
      max = abs(Ak[k]);
      for (j = k + 1; j < n; ++j) {
        absAjk = abs(A[j][k]);
        if (max < absAjk) {
          max = absAjk;
          Pk = j;
        }
      }
      P[k] = Pk;

      if (Pk != k) {
        A[k] = A[Pk];
        A[Pk] = Ak;
        Ak = A[k];
      }

      Akk = Ak[k];

      for (i = k + 1; i < n; ++i) {
        A[i][k] /= Akk;
      }

      for (i = k + 1; i < n; ++i) {
        Ai = A[i];
        for (j = k + 1; j < n1; ++j) {
          Ai[j] -= Ai[k] * Ak[j];
          ++j;
          Ai[j] -= Ai[k] * Ak[j];
        }
        if (j === n1) Ai[j] -= Ai[k] * Ak[j];
      }
    }

    return {
      LU: A,
      P: P,
    };
  }
}
