import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AffiliatesEnum } from '../enums/affiliates.enum';
import { getAffiliateData } from '../functions/affiliates';
import { AffiliateInformation, MetaAffiliate } from '../interfaces/affiliate.interface';
import { Product } from '../interfaces/product.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { ExtendedShoppingCartItem } from '../interfaces/shopping-cart-item.interface';
import { environment } from './../../../environments/environment';
import * as Affiliates from '../../../assets/json/affiliate-informations.json';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  //TODO: add correct affiliateUrl
  ikeaAddURL = 'http://www.ikea.com/webapp/wcs/stores/servlet/IrwWSOrderItemAdd?storeId=5&langId=-3&type=json';
  //ikeaProductAffiliateURL = "http://partners.webmasterplan.com/click.asp?ref=492256&site=14354&type=text&tnb=6&diurl=http://www.ikea.com/de/de/catalog/products/";
  ikeaProductAffiliateURL = 'http://localhost';
  url: string;
  itemsToCheckout: ExtendedShoppingCartItem[];
  selectedShelf: ShelfScenes;
  buyShelf: boolean;
  checkedoutShelf: boolean = false;

  affiliateInformations: { [key: string]: AffiliateInformation } = Affiliates;

  constructor(private http: HttpClient) {}

  public checkout$(affiliateName: string, product: Product): Observable<string> {
    switch (affiliateName) {
      case AffiliatesEnum.AMAZON_DE:
        return this.addToAmazonCartSingle$(product, AffiliatesEnum.AMAZON_DE);
      case AffiliatesEnum.AMAZON_US:
        return this.addToAmazonCartSingle$(product, AffiliatesEnum.AMAZON_US);
      case AffiliatesEnum.WALMART_US:
        return this.addToWalmartCartSingle$(product, AffiliatesEnum.WALMART_US);
    }

    return of(null);
  }

  addToAmazonCartSingle$(product: Product, affiliateId: AffiliatesEnum): Observable<string> {
    const url = this.affiliateInformations[affiliateId].addToCartUrl;

    let itemsUrl = '';

    itemsUrl += `&ASIN.${1}=${product.affiliates[affiliateId].articleNumber}&Quantity.${1}=${1}`;
    itemsUrl += `&AssociateTag=${this.affiliateInformations[affiliateId].affiliateId}`;
    itemsUrl = itemsUrl.substring(1); // remove first &

    const checkoutUrl = `${url}${itemsUrl}`;
    window.open(checkoutUrl, '_blank');
    return of(checkoutUrl);
  }

  addToWalmartCartSingle$(product: Product, affiliateId: AffiliatesEnum): Observable<string> {
    const url = this.affiliateInformations[affiliateId].addToCartUrl;
    let itemsUrl = '';

    itemsUrl += `${product.affiliates[affiliateId].articleNumber}|${1}`;
    itemsUrl += `&irgwc=1&sourceid=imp_RwBQhT3-rxyIUKiWAaW7RzRhUkG18rxRHUBDTE0&veh=aff&wmlspartner=imp_1394255&clickid=RwBQhT3-rxyIUKiWAaW7RzRhUkG18rxRHUBDTE0&sharedid=&affiliates_ad_id=568844&campaign_id=9383`;

    const checkoutUrl = `${url}${itemsUrl}`;
    window.open(checkoutUrl, '_blank');
    return of(checkoutUrl);
  }

  public checkoutAll$(affiliateName: string, items: ExtendedShoppingCartItem[], shelf: ShelfScenes, buyShelf: boolean): Observable<string> {
    switch (affiliateName) {
      case AffiliatesEnum.IKEA_DE:
        return this.checkoutIkeaDEAll$(items, shelf, buyShelf, AffiliatesEnum.IKEA_DE);
      case AffiliatesEnum.AMAZON_DE:
        return this.checkoutAmazonAll$(items, shelf, buyShelf, AffiliatesEnum.AMAZON_DE);
      case AffiliatesEnum.AMAZON_US:
        return this.checkoutAmazonAll$(items, shelf, buyShelf, AffiliatesEnum.AMAZON_US);
      case AffiliatesEnum.WALMART_US:
        return this.checkoutWalmartAll$(items, shelf, buyShelf, AffiliatesEnum.WALMART_US);
    }

    return of(null);
  }

  checkoutAmazonAll$(
    items: ExtendedShoppingCartItem[],
    shelf: ShelfScenes,
    buyShelf: boolean,
    affiliateId: AffiliatesEnum
  ): Observable<string> {
    if (buyShelf) {
      return this.addToAmazonCartAll$(items, affiliateId, shelf);
    } else {
      return this.addToAmazonCartAll$(items, affiliateId);
    }
  }

  addToAmazonCartAll$(items: ExtendedShoppingCartItem[], affiliateId: AffiliatesEnum, shelf?: ShelfScenes): Observable<string> {
    const url = this.affiliateInformations[affiliateId].addToCartUrl;

    let itemsUrl = '';

    items.forEach((item: ExtendedShoppingCartItem, index) => {
      const affiliate: MetaAffiliate = getAffiliateData(item.item);
      if (affiliate !== null) {
        if (affiliate.name === affiliateId) {
          const itemUrl = `&ASIN.${index + 1}=${item.item.affiliates[affiliateId].articleNumber}&Quantity.${index + 1}=${
            item.slotsToBuy.length
          }`;
          itemsUrl += itemUrl;
        }
      }
    });
    if (shelf && getAffiliateData(shelf).name === affiliateId) {
      itemsUrl += `&ASIN.${items.length + 1}=${shelf.affiliates[affiliateId].articleNumber}&Quantity.${items.length + 1}=1`;
    }
    itemsUrl += `&AssociateTag=${this.affiliateInformations[affiliateId].affiliateId}`;
    itemsUrl = itemsUrl.substring(1); // remove first &

    const checkoutUrl = `${url}${itemsUrl}`;
    window.open(checkoutUrl, '_blank');
    return of(checkoutUrl);
  }

  checkoutIkeaDEAll$(
    items: ExtendedShoppingCartItem[],
    shelf: ShelfScenes,
    buyShelf: boolean,
    affiliateId: AffiliatesEnum
  ): Observable<string> {
    if (buyShelf) {
      return this.addToIkeaDECartAll$(items, affiliateId, shelf);
    } else {
      return this.addToIkeaDECartAll$(items, affiliateId);
    }
  }

  addToIkeaDECartAll$(items: ExtendedShoppingCartItem[], affiliateId: AffiliatesEnum, shelf?: ShelfScenes): Observable<string> {
    const url = this.affiliateInformations[affiliateId].addToCartUrl;

    let itemsUrl = '';

    items.forEach((item: ExtendedShoppingCartItem, index) => {
      const affiliate: MetaAffiliate = getAffiliateData(item.item);
      if (affiliate !== null) {
        if (affiliate.name === affiliateId) {
          const itemUrl = `,${item.item.affiliates[affiliateId].articleNumber}:${item.slotsToBuy.length}`;
          itemsUrl += itemUrl;
        }
      }
    });
    if (shelf && getAffiliateData(shelf).name === affiliateId) {
      itemsUrl += `,${shelf.affiliates[affiliateId].articleNumber}:1`;
    }
    // itemsUrl += `&AssociateTag=${this.affiliateInformations[affiliateId].affiliateId}`;
    itemsUrl = itemsUrl.substring(1); // remove first &

    const checkoutUrl = `${url}${itemsUrl}`;
    window.open(checkoutUrl, '_blank');
    return of(checkoutUrl);
  }

  checkoutWalmartAll$(
    items: ExtendedShoppingCartItem[],
    shelf: ShelfScenes,
    buyShelf: boolean,
    affiliateId: AffiliatesEnum
  ): Observable<string> {
    if (buyShelf) {
      return this.addToWalmartCartAll$(items, affiliateId, shelf);
    } else {
      return this.addToWalmartCartAll$(items, affiliateId);
    }
  }

  addToWalmartCartAll$(items: ExtendedShoppingCartItem[], affiliateId: AffiliatesEnum, shelf?: ShelfScenes): Observable<string> {
    const url = this.affiliateInformations[affiliateId].addToCartUrl;
    let itemsUrl = '';
    items.forEach((item: ExtendedShoppingCartItem) => {
      const affiliate: MetaAffiliate = getAffiliateData(item.item);
      if (affiliate.name === AffiliatesEnum.WALMART_US) {
        if (itemsUrl !== '') {
          itemsUrl += ',';
        }
        const itemUrl = `${item.item.affiliates[affiliateId].articleNumber}|${item.slotsToBuy.length}`;
        itemsUrl += itemUrl;
      }
    });
    if (shelf && getAffiliateData(shelf).name === affiliateId) {
      if (itemsUrl !== '') {
        itemsUrl += ',';
      }
      itemsUrl += `${shelf.affiliates[affiliateId].articleNumber}|1`;
    }
    itemsUrl += `&irgwc=1&sourceid=imp_RwBQhT3-rxyIUKiWAaW7RzRhUkG18rxRHUBDTE0&veh=aff&wmlspartner=imp_1394255&clickid=RwBQhT3-rxyIUKiWAaW7RzRhUkG18rxRHUBDTE0&sharedid=&affiliates_ad_id=568844&campaign_id=9383`;

    const checkoutUrl = `${url}${itemsUrl}`;
    window.open(checkoutUrl, '_blank');
    return of(checkoutUrl);
  }
}
