import { createAction, props } from '@ngrx/store';
import { AffiliatesEnum } from '../enums/affiliates.enum';
import { MaterialsEnum } from '../enums/materials.enum';
import { SortCriteriaEnum } from '../enums/sort.enum';
import { ThemesEnum } from '../enums/themes.enum';
import { TypesEnum } from '../enums/types.enum';
import { Color } from '../interfaces/color.interface';
import { FilteredItems, Item } from '../interfaces/item.interface';
import { LoadItemsConfiguration } from '../interfaces/load-items-configuration.interface';

const actionType = '[ShelfItemPicker]';

export const loadItemsAction = createAction(`${actionType} Load items`, props<{ configuration: LoadItemsConfiguration }>());
export const loadItemsSucceededAction = createAction(`${actionType} Load items succeeded`, props<{ filteredItems: FilteredItems }>());
export const loadItemsFailedAction = createAction(`${actionType} Load items failed`, props<{ error: Error }>());

export const loadItemsOfCategoryFailedAction = createAction(`${actionType} Load items of category failed`);

export const showItemDetailsAction = createAction(`${actionType} Show item details`, props<{ item: Item }>());
export const leaveItemDetailsAction = createAction(`${actionType} Leave item details`);

export const enterProductFilterAction = createAction(`${actionType} Enter product filter`);
export const setSortCriterionAction = createAction(`${actionType} Set sort criterion`, props<{ sortCriterion: SortCriteriaEnum }>());
export const toggleTypeAction = createAction(`${actionType} Toggle type`, props<{ itemType: TypesEnum }>());
export const toggleThemeAction = createAction(`${actionType} Toggle theme`, props<{ theme: ThemesEnum }>());
export const toggleAffiliateAction = createAction(`${actionType} Toggle affiliate`, props<{ affiliate: AffiliatesEnum }>());
export const setPriceRangeAction = createAction(`${actionType} Set price range`, props<{ minPrice: number; maxPrice: number }>());
export const toggleShowOnlySaleProductsAction = createAction(`${actionType} Toggle show only sale products`);
export const toggleColorAction = createAction(`${actionType} Toggle color`, props<{ color: Color }>());
export const toggleMaterialAction = createAction(`${actionType} Toggle material`, props<{ material: MaterialsEnum }>());
export const leaveProductFilterAction = createAction(`${actionType} Leave product filter`, props<{ reset?: boolean }>());
export const resetProductFilterAtion = createAction(`${actionType} Reset product filter`);

export const setAffiliatesAction = createAction(`${actionType} Set Affiliates Action`, props<{ affiliates: AffiliatesEnum[] }>());
