<div class="displayTable">
  <div class="displayTableCell">
    <div class="authBlock">
      <h3>{{ 'general.login.sign-up.title' | transloco }}</h3>

      <div class="formGroup">
        <input type="email" class="formControl" [placeholder]="'general.login.general.placeholder.mail' | transloco" #userEmail required />
      </div>

      <div class="formGroup">
        <input type="password" class="formControl" [placeholder]="'general.login.general.placeholder.pwd' | transloco" #userPwd required />
      </div>

      <div class="formGroup">
        <input type="button" class="btn btnPrimary" [value]="'general.login.sign-up.title' | transloco"
          (click)="authService.signUp(userEmail.value, userPwd.value)" />
      </div>

      <div class="formGroup">
        <span class="or">
          <span class="orInner">{{ 'general.login.sign-up.or' | transloco }}</span>
        </span>
      </div>

      <!-- Continue with Google -->
      <!--
      <div class="formGroup">
        <button type="button" class="btn googleBtn" (click)="authService.googleAuth()">
          <i class="fab fa-google-plus-g"></i>
          Continue with Google
        </button>
      </div>
      //-->

      <!-- Continue with Facebook -->
      <!--
      <div class="formGroup">
        <button type="button" class="btn facebookBtn" (click)="authService.FacebookAuth()">
          <i class="fab fa-facebook"></i>
          Continue with Facebook
        </button>
      </div>
      -->
    </div>

    <div class="redirectToLogin">
      <span>
        {{ 'general.login.sign-up.have-account' | transloco }}
        <span class="redirect" routerLink="/sign-in">
          {{ 'general.login.sign-up.login' | transloco }}
        </span>
      </span>
    </div>
  </div>
</div>
