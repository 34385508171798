import { SlotScenes } from './../interfaces/scenes-slot.interface';
import { Injectable } from '@angular/core';
import { ImgShelfItemStylingInterface } from '../interfaces/img-shelf-item-styling.interface';
import { Item } from '../interfaces/item.interface';

@Injectable({
  providedIn: 'root',
})
export class ImagePrerenderService {
  constructor() { }

  calculateDestinationStyle(item: Item, slot: SlotScenes): ImgShelfItemStylingInterface {
    const itemHeightCm = item.show.height;
    const itemWidthCm = item.show.width;
    // pixel / cm
    const cmToPixelX = slot.slotData.width / slot.slotSizes.width;
    const cmToPixelY = slot.slotData.height / slot.slotSizes.height;

    return {
      width: itemWidthCm * cmToPixelX,
      height: itemHeightCm * cmToPixelY,
      position: item.position
    };
  }
}
