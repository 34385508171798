import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { loadItemsFailedAction } from '../actions/admin.actions';
import {
  loadHacksInitialAction,
  loadHacksItemsSucceededAction,
  toggleChooseHacksItem,
  toggleHacksTypeAction,
} from '../actions/hacks-picker.actions';
import { addItemToCartSucceededAction } from '../actions/shopping-cart.actions';
import { HacksSlotsIdEnum, HacksTypesEnum } from '../enums/hacks-types.enum';
import { TypesEnum } from '../enums/types.enum';
import { Design } from '../interfaces/design.interface';
import { Item } from '../interfaces/item.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';
import { ExtendedShoppingCartItem } from '../interfaces/shopping-cart-item.interface';
import { getActiveTypesHacks } from '../reducers/hacks-picker.reducer';
import { getCurrentDesignId, getExtendedShoppingCartItemsFromCurrentDesign } from '../reducers/my-designs.reducer';
import { DesignService } from '../services/design.service';
import { ItemService } from '../services/item.service';
import { ShoppingcartService } from '../services/shoppingcart.service';
import { ShoppingCartItem } from './../interfaces/shopping-cart-item.interface';

@Injectable()
export class HacksPickerEffects {
  constructor(
    private actions$: Actions,
    private itemService: ItemService,
    private userDataService: DesignService,
    private shoppingCardService: ShoppingcartService,
    private store: Store
  ) {}

  loadItemsAfterToggle$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(toggleHacksTypeAction, loadHacksInitialAction),
        withLatestFrom(this.store.select(getActiveTypesHacks)),
        switchMap(([action, filterOptions]: [any, TypesEnum[]]) => {
          return this.itemService.getFilteredHacks$(filterOptions);
        }),
        map((filteredItems: Item[]) => loadHacksItemsSucceededAction({ items: filteredItems })),
        catchError((error, caught) => {
          console.error('>>> load items error', error);
          this.store.dispatch(loadItemsFailedAction({ error }));
          return caught;
        })
      )
  );

  addOrRemoveHacksItemToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(toggleChooseHacksItem),
      withLatestFrom(this.store.select(getExtendedShoppingCartItemsFromCurrentDesign)),
      // update shoppingCartItem array
      map(([action, shoppingCardItems]: [any, ExtendedShoppingCartItem[]]) => {
        switch (action.item.type) {
          case HacksTypesEnum.Feet:
            return this.shoppingCardService.toggleHacksItemInList(
              shoppingCardItems,
              { id: HacksSlotsIdEnum.Feet } as SlotScenes,
              action.item
            );
          case HacksTypesEnum.Plates:
            return this.shoppingCardService.toggleHacksItemInList(
              shoppingCardItems,
              { id: HacksSlotsIdEnum.Plates } as SlotScenes,
              action.item
            );
          case HacksTypesEnum.Sticker:
            return this.shoppingCardService.toggleHacksItemInList(
              shoppingCardItems,
              { id: HacksSlotsIdEnum.Sticker } as SlotScenes,
              action.item
            );
          case HacksTypesEnum.AdditionalHacks:
            return this.shoppingCardService.toggleHacksItemInList(
              shoppingCardItems,
              { id: HacksSlotsIdEnum.AdditionalHacks } as SlotScenes,
              action.item
            );
        }
      }),
      // get neccessary data from store and map new shoppingCartItem array to flat object for firestore
      switchMap((extendedShoppingCartItems: ExtendedShoppingCartItem[]) =>
        combineLatest([
          of(extendedShoppingCartItems),
          this.store.select(getCurrentDesignId).pipe(take(1)),
          this.itemService.getShoppingCartItems$(extendedShoppingCartItems),
        ])
      ),
      // safe changes to firestore
      switchMap(([extendedShoppingCartItems, designId, shoppingCartItems]: [ExtendedShoppingCartItem[], string, ShoppingCartItem[]]) =>
        this.userDataService
          .updateMyDesign$(designId, { shoppingCartItems } as Design)
          .pipe(map(() => ({ designId, shoppingCartItems, extendedShoppingCartItems })))
      ),
      map(({ designId, shoppingCartItems, extendedShoppingCartItems }) =>
        addItemToCartSucceededAction({ designId, shoppingCartItems, extendedShoppingCartItems })
      )
    )
  );
}
