import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inArray',
})
export class InArrayPipe implements PipeTransform {
  transform(value: unknown, array: unknown[]): boolean {
    return array?.indexOf(value) > -1 ?? false;
  }
}
