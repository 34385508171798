import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '../../login/services/auth.service';
import { ExtendedDesign, Design } from '../interfaces/design.interface';

@Pipe({
  name: 'isMyDesign',
})
export class IsMyDesignPipe implements PipeTransform {
  constructor(private authService: AuthService) {}

  transform(design: ExtendedDesign | Design | null): boolean {
    return design?.userId === this.authService.getUserId() ?? false;
  }
}
