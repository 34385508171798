<ion-header>
  <!-- Desktop-View -->
  <div *ngIf="sidebar">
    <div class="dropdown-box">
      <div class="dropdown">
        <ion-select interface="popover" [(ngModel)]="activeType" [value]="activeType" (ionChange)="onTypeChange($event)"
          *ngLet="(types$ | async) as types">
          <ion-select-option *ngFor="let type of types" [value]="type">
            {{ 'shelf-types.' + type | transloco }}
          </ion-select-option>
        </ion-select>
      </div>

      <div class="dropdown">
        <ion-select interface="popover" [(ngModel)]="activeColor" [value]="activeColor"
          (ionChange)="onColorChange($event)" *ngLet="(colors$ | async) as colors">
          <ion-select-option *ngFor="let color of colors" [value]="color">
            {{ 'shelf-colors.' + color | transloco }}
          </ion-select-option>
        </ion-select>
      </div>
    </div>
  </div>

  <!-- Mobil-View -->
  <div class="quick-filter" *ngIf="!sidebar && isShelfPickerOpen$ | async">
    <div class="types-filter" *ngLet="(types$ | async) as types">
      <div [ngClass]="{ type: true, active: isTypeActive$(type) | async }" *ngFor="let type of types | slice: 1"
        (click)="handleToggleType(type)">
        {{ 'shelf-types.' + type | transloco }}
      </div>
    </div>
    <div class="colors-filter" *ngLet="(colors$ | async) as colors">
      <div [ngClass]="{ color: true, active: isColorActive$(color) | async }" *ngFor="let color of colors | slice: 1"
        (click)="handleToggleColor(color)">
        {{ 'shelf-colors.' + color | transloco }}
      </div>
    </div>
  </div>
</ion-header>

<ion-content [scrollY]="false" *ngIf="{
    itemsList: productListItems$ | async,
    currentShelf: currentShelf$ | async,
    isShelfPickerOpen: isShelfPickerOpen$ | async,
    currentDesignUserId: currentDesignUserId$ | async
  } as obs" [ngClass]="{ sidebar: sidebar, horizontal: !obs.isShelfPickerOpen }">
  <ng-container>
    <ion-button class="scroll-button left" fill="solid" (click)="handleScrollBackward()"
      [hidden]="!(scrollLeftButtonVisible$ | async)">
      <ion-icon name="arrow-back-outline"></ion-icon>
    </ion-button>

    <ion-button class="scroll-button right" fill="solid" (click)="handleScrollForward()"
      [hidden]="!(scrollRightButtonVisible$ | async)">
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </ion-button>
  </ng-container>

  <div #scrollingContainer class="virtual-scroller-container" [ngClass]="{
      horizontal: !obs.isShelfPickerOpen,
      vertical: obs.isShelfPickerOpen,
      'horizontal-desktop': sidebar && !obs.isShelfPickerOpen
    }" *ngLet="shelves$ | async as shelves">
    <virtual-scroller #scroll [items]="(isLoadingShelves$ | async) ? (skeletonItems$ | async) : shelves"
      [executeRefreshOutsideAngularZone]="false" [checkResizeInterval]="1000" (vsUpdate)="onVirtualScrollerUpdate()"
      [enableUnequalChildrenSizes]="true" [horizontal]="!obs.isShelfPickerOpen">
      <div *ngFor="let item of scroll.viewPortItems; let frameIndex = index; trackBy: trackByItem" class="item">
        <div *ngIf="item.skeleton" class="skeleton-item">
          <ion-skeleton-text animated class="item"></ion-skeleton-text>
        </div>
        <ng-container *ngIf="!item.skeleton">
          <app-shelf-picker-item [shelf]="item" [selected]="obs.currentShelf?.id === item?.id"
            (shelfClicked)="handleShelfClick($event, obs.itemsList, obs.currentShelf, obs.currentDesignUserId)"
            (detailsClicked)="handleDetailsClick($event)"></app-shelf-picker-item>
        </ng-container>
      </div>
    </virtual-scroller>
  </div>
</ion-content>
