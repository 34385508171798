import { Injectable } from '@angular/core';
import { doc, docData, Firestore, setDoc } from '@angular/fire/firestore';
import { User } from 'firebase/auth';
import { from, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { AppUser } from '../../login/entities/user.interface';
import { AuthService } from '../../login/services/auth.service';
import { Settings } from '../interfaces/settings.interface';

const DefaultSettings: Settings = {
  firstUse: true,
};

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  constructor(public firestore: Firestore, private authService: AuthService) {}

  loadSettings$(): Observable<Settings> {
    let userRef: User = this.authService.userData;

    return from(docData(doc(this.firestore, 'users', userRef.uid))).pipe(
      switchMap((user: AppUser) => {
        if (user && user.hasOwnProperty('settings')) {
          return of(user.settings as Settings);
        } else {
          return this.saveDefaultSettings$();
        }
      })
    );
  }

  saveDefaultSettings$(): Observable<Settings> {
    let userRef: User = this.authService.userData;
    return from(setDoc(doc(this.firestore, 'users', userRef.uid), { settings: { ...DefaultSettings } }, { merge: true })).pipe(
      map(() => DefaultSettings)
    );
  }

  unsetFirstUse$(): Observable<void> {
    let userRef: User = this.authService.userData;
    return from(setDoc(doc(this.firestore, 'users', userRef.uid), { settings: { firstUse: false } }, { merge: true })).pipe(
      map(() => null)
    );
  }
}
