import { environment } from './../../../environments/environment';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProductCategories } from '../../core/enums/product-categories.enum';
import { Store } from '@ngrx/store';
import {
  leaveProductFilterAction,
  setSortCriterionAction,
  toggleAffiliateAction,
  toggleColorAction,
  toggleMaterialAction,
  toggleShowOnlySaleProductsAction,
  toggleThemeAction,
  toggleTypeAction,
  resetProductFilterAtion,
  setPriceRangeAction,
} from '../../core/actions/shelf-item-picker.actions';
import { ThemesEnum } from '../../core/enums/themes.enum';
import { TypesEnum } from '../../core/enums/types.enum';
import { SortCriteriaEnum } from '../../core/enums/sort.enum';
import { ProductFilterEnum } from '../../core/enums/product-filter.enum';
import { MaterialsEnum } from '../../core/enums/materials.enum';
import { AffiliatesEnum } from '../../core/enums/affiliates.enum';
import { Observable, Subject, takeUntil } from 'rxjs';
import {
  getTotalFilteredItems,
  getLowestItemPrice,
  getHighestItemPrice,
  getFilterOptions,
} from '../../core/reducers/shelf-item-picker.reducer';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ProductFilterOptions } from '../../core/interfaces/product-filter-options.interface';
import { AllColors, Color } from '../../core/interfaces/color.interface';
import { ProductService } from '../../core/services/product.service';
import { getTypesWithoutHackTypes } from '../../core/functions/hacks';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss'],
})
export class ProductFilterComponent implements OnInit, OnDestroy {
  @Input() productCategory!: ProductCategories;
  themes: ThemesEnum[] = Object.values(ThemesEnum);
  sortCriteria: SortCriteriaEnum[] = Object.values(SortCriteriaEnum);
  types: TypesEnum[] = Object.values(TypesEnum);
  filterOptions: ProductFilterEnum[] = Object.values(ProductFilterEnum);
  affiliates: AffiliatesEnum[] = Object.values(AffiliatesEnum);
  materials: MaterialsEnum[] = Object.values(MaterialsEnum);
  allColors: AllColors;

  environment = environment;
  affiliatesEnum = AffiliatesEnum;

  // observables and subjects
  destroy$ = new Subject();
  totalFilteredItems$: Observable<number> = this.store.select(getTotalFilteredItems);
  lowestItemPrice$: Observable<number> = this.store.select(getLowestItemPrice);
  highestItemPrice$: Observable<number> = this.store.select(getHighestItemPrice);

  // form group and form controls
  productFilterFormGroup: FormGroup;
  sortCriterionFormControl = new FormControl('', []);
  activeTypesFormControl = new FormControl([], []);
  activeThemeFormControl = new FormControl('', []);
  activeAffiliateFormControls: { [key: string]: FormControl };
  minPriceFormControl = new FormControl(null, []);
  maxPriceFormControl = new FormControl(null, []);
  showOnlySaleProductsFormControl = new FormControl(false, []);
  activeColorsFormControl = new FormControl([], []);
  activeMaterialsFormControl = new FormControl([], []);

  constructor(private store: Store, private formBuilder: FormBuilder, private productService: ProductService) { }

  ngOnInit(): void {
    this.allColors = this.productService.getAllColors();
    this.store
      .select(getFilterOptions)
      .pipe(takeUntil(this.destroy$))
      .subscribe((filterOptions: ProductFilterOptions) => {
        this.initFormControls(filterOptions);
      });

    // Remove hack-types
    this.types = getTypesWithoutHackTypes();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  initFormControls(filterOptions: ProductFilterOptions): void {
    this.sortCriterionFormControl.setValue(filterOptions.sortCriterion);
    // TODO: Stefan B fragen: Wegen string-cast
    this.activeTypesFormControl.setValue(filterOptions.types);
    this.activeThemeFormControl.setValue(filterOptions.theme);

    // generate a form control for each active affiliate
    this.activeAffiliateFormControls = {};
    this.affiliates.forEach((affiliateName: string) => {
      this.activeAffiliateFormControls[affiliateName] = new FormControl('', []);
      this.activeAffiliateFormControls[affiliateName].setValue(filterOptions.affiliates.indexOf(affiliateName) > -1);
    });

    this.minPriceFormControl.setValue(filterOptions.prices[0]);
    this.maxPriceFormControl.setValue(filterOptions.prices[1]);
    this.showOnlySaleProductsFormControl.setValue(filterOptions.sale);
    this.activeColorsFormControl.setValue(filterOptions.colors);
    this.activeMaterialsFormControl.setValue(filterOptions.materials);

    const formGroupTemplate = {
      sortCriterion: this.sortCriterionFormControl,
      types: this.activeTypesFormControl,
      theme: this.activeThemeFormControl,
      minPrice: this.minPriceFormControl,
      maxPrice: this.maxPriceFormControl,
      sale: this.showOnlySaleProductsFormControl,
      colors: this.activeColorsFormControl,
      materials: this.activeMaterialsFormControl,
    };
    this.affiliates.forEach(
      (affiliateName: string) => (formGroupTemplate[`affiliate.${affiliateName}`] = this.activeAffiliateFormControls[affiliateName])
    );

    this.productFilterFormGroup = this.formBuilder.group(formGroupTemplate);
  }

  // general filter triggers
  handleClose(): void {
    this.store.dispatch(leaveProductFilterAction({ reset: true }));
  }

  handleApplyFilter(): void {
    this.store.dispatch(leaveProductFilterAction({}));
  }

  handleResetFilter(): void {
    this.store.dispatch(resetProductFilterAtion());
  }

  // user triggered actions to modify filter options
  handleSortChanged(event: CustomEvent): void {
    this.store.dispatch(setSortCriterionAction({ sortCriterion: event.detail.value }));
  }

  handleToggleType(type: TypesEnum): void {
    this.store.dispatch(toggleTypeAction({ itemType: type }));
  }

  handleToggleTheme(theme: ThemesEnum): void {
    this.store.dispatch(toggleThemeAction({ theme }));
  }

  handleToggleAffiliate(affiliate: AffiliatesEnum): void {
    this.store.dispatch(toggleAffiliateAction({ affiliate }));
  }

  handleSetPriceRange(event: CustomEvent): void {
    this.store.dispatch(setPriceRangeAction({ minPrice: event.detail.value.lower, maxPrice: event.detail.value.upper }));
  }

  handleToggleShowOnlySaleProducts(): void {
    this.store.dispatch(toggleShowOnlySaleProductsAction());
  }

  handleToggleColorOption(color: Color): void {
    this.store.dispatch(toggleColorAction({ color }));
  }

  handleToggleMaterial(material: MaterialsEnum): void {
    this.store.dispatch(toggleMaterialAction({ material }));
  }

  hasActiveAffiliates(): boolean {
    let result = false;
    this.affiliates.forEach((affiliateName: string) => {
      if (this.activeAffiliateFormControls[affiliateName]?.value === true) {
        result = true;
        return;
      }
    });
    return result;
  }
}
