<ion-content>
  <div class="root">
    <ion-button (click)="handleClose()" class="close-button" fill="clear">
      <ion-icon name="close"></ion-icon>
    </ion-button>

    <div class="menu-logo">
      <img class="vertical-logo" [src]="logoPath" />
    </div>

    <ul class="menu-items">
      <li (click)="handleShowMyDesigns()">{{ 'app-menu.my-designs' | transloco }}</li>
      <li (click)="handleShowContactInfo()">
        <a [href]="'app-menu.contact-us-link' | transloco" target="_blank">{{ 'app-menu.contact-us' | transloco }}</a>
      </li>
      <li (click)="handleShowImprint()">
        <a [href]="'app-menu.imprint-link' | transloco" target="_blank">{{ 'app-menu.imprint' | transloco }}</a>
      </li>
      <li (click)="handleShowPrivacyPolicy()">
        <a [href]="'app-menu.privacy-policy-link' | transloco" target="_blank">{{ 'app-menu.privacy-policy' | transloco
          }}</a>
      </li>
      <li (click)="handleSignIn()" *ngIf="!isLoggedIn">{{ 'app-menu.login' | transloco }}</li>
      <li (click)="handleLogout()" *ngIf="isLoggedIn">{{ 'app-menu.logout' | transloco }}</li>
    </ul>

    <div class="social-media">
      <div class="follow-us">{{ 'app-menu.social-media.follow-us' | transloco }}</div>
      <div class="social-icons">
        <a href="https://www.facebook.com/MyShelfy" target="_blank">
          <ion-icon name="logo-facebook"></ion-icon>
        </a>
        <a href="https://www.instagram.com/my.shelfy" target="_blank">
          <ion-icon name="logo-instagram"></ion-icon>
        </a>
        <a href="https://twitter.com/myshelfy" target="_blank">
          <ion-icon name="logo-twitter"></ion-icon>
        </a>
        <a href="https://www.pinterest.de/myshelfy" target="_blank">
          <ion-icon name="logo-pinterest"></ion-icon>
        </a>
      </div>
    </div>

    <ul class="menu-items" *ngIf="isAdmin">

      <li (click)="handleAdminTool()">{{ 'app-menu.admintool' | transloco }}</li>
      <li (click)="handleEditShelf()">{{ 'app-menu.edit-shelf' | transloco }}</li>
    </ul>

    <div class="current-app-version"
      [ngClass]="{'not-all': environment.allowedAffiliates.length===1 && environment.allowedAffiliates[0]===affiliatesEnum.ALL}">
      {{ 'app-menu.current-app-version' | transloco }}: {{ currentAppVersion }}
    </div>

    <div *ngIf="environment.allowedAffiliates.length===1 && environment.allowedAffiliates[0]===affiliatesEnum.ALL"
      class="footer-img" (click)="handleCatClick($event)" (touchstart)="handleCatClick($event)">
      <img src="../../assets/katze_front.png" height="151" />
    </div>
  </div>
</ion-content>
