import { Injectable } from '@angular/core';
import { collection, collectionData, doc, docData, Firestore, getDocs, orderBy, query, Query, where } from '@angular/fire/firestore';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { AffiliatesEnum } from '../enums/affiliates.enum';
import { ShelfScenesEnum } from '../enums/scenes-shelf.enum';
import { LoadShelvesConfiguration } from '../interfaces/load-shelves-configuration.interface';
import { Scene } from '../interfaces/scenes-scene.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';

@Injectable({
  providedIn: 'root',
})
export class ShelvesService {
  // private basePath: string = environment.basePath;
  // private serverUrl: string = environment.serverUrl;
  private shelfCache: ShelfScenes[] = [];
  affiliatesEnum = AffiliatesEnum;

  constructor(private firestore: Firestore) { }

  // private prepareHeader(): object {
  //   let headers = new HttpHeaders();
  //   headers = headers.set('Content-Type', 'application/json');
  //   headers = headers.set('Accept', 'application/json');

  //   return {
  //     headers: headers
  //   };
  // }

  getShelf$(id: string): Observable<ShelfScenes> {
    return docData(doc(this.firestore, 'shelfs', id), { idField: 'id' }).pipe(
      take(1),
      map((shelf: ShelfScenes) => {
        for (let scene of shelf.scenes) {
          if (scene) {
            scene.slots = scene.slots.map((slot: SlotScenes, index) => {
              slot.slotData.width = slot.slotData.lr.x - slot.slotData.ll.x;
              slot.slotData.height = slot.slotData.lr.y - slot.slotData.ur.y;
              return slot;
            });
          }
        }

        shelf.activeAffiliates = [];
        for (const [affiliateName, affiliateData] of Object.entries(shelf.affiliates)) {
          shelf.activeAffiliates.push(affiliateName);
        }

        return shelf;
      }),
      map((shelf: ShelfScenes) => {
        shelf.scenes = shelf.scenes.filter((scene: Scene) => scene.type != ShelfScenesEnum.FRONTAL);
        return shelf;
      }),
      switchMap((shelf: ShelfScenes) => this.getShelfWithSortedSlots$(shelf))
    );
  }

  getShelfWithSortedSlots$(shelf: ShelfScenes): Observable<ShelfScenes> {
    return this.sortShelfCoordinates$(shelf?.scenes[0]?.slots).pipe(
      map((sortedSlots: SlotScenes[]) => {
        if (shelf && shelf?.scenes[0]?.slots) {
          shelf.scenes[0].slots = sortedSlots;
        }
        // console.log('##sorted shelf: ', shelf.slotsData.slots);
        return shelf;
      })
    );
  }

  sortShelfCoordinates$(slots: SlotScenes[]): Observable<SlotScenes[]> {
    return of(slots).pipe(
      map((slots: SlotScenes[]) => {
        // sort by y and x
        if (slots) {
          slots.sort((a, b) => {
            let s = a.slotData.ul.y - b.slotData.ul.y;
            if (s == 0) {
              s = a.slotData.ul.x - b.slotData.ul.x;
            }
            return s;
          });
        }

        return slots;
      }),
      map((slots: SlotScenes[]) => {
        // set correct slotnumber depending on new index
        if (slots) {
          slots.forEach((slot, index) => {
            slot.id = index;
          });
        }
        return slots;
      })
    );
  }

  private _compareForPriceMax = (n1, n2) => {
    if (n1.priceMax > n2.priceMax) {
      return 1;
    }

    if (n1.priceMax < n2.priceMax) {
      return -1;
    }

    return 0;
  };

  getShelves$(config?: LoadShelvesConfiguration): Observable<ShelfScenes[]> {
    const constraints = [];

    if (config) {
      if (config.type) {
        constraints.push(where('type', '==', config.type));
        constraints.push(orderBy('color'));
      } else {
        constraints.push(orderBy('type'));
      }
    } else {
      constraints.push(orderBy('type'));
    }

    const shelfsQuery = query(collection(this.firestore, 'shelfs'), where('active', '==', true), orderBy('type'));

    // TODO: how to get id with firebase7?
    // docData(docRef, { idField: 'id' })

    return of(null).pipe(
      switchMap(() => from(getDocs(shelfsQuery)).pipe(take(1))),
      map((snapshot) => snapshot.docs),
      switchMap((querySnapshot) => {
        const obs: Observable<ShelfScenes>[] = [];
        querySnapshot.forEach((doc) => {
          const shelf: ShelfScenes = doc.data() as ShelfScenes;
          shelf.activeAffiliates = [];
          for (const [affiliateName, affiliateData] of Object.entries(shelf.affiliates)) {
            shelf.activeAffiliates.push(affiliateName);
          }
          obs.push(this.getShelfWithSortedSlots$(shelf));
        });
        return forkJoin(obs);
      })
    );
  }

  getShelvesForColor$(config?: { affiliateId?: string; color?: string }): Observable<ShelfScenes[]> {
    let shelvesQuery: Query = null;

    if (config?.color != null) {
      shelvesQuery = query(collection(this.firestore, 'shelfs'), where('color', '==', config.color), orderBy('type'));
    } else {
      shelvesQuery = query(collection(this.firestore, 'shelfs'), orderBy('type'));
    }

    // valueChanges({ idField: 'id' })

    return collectionData(shelvesQuery, { idField: 'id' }).pipe(
      switchMap((shelves: ShelfScenes[]) => {
        const obs: Observable<ShelfScenes>[] = [];
        shelves.forEach((shelf) => {
          shelf.activeAffiliates = [];
          for (const [affiliateName, affiliateData] of Object.entries(shelf.affiliates)) {
            shelf.activeAffiliates.push(affiliateName);
          }
          obs.push(this.getShelfWithSortedSlots$(shelf));
        });
        return forkJoin(obs);
      }),
      map((shelves: ShelfScenes[]) => {
        return shelves.filter((shelf) => {
          let succ = true;
          if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] !== this.affiliatesEnum.ALL) {
            succ = succ && shelf.affiliates[environment.allowedAffiliates[0]]?.active;
          }
          else {
            if (config?.affiliateId) {
              succ = succ && shelf.affiliates[config.affiliateId]?.active;
            }
          }

          return succ;
        });
      })
    );
  }

  getShelfTypes$(color: string): Observable<ShelfScenes[]> {
    const shelvesQuery: Query = query(collection(this.firestore, 'shelfs'), where('color', '==', color)); //.orderBy('name'))

    return collectionData(shelvesQuery, { idField: 'id' }).pipe(
      take(1),
      switchMap((shelves: ShelfScenes[]) => {
        const obs: Observable<ShelfScenes>[] = [];
        shelves.forEach((shelf) => {
          shelf.activeAffiliates = [];
          for (const [affiliateName, affiliateData] of Object.entries(shelf.affiliates)) {
            shelf.activeAffiliates.push(affiliateName);
          }
          obs.push(this.getShelfWithSortedSlots$(shelf));
        });
        return forkJoin(obs);
      })
    );
  }

  /**
   * Gets all shelf colors for one type.
   * @param type (e.h. 2x2, 4x4)
   * @returns shelves
   */
  // .orderBy('color')
  getShelvesForType$(type: string): Observable<ShelfScenes[]> {
    const shelvesQuery = query(collection(this.firestore, 'shelfs'), where('type', '==', type));

    return of(null).pipe(
      switchMap(() => collectionData(shelvesQuery, { idField: 'id' }).pipe(take(1))),
      switchMap((shelves: ShelfScenes[]) => {
        const obs: Observable<ShelfScenes>[] = [];

        shelves.forEach((shelf) => {
          shelf.activeAffiliates = [];
          for (const [affiliateName, affiliateData] of Object.entries(shelf.affiliates)) {
            shelf.activeAffiliates.push(affiliateName);
          }
          if (shelf.active) {
            obs.push(this.getShelfWithSortedSlots$(shelf));
          }
        });

        return forkJoin(obs);
      })
    );
  }

  transferSelectedItems(currentShelf: ShelfScenes, newShelf: ShelfScenes): ShelfScenes {
    // create map with rows
    const rowMapCurrent: Map<string, { slotImage: string; itemId: string }[]> = new Map();
    const rowMapNew: Map<string, { slotImage: string; itemId: string }[]> = new Map();
    const currentNoCols: number = +currentShelf?.type.split('x')[0];
    // const currentNoRows: number = +currentShelf?.type.split('x')[1];
    const newNoCols: number = +newShelf.type.split('x')[0];
    // const newNoRows: number = +newShelf.type.split('x')[1];

    // map of rows of current shelf (cut to fit)
    let chunk = newNoCols < currentNoCols ? newNoCols : currentNoCols;
    for (let i = 0, j = currentShelf?.scenes[0].slots.length, k = 0; i < j; i += currentNoCols, k++) {
      let row: { slotImage: string; itemId: string }[] = currentShelf?.scenes[0].slots
        .slice(i, i + chunk)
        .map((slot: SlotScenes) => ({ slotImage: slot.slotImage, itemId: slot.itemId }));
      rowMapCurrent.set(`r${k}`, row);
    }

    // map of rows of new shelf
    for (let i = 0, j = newShelf.scenes[0].slots.length, k = 0; i < j; i += newNoCols, k++) {
      let row: { slotImage: string; itemId: string }[] = newShelf.scenes[0].slots
        .slice(i, i + newNoCols)
        .map((slot: SlotScenes) => ({ slotImage: slot.slotImage, itemId: slot.itemId }));
      rowMapNew.set(`r${k}`, row);
    }

    // map current to new
    rowMapCurrent.forEach((currentRowWithImages: { slotImage: string; itemId: string }[], key: string) => {
      if (rowMapNew.has(key)) {
        rowMapNew.set(key, currentRowWithImages);
      }
    });

    // map into slotarray
    for (let i = 0, j = newShelf.scenes[0].slots.length, k = 0; i < j; i += newNoCols, k++) {
      rowMapNew.get(`r${k}`).forEach((slotImages: { slotImage: string; slotImage400: string; itemId: string }, index: number) => {
        newShelf.scenes.forEach((scene: Scene, sceneIndex: number) => {
          if (scene.slots.length > 0) {
            scene.slots[i + index].slotImage = slotImages.slotImage;
            scene.slots[i + index].itemId = slotImages.itemId;
          }
        });
      });
    }

    return newShelf;
  }
}
