<ion-fab class="social-fab">
  <ion-fab-button class="root-button" [ngClass]="{'is-mobil': !(desktopPlatform$ | async)}">
    <div class="button-content"
      [ngClass]="{'is-desktop': desktopPlatform$ | async, 'is-mobil': !(desktopPlatform$ | async)}">
      <ion-icon name="share-social"></ion-icon>
      <ion-label class="option-text">{{ 'app-menu.share' | transloco }}</ion-label>
    </div>
  </ion-fab-button>
  <ion-fab-list side="top" #shareButtons>
    <ion-fab-button (click)="handleShareOnPinterest()" class="target-option">
      <ion-icon name="logo-pinterest"></ion-icon>
    </ion-fab-button>
    <ion-fab-button (click)="handleShareOnTwitter()" class="target-option">
      <ion-icon name="logo-twitter"></ion-icon>
    </ion-fab-button>
    <ion-fab-button (click)="handleShareOnFacebook()" class="target-option">
      <ion-icon name="logo-facebook"></ion-icon>
    </ion-fab-button>
    <ion-fab-button (click)="handleShare()" class="target-option">
      <ion-icon name="share-outline"></ion-icon>
    </ion-fab-button>
  </ion-fab-list>
</ion-fab>
