import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Animation, AnimationController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import {
  enterItemPickerAction,
  hideIntroductionAnimationAction,
  openDesignCloneDialogAction,
} from '../../core/actions/shelf-designer.actions';
import { ShelfScenes } from '../../core/interfaces/scenes-shelf.interface';
import { SlotScenes } from '../../core/interfaces/scenes-slot.interface';
import { ExtendedShoppingCartItem } from '../../core/interfaces/shopping-cart-item.interface';
import { IsEmptyPipe } from '../../core/pipes/is-empty.pipe';
import { isShelfItemLoadConfigSuccess } from '../../core/reducers/shelf-item.reducer';
import { getShoppingCardItemById, isLoadConfigSuccess } from '../../core/reducers/shoppingcart.reducer';
import { ImagePrerenderService } from '../../core/services/image-prerender.service';
import { PerspectiveImageService } from '../../core/services/perspective-image.service';
import { ImgShelfItemStylingInterface } from '../../core/interfaces/img-shelf-item-styling.interface';
import { ItemPositionEnum } from '../../core/enums/item-position.enum';
import { AffiliatesEnum } from '../../core/enums/affiliates.enum';
import { Item } from '../../core/interfaces/item.interface';

@Component({
  selector: 'app-shelf-item',
  templateUrl: './shelf-item.component.html',
  styleUrls: ['./shelf-item.component.scss'],
  providers: [IsEmptyPipe],
})
export class ShelfItemComponent implements OnInit, OnDestroy, OnChanges {
  environment = environment;
  affiliatesEnum = AffiliatesEnum;

  // UI bindings
  transformStyle$ = new BehaviorSubject<SafeStyle>(null);
  isBuildItemImage$ = new BehaviorSubject<boolean>(false);
  placeholderImagePath$ = new BehaviorSubject<string>('./assets/imgs/choose-button.png');
  placeholderOpacity$ = new BehaviorSubject<number>(0.0);
  playPlaceholderAnimation$ = new BehaviorSubject<boolean>(false);
  imgStyling: ImgShelfItemStylingInterface = {
    width: 0,
    height: 0,
    position: ItemPositionEnum.Right
  };
  imgResolution: number = 1000;

  @Input() shelf: ShelfScenes;
  @Input() slot: SlotScenes;
  @Input() isOwnDesign: boolean;

  observer: ResizeObserver;
  shelItemWidth$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  @ViewChild('img') img: ElementRef<HTMLImageElement>;
  @ViewChild('placeholder') placeholder: ElementRef;
  @ViewChild('shelfyWarning') shelfyWarning: ElementRef;

  private loadConfigSuccess = false;
  private shelfItemLoadConfigSuccess$: Observable<boolean> = this.store.select(isShelfItemLoadConfigSuccess); // TODO: Can be optimized?
  private shelfConfiguratorLoadConfigSuccess$: Observable<boolean> = this.store.select(isLoadConfigSuccess);

  private destroy$ = new Subject<void>();

  private clickOnSlotAnimation: Animation;
  slotAnimationActive: boolean;

  doesItemFitInSlot$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor(
    private perspectiveImageService: PerspectiveImageService,
    private imagePrerender: ImagePrerenderService,
    private store: Store,
    private sanitizer: DomSanitizer,
    private animationController: AnimationController,
    private isEmpty: IsEmptyPipe,
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone
  ) {
    this.slotAnimationActive = false;

    this.shelfConfiguratorLoadConfigSuccess$.pipe(takeUntil(this.destroy$)).subscribe((success: boolean) => {
      if (success) {
        this.shelfItemLoadConfigSuccess$.pipe(takeUntil(this.destroy$)).subscribe((loadConfigSuccess: boolean) => {
          this.loadConfigSuccess = loadConfigSuccess;
        });
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slot) {
      // TODO: check if shelf is empty
      if (this.slot.id === 0 && this.isEmpty.transform(this.shelf)) {
        this.slotAnimationActive = true;
        this.placeholderOpacity$.next(1);
      }
      this.render();
    } else if (changes.shelf) {
      if (this.slot?.id === 0) {
        if (this.isEmpty.transform(this.shelf)) {
          this.slotAnimationActive = true;
          this.placeholderOpacity$.next(1);
        } else {
          this.slotAnimationActive = false;
          this.placeholderOpacity$.next(0);
        }
      }
    }
  }

  ngOnInit(): void {
    /* for saved config */
    if (this.loadConfigSuccess) {
      this.loadConfigSuccess = false;
    } else {
      // this.store.dispatch(buildItemImageAction({ slot: this.slot }));
    }

    if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] !== this.affiliatesEnum.ALL) {
      switch (environment.allowedAffiliates[0]) {
        case AffiliatesEnum.OTTO_DE:
          this.placeholderImagePath$.next('./assets/imgs/choose-button-otto.png');
          break;
      }
    }
  }

  ngAfterViewInit(): void {
    this.render();
    this.initResizer();
  }

  initResizer(): void {
    this.observer = new ResizeObserver(entries => {
      this.zone.run(() => {
        let width = entries[0].contentRect.width;
        this.shelItemWidth$.next(width);
      });
    });

    if (this.shelfyWarning?.nativeElement !== undefined) {
      this.observer.observe(this.shelfyWarning.nativeElement);
    }
  }

  private render(): void {
    if (!this.img?.nativeElement) return;
    this.store
      .select(getShoppingCardItemById(this.slot.itemId))
      .pipe(takeUntil(this.destroy$))
      .subscribe((item: ExtendedShoppingCartItem) => {
        if (item) {
          this.img.nativeElement.src = item.item.imageFrontalPath.replace('.png', `_${this.imgResolution}x${this.imgResolution}.png`);
          this.imgStyling = this.imagePrerender.calculateDestinationStyle(item.item, this.slot);
          this.doesItemFit(item.item);
        }
        this.buildCSSTransformation();
        this.changeDetector.detectChanges();
      });
  }

  doesItemFit(item: Item): void {
    let ret: boolean = true;
    ret = (this.slot.slotSizes.height >= item.height);
    ret = ret && (this.slot.slotSizes.width >= item.width);
    // item.depth can be NaN
    let succDepth: boolean = true;
    try {
      if (item.depth > 0) {
        succDepth = (this.slot.slotSizes.depth >= item.depth);
      }
    } catch (e) { }

    this.doesItemFitInSlot$.next(ret && succDepth);
  }

  @HostListener('mouseover') mouseOver(): void {
    this.placeholderOpacity$.next(1.0);
    this.slotAnimationActive = false;
  }

  @HostListener('mouseleave') mouseLeave(): void {
    if (!this.playPlaceholderAnimation$.getValue()) {
      this.placeholderOpacity$.next(0.0);
    }
  }

  public playClickOnSlotAnimation() {
    const play = this.slot?.id === 0 && this.isEmpty.transform(this.shelf);
    this.playPlaceholderAnimation$.next(play);
    if (play) {
      this.placeholderOpacity$.next(1.0);
    }

    if (!this.clickOnSlotAnimation) {
      this.clickOnSlotAnimation = this.animationController
        .create()
        .addElement(this.placeholder.nativeElement)
        .duration(700)
        .fromTo('transform', 'scale(1.15)', 'scale(0.9)')
        .direction('alternate')
        .fill('both')
        .iterations(Infinity);
    }

    this.clickOnSlotAnimation.play();
  }

  public stopClickOnSlotAnimation() {
    this.placeholderOpacity$.next(0);
    if (this.clickOnSlotAnimation) {
      this.clickOnSlotAnimation.stop();
    }
  }

  @HostListener('click') openItemPicker(): void {
    /**
     * If introduction animation was created (and played), stop it
     */
    if (this.clickOnSlotAnimation) {
      this.store.dispatch(hideIntroductionAnimationAction());
    }

    if (this.isOwnDesign) {
      this.store.dispatch(enterItemPickerAction({ shelf: this.shelf, slot: this.slot }));
    } else {
      this.store.dispatch(openDesignCloneDialogAction());
    }
  }

  getWidth(urx: number, ulx: number): number {
    return urx - ulx;
  }

  getHeight(lry: number, ury: number): number {
    return lry - ury;
  }

  private buildCSSTransformation(): void {
    const w = this.slot.slotData.width;
    const h = this.slot.slotData.height;
    var dst = [];

    const referenceX = this.slot.slotData.ul.x;
    const referenceY = this.slot.slotData.ul.y;

    dst.push([this.slot.slotData.ul.x - referenceX, this.slot.slotData.ul.y - referenceY]);
    dst.push([this.slot.slotData.ur.x - referenceX, this.slot.slotData.ur.y - referenceY]);
    dst.push([this.slot.slotData.lr.x - referenceX, this.slot.slotData.lr.y - referenceY]);
    dst.push([this.slot.slotData.ll.x - referenceX, this.slot.slotData.ll.y - referenceY]);

    let transform = this.perspectiveImageService.computeTransform(
      [
        [0, 0],
        [w, 0],
        [w, h],
        [0, h],
      ],
      dst
    );

    this.transformStyle$.next(this.sanitizer.bypassSecurityTrustStyle(`transform-origin: 0px 0px; transform: ${transform}`));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
