import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { leaveItemDetailsAction as leaveItemDetailsAction } from '../../core/actions/shelf-item-picker.actions';
import { leaveShelfDetailsAction } from '../../core/actions/shelf-picker.actions';
import { Item } from '../../core/interfaces/item.interface';
import { chooseItemAction, chooseShelfAction } from '../../core/actions/shelf-designer.actions';
import { ProductCategories } from '../../core/enums/product-categories.enum';
import { ContextCategories } from '../../core/enums/context-categories.enum';
import { ShelfScenes } from '../../core/interfaces/scenes-shelf.interface';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss'],
})
export class ProductDetailsComponent implements OnInit {
  @Input() product!: ShelfScenes | Item;
  @Input() productCategory!: ProductCategories;
  @Input() context!: ContextCategories;
  @Input() slotNumber?: number;

  pickerContext = ContextCategories.Picker;

  constructor(private store: Store) { }

  ngOnInit(): void { }

  handleClose(): void {
    if (this.productCategory === ProductCategories.Shelf) {
      this.store.dispatch(leaveShelfDetailsAction({ pickerType: null }));
    } else if (this.productCategory === ProductCategories.Item) {
      this.store.dispatch(leaveItemDetailsAction());
    }
  }

  handleChoose(): void {
    // this.store.dispatch(setItemInShelfAction({ slot: this.slot, item: this.item }));
    // current slot is saved in redux now... refactor...
    if (this.productCategory === ProductCategories.Shelf) {
      this.store.dispatch(chooseShelfAction({ shelfId: this.product.id }));
    } else if (this.productCategory === ProductCategories.Item) {
      this.store.dispatch(chooseItemAction({ item: this.product as Item }));
    }
  }
}
