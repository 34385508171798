<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <a href="/">
        <img class="app-logo" [src]="logoPath" />
      </a>
    </ion-buttons>
    <ion-title> </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="handleShowAppMenu()" class="app-menu">
        <ion-icon name="menu" slot="icon-only"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<app-product-details
  *ngLet="product$ | async as obsProduct"
  [product]="obsProduct"
  [productCategory]="productCategory"
  [context]="ContextCategories.Page"
></app-product-details>
