import { TypesEnum } from './types.enum';

export enum HacksTypesEnum {
  Feet = TypesEnum.Feet,
  Sticker = TypesEnum.Sticker,
  Plates = TypesEnum.Plates,
  AdditionalHacks = TypesEnum.AdditionalHacks,
}

export enum HacksSlotsIdEnum {
  Feet = -1,
  Sticker = -2,
  Plates = -3,
  AdditionalHacks = -4,
}
