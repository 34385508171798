import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imageSize',
})
export class ImageSizePipe implements PipeTransform {
  transform(src: string | null | undefined, size: string = '62x62', extension: string = 'png'): unknown {
    if (!src) {
      return '';
    }
    const imageSrc = src as string;
    // console.log(`>>> IMAGE PIPE: [src]='${src}' [ext]='${extension}' [size]='${size}'`);

    const defaultExtension = imageSrc.split('.').reverse()[0];
    let srcSet = `${imageSrc.replace(`.${defaultExtension}`, `_${size}.${extension}`)}`;

    // add GET parameter alt=media to retrieve an image (instead of JSON)
    if (srcSet.indexOf('?alt=media') === -1) {
      srcSet += '?alt=media';
    }

    // console.log('>>> FORMATTED IMAGE SRC SET', srcSet);

    return srcSet;
  }
}
