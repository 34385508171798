import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { loadMyDesignsAction } from '../core/actions/my-designs.actions';
import { hideMyDesignsAction, showMyDesignAction } from '../core/actions/shelf-designer.actions';
import { ExtendedDesign, Design } from '../core/interfaces/design.interface';
import { getMyDesigns, isLoadingMyDesigns } from '../core/reducers/my-designs.reducer';
import { AuthService } from '../login/services/auth.service';

@Component({
  selector: 'app-my-designs',
  templateUrl: './my-designs.component.html',
  styleUrls: ['./my-designs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyDesignsComponent implements OnInit {
  myDesigns$: Observable<ExtendedDesign[]> = this.store.select(getMyDesigns);
  loadingMyDesigns$: Observable<boolean> = this.store.select(isLoadingMyDesigns);
  userId = this.authService.userData.uid;

  constructor(private store: Store, private authService: AuthService) {}

  ngOnInit(): void {
    this.store.dispatch(loadMyDesignsAction());
  }

  handleLoadDesign(myDesign: Design) {
    this.store.dispatch(showMyDesignAction({ designId: myDesign.id }));
  }

  handleClose() {
    this.store.dispatch(hideMyDesignsAction());
  }

  handleRemoveDesign(myDesign: Design) {}

  trackByItem(index, design: ExtendedDesign) {
    return design.id;
  }
}
