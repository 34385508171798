import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { BehaviorSubject, from, map, switchMap, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { BottomPanePositionsEnum } from '../../../core/enums/bottom-pane-positions.enum';
import { environment } from '../../../../environments/environment';
import {
  chooseShelfAction,
  chooseShelfColorAction,
  openDesignCloneDialogAction,
  setShelfScenesHacksPickerSizeMobileAction,
} from '../../../core/actions/shelf-designer.actions';
import {
  loadShelfTypesAction,
  setBottomPanelPositionAction,
  showShelfDetailsAction,
  toggleShelfColorAction,
  toggleShelfTypeAction,
} from '../../../core/actions/shelf-picker.actions';
import { PickerTypes } from '../../../core/enums/picker-types.enum';
import { ShelfColorsEnum } from '../../../core/enums/shelf-colors.enum';
import { ShelfTypesEnum } from '../../../core/enums/shelf-types.enum';
import { ShelfScenes } from '../../../core/interfaces/scenes-shelf.interface';
import { ProductListProduct } from '../../../core/interfaces/shopping-cart-item.interface';
import { getCurrentDesignUserId, getCurrentExtendedShoppingCartItemsAsListOfSingleItems } from '../../../core/reducers/my-designs.reducer';
import { getCurrentShelf, isShelfPickerOpen } from '../../../core/reducers/shelf-designer.reducer';
import {
  getActiveShelfColor,
  getActiveShelfType,
  getAvailableShelfColors,
  getAvailableShelfTypes,
  getBottomPanelPosition,
  getFilterdShelfTypes,
  isShelvesLoading,
} from '../../../core/reducers/shelf-picker.reducer';

@Component({
  selector: 'app-shelf-picker',
  templateUrl: './shelf-picker.component.html',
  styleUrls: ['./shelf-picker.component.scss'],
})
export class ShelfPickerComponent implements OnInit, AfterViewInit {
  environment = environment;
  shelves$: Observable<ShelfScenes[]> = this.store.select(getFilterdShelfTypes);
  currentShelf$: Observable<ShelfScenes> = this.store.select(getCurrentShelf);
  isLoadingShelves$: Observable<boolean> = this.store.select(isShelvesLoading);
  bottomPanelPosition$: Observable<string> = this.store.select(getBottomPanelPosition);
  productListItems$: Observable<ProductListProduct[]> = this.store.select(getCurrentExtendedShoppingCartItemsAsListOfSingleItems);
  isShelfPickerOpen$: Observable<boolean> = this.store.select(isShelfPickerOpen);
  currentDesignUserId$: Observable<string> = this.store.select(getCurrentDesignUserId);

  scrollLeftButtonVisible$ = new BehaviorSubject<boolean>(false);
  scrollRightButtonVisible$ = new BehaviorSubject<boolean>(true);

  types$: Observable<ShelfTypesEnum[]> = this.store.select(getAvailableShelfTypes);
  colors$: Observable<ShelfColorsEnum[]> = this.store.select(getAvailableShelfColors);

  activeType: ShelfTypesEnum = ShelfTypesEnum.ALL;
  activeColor: ShelfColorsEnum = ShelfColorsEnum.ALL;

  activeType$: Observable<ShelfTypesEnum> = this.store.select(getActiveShelfType);
  activeColor$: Observable<ShelfColorsEnum> = this.store.select(getActiveShelfColor);

  confirmStrings: {
    label: string;
    message: string;
    confirm: string;
    cancel: string;
  } = {
      label: '',
      cancel: '',
      confirm: '',
      message: '',
    };

  @Input() pickerType: PickerTypes = PickerTypes.ShelfType;
  @Input('sidebar') sidebar = true;

  @ViewChild('scrollingContainer') scrollingContainer: ElementRef;

  private _virtualScroller: VirtualScrollerComponent;

  skeletonItems$: BehaviorSubject<ShelfScenes[]> = new BehaviorSubject(this.getSkeletonShelves());
  @ViewChild('scroll')
  set virtualScroller(virtualScroller: VirtualScrollerComponent) {
    const firstInit = virtualScroller !== undefined && (this._virtualScroller == undefined || this._virtualScroller == null);

    this._virtualScroller = virtualScroller;

    if (firstInit) {
      this.recalculateVirtualScroller();
    }
  }

  get virtualScroller(): VirtualScrollerComponent {
    return this._virtualScroller;
  }

  constructor(
    private store: Store,
    private alertController: AlertController,
    private changeDetectorRef: ChangeDetectorRef,
    private transloco: TranslocoService
  ) {
    this.transloco
      .selectTranslation()
      .pipe(
        tap(() => {
          this.confirmStrings.label = this.transloco.translate('general.confirm-dialog-shelf-switch.label');
          this.confirmStrings.message = this.transloco.translate('general.confirm-dialog-shelf-switch.message');
          this.confirmStrings.confirm = this.transloco.translate('general.confirm-dialog-shelf-switch.confirm');
          this.confirmStrings.cancel = this.transloco.translate('general.confirm-dialog-shelf-switch.cancel');
        })
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.store.dispatch(loadShelfTypesAction());
  }

  ngOnInit(): void { }

  handleShelfClick(newShelf: ShelfScenes, list: any[], currentShelf: ShelfScenes, designUser: string): void {
    // check if this shelf is already active in the current design
    if (newShelf.id === currentShelf.id) {
      return;
    }

    let user = JSON.parse(localStorage.getItem('user'));
    if (user.uid === designUser) {
      if (list.length > 1) {
        this.openConfirmDialog(newShelf);
      } else {
        this.doShelfSwitch(newShelf);
      }
    } else {
      this.store.dispatch(openDesignCloneDialogAction());
    }
    // http://localhost:4200/shelfdesigner/YsAb9PidxOTZepq8aTmM/0
  }

  openConfirmDialog(shelf: ShelfScenes): void {
    from(
      this.alertController.create({
        header: this.confirmStrings.label,
        message: this.confirmStrings.message,
        buttons: [
          {
            text: this.confirmStrings.confirm,
            handler: () => {
              this.doShelfSwitch(shelf);
            },
          },
          {
            text: this.confirmStrings.cancel,
            role: 'cancel',
            handler: () => {
              console.log('Confirm Cancel');
            },
          },
        ],
      })
    )
      .pipe(
        switchMap((alert) => {
          return from(alert.present());
        })
      )
      .subscribe();
  }

  doShelfSwitch(shelf: ShelfScenes): void {
    switch (this.pickerType) {
      case PickerTypes.ShelfType:
        this.store.dispatch(chooseShelfAction({ shelfId: shelf.id }));
        break;
      case PickerTypes.ShelfColor:
        this.store.dispatch(chooseShelfColorAction({ shelfId: shelf.id }));
        break;
    }
    this.store.dispatch(setShelfScenesHacksPickerSizeMobileAction({ open: false }));
  }

  handleDetailsClick(shelf: ShelfScenes): void {
    this.store.dispatch(showShelfDetailsAction({ shelf }));
  }

  handleClose(): void {
    this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.MIDDLE }));
  }

  handleOpen(): void {
    this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.TOP }));
  }

  private recalculateVirtualScroller() {
    if (this.virtualScroller) {
      this.virtualScroller.invalidateAllCachedMeasurements();
    }
  }

  private getSkeletonShelves(): ShelfScenes[] {
    const shelves = new Array<ShelfScenes>();
    for (let i = 0; i < 50; i++) {
      const shelf = { skeleton: true } as ShelfScenes;
      shelves.push(shelf);
    }

    return shelves;
  }

  onVirtualScrollerUpdate(): void {
    this.scrollLeftButtonVisible$.next(this.virtualScroller.viewPortInfo.scrollStartPosition > 0);
    this.scrollRightButtonVisible$.next(
      this.virtualScroller.viewPortInfo.scrollStartPosition + 10 < this.virtualScroller.viewPortInfo.maxScrollPosition
    );
    this.changeDetectorRef.detectChanges();
  }

  handleScrollForward(): void {
    this.virtualScroller.scrollToPosition(this.virtualScroller.viewPortInfo.scrollEndPosition);
  }

  handleScrollBackward(): void {
    this.virtualScroller.scrollToPosition(
      this.virtualScroller.viewPortInfo.scrollStartPosition - this.scrollingContainer.nativeElement.offsetWidth
    );
  }

  handleToggleType(type: ShelfTypesEnum): void {
    this.store.dispatch(toggleShelfTypeAction({ shelfType: type }));
  }

  isTypeActive$(type: ShelfTypesEnum): Observable<boolean> {
    return this.activeType$.pipe(map((activeType: ShelfTypesEnum) => activeType == type));
  }

  onTypeChange(event: CustomEvent): void {
    this.store.dispatch(toggleShelfTypeAction({ shelfType: event.detail.value }));
  }

  handleToggleColor(color: ShelfColorsEnum): void {
    this.store.dispatch(toggleShelfColorAction({ shelfColor: color }));
  }

  isColorActive$(color: ShelfColorsEnum): Observable<boolean> {
    return this.activeColor$.pipe(map((activeColor: ShelfColorsEnum) => activeColor == color));
  }

  onColorChange(event: CustomEvent): void {
    this.store.dispatch(toggleShelfColorAction({ shelfColor: event.detail.value }));
  }
}
