import { Pipe, PipeTransform } from '@angular/core';
import { ProductCategories } from '../enums/product-categories.enum';
import { Item } from '../interfaces/item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { environment } from './../../../environments/environment';
import { AffiliatesEnum } from '../enums/affiliates.enum';

type ProductProperty = 'image' | 'hasActiveAffiliates' | 'hasMaterial' | 'url';

@Pipe({
  name: 'product',
})
export class ProductPipe implements PipeTransform {
  localPath: string = 'http://localhost:4200';
  shelfyPath: string = 'https://app.hello-shelfy.com';
  ottoPath: string = 'https://otto.hello-shelfy.com';
  pathToUse: string = '';
  debugLocal: boolean = false;
  affiliatesEnum = AffiliatesEnum;

  transform(product: Item | ShelfScenes, category: ProductCategories, property: ProductProperty): string | boolean {
    if (property === 'image') {
      if (category === ProductCategories.Shelf) {
        return (product as ShelfScenes).scenes[0]?.imageUrl;
      } else if (category === ProductCategories.Item) {
        return (product as Item)?.image180RemotePath;
      }
    } else if (property === 'hasActiveAffiliates') {
      return Object.keys(product.affiliates).length > 0;
    } else if (property === 'hasMaterial') {
      return Object.keys(product.materials).length > 0;
    } else if (property === 'url') {
      if (this.debugLocal) {
        return this.buildPath(this.localPath, category, product);
      } else {
        if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] !== this.affiliatesEnum.ALL) {
          switch (environment.allowedAffiliates[0]) {
            case AffiliatesEnum.OTTO_DE:
              return this.buildPath(this.ottoPath, category, product);
          }
        }
        return this.buildPath(this.shelfyPath, category, product);
      }

    }
  }

  private buildPath(pathBase: string, category: ProductCategories, product: Item | ShelfScenes): string {
    if (category === ProductCategories.Item) {
      return pathBase + '/product/item/' + product?.id;
    } else {
      return pathBase + '/product/shelf/' + product?.id;
    }
  }
}
