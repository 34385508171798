import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { initUserInterfaceAction, openReviewPopupSuccededAction } from '../actions/general.actions';

export interface GeneralState {
  splitMode: boolean | null;
  desktopPlatform: boolean;
  screenWidth: number;
  blockReviewForNativeApps: boolean;
}

export const initialState: GeneralState = {
  splitMode: null,
  desktopPlatform: false,
  screenWidth: 0,
  blockReviewForNativeApps: false,
};

export const generalReducer = createReducer(
  initialState,
  on(initUserInterfaceAction, (state, action) => {
    return {
      ...state,
      splitMode: action.splitMode,
      desktopPlatform: action.desktopPlatform,
      screenWidth: action.screenWidth,
    };
  }),
  on(openReviewPopupSuccededAction, (state, action) => {
    return {
      ...state,
      blockReviewForNativeApps: true,
    };
  }),

);

export function reducer(state: GeneralState = initialState, action: Action): GeneralState {
  return generalReducer(state, action);
}

export const generalState = createFeatureSelector<GeneralState>('general');
export const isSplitMode = createSelector(generalState, (state: GeneralState) => state.splitMode);
export const isDesktopPlatform = createSelector(generalState, (state: GeneralState) => state.desktopPlatform);
export const getScreenWidth = createSelector(generalState, (state: GeneralState) => state.screenWidth);
export const canShowReviewPopup = createSelector(generalState, (state: GeneralState) => (!(state.blockReviewForNativeApps)));
