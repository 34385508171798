import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-product-badge',
  templateUrl: './product-badge.component.html',
  styleUrls: ['./product-badge.component.scss']
})
export class ProductBadgeComponent implements OnInit {
  @Input() text!: string;
  @Input() priority!: 'primary' | 'secondary';

  constructor() { }

  ngOnInit(): void {
  }

}
