import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { BehaviorSubject, tap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { BottomPanePositionsEnum } from '../../../core/enums/bottom-pane-positions.enum';
import { TypesEnum } from '../../../core/enums/types.enum';
import { environment } from '../../../../environments/environment';
import { setBottomPanelPositionAction } from '../../../core/actions/shelf-picker.actions';
import { PickerTypes } from '../../../core/enums/picker-types.enum';
import { isHacksPickerOpen } from '../../../core/reducers/shelf-designer.reducer';
import { getBottomPanelPosition } from '../../../core/reducers/shelf-picker.reducer';
import { getActiveTypesHacks, getHacksItems, getHacksItemsToBuyIds } from '../../../core/reducers/hacks-picker.reducer';
import { loadHacksInitialAction, toggleChooseHacksItem, toggleHacksTypeAction } from '../../../core/actions/hacks-picker.actions';
import { Item } from '../../../core/interfaces/item.interface';
import { showItemDetailsAction } from '../../../core/actions/shelf-item-picker.actions';
import { getCurrentDesignUserId } from '../../../core/reducers/my-designs.reducer';
import { openDesignCloneDialogAction } from '../../../core/actions/shelf-designer.actions';
import { getHackTypes } from '../../../core/functions/hacks';

@Component({
  selector: 'app-hacks-picker',
  templateUrl: './hacks-picker.component.html',
  styleUrls: ['./hacks-picker.component.scss'],
})
export class HacksPickerComponent implements OnInit, AfterViewInit {
  environment = environment;
  currentDesignUserId$: Observable<string> = this.store.select(getCurrentDesignUserId);
  hacks$: Observable<Item[]> = this.store.select(getHacksItems);
  bottomPanelPosition$: Observable<string> = this.store.select(getBottomPanelPosition);
  isHacksPickerOpen$: Observable<boolean> = this.store.select(isHacksPickerOpen);

  scrollLeftButtonVisible$ = new BehaviorSubject<boolean>(false);
  scrollRightButtonVisible$ = new BehaviorSubject<boolean>(true);

  types: TypesEnum[];

  activeTypes$: Observable<TypesEnum[]> = this.store.select(getActiveTypesHacks);
  hackItemsToBuyIds$: Observable<string[]> = this.store.select(getHacksItemsToBuyIds);

  @Input() pickerType: PickerTypes = PickerTypes.ShelfType;
  @Input('sidebar') sidebar = true;

  @ViewChild('scrollingContainer') scrollingContainer: ElementRef;

  private _virtualScroller: VirtualScrollerComponent;

  skeletonItems$: BehaviorSubject<Item[]> = new BehaviorSubject(this.getSkeletonHacks());
  @ViewChild('scroll')
  set virtualScroller(virtualScroller: VirtualScrollerComponent) {
    const firstInit = virtualScroller !== undefined && (this._virtualScroller == undefined || this._virtualScroller == null);

    this._virtualScroller = virtualScroller;

    if (firstInit) {
      this.recalculateVirtualScroller();
    }
  }

  get virtualScroller(): VirtualScrollerComponent {
    return this._virtualScroller;
  }

  constructor(private store: Store, private changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.store.dispatch(loadHacksInitialAction());
  }

  ngOnInit(): void {
    // Remove all types, that were no Hacks-Types


    this.types = getHackTypes();

    this.hacks$.subscribe().unsubscribe();
  }

  handleHacksClick(hack: Item, uidDesign: string): void {
    let user = JSON.parse(localStorage.getItem('user'));
    if (user.uid === uidDesign) {
      this.store.dispatch(toggleChooseHacksItem({ item: hack }));
    } else {
      this.store.dispatch(openDesignCloneDialogAction());
    }
  }

  handleDetailsClick(hack: Item): void {
    this.store.dispatch(showItemDetailsAction({ item: hack }));
  }

  handleClose(): void {
    this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.MIDDLE }));
  }

  handleOpen(): void {
    this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.TOP }));
  }

  private recalculateVirtualScroller() {
    if (this.virtualScroller) {
      this.virtualScroller.invalidateAllCachedMeasurements();
    }
  }

  private getSkeletonHacks(): Item[] {
    const frames = new Array<Item>();
    for (let i = 0; i < 50; i++) {
      const frame = { skeletonItem: true } as Item;
      frames.push(frame);
    }

    return frames;
  }

  onVirtualScrollerUpdate(): void {
    this.scrollLeftButtonVisible$.next(this.virtualScroller.viewPortInfo.scrollStartPosition > 0);
    this.scrollRightButtonVisible$.next(
      this.virtualScroller.viewPortInfo.scrollStartPosition + 10 < this.virtualScroller.viewPortInfo.maxScrollPosition
    );
    this.changeDetectorRef.detectChanges();
  }

  handleScrollForward(): void {
    this.virtualScroller.scrollToPosition(this.virtualScroller.viewPortInfo.scrollEndPosition);
  }

  handleScrollBackward(): void {
    this.virtualScroller.scrollToPosition(
      this.virtualScroller.viewPortInfo.scrollStartPosition - this.scrollingContainer.nativeElement.offsetWidth
    );
  }

  handleToggleType(type: TypesEnum): void {
    this.store.dispatch(toggleHacksTypeAction({ hacksType: type }));
  }

  debug(value): void {
    console.log('>>> DEBUG:', value);
  }
}
