import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import {
  addSceneSuccededAction,
  hideSlotsEditorAction,
  importDataFromAffiliateAction,
  importDataFromAffiliateFailedAction,
  importDataFromAffiliateSucceededAction,
  loadAffiliatesFailedAction,
  loadAffiliatesSucceededAction,
  loadOrFilterAdminItemsAction,
  loadItemsFailedAction,
  loadOrFilterAdminItemsSucceededAction,
  loadShelfSucceededAction,
  openSlotsEditorAction,
  openSlotsEditorSucceededAction,
  updateDataFromAffiliateAction,
  updateDataFromAffiliateFailedAction,
  updateDataFromAffiliateSucceededAction,
  updateItemAction,
  updateItemFailedAction,
  updateItemSucceededAction,
} from '../actions/admin.actions';
import { Affiliate } from '../interfaces/affiliate.interface';
import { Item } from '../interfaces/item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';

export interface AdminState {
  loadingItems: boolean;
  items: Item[];
  itemsCache: Item[];
  totalItems: number;
  updatingItem: boolean;
  updatingItemFromAffiliate: boolean;
  openingSlotsEditor: boolean;
  importingItemFromAffiliate: boolean;
  affiliates: Affiliate[];
  shelf: ShelfScenes;
}

export const initialState: AdminState = {
  loadingItems: false,
  items: [],
  itemsCache: [],
  totalItems: Number.MAX_VALUE,
  updatingItem: false,
  updatingItemFromAffiliate: false,
  openingSlotsEditor: false,
  importingItemFromAffiliate: false,
  affiliates: [],
  shelf: null,
};

export const adminReducer = createReducer(
  initialState,
  on(updateItemAction, (state) => {
    return { ...state, updatingItem: true };
  }),
  on(updateItemSucceededAction, (state, action) => {
    return { ...state, updatingItem: false, items: [...state.items.filter((item) => item.id !== action.item.id), action.item] };
  }),
  on(updateItemFailedAction, (state) => {
    return { ...state, updatingItem: false };
  }),
  on(openSlotsEditorAction, (state) => {
    return { ...state, openingSlotsEditor: true };
  }),
  on(openSlotsEditorSucceededAction, (state) => {
    return { ...state, openingSlotsEditor: false };
  }),
  on(hideSlotsEditorAction, (state, action) => {
    return { ...state, openingSlotsEditor: false, shelf: action.shelf };
  }),
  on(importDataFromAffiliateAction, (state) => {
    return { ...state, importingItemFromAffiliate: true };
  }),
  on(importDataFromAffiliateSucceededAction, (state): AdminState => {
    return { ...state, importingItemFromAffiliate: false };
  }),
  on(importDataFromAffiliateFailedAction, (state) => {
    return { ...state, importingItemFromAffiliate: false };
  }),
  on(updateDataFromAffiliateAction, (state) => {
    return { ...state, updatingItemFromAffiliate: true };
  }),
  on(updateDataFromAffiliateSucceededAction, (state) => {
    return { ...state, updatingItemFromAffiliate: false };
  }),
  on(updateDataFromAffiliateFailedAction, (state) => {
    return { ...state, updatingItemFromAffiliate: false };
  }),
  on(loadOrFilterAdminItemsAction, (state) => {
    return { ...state, loadingItems: true };
  }),
  on(loadOrFilterAdminItemsSucceededAction, (state, action) => {
    if (state.itemsCache.length <= 0) {
      return { ...state, loadingItems: false, items: action.items, itemsCache: action.items };
    } else {
      return { ...state, loadingItems: false, items: action.items };
    }
  }),
  on(loadItemsFailedAction, (state) => {
    return { ...state, loadingItems: false, items: [] };
  }),
  on(loadAffiliatesSucceededAction, (state: AdminState, action) => {
    return { ...state, affiliates: action.affiliates };
  }),
  on(loadAffiliatesFailedAction, (state: AdminState) => {
    return { ...state, affiliates: [] };
  }),
  on(addSceneSuccededAction, (state: AdminState, action) => {
    return { ...state, shelf: action.shelf };
  }),
  on(loadShelfSucceededAction, (state: AdminState, action) => {
    return { ...state, shelf: action.shelf };
  })
);

export function reducer(state: AdminState = initialState, action: Action): AdminState {
  return adminReducer(state, action);
}

export const adminState = createFeatureSelector<AdminState>('admin');
export const isLoadingItems = createSelector(adminState, (state: AdminState) => state.loadingItems);
export const getItems = createSelector(adminState, (state: AdminState) => state.items);
export const getCachedItems = createSelector(adminState, (state: AdminState) => state.itemsCache);
export const isUpdatingItem = createSelector(adminState, (state: AdminState) => state.updatingItem);
export const isUpdatingProductFromAffiliate = createSelector(adminState, (state: AdminState) => state.updatingItemFromAffiliate);
export const isImportingProductFromAffiliate = createSelector(adminState, (state: AdminState) => state.importingItemFromAffiliate);
export const isOpeningSlotsEditor = createSelector(adminState, (state: AdminState) => state.openingSlotsEditor);
export const getAffiliates = createSelector(adminState, (state: AdminState) => state.affiliates);
export const getShelf = createSelector(adminState, (state: AdminState) => state.shelf);
