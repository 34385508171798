import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ResizeObserver } from '@juggle/resize-observer';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { contain } from 'intrinsic-scale';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { delay, distinctUntilChanged, map, share, switchMap, takeUntil, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { shareAction, showAppMenuAction } from '../../core/actions/general.actions';
import {
  createNewDesignAction,
  enterShelfColorPickerAction,
  enterShelfPickerAction,
  hideBottomPickerAction,
  openDesignCloneDialogAction,
  showBottomPickerAction,
  showIntroductionAnimationAction,
} from '../../core/actions/shelf-designer.actions';
import { showProductListAction } from '../../core/actions/shopping-cart.actions';
import { AffiliatesEnum } from '../../core/enums/affiliates.enum';
import { BottomPanePositionsEnum } from '../../core/enums/bottom-pane-positions.enum';
import { ShelfScenes } from '../../core/interfaces/scenes-shelf.interface';
import { Settings } from '../../core/interfaces/settings.interface';
import { isSplitMode } from '../../core/reducers/general.reducer';
import { isCurrentDesignOwnDesign } from '../../core/reducers/my-designs.reducer';
import {
  getCurrentSceneIndex,
  getCurrentShelf,
  isBottomPickerVisible,
  isChangingShelfType,
  isIntroductionAnimationVisible,
  isShelfLoading,
} from '../../core/reducers/shelf-designer.reducer';
import { getBottomPanelPosition } from '../../core/reducers/shelf-picker.reducer';
import { ProductListService } from '../../core/services/product-list.service';
import { SettingsService } from '../../core/services/settings.service';
import { ShelfDesignerService } from '../../core/services/shelf-designer.service';
import { ShelfItemComponent } from '../shelf-item/shelf-item.component';
import { showMyDesignsAction } from './../../core/actions/shelf-designer.actions';

@Component({
  selector: 'app-shelf-designer',
  templateUrl: './shelf-designer.component.html',
  styleUrls: ['./shelf-designer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShelfDesignerComponent implements OnInit, OnDestroy, AfterViewInit {
  environment = environment;
  isNativePlatform: boolean;
  affiliatesEnum = AffiliatesEnum;

  //   zoom = 0.3;
  private destroy$ = new Subject();
  private shelfImageWidth: number;
  private shelfImageHeight: number;
  private myDesignId: string; // not in use corrently
  private currentShelf: ShelfScenes;
  private introductionAnimationShefItem: ShelfItemComponent;
  private isIntroductionAnimationVisible$: Observable<boolean> = this.store.select(isIntroductionAnimationVisible);

  // UI bindings
  shelfZoomFactor$ = new BehaviorSubject(1.0);
  containerZoomFactor$ = new BehaviorSubject(1.0);
  currentShelf$: Observable<ShelfScenes> = this.store.select(getCurrentShelf);
  currentSceneIndex$: Observable<number> = this.store.select(getCurrentSceneIndex).pipe(
    tap((currentSceneIndex: number) => {
      // update scene index in URL
      const locationPaths = this.location.path().split('/');
      locationPaths.shift(); // remove first empty path
      if (locationPaths.length < 3) {
        locationPaths.push(currentSceneIndex.toString());
      } else {
        locationPaths[2] = currentSceneIndex.toString();
      }
      this.location.replaceState(`/${locationPaths.join('/')}`);
    })
  );
  currentSceneIndex: number = 0;
  shelfImageBounds$ = new BehaviorSubject({ x: 0, y: 0, width: 0, height: 0 });
  isChangingShelf$: Observable<boolean> = this.store.select(isChangingShelfType);
  isShelfLoading$: Observable<boolean> = this.store.select(isShelfLoading);
  currentDesignIsOwnDesign$: Observable<boolean> = this.store.select(isCurrentDesignOwnDesign);

  bottomPanelVisible$: Observable<boolean> = this.store.select(isBottomPickerVisible);
  bottomPanelPosition$: Observable<string> = this.store.select(getBottomPanelPosition);
  totalPrice$: Observable<number> = this.productListService.getTotalPrice$();
  rootHeight$: Observable<string>;

  splitMode$: Observable<boolean> = this.store.select(isSplitMode);

  logoPath: string = '../../assets/imgs/shelfy-logo.png';

  @ViewChildren('shelfImage') private shelfImageList: QueryList<ElementRef>;
  @ViewChild('shelfConfigurator', { static: true }) private shelfConfigurator: ElementRef;
  @ViewChild('shelfImageImg', { static: true }) private shelfImageImg: ElementRef;
  @ViewChildren(ShelfItemComponent) private shelfItems!: QueryList<ShelfItemComponent>;

  constructor(
    private store: Store,
    private location: Location,
    private settingsService: SettingsService,
    private productListService: ProductListService,
    public modalController: ModalController,
    private shelfDesignerService: ShelfDesignerService,
    private platform: Platform,
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
    if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] !== this.affiliatesEnum.ALL) {
      switch (environment.allowedAffiliates[0]) {
        case AffiliatesEnum.OTTO_DE:
          this.logoPath = '../../assets/imgs/otto-logo.svg';
          break;
      }
    }
    this.isNativePlatform = this.platform.is('cordova');

    // calculates designer height depending of the position of the shelves-designs-hacks-picker
    this.rootHeight$ = this.bottomPanelPosition$.pipe(
      distinctUntilChanged(),
      map((position: BottomPanePositionsEnum) => {
        if (position === BottomPanePositionsEnum.BOTTOM) {
          return `calc(100% - ${this.shelfDesignerService.getBottomSheetBottomPosition()}px)`;
        } else if (position === BottomPanePositionsEnum.MIDDLE || position === BottomPanePositionsEnum.TOP) {
          return `calc(100% - ${this.shelfDesignerService.getBottomSheetMiddlePosition()}px)`;
        }
      }),
      share()
    );

    // TODO: implement via REDUX or
    // move to init?
    this.settingsService
      .loadSettings$()
      .pipe(takeUntil(this.destroy$))
      .subscribe((settings: Settings) => {
        if (settings.firstUse) {
          this.store.dispatch(showIntroductionAnimationAction());
        }
      });

    this.currentShelf$.pipe(takeUntil(this.destroy$)).subscribe((shelf: ShelfScenes) => {
      this.currentShelf = shelf;

      if (shelf !== null) {
        if (!this.myDesignId) {
          // this.initializeShelf(shelf);
        }

        this.calculateZoomFactorOnShelfImageResize();
      }
    });

    this.currentSceneIndex$.pipe(takeUntil(this.destroy$)).subscribe((currentSceneIndex) => {
      this.currentSceneIndex = currentSceneIndex;
      this.calculateZoomFactorOnShelfImageResize();
    });
  }

  ngAfterViewInit(): void {
    const observer = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.shelfImageWidth = entry.contentRect.width;
        this.shelfImageHeight = entry.contentRect.height;

        this.calculateZoomFactorOnShelfImageResize();
      });
    });

    observer.observe(this.shelfImageList.first.nativeElement);

    this.shelfItems.changes
      .pipe(
        delay(10),
        takeUntil(this.destroy$),
        switchMap(() => this.isIntroductionAnimationVisible$),
        tap((isIntroductionAnimationVisible) => {
          isIntroductionAnimationVisible ? this.showIntroductionAnimation() : this.hideIntroductionAnimation();
        })
      )
      .subscribe(() => { });

    this.store.select(isSplitMode).subscribe((isSplitMode) => {
      console.log('>>> isSplitMode', isSplitMode);
      if (isSplitMode == null) return;
      if (isSplitMode) {
        this.store.dispatch(hideBottomPickerAction());
      } else {
        this.store.dispatch(showBottomPickerAction());
      }
    });
  }

  public showIntroductionAnimation() {
    if (this.shelfItems.length > 0) {
      const index = Math.max(0, Math.round(this.shelfItems.length / 2) - 1);
      this.introductionAnimationShefItem = this.shelfItems.toArray()[index];
      this.introductionAnimationShefItem.playClickOnSlotAnimation();
    }
  }

  public hideIntroductionAnimation() {
    if (this.introductionAnimationShefItem) {
      this.introductionAnimationShefItem.stopClickOnSlotAnimation();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private calculateZoomFactorOnShelfImageResize(): void {
    const currentShelfScene = this.currentShelf?.scenes[this.currentSceneIndex];
    if (!currentShelfScene) return;

    if (this.shelfImageWidth > 0 && currentShelfScene.imageSize) {
      let shelfZoomFactor = this.shelfImageWidth / currentShelfScene.imageSize.width;

      if (currentShelfScene.imageSize.height * shelfZoomFactor > this.shelfImageHeight) {
        shelfZoomFactor = this.shelfImageHeight / currentShelfScene.imageSize?.height;
      }
      if (currentShelfScene.imageSize.width * shelfZoomFactor > this.shelfImageWidth) {
        shelfZoomFactor = this.shelfImageWidth / currentShelfScene.imageSize?.width;
      }

      this.shelfZoomFactor$.next(shelfZoomFactor);
    }

    const imageNaturalWidth = currentShelfScene.imageSize?.width;
    const imageNaturalHeight = currentShelfScene.imageSize?.height;

    let imageSize = contain(this.shelfImageWidth, this.shelfImageHeight, imageNaturalWidth, imageNaturalHeight);

    this.shelfImageBounds$.next({ x: imageSize.x, y: imageSize.y, width: imageSize.width, height: imageSize.height });
  }

  handleShowAppMenu(): void {
    this.store.dispatch(showAppMenuAction());
  }

  handleChangeShelf(): void {
    this.store.dispatch(enterShelfPickerAction());
  }

  handleChangeShelfColor(): void {
    this.store.dispatch(enterShelfColorPickerAction());
  }

  handleShare(): void {
    this.store.dispatch(shareAction());
  }

  handleCreateNewDesign(): void {
    this.store.dispatch(createNewDesignAction());
  }

  handleShowBottomPicker() {
    this.store.dispatch(showBottomPickerAction());
  }

  handleCloneDialog(): void {
    this.store.dispatch(openDesignCloneDialogAction());
  }

  onGotoProductListClick() {
    this.store.dispatch(showProductListAction());
  }

  handleShowMyDesigns() {
    this.store.dispatch(showMyDesignsAction());
  }

  handleShelfImageLoaded(event: Event) {
    // console.log('>>> ShelfDesignerComponent: handleShelfImageLoaded', event);
    this.shelfDesignerService.notifyShelfImageLoaded();
  }

  handleLogoClick(): void {
    if (!this.isNativePlatform) {
      let goToLandingPage = true;

      // if in developer mode, ask whether user really wants to go to live landing page
      if (window.location.href.includes('localhost')) {
        goToLandingPage = confirm(this.translocoService.translate('developer.reallyGoToLandingPage'));
      }

      if (goToLandingPage) {
        window.location.href = 'https://hello-shelfy.com';
      }
    }
  }

  // @HostListener('mouseover') mouseOver(): void {
  //   console.log('>>> nutzer ist im Rregal...');
  // }

  // @HostListener('mouseleave') mouseOut(): void {
  //   console.log('>>> nutzer ist nicht mehr im Rregal...');
  // }
}
