<ion-content
  [scrollY]="false"
  [ngClass]="{ sidebar: sidebar, horizontal: !obs.isScenesPickerOpen, 'horizontal-desktop': sidebar && !obs.isScenesPickerOpen }"
  *ngLet="{
    shelfScenes: shelfScenes$ | async,
    isScenesPickerOpen: isScenesPickerOpen$ | async,
    scrollRightButtonVisible: scrollRightButtonVisible$ | async,
    scrollLeftButtonVisible: scrollLeftButtonVisible$ | async,
    sceneIndex: sceneIndex$ | async
  } as obs"
>
  <ng-container>
    <ion-button class="scroll-button left" fill="solid" (click)="handleScrollBackward()" [hidden]="!scrollLeftButtonVisible">
      <ion-icon name="arrow-back-outline"></ion-icon>
    </ion-button>

    <ion-button class="scroll-button right" fill="solid" (click)="handleScrollForward()" [hidden]="!scrollRightButtonVisible">
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </ion-button>
  </ng-container>

  <div class="no-scenes-message" *ngIf="obs.shelfScenes?.length < 2">{{ 'shelf-scenes-picker.no-scenes-available' | transloco }}</div>

  <!-- virtual scroller -->
  <div
    #scrollingContainer
    class="virtual-scroller-container"
    [ngClass]="{
      horizontal: !obs.isScenesPickerOpen,
      vertical: obs.isScenesPickerOpen,
      'horizontal-desktop': sidebar && !obs.isScenesPickerOpen
    }"
    *ngIf="!(obs.shelfScenes?.length < 2)"
  >
    <virtual-scroller
      *ngIf="obs.shelfScenes?.length > 1"
      #scroll
      [items]="(isLoadingShelves$ | async) ? (skeletonItems$ | async) : obs.shelfScenes"
      [executeRefreshOutsideAngularZone]="false"
      [checkResizeInterval]="1000"
      (vsUpdate)="onVirtualScrollerUpdate()"
      [enableUnequalChildrenSizes]="true"
      [horizontal]="!obs.isScenesPickerOpen"
    >
      <div *ngFor="let scene of scroll.viewPortItems" class="item">
        <div *ngIf="scene.skeleton" class="skeleton-item">
          <ion-skeleton-text animated class="item"></ion-skeleton-text>
        </div>
        <ng-container *ngIf="!scene.skeleton">
          <app-shelf-scenes-picker-item
            [scene]="scene"
            [sceneIndex]="scene.index"
            [selected]="scene?.index === obs.sceneIndex"
            (shelfClicked)="handleShelfClick($event)"
            (detailsClicked)="handleDetailsClick($event)"
          ></app-shelf-scenes-picker-item>
        </ng-container>
      </div>
    </virtual-scroller>
  </div>

  <!-- virtual scroller end-->
</ion-content>
