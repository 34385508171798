import { HacksTypesEnum } from '../enums/hacks-types.enum';
import { TypesEnum } from '../enums/types.enum';

export const getTypesWithoutHackTypes = (): TypesEnum[] => {
  let types: TypesEnum[] = Object.values(TypesEnum) as TypesEnum[];
  const hacksTypes: HacksTypesEnum[] = Object.values(HacksTypesEnum) as HacksTypesEnum[];
  hacksTypes.forEach((hack: HacksTypesEnum) => {
    let i: number = types.findIndex((type) => hack === (type as unknown as HacksTypesEnum));
    if (i !== -1) {
      types.splice(i, 1);
    }
  });

  return types;
};

export const getHackTypes = (): TypesEnum[] => {
  let typesOriginal: TypesEnum[] = Object.values(TypesEnum);
  let typesNew: TypesEnum[] = [];
  const hacksTypes: HacksTypesEnum[] = Object.values(HacksTypesEnum) as HacksTypesEnum[];

  typesOriginal.forEach((type: TypesEnum) => {
    for (let i: number = 0; i < hacksTypes.length; i++) {
      if ((type as unknown as HacksTypesEnum) === hacksTypes[i]) {
        typesNew.push(type);
        break;
      }
    }
  });

  return typesNew;
};
