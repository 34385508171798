import { Component, Input, OnInit } from '@angular/core';
import { ColorHexCodes } from '../../enums/colors.enum';
import { Color } from '../../interfaces/color.interface';

@Component({
  selector: 'app-color-circle',
  templateUrl: './color-circle.component.html',
  styleUrls: ['./color-circle.component.scss'],
})
export class ColorCircleComponent implements OnInit {
  @Input() color!: Color;
  @Input() active?: boolean;

  constructor() {}

  ngOnInit(): void {}
}
