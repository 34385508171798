import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { chooseShelfSceneAction } from '../../../../core/actions/shelf-designer.actions';
import { Scene } from '../../../../core/interfaces/scenes-scene.interface';
import { environment } from '../../../../../environments/environment';
import { AffiliatesEnum } from '../../../../core/enums/affiliates.enum';
import { Observable } from 'rxjs';
import { isSplitMode } from '../../../../core/reducers/general.reducer';

@Component({
  selector: 'app-shelf-scenes-picker-item',
  templateUrl: './shelf-scenes-picker-item.component.html',
  styleUrls: ['./shelf-scenes-picker-item.component.scss'],
})
export class ShelfScenesPickerItemComponent implements OnInit {
  @Input('scene') scene: Scene;
  @Input('sceneIndex') sceneIndex: number;
  @Input('selected') selected: boolean;

  splitMode$: Observable<boolean> = this.store.select(isSplitMode);

  environment = environment;
  affiliatesEnum = AffiliatesEnum;

  constructor(private store: Store) {}

  ngOnInit(): void {}

  handleItemClick(sceneIndex: number): void {
    this.store.dispatch(chooseShelfSceneAction({ sceneIndex }));
  }
}
