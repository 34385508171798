import { DebugService } from './core/services/debug.service';
import { AfterViewInit, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, takeUntil, tap } from 'rxjs';
import { environment } from '../environments/environment';
import { initUserInterfaceAction } from './core/actions/general.actions';
import { isDesktopPlatform, isSplitMode } from './core/reducers/general.reducer';
import { GeoLocationService } from './core/services/geo-location.service';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = 'Shelf Designer';

  resizeObserver: ResizeObserver;

  @ViewChild('ionapp', { read: ElementRef }) ionapp: ElementRef;

  desktopPlatform$: Observable<boolean> = this.store.select(isDesktopPlatform);
  splitMode$: BehaviorSubject<boolean | null> = new BehaviorSubject<boolean | null>(null);
  destroy$: Subject<void> = new Subject();

  showLandscapeWarning: boolean = false;

  // Please keep 'debugService' in the constructor! Used in migrateData()
  constructor(
    private router: Router,
    private zone: NgZone,
    private store: Store,
    private platform: Platform,
    // private debugService: DebugService,
    private geoLocationService: GeoLocationService
  ) {
    this.initializeApp();
  }

  ngOnInit(): void {
    this.store
      .select(isSplitMode)
      .pipe(
        takeUntil(this.destroy$),
        tap((splitMode: boolean) => this.splitMode$.next(splitMode))
      )
      .subscribe();

    this.geoLocationService.initGeoLocation$().subscribe((country: string) => {
      console.log('>>> user country', country);
    });

    // !!! activate this, if you want to copy a whole collection from original-project to dev-project
    // this.migrateData();
    // !!! activate and modify the function
    // this.updateData();
    // !!! get counts of all items, design, shelves
    // this.readAllData();
    // !!! Read all Design we have and give out the count of all design createt per each month
    // this.debugService.readDesigns$('designs').subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.observeResize();
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        // Example url: https://app.hello-shelfy.com/shelfdesigner/TtnIuwiatvyDeYocGd4T/0
        // slug = /shelfdesigner/TtnIuwiatvyDeYocGd4T/0
        const slug = event.url.split('.com').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
        // If no match, do nothing - let regular routing
        // logic take over
      });
    });
  }

  observeResize(): void {
    if (!this.resizeObserver) {
      // observe view div for size changes to update the ThreeJS canvas
      this.resizeObserver = new ResizeObserver((entries) => {
        for (const entry of entries) {
          this.onResize(entry);
        }
      });
    }
    this.resizeObserver.observe(this.ionapp.nativeElement);
  }

  onResize(resizeInfo: ResizeObserverEntry): void {
    if (resizeInfo.contentRect.width <= 0) return;

    const isSplitMode: boolean = resizeInfo.contentRect.width >= environment.mobilWidthBreakpoint;

    if (!this.platform.is('desktop')) {
      const ratioHeightToWidth: number = resizeInfo.contentRect.height / resizeInfo.contentRect.width;
      this.showLandscapeWarning = ratioHeightToWidth <= 0.55 && resizeInfo.contentRect.height < 500;
    } else {
      this.showLandscapeWarning = false;
    }

    if (isSplitMode !== this.splitMode$.getValue()) {
      this.store.dispatch(
        initUserInterfaceAction({
          splitMode: isSplitMode,
          screenWidth: resizeInfo.contentRect.width,
          desktopPlatform: this.platform.is('desktop'),
        })
      );
    }
  }

  // migrateData(): void {
  //   this.debugService.migrateAColection$('shelfs').subscribe();
  //   this.debugService.migrateAColection$('items').subscribe();
  //   this.debugService.migrateAColection$('designs').subscribe();
  // }

  // updateData(): void {
  //   this.debugService.update$().subscribe();
  // }

  // readAllData(): void {
  //   this.debugService.read$('shelfs').subscribe();
  //   this.debugService.read$('items').subscribe();
  //   this.debugService.read$('designs').subscribe();
  // }
}
