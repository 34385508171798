import { Component, OnInit, ViewChild } from '@angular/core';
import { IonFabList } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { createNewDesignAction } from '../../core/actions/shelf-designer.actions';
import { showProductListAction } from '../../core/actions/shopping-cart.actions';
import { ProductListService } from '../../core/services/product-list.service';
import { environment } from '../../../environments/environment';
import { AffiliatesEnum } from '../../core/enums/affiliates.enum';

@Component({
  selector: 'app-designer-options-bar',
  templateUrl: './designer-options-bar.component.html',
  styleUrls: ['./designer-options-bar.component.scss'],
})
export class DesignerOptionsBarComponent implements OnInit {
  @ViewChild('shareButtons', { static: false }) fab: IonFabList;
  environment = environment;
  affiliatesEnum = AffiliatesEnum;

  totalPrice$: Observable<number> = this.productListService.getTotalPrice$();

  constructor(private store: Store, private productListService: ProductListService) { }

  ngOnInit(): void { }

  handleShowProductList() {
    this.store.dispatch(showProductListAction());
  }

  handleCreateNewDesign(): void {
    this.store.dispatch(createNewDesignAction());
  }
}
