<ion-content>
  <div class="root">
    <ion-button (click)="handleClose()" class="close-button" fill="clear">
      <ion-icon name="close"></ion-icon>
    </ion-button>

    <div class="menu-logo">
      <img class="vertical-logo" src="../../assets/imgs/shelfy-logo.png" />
    </div>

    <ul class="menu-items">
      <li (click)="handleDeleteShelves()">{{ 'admin-tool.delete-shelves' | transloco }}</li>
      <li (click)="handleHome()">{{ 'general.home' | transloco }}</li>
      <li (click)="handleResetUsageCount()">Reset Usage Count</li>
      <li (click)="handleCheckForRequiredFields()">Check for required fields</li>
    </ul>

    <div class="footer-img">
      <img src="../../assets/katze_front.png" height="151" />
    </div>
  </div>
</ion-content>
