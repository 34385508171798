import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Item } from '../../../../core/interfaces/item.interface';
import { environment } from '../../../../../environments/environment';
import { AffiliatesEnum } from '../../../../core/enums/affiliates.enum';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { isSplitMode } from '../../../../core/reducers/general.reducer';

@Component({
  selector: 'app-hacks-picker-item',
  templateUrl: './hacks-picker-item.component.html',
  styleUrls: ['./hacks-picker-item.component.scss'],
})
export class HacksPickerItemComponent implements OnInit {
  @Input() item: Item;
  @Input() selected = false;
  @Output() hacksClicked: EventEmitter<Item> = new EventEmitter();
  @Output() detailsClicked: EventEmitter<Item> = new EventEmitter();

  splitMode$: Observable<boolean> = this.store.select(isSplitMode);

  environment = environment;
  affiliatesEnum = AffiliatesEnum;

  constructor(private store: Store) { }

  ngOnInit(): void { }

  handleHacksClick(item: Item): void {
    this.hacksClicked.emit(item);
  }

  handleDetailsClick(item: Item): void {
    this.detailsClicked.emit(item);
  }
}
