import { Component, Input, OnInit } from '@angular/core';
import { Item } from '../../core/interfaces/item.interface';
import { ShelfScenes } from '../../core/interfaces/scenes-shelf.interface';

@Component({
  selector: 'app-product-rating',
  templateUrl: './product-rating.component.html',
  styleUrls: ['./product-rating.component.scss'],
})
export class ProductRatingComponent implements OnInit {
  @Input() product!: ShelfScenes | Item;

  constructor() {}

  ngOnInit(): void {}
}
