export enum ColorNames {
  White = 'white',
  Yellow = 'yellow',
  Beige = 'beige',
  Orange = 'orange',
  Brown = 'brown',
  Green = 'green',
  Turquoise = 'turquoise',
  Colorful = 'colorful',
  Pink = 'pink',
  DarkPink = 'dark-pink',
  Red = 'red',
  Purple = 'purple',
  Lilac = 'lilac',
  Blue = 'blue',
  Gray = 'gray',
  Black = 'black',
}

export enum ColorHexCodes {
  White = '#ffffff',
  Yellow = '#FFFF99',
  Beige = '#FFCC99',
  Orange = '#FF9933',
  Brown = '#996633',
  Green = '#99CC99',
  Turquoise = '#00cccc',
  Colorful = 'linear-gradient(to right, red, orange, yellow, green, blue)',
  Pink = '#FF99CC',
  DarkPink = '#FF3399',
  Red = '#FF0000',
  Purple = '#CC33CC',
  Lilac = '#9966CC',
  Blue = '#0000FF',
  Gray = '#999999',
  Black = '#000000',
}
