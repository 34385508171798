import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import {
  enterShelfColorPickerFailedAction,
  enterShelfColorPickerSucceededAction,
  enterShelfPickerFailedAction,
  enterShelfPickerSucceededAction,
} from '../actions/shelf-designer.actions';
import {
  bottomPanelPositionChangedAction,
  leaveShelfDetailsAction,
  loadShelfColorsAction,
  loadShelfColorsSucceededAction,
  loadShelfTypesAction,
  loadShelfTypesFailedAction,
  loadShelfTypesSucceededAction,
  setSelectedCategoryAction,
  showShelfDetailsAction,
  toggleShelfColorAction,
  toggleShelfTypeAction,
} from '../actions/shelf-picker.actions';
import { AffiliatesEnum } from '../enums/affiliates.enum';
import { BottomPanePositionsEnum } from '../enums/bottom-pane-positions.enum';
import { ShelfColorsEnum } from '../enums/shelf-colors.enum';
import { ShelfTypesEnum } from '../enums/shelf-types.enum';
import { Design } from '../interfaces/design.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { ShelfCategory } from '../interfaces/shelf-category.interface';

export interface ShelfState {
  shelfData: ShelfCategory[];
  shelvesLoading: boolean;
  selectedCategory: ShelfCategory;
  shelves: ShelfScenes[];
  shelfDetailsVisible: string | null;
  bottomPanelPosition: BottomPanePositionsEnum;
  activeType: ShelfTypesEnum;
  activeColor: ShelfColorsEnum;
}

export const initialState: ShelfState = {
  shelfData: null,
  shelvesLoading: false,
  selectedCategory: null,
  shelves: [],
  shelfDetailsVisible: null,
  bottomPanelPosition: BottomPanePositionsEnum.BOTTOM,
  activeType: ShelfTypesEnum.ALL,
  activeColor: ShelfColorsEnum.ALL,
};

export const shelfPickerReducer = createReducer(
  initialState,
  on(enterShelfPickerSucceededAction, (state: ShelfState, action) => {
    return { ...state, shelves: action.shelves };
  }),
  on(enterShelfPickerFailedAction, (state: ShelfState) => {
    return { ...state, shelves: [] };
  }),
  on(enterShelfColorPickerSucceededAction, (state: ShelfState, action) => {
    return { ...state, shelves: action.shelves };
  }),
  on(enterShelfColorPickerFailedAction, (state: ShelfState) => {
    return { ...state, shelves: [] };
  }),
  on(setSelectedCategoryAction, (state: ShelfState, action) => {
    return { ...state, selectedCategory: action.selectedCategory };
  }),
  on(loadShelfTypesAction, (state: ShelfState, action) => {
    return { ...state, shelvesLoading: true };
  }),
  on(loadShelfTypesSucceededAction, (state: ShelfState, action) => {
    return { ...state, shelves: action.shelves, shelvesLoading: false };
  }),
  on(loadShelfTypesFailedAction, (state: ShelfState, action) => {
    return { ...state, shelves: [], shelvesLoading: false };
  }),
  on(loadShelfColorsAction, (state: ShelfState) => {
    return { ...state, shelvesLoading: true };
  }),
  on(loadShelfColorsSucceededAction, (state: ShelfState, action) => {
    return { ...state, shelvesLoading: false, shelves: action.shelves };
  }),
  on(showShelfDetailsAction, (state: ShelfState, action) => {
    return { ...state, shelfDetailsVisible: action.shelf.id };
  }),
  on(leaveShelfDetailsAction, (state: ShelfState, action) => {
    return { ...state, shelfDetailsVisible: null };
  }),
  on(bottomPanelPositionChangedAction, (state: ShelfState, action) => {
    return { ...state, bottomPanelPosition: action.position };
  }),
  on(toggleShelfTypeAction, (state, action) => {
    const newType = action.shelfType === state.activeType ? ShelfTypesEnum.ALL : action.shelfType;
    return {
      ...state,
      activeType: newType,
    };
  }),
  on(toggleShelfColorAction, (state, action) => {
    const newColor = action.shelfColor === state.activeColor ? ShelfColorsEnum.ALL : action.shelfColor;
    return {
      ...state,
      activeColor: newColor,
    };
  })
);

export function reducer(state: ShelfState = initialState, action: Action): ShelfState {
  return shelfPickerReducer(state, action);
}

export const shelfPickerState = createFeatureSelector<ShelfState>('shelfpicker');
export const getShelves = createSelector(shelfPickerState, (state: ShelfState) => {
  return state.shelves.filter((shelf: ShelfScenes) => {
    let valid: boolean = true;
    let checkShelf: boolean = false;

    // Only for a specific affiliate
    if (environment.allowedAffiliates[0] !== AffiliatesEnum.ALL) {
      environment.allowedAffiliates.forEach((affiliate) => {
        if (shelf.affiliates[affiliate]?.active) {
          checkShelf = true;
          return;
        }
      });
      // Otherwise, allow all shelves
    } else {
      checkShelf = true;
    }
    return valid && checkShelf;
  });
});
export const getFilterdShelfTypes = createSelector(getShelves, shelfPickerState, (shelves: ShelfScenes[], state: ShelfState) => {
  return shelves.filter((shelf: ShelfScenes) => {
    let valid: boolean = true;

    if (state.activeColor != ShelfColorsEnum.ALL) {
      valid = valid && state.activeColor.toLowerCase() === shelf.color.toLowerCase();
    }
    if (state.activeType != ShelfTypesEnum.ALL) {
      valid = valid && state.activeType.toLowerCase() === shelf.type.toLowerCase();
    }

    return valid;
  });
});

export const getActiveShelfType = createSelector(shelfPickerState, (state: ShelfState) => state.activeType);
export const getActiveShelfColor = createSelector(shelfPickerState, (state: ShelfState) => state.activeColor);

export const getAvailableShelfTypes = createSelector(getShelves, (shelves: ShelfScenes[]) => {
  let list: ShelfTypesEnum[] = [ShelfTypesEnum.ALL];
  shelves.forEach((shelf: ShelfScenes) => {
    if (!list.includes(shelf.type)) {
      list.push(shelf.type);
    }
  });
  return list;
});
export const getAvailableShelfColors = createSelector(getShelves, (shelves: ShelfScenes[]) => {
  let list: ShelfColorsEnum[] = [ShelfColorsEnum.ALL];
  shelves.forEach((shelf: ShelfScenes) => {
    if (!list.includes(shelf.color)) {
      list.push(shelf.color);
    }
  });
  return list;
});

export const getShelfById = createSelector(getShelves, (shelves: ShelfScenes[], props: Design) => {
  return shelves?.find((shelf: ShelfScenes) => shelf.id === props.shelfId);
});
export const isShelvesLoading = createSelector(shelfPickerState, (state: ShelfState) => state.shelvesLoading);
export const getShelfPickerData = createSelector(shelfPickerState, (state: ShelfState) => state.shelfData);
export const getSelectedCategory = createSelector(shelfPickerState, (state: ShelfState) => state.selectedCategory);
export const getBottomPanelPosition = createSelector(shelfPickerState, (state: ShelfState) => state.bottomPanelPosition);
