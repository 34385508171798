<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="handleClose()">
        <ion-icon name="arrow-back-outline" class="arrow-back-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="choose-item">
      {{ 'shelf-item-picker.choose-item' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="handleClear()"><ion-icon name="trash-outline" class="trash-outline"></ion-icon></ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content [scrollY]="false">
  <div class="themes-filter v1">
    <div [ngClass]="{ theme: true, active: isThemeActive$(theme) | async }" *ngFor="let theme of themes"
         (click)="handleToggleTheme(theme)">
      <div class="title">{{ 'themes.' + theme | transloco }}</div>
      <!-- ! This is a Reference ! -->
      <div class="image">
        <img [src]="'../../assets/imgs/quick-filter-images/theme-' + theme + '.webp'" />
      </div>
    </div>
  </div>

  <div class="types-filter">
    <div [ngClass]="{ type: true, active: isTypeActive$(type) | async }" *ngFor="let type of types"
         (click)="handleToggleType(type)">
      {{ 'types.' + type | transloco }}
    </div>
  </div>

  <div class="filter-line">
    <div class="filter-options" (click)="handleShowFilter()">
      <ion-icon name="options-outline"></ion-icon>
      {{ 'filter.title' | transloco }}
    </div>
  </div>

  <!-- virtual scroller -->
  <div #scrollingContainer class="virtual-scroller-container" *ngLet="items$ | async as items">
    <virtual-scroller #scroll [items]="(isLoadingItems$ | async) ? (skeletonItems$ | async) : items"
                      [executeRefreshOutsideAngularZone]="false" [checkResizeInterval]="1000" (vsUpdate)="onVirtualScrollerUpdate()"
                      [enableUnequalChildrenSizes]="true">
      <div *ngFor="let item of scroll.viewPortItems; let frameIndex = index; trackBy: trackByItem" class="item" >
        <div *ngIf="item.skeletonItem" class="skeleton-item">
          <ion-skeleton-text animated></ion-skeleton-text>
        </div>

        <app-shelf-item-picker-item *ngIf="!item.skeletonItem" [item]="item" [selectedItemId]="selectedItemId$ | async" (onItemClick)="handleItemClick($event)"
                                    (onDetailsClick)="handleShowItemDetails(item)"
                                    (onEditClick)="handleEditItem(item)"
                                    (onChooseItemClick)="handleEditItemForNotAdmin(item)"></app-shelf-item-picker-item>
      </div>
    </virtual-scroller>
  </div>

  <!-- virtual scroller end-->

  <ion-fab vertical="bottom" horizontal="center" slot="fixed">
    <ion-button (click)="handleScrollToTop()" fill="outline" shape="round">
      <ion-icon name="chevron-up-outline" slot="end"></ion-icon>Top
    </ion-button>
  </ion-fab>
</ion-content>
