import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Card } from '../../../core/classes/card.class';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  @Input() card: Card;
  @Output() onSelect: EventEmitter<Card> = new EventEmitter();

  // UI bindings
  constructor() {}

  ngOnInit(): void {}

  handleSelectProductCard(): void {
    this.onSelect.emit(this.card);
  }
}
