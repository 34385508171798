<ion-content [scrollY]="false">
  <div class="root" [ngClass]="{ sidebar: sidebar }" *ngLet="{
      activeTab: activeTab$ | async,
      isShelfPickerOpen: isShelfPickerOpen$ | async,
      isScenesPickerOpen: isScenesPickerOpen$ | async,
      isHacksPickerOpen: isHacksPickerOpen$ | async,
      bottomPanelPosition: bottomPanelPosition$ | async,
      scrollHorizontal: scrollHorizontal$ | async
    } as obs">
    <!-- Mobil-View -->
    <div *ngIf="!sidebar" class="mobil-mode">

      <div class="header">
        <!-- Left button in middle-position to go down -->
        <ion-button class="left-move-button" fill="clear" (click)="handleMoveSheetTo(bottomPanePositions[2], $event)"
          *ngIf="!sidebar && obs.bottomPanelPosition === bottomPanePositions[1]">
          <ion-icon icon="chevron-down-circle-outline"></ion-icon>
        </ion-button>
        <!-- Right button in middle-position to go up -->
        <ion-button class="right-move-button" fill="clear" (click)="handleMoveSheetTo(bottomPanePositions[0], $event)"
          *ngIf="!sidebar && obs.bottomPanelPosition === bottomPanePositions[1]">
          <ion-icon icon="chevron-up-circle-outline"></ion-icon>
        </ion-button>
        <!-- Right button in down-position to go middle -->
        <ion-button class="right-move-button" fill="clear" (click)="handleMoveSheetTo(bottomPanePositions[1], $event)"
          *ngIf="!sidebar && obs.bottomPanelPosition === bottomPanePositions[2]">
          <ion-icon icon="chevron-up-circle-outline"></ion-icon>
        </ion-button>
        <!-- Right button in up-position to go middle -->
        <ion-button class="right-move-button" fill="clear" (click)="handleMoveSheetTo(bottomPanePositions[1], $event)"
          *ngIf="!sidebar && obs.bottomPanelPosition === bottomPanePositions[0]">
          <ion-icon icon="chevron-down-circle-outline"></ion-icon>
        </ion-button>

        <ion-segment mode="ios" [value]="obs.activeTab" (click)="onClickSegment(obs.bottomPanelPosition)"
          (ionChange)="onSegmentChange($event.detail.value, obs.bottomPanelPosition)">
          <ion-segment-button value="shelf" mode="ios">
            <ion-label>{{ 'general.tabs.shelf' | transloco }}</ion-label>
          </ion-segment-button>

          <ion-segment-button value="scene" mode="ios">
            <span>{{ 'general.tabs.scene' | transloco }}</span>
          </ion-segment-button>

          <ion-segment-button value="hacks" mode="ios">
            <span>{{ 'general.tabs.hacks' | transloco }}</span>
          </ion-segment-button>
        </ion-segment>
      </div>

      <app-shelf-picker *ngIf="obs.activeTab == shelfsceneHacksPickerTabs[0]" [sidebar]="sidebar"
        class="content"></app-shelf-picker>

      <app-shelf-scenes-picker *ngIf="obs.activeTab == shelfsceneHacksPickerTabs[1]" [sidebar]="sidebar"
        class="content"></app-shelf-scenes-picker>

      <app-hacks-picker *ngIf="obs.activeTab == shelfsceneHacksPickerTabs[2]" [sidebar]="sidebar"
        class="content"></app-hacks-picker>
    </div>

    <!-- Desktop-View -->
    <div *ngIf="sidebar" class="desktop-mode">
      <div class="header-desktop">
        <ion-segment class="tabs" mode="ios" [value]="obs.activeTab"
          (ionChange)="onSegmentChangeDesktop($event.detail.value, shelfPicker, scenePicker, hacksPicker)">
          <ion-segment-button [value]="shelfsceneHacksPickerTabs[0]" mode="ios">
            <ion-label>{{ 'general.tabs.shelf' | transloco }}</ion-label>
          </ion-segment-button>

          <ion-segment-button [value]="shelfsceneHacksPickerTabs[1]" mode="ios">
            <span>{{ 'general.tabs.scene' | transloco }}</span>
          </ion-segment-button>

          <ion-segment-button [value]="shelfsceneHacksPickerTabs[2]" mode="ios">
            <span>{{ 'general.tabs.hacks' | transloco }}</span>
          </ion-segment-button>
        </ion-segment>
      </div>

      <div class="scroll-content">
        <!-- Shelfs -->
        <div #shelfPicker>
          <div class="headline">
            <span class="label">{{ 'general.tabs.shelf' | transloco }}</span>
            <span class="button" *ngIf="obs.isShelfPickerOpen" (click)="onClickToggleShelfPicker()">
              {{ 'general.show-less' | transloco }}
            </span>
            <span class="button" *ngIf="!obs.isShelfPickerOpen" (click)="onClickToggleShelfPicker()">
              {{ 'general.show-all' | transloco }}
            </span>
          </div>
          <app-shelf-picker [sidebar]="sidebar" class="content"></app-shelf-picker>
        </div>
        <!-- Scenes -->
        <div #scenePicker>
          <div class="headline padding-between-pickers">
            <span class="label">{{ 'general.tabs.scene' | transloco }}</span>
            <span class="button" *ngIf="obs.isScenesPickerOpen" (click)="onClickToggleScenesPicker()">
              {{ 'general.show-less' | transloco }}
            </span>
            <span class="button" *ngIf="!obs.isScenesPickerOpen" (click)="onClickToggleScenesPicker()">
              {{ 'general.show-all' | transloco }}
            </span>
          </div>
          <app-shelf-scenes-picker [sidebar]="sidebar" class="content"></app-shelf-scenes-picker>
        </div>
        <!-- Hacks -->
        <div #hacksPicker>
          <div class="headline padding-between-pickers">
            <span class="label">{{ 'general.tabs.hacks' | transloco }}</span>
            <span class="button" *ngIf="obs.isHacksPickerOpen" (click)="onClickToggleHacksPicker()">
              {{ 'general.show-less' | transloco }}
            </span>
            <span class="button" *ngIf="!obs.isHacksPickerOpen" (click)="onClickToggleHacksPicker()">
              {{ 'general.show-all' | transloco }}
            </span>
          </div>
          <app-hacks-picker [sidebar]="sidebar" class="content"></app-hacks-picker>
        </div>
      </div>
    </div>
  </div>
</ion-content>
