import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-item-image',
  templateUrl: './item-image.component.html',
  styleUrls: ['./item-image.component.scss'],
})
export class ItemImageComponent implements OnInit {
  @Input('src') src: string;
  @Input('size') size: string = '250x250';
  @Output('loaded') loaded = new EventEmitter<Event>();

  constructor() {}

  ngOnInit(): void {}

  handleImageLoad(event: Event): void {
    this.loaded.emit(event);
  }
}
