import { Pipe, PipeTransform } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

@Pipe({
  name: 'amountConverter',
})
export class AmountConverterPipe implements PipeTransform {
  constructor(private translocoService: TranslocoService) {}

  transform(value: number | string, digits?: number): string {
    return new Intl.NumberFormat(this.translocoService.getActiveLang(), {
      minimumFractionDigits: digits ?? 0,
    }).format(Number(value));
  }
}
