export enum AffiliatesEnum {
  AMAZON_DE = 'amazonDE',
  AMAZON_US = 'amazonUS',
  IKEA_DE = 'ikeaDE',
  POCO_DE = 'pocoDE',
  OTTO_DE = 'ottoDE',
  WALMART_US = 'walmartUS',
  DEPOT_DE = 'depotDE',
  ALL = 'all',
}
