import { FormControl } from '@angular/forms';
import { createAction, props } from '@ngrx/store';
import { Affiliate } from '../interfaces/affiliate.interface';
import { Item } from '../interfaces/item.interface';
import { FilterAdminItems, LoadItemsConfiguration } from '../interfaces/load-items-configuration.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';

const actionType = '[Admin]';

export const showAdminMenuAction = createAction(`${actionType} Show admin menu`);

export const hideAdminMenuAction = createAction(`${actionType} hide admin menu`);

export const loadOrFilterAdminItemsAction = createAction(
  `${actionType} Load or filter admin items`,
  props<{ configuration: LoadItemsConfiguration, filterAdminItems: FilterAdminItems }>()
);
export const loadOrFilterAdminItemsSucceededAction = createAction(
  `${actionType} Load or filter admin items succeeded`,
  props<{ items: Item[] }>()
);
export const loadItemsFailedAction = createAction(`${actionType} Load items failed`, props<{ error: Error }>());

export const updateItemAction = createAction(`${actionType} Update item`, props<{ item: Item }>());
export const updateItemSucceededAction = createAction(`${actionType} Update item succeeded`, props<{ item: Item }>());
export const updateItemFailedAction = createAction(`${actionType} Update item failed`, props<{ error: any }>());

export const updateShelfAction = createAction(`${actionType} Update shelf`, props<{ shelf: ShelfScenes }>());
export const updateShelfSucceededAction = createAction(`${actionType} Update shelf succeeded`);
export const updateShelfFailedAction = createAction(`${actionType} Update shelf failed`, props<{ error: any }>());

export const showItemsListAction = createAction(`${actionType} Show items list`);
export const showShelfsListAction = createAction(`${actionType} Show shelves list`);
export const showHomeAction = createAction(`${actionType} Show Home`);

export const addNewShelfAction = createAction(`${actionType} Add new shelf`);

export const cloneShelfAction = createAction(`${actionType} Clone shelf`, props<{ shelf: ShelfScenes }>());

export const showDeleteShelfConfirmationQuestionAction = createAction(
  `${actionType} Show delete shelf confirmation question`,
  props<{ shelf: ShelfScenes }>()
);

export const deleteShelfAction = createAction(`${actionType} Delete shelf`, props<{ shelf: ShelfScenes }>());
export const deleteShelfSucceededAction = createAction(`${actionType} Delete shelf succeeded`);
export const deleteShelfFailedAction = createAction(`${actionType} Delete shelf failed`, props<{ error: Error }>());

export const addNewItemAction = createAction(`${actionType} Add new item`);

export const cloneItemAction = createAction(`${actionType} Clone item`, props<{ item: Item }>());

export const showDeleteItemConfirmationQuestionAction = createAction(
  `${actionType} Show delete item confirmation question`,
  props<{ item: Item }>()
);

export const deleteItemAction = createAction(`${actionType} Delete item`, props<{ item: Item }>());
export const deleteItemSucceededAction = createAction(`${actionType} Delete item succeeded`);
export const deleteItemFailedAction = createAction(`${actionType} Delete item failed`, props<{ error: Error }>());

export const openSlotsEditorAction = createAction(`${actionType} Show slots editor`, props<{ shelf: ShelfScenes; sceneIndex: number }>());
export const openSlotsEditorSucceededAction = createAction(`${actionType} Show slots editor succeeded`);
export const hideSlotsEditorAction = createAction(`${actionType} Hide slots editor`, props<{ shelf: ShelfScenes }>());

export const deleteSlotAction = createAction(`${actionType} Delete slot`, props<{ slot: SlotScenes }>());
export const duplicateSlotAction = createAction(`${actionType} Duplicate slot`, props<{ slot: SlotScenes }>());

export const updateDataFromAffiliateAction = createAction(
  `${actionType} Update data from affiliate`,
  props<{
    asin: string;
    affiliateId: string;
    productUrl: string;
    ratingFormControl: FormControl;
    ratingNumberFormControl: FormControl;
    priceFormControl: FormControl;
    priceSaleFormControl: FormControl;
    sellerUrlFormControl: FormControl;
  }>()
);
export const updateDataFromAffiliateSucceededAction = createAction(`${actionType} Update data from affiliate succeeded`);
export const updateDataFromAffiliateFailedAction = createAction(`${actionType} Update data from affiliate failed`);

export const importDataFromAffiliateAction = createAction(
  `${actionType} Import data from affiliate`,
  props<{
    asin: string;
    affiliateId: string;
    productUrl: string;
    nameFormControl: FormControl;
    descriptionFormControl: FormControl;
    colorManufacturerFormControl: FormControl;
    manufacturerFormControl: FormControl;
    materialsFormControl?: FormControl;
    ratingFormControl: FormControl;
    ratingNumberFormControl: FormControl;
    priceFormControl: FormControl;
    priceSaleFormControl: FormControl;
    sellerUrlFormControl: FormControl;
  }>()
);
export const importDataFromAffiliateSucceededAction = createAction(`${actionType} Import data from affiliate succeeded`);
export const importDataFromAffiliateFailedAction = createAction(
  `${actionType} Import data from affiliate failed`,
  props<{ error: Error }>()
);

export const loadAffiliatesAction = createAction(`${actionType} Load affiliates`);
export const loadAffiliatesSucceededAction = createAction(`${actionType} Load affiliates succeeded`, props<{ affiliates: Affiliate[] }>());
export const loadAffiliatesFailedAction = createAction(`${actionType} Load affiliates failed`, props<{ error: Error }>());

export const resetUsageCountAction = createAction(`${actionType} Reset Usage Count`);

export const checkForRequiredFieldsAction = createAction(`${actionType} Check for required field`);

export const loadShelfAction = createAction(`${actionType} Load shelf`, props<{ id: string }>());
export const loadShelfSucceededAction = createAction(`${actionType} Load shelf succeeded`, props<{ shelf: ShelfScenes }>());
export const loadShelfFailedAction = createAction(`${actionType} Load shelf failed`, props<{ error: Error }>());

export const addSceneAction = createAction(`${actionType} Add scene`, props<{ shelf: ShelfScenes }>());
export const addSceneSuccededAction = createAction(`${actionType} Add scene succeded`, props<{ shelf: ShelfScenes }>());

export const showDeleteSceneConfirmationQuestionAction = createAction(
  `${actionType} Show delete scene confirmation question`,
  props<{ shelf: ShelfScenes; sceneIndexToDelete: number }>()
);
export const deleteSceneAction = createAction(
  `${actionType} Delete scene from shelf`,
  props<{ shelf: ShelfScenes; sceneIndexToDelete: number }>()
);
export const deleteSceneSucceededAction = createAction(`${actionType} Delete scene succeeded`);
export const deleteSceneFailedAction = createAction(`${actionType} Delete scene failed`, props<{ error: Error }>());

export const deleteShelvesAction = createAction(`${actionType} Delete shelves`);
