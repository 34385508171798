import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { loadMyDesignsAction, loadMyDesignsSucceededAction } from '../actions/my-designs.actions';
import {
  chooseShelfSucceededAction,
  createNewDesignAction,
  createNewDesignSucceededAction,
  loadMyDesignAction,
  loadMyDesignSucceededAction,
  resetSavedConfigStatusAction,
  saveDesignAction,
  saveDesignSucceededAction,
} from '../actions/shelf-designer.actions';
import {
  addItemToCartSucceededAction,
  removeItemGroupSucceededAction,
  removeItemSucceededAction,
  setBuyingItemSucceededAction,
  setBuyingShelfSucceededAction,
} from '../actions/shopping-cart.actions';
import { getAffiliateData } from '../functions/affiliates';
import { getPriceOfProduct } from '../functions/price';
import { MetaAffiliate } from '../interfaces/affiliate.interface';
import { ExtendedDesign } from '../interfaces/design.interface';
import { Product } from '../interfaces/product.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { ExtendedShoppingCartItem, ProductListProduct, ShoppingCartItem } from '../interfaces/shopping-cart-item.interface';
import {
  ProductListForShoppingCart,
  SortedShoppingCartInactiveProducts,
  SortedShoppingCartProducts,
} from '../interfaces/shopping-cart.interface';
import { AffiliatePipe } from '../pipes/affiliate.pipe';
import { getCurrentShelf } from './shelf-designer.reducer';
import { SlotScenes } from '../interfaces/scenes-slot.interface';
import { HacksTypesEnum } from '../enums/hacks-types.enum';

// TODO: try to avoid creating a pipe instance (not injectable in reducer)
const affiliatePipe = new AffiliatePipe();

export interface MyDesignsState {
  myDesigns: ExtendedDesign[];
  loadingMyDesigns: boolean;
  savingDesign: boolean;
  loadingDesign: boolean;
  loadDesignSuccess: boolean;
  creatingNewDesign: boolean;
  currentDesign: ExtendedDesign;
  currentDesignIsOwnDesign: boolean;
}

export const initialState: MyDesignsState = {
  myDesigns: [],
  loadingMyDesigns: false,
  savingDesign: false,
  loadingDesign: false,
  loadDesignSuccess: false,
  creatingNewDesign: false,
  currentDesign: null,
  currentDesignIsOwnDesign: false,
};

export const myDesignsReducer = createReducer(
  initialState,
  on(loadMyDesignsAction, (state, action) => {
    return { ...state, myDesigns: [], loadingMyDesigns: true };
  }),

  on(loadMyDesignsSucceededAction, (state, action) => {
    return { ...state, myDesigns: action.designs, loadingMyDesigns: false };
  }),

  on(addItemToCartSucceededAction, (state, action) => {
    return {
      ...state,
      currentDesign: updateShoppingCartItemsOfDesign2(state, action.extendedShoppingCartItems),
    };
  }),

  on(resetSavedConfigStatusAction, (state) => {
    return { ...state, loadDesignSuccess: false, currentDesign: null };
  }),

  on(removeItemGroupSucceededAction, (state, action) => {
    const updatedDesign = { ...state.currentDesign };
    updatedDesign.shoppingCartItems = action.shoppingCartItems;

    return {
      ...state,
      currentDesign: updatedDesign,
    };
  }),

  on(saveDesignAction, (state) => {
    return { ...state, savingDesign: true };
  }),

  on(saveDesignSucceededAction, (state, action) => {
    return { ...state, savingDesign: false, currentDesign: action.design };
  }),

  on(loadMyDesignAction, (state) => {
    return { ...state, loadingDesign: true };
  }),

  on(loadMyDesignSucceededAction, (state, action) => {
    let design = action.design;
    design.shelf = action.shelf;
    return {
      ...state,
      loadDesignSuccess: true,
      currentDesign: design,
      currentDesignIsOwnDesign: action.currentDesignIsOwnDesign,
    };
  }),

  on(setBuyingShelfSucceededAction, (state, action) => {
    const updatedDesign = { ...state.currentDesign };
    updatedDesign.buyingShelf = action.isBuyingShelf;

    return {
      ...state,
      currentDesign: updatedDesign,
    };
  }),

  on(setBuyingItemSucceededAction, (state, action) => {
    return {
      ...state,
      currentDesign: { ...state.currentDesign, shoppingCartItems: action.shoppingCartItems },
    };
  }),

  on(createNewDesignAction, (state, action) => {
    return { ...state, creatingNewDesign: true };
  }),

  on(createNewDesignSucceededAction, (state, action) => {
    return { ...state, creatingNewDesign: false, currentDesign: action.design };
  }),

  on(removeItemSucceededAction, (state, action) => {
    const updatedDesign = { ...state.currentDesign };
    updatedDesign.shoppingCartItems = action.shoppingCartItems;

    return {
      ...state,
      currentDesign: updatedDesign,
    };
  }),

  // TODO: fix with #227
  on(chooseShelfSucceededAction, (state, action) => {
    const updatedDesign = { ...state.currentDesign };
    if (action.newCartItems) {
      updatedDesign.shoppingCartItems = action.newCartItems;
    }

    return {
      ...state,
      currentDesign: updatedDesign,
    };
  })
);

export function reducer(state: MyDesignsState = initialState, action: Action): MyDesignsState {
  return myDesignsReducer(state, action);
}

function updateShoppingCartItemsOfDesign2(state: MyDesignsState, newItems: ExtendedShoppingCartItem[]): ExtendedDesign {
  let designToUpdate: ExtendedDesign = { ...state.currentDesign };
  designToUpdate.shoppingCartItems = newItems;
  return designToUpdate;
}

export const myDesignsState = createFeatureSelector<MyDesignsState>('mydesigns');
export const getMyDesigns = createSelector(myDesignsState, (state: MyDesignsState) => state.myDesigns);
export const isLoadingMyDesigns = createSelector(myDesignsState, (state: MyDesignsState) => state.loadingMyDesigns);

export const isSavingDesign = createSelector(myDesignsState, (state: MyDesignsState) => state.savingDesign);
export const isLoadingDesign = createSelector(myDesignsState, (state: MyDesignsState) => state.loadingDesign);
export const isLoadDesignSuccess = createSelector(myDesignsState, (state: MyDesignsState) => state.loadDesignSuccess);

// get data by designId
export const getDesignByDesignId = (designId: string) =>
  createSelector(myDesignsState, (state: MyDesignsState) => state.myDesigns.find((design: ExtendedDesign) => design.id === designId));

export const getExtendedShoppingCartItemsFromDesign = (designId: string) =>
  createSelector(getDesignByDesignId(designId), (design: ExtendedDesign) => design?.shoppingCartItems);

export const getExtendedShoppingCartItemsFromCurrentDesign = createSelector(
  myDesignsState,
  (state: MyDesignsState) => state.currentDesign?.shoppingCartItems
);

export const getShoppingCartItemsFromDesign = (designId: string) =>
  createSelector(getDesignByDesignId(designId), (design: ExtendedDesign) => {
    const shoppingCartItems: ShoppingCartItem[] = design.shoppingCartItems?.slice().map((extendedItem: ExtendedShoppingCartItem) => ({
      itemId: extendedItem.item.id,
      slotnumber: extendedItem.slotnumber,
      slotsToBuy: extendedItem.slotsToBuy,
    }));
    return shoppingCartItems;
  });

// get data to current saved design
export const getCurrentDesignId = createSelector(myDesignsState, (state: MyDesignsState) => state.currentDesign?.id);
export const getCurrentDesignUserId = createSelector(myDesignsState, (state: MyDesignsState) => {
  return state.currentDesign?.userId;
});

// TODO: umbauen
export const getCurrentDesign = createSelector(myDesignsState, (state: MyDesignsState) => {
  return state.currentDesign;
});

export const getSumAffiliatePriceOfItemsFromDesign = createSelector(getCurrentDesign, (design: ExtendedDesign) => {
  let sumPrice = 0.0;
  design?.shoppingCartItems?.forEach((item: ExtendedShoppingCartItem) => {
    item.item.activeAffiliates = [];

    for (const [affiliateName, affiliateData] of Object.entries(item.item.affiliates)) {
      item.item.activeAffiliates.push(affiliateName);
    }

    const itemPrice: number = affiliatePipe.transform(item.item, 'price') as number;
    if (!isNaN(itemPrice)) {
      sumPrice += (affiliatePipe.transform(item.item, 'price') as number) * item.slotsToBuy.length;
    }
  });
  return sumPrice;
});

export const getExtendedShoppingCartItemsForCurrentDesign = createSelector(
  getCurrentDesign,
  (design: ExtendedDesign) => design?.shoppingCartItems
);

export const getExtendedShoppingCartItemByIdForCurrentDesign = (itemId: string) =>
  createSelector(getCurrentDesign, (design: ExtendedDesign) =>
    design?.shoppingCartItems.find((cartItem: ExtendedShoppingCartItem) => cartItem.item.id === itemId)
  );

export const getCurrentExtendedShoppingCartItemsAsListOfSingleItems = createSelector(
  getExtendedShoppingCartItemsForCurrentDesign,
  (cartItems: ExtendedShoppingCartItem[]) => {
    let productListItems: ProductListProduct[] = [];
    cartItems?.forEach((cartItem: ExtendedShoppingCartItem) => {
      cartItem.slotnumber?.forEach((slotNumber: number) => {
        productListItems.push({ item: cartItem.item, slotnumber: slotNumber, buyItem: cartItem.slotsToBuy.includes(slotNumber) });
      });
    });
    return productListItems;
  }
);

export const isBuyingCurrentShelf = createSelector(getCurrentDesign, (design: ExtendedDesign) => design?.buyingShelf);

export const isCurrentDesignOwnDesign = createSelector(myDesignsState, (state: MyDesignsState) => state.currentDesignIsOwnDesign);

export const getSortedProducts = createSelector(
  getCurrentExtendedShoppingCartItemsAsListOfSingleItems,
  myDesignsState,
  getCurrentShelf,
  (productListProducts: ProductListProduct[], state: MyDesignsState, shelf: ShelfScenes) => {
    const groupedProducts: { [affiliateName: string]: { items: ProductListProduct[]; totalPrice: 0 } } = {};

    // shelf
    let affiliate = getAffiliateData(shelf);
    groupedProducts[affiliate.name] = { items: [], totalPrice: 0 };
    if (state.currentDesign.buyingShelf) {
      groupedProducts[affiliate.name].items.push({
        item: shelf,
        buyItem: state.currentDesign.buyingShelf,
        slotnumber: -1,
      } as ProductListProduct);
      groupedProducts[affiliate.name].totalPrice += getPriceOfProduct(affiliate.data, 'price');
    }

    // items
    productListProducts.forEach((item: ProductListProduct) => {
      if (item.buyItem) {
        let affiliate = getAffiliateData(item.item);
        if (!groupedProducts[affiliate.name]) {
          groupedProducts[affiliate.name] = { items: [], totalPrice: 0 };
        }
        groupedProducts[affiliate.name].items.push(item);
        groupedProducts[affiliate.name].totalPrice += getPriceOfProduct(affiliate.data, 'price');
      }
    });

    // remove empty affiliate (an affiliate without any items)
    let entryToRemove: string;
    for (const [key, value] of Object.entries(groupedProducts)) {
      if (value.items.length == 0) {
        entryToRemove = key;
      }
    }
    delete groupedProducts[entryToRemove];

    return groupedProducts;
  }
);

export const getShoppingCartItemsInactive = createSelector(
  getExtendedShoppingCartItemsForCurrentDesign,
  getCurrentShelf,
  (cartItems: ExtendedShoppingCartItem[], shelf: ShelfScenes) => {
    let ret: SortedShoppingCartInactiveProducts = { list: [] } as SortedShoppingCartInactiveProducts;

    // shelf
    if (shelf !== null) {
      let affiliate: MetaAffiliate = getAffiliateData(shelf);

      if (affiliate === null) {
        ret.list.push(shelf);
      }
    }

    // Items
    if (cartItems) {
      cartItems.forEach((item: ExtendedShoppingCartItem) => {
        let affiliate = getAffiliateData(item.item);
        if (affiliate === null) {
          ret.list.push(item.item);
        }
      });
    }

    return ret;
  }
);

export const getShopingCartItems = createSelector(
  getExtendedShoppingCartItemsForCurrentDesign,
  myDesignsState,
  getCurrentShelf,
  (cartItems: ExtendedShoppingCartItem[], state: MyDesignsState, shelf: ShelfScenes) => {
    const groupedProducts: SortedShoppingCartProducts = {} as SortedShoppingCartProducts;

    let sumToBuy,
      sumNotToBuy: number = 0;
    let affiliate: MetaAffiliate;
    let slots: SlotScenes[];
    let ret: boolean = false;

    let hacksTypes: HacksTypesEnum[] = [
      HacksTypesEnum.Feet,
      HacksTypesEnum.Plates,
      HacksTypesEnum.Sticker,
      HacksTypesEnum.AdditionalHacks,
    ];

    // shelf
    if (shelf !== null) {
      affiliate = getAffiliateData(shelf);

      if (affiliate !== null) {
        groupedProducts[affiliate.name] = { list: [], totalPrice: 0 };
        slots = shelf.scenes[0].slots;

        if (state.currentDesign.buyingShelf) {
          groupedProducts[affiliate.name].totalPrice += getPriceOfProduct(affiliate.data, 'price');
          sumToBuy = 1;
        } else {
          sumNotToBuy = 1;
        }

        groupedProducts[affiliate.name].list.push({
          products: [
            {
              item: shelf as Product,
              slotnumber: -1,
              buyItem: state.currentDesign.buyingShelf,
            } as ProductListProduct,
          ],
          sumToBuy,
          sumNotToBuy,
        } as ProductListForShoppingCart);
      }
    }

    // items
    if (cartItems) {
      cartItems.forEach((item: ExtendedShoppingCartItem) => {
        let itemFit: number = -2;

        // Check, if the item fit in the box
        if (slots !== undefined && slots !== null) {
          slots.forEach(slot => {
            if (slot.itemId === item.item.id) {
              if (slot.slotSizes.height >= item.item.height && slot.slotSizes.width >= item.item.width && slot.slotSizes.depth >= item.item.depth) {
                itemFit = 1;
                return;
              } else {
                itemFit = 0;
                return;
              }
            }
          });
        }

        affiliate = getAffiliateData(item.item);
        sumNotToBuy = sumToBuy = 0;
        if (affiliate !== null) {
          if (!groupedProducts[affiliate.name]) {
            groupedProducts[affiliate.name] = { list: [], totalPrice: 0 };
          }

          let products: ProductListProduct[] = [];
          let productBuy: boolean = false;

          let hacksItem: boolean = false;
          hacksTypes.forEach((hacksType) => {
            if (hacksType == item.item.type) {
              hacksItem = true;
              return;
            }
          })

          item.slotnumber.forEach((slotnumber: any) => {
            if (item.slotsToBuy.includes(slotnumber)) {
              sumToBuy += 1;
              productBuy = true;
            } else {
              sumNotToBuy += 1;
              productBuy = false;
            }
            products.push({
              item: item.item as Product,
              slotnumber,
              buyItem: productBuy,
              itemFit: itemFit,
              hacksItem,
            } as ProductListProduct);
          });

          let price: number = +getPriceOfProduct(affiliate.data, 'price');
          let list: ProductListForShoppingCart = {
            products: products,
            sumNotToBuy,
            sumToBuy,
          } as ProductListForShoppingCart;

          groupedProducts[affiliate.name].list.push(list);
          groupedProducts[affiliate.name].totalPrice += sumToBuy * price;
        }
      });
    }

    // remove empty affiliate (an affiliate without any products)
    for (const [key, value] of Object.entries(groupedProducts)) {
      if (value.list.length == 0) {
        delete groupedProducts[key];
      }
    }
    return groupedProducts;
  }
);
