import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { ItemImage } from '../interfaces/item-image.interface';
import { enterItemPickerAction } from '../actions/shelf-designer.actions';
import {
  buildItemImageAction,
  buildItemImageSucceededAction,
  clearItemImagesAction,
  removeItemImageAction,
} from '../actions/shelf-item.actions';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';

export interface ShelfItemState {
  slotItemImages: ItemImage[];
  buildItemImage: boolean;
  setImage: boolean;
  selectedShelf: ShelfScenes;
  selectedSlot: SlotScenes;
  loadConfigSuccess: boolean;
}

export const initialState: ShelfItemState = {
  slotItemImages: null,
  buildItemImage: false,
  setImage: false,
  selectedShelf: null,
  selectedSlot: null,
  loadConfigSuccess: false,
};

export const shelfItemReducer = createReducer(
  initialState,
  on(enterItemPickerAction, (state, action) => {
    return { ...state, selectedShelf: action.shelf, selectedSlot: action.slot };
  }),
  on(buildItemImageAction, (state) => {
    return { ...state, buildItemImage: true };
  }),
  on(buildItemImageSucceededAction, (state, action) => {
    return { ...state, buildItemImage: false, slotItemImages: action.itemImages };
  }),

  on(clearItemImagesAction, (state) => {
    return { ...state, setImage: true };
  }),
  on(removeItemImageAction, (state) => {
    return { ...state, setImage: true };
  })
);

export function reducer(state: ShelfItemState = initialState, action: Action): ShelfItemState {
  return shelfItemReducer(state, action);
}

export const shelfItemState = createFeatureSelector<ShelfItemState>('shelfItem');
export const getSlotItemImages = createSelector(shelfItemState, (state: ShelfItemState) => state.slotItemImages);
export const isBuildItemImage = createSelector(shelfItemState, (state: ShelfItemState) => state.buildItemImage);
export const isSetImage = createSelector(shelfItemState, (state: ShelfItemState) => state.setImage);
export const getSelectedShelf = createSelector(shelfItemState, (state: ShelfItemState) => state.selectedShelf);
export const getSelectedSlot = createSelector(shelfItemState, (state: ShelfItemState) => state.selectedSlot);
export const isShelfItemLoadConfigSuccess = createSelector(shelfItemState, (state: ShelfItemState) => state.loadConfigSuccess);
