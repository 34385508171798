import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  loadItemAction,
  loadItemFailedAction,
  loadItemSucceededAction,
  loadShelfAction,
  loadShelfFailedAction,
  loadShelfSucceededAction,
} from '../actions/product-details.actions';
import { ItemService } from '../services/item.service';
import { ShelvesService } from '../services/shelf.service';

@Injectable()
export class ProductDetailsEffects {
  constructor(private actions$: Actions, private store: Store, private itemService: ItemService, private shelfService: ShelvesService) {}

  loadShelf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadShelfAction),
        switchMap((action) => this.shelfService.getShelf$(action.id)),
        map((shelf) => loadShelfSucceededAction({ shelf })),
        catchError((error, caught) => {
          this.store.dispatch(loadShelfFailedAction({ error }));
          return caught;
        })
      ),
    { dispatch: true }
  );

  loadItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadItemAction),
        switchMap((action) => this.itemService.getItem$(action.id)),
        map((item) => loadItemSucceededAction({ item })),
        catchError((error, caught) => {
          this.store.dispatch(loadItemFailedAction({ error }));
          return caught;
        })
      ),
    { dispatch: true }
  );
}
