import { createAction, props } from '@ngrx/store';
import { TypesEnum } from '../enums/types.enum';
import { Item } from '../interfaces/item.interface';

const actionType = '[Hacks-Picker]';

export const toggleHacksTypeAction = createAction(`${actionType} Toggle hacks type`, props<{ hacksType: TypesEnum }>());
export const loadHacksItemsSucceededAction = createAction(`${actionType} Load hacks items succeded`, props<{ items: Item[] }>());
export const loadHacksInitialAction = createAction(`${actionType} Load hacks initial`);

export const toggleChooseHacksItem = createAction(`${actionType} Toggle choose hacks-item`, props<{ item: Item }>());
export const loadChooseHacksItems = createAction(`${actionType} Load choose hacks-items`, props<{ items: string[] }>());

export const saveHacksToFirebase = createAction(`${actionType} Save hacks to firebase`, props<{ id: string }>());
export const saveHacksToFirebaseSucceded = createAction(`${actionType} Save hacks to firebase succeded`, props<{ id: string }>());
export const saveHacksToFirebaseFailed = createAction(`${actionType} Save hacks to firebase fail`, props<{ error: string }>());
