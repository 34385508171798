import { LOCALE_ID, Provider } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';

export class LocaleId extends String {
  constructor(private translocoService: TranslocoService) {
    super();
  }

  toString(): string {
    return this.translocoService.getActiveLang();
  }

  valueOf(): string {
    return this.toString();
  }
}

export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [TranslocoService],
};
