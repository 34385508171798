<div>
  <!-- Card Images -->
  <ng-content select="[slot=images]"></ng-content>

  <!-- Card Label Rows -->
  <ng-content select="[slot=labels]"></ng-content>

  <!-- Card Buttons -->
  <ng-content select="[slot=buttons]"></ng-content>
</div>
