import { Injectable } from '@angular/core';
import { ColorHexCodes, ColorNames } from '../enums/colors.enum';
import { getBestProductPrice } from '../functions/price';
import { AllColors } from '../interfaces/color.interface';
import { Item } from '../interfaces/item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  allColors: AllColors;

  constructor() {
    this.allColors = [];
    Object.keys(ColorNames).forEach((colorKey: string) => {
      this.allColors.push({
        key: colorKey,
        name: ColorNames[colorKey],
        code: ColorHexCodes[colorKey],
      });
    });
  }

  getPrice(product: ShelfScenes | Item): number {
    return getBestProductPrice(product);
  }

  /**
   * Returns a specific customer rating for a product. Currently the best customer rating is used.
   * TODO: Define a better metric which customer rating to use
   * @param product A shelf or item
   * @returns The best customer rating available for this product
   */
  getCustomerRating(product: ShelfScenes | Item): number {
    let bestCustomerRating: number = 0;

    for (const [affiliateName, affiliateData] of Object.entries(product.affiliates)) {
      if (affiliateData.active) {
        if (typeof affiliateData.rating === 'number' && affiliateData.rating > bestCustomerRating) {
          bestCustomerRating = affiliateData.rating;
        }
      }
    }

    return bestCustomerRating;
  }

  getAllColors(): AllColors {
    return this.allColors;
  }
}
