<ion-header>
  <ng-container *ngLet="activeTypes$ | async as activeTypes">
    <div class="quick-filter" *ngIf="isHacksPickerOpen$ | async">
      <div class="types-filter">
        <div
          [ngClass]="{ type: true, active: type | inArray: activeTypes }"
          *ngFor="let type of types"
          (click)="handleToggleType(type)"
        >
          {{ 'types.' + type | transloco }}
        </div>
      </div>
    </div>
  </ng-container>
</ion-header>

<ion-content
[scrollY]="false"
  *ngLet="{
    isHacksPickerOpen: isHacksPickerOpen$ | async,
    hackItemsToBuyIds: hackItemsToBuyIds$ | async,
    currentDesignUserId: currentDesignUserId$ | async
  } as obs"
  [ngClass]="{ sidebar: sidebar, horizontal: !obs.isHacksPickerOpen }"
>
  <ng-container>
    <ion-button class="scroll-button left" fill="solid" (click)="handleScrollBackward()" [hidden]="!(scrollLeftButtonVisible$ | async)">
      <ion-icon name="arrow-back-outline"></ion-icon>
    </ion-button>

    <ion-button class="scroll-button right" fill="solid" (click)="handleScrollForward()" [hidden]="!(scrollRightButtonVisible$ | async)">
      <ion-icon name="arrow-forward-outline"></ion-icon>
    </ion-button>
  </ng-container>

  <div
    #scrollingContainer
    class="virtual-scroller-container"
    [ngClass]="{
      horizontal: !obs.isHacksPickerOpen,
      vertical: obs.isHacksPickerOpen,
      'horizontal-desktop': sidebar && !obs.isHacksPickerOpen
    }"
    *ngLet="hacks$ | async as hacks"
  >
    <virtual-scroller
      #scroll
      [items]="(isLoadingShelves$ | async) ? (skeletonItems$ | async) : hacks"
      [executeRefreshOutsideAngularZone]="false"
      [checkResizeInterval]="1000"
      (vsUpdate)="onVirtualScrollerUpdate()"
      [enableUnequalChildrenSizes]="true"
      [horizontal]="!obs.isHacksPickerOpen"
    >
      <div *ngFor="let item of scroll.viewPortItems; let frameIndex = index; trackBy: trackByItem" class="item">
        <div *ngIf="item.skeleton" class="skeleton-item">
          <ion-skeleton-text animated class="item"></ion-skeleton-text>
        </div>
        <ng-container *ngIf="!item.skeleton">
          <app-hacks-picker-item
            [item]="item"
            [selected]="item.id | inArray: obs.hackItemsToBuyIds"
            (hacksClicked)="handleHacksClick($event, obs.currentDesignUserId)"
            (detailsClicked)="handleDetailsClick($event)"
          ></app-hacks-picker-item>
        </ng-container>
      </div>
    </virtual-scroller>
  </div>
</ion-content>
