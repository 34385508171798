import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslocoModule } from '@ngneat/transloco';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { CoreUIModule } from '../../core-ui/core-ui.module';
import { CoreModule } from '../../core/core.module';
import { ShelfPickerItemComponent } from './shelf-picker/shelf-picker-item/shelf-picker-item.component';
import { ShelfPickerComponent } from './shelf-picker/shelf-picker.component';
import { ShelfScenesPickerComponent } from './shelf-scenes-picker/shelf-scenes-picker.component';
import { ShelvesScenesHacksPickerComponent } from './shelves-scenes-hacks-picker.component';
import { ShelfScenesPickerItemComponent } from './shelf-scenes-picker/shelf-scenes-picker-item/shelf-scenes-picker-item.component';
import { HacksPickerItemComponent } from './hacks-picker/hacks-picker-item/hacks-picker-item.component';
import { HacksPickerComponent } from './hacks-picker/hacks-picker.component';
import { NgLetModule } from 'ng-let';

@NgModule({
  declarations: [
    ShelvesScenesHacksPickerComponent,
    ShelfPickerComponent,
    ShelfPickerItemComponent,
    ShelfScenesPickerComponent,
    ShelfScenesPickerItemComponent,
    HacksPickerItemComponent,
    HacksPickerComponent,
  ],
  imports: [CommonModule, IonicModule, CoreModule, TranslocoModule, VirtualScrollerModule, CoreUIModule, NgLetModule],
  exports: [ShelvesScenesHacksPickerComponent],
})
export class ShelvesScenesHacksPickerModule {}
