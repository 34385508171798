<h1>Shelfy Amazon-Linker</h1>

<div class="link-section">
  <ion-list>
    <ion-item>
      <ion-label>Produkt-Link: </ion-label>
      <ion-input #asin class="asin-field" type="text"></ion-input>
    </ion-item>
  </ion-list>
  <ion-button (click)="handleGotoAmazon(asin.value)">Affiliate-Link erstellen</ion-button>
</div>

<div class="affiliate-link-section" *ngIf="affiliateLink">
  <div class="hint">Hier klicken zum Kaufen bei Amazon</div>
  <a [href]="affiliateLink" target="_blank">{{ affiliateLink }}</a>
</div>

<h1 style="margin-top: 30px">Feste Links</h1>
<a
  target="_blank"
  href="https://www.amazon.de/dp/B08V8VZ1FZ?&linkCode=ll1&tag=hello-shelfy-21&linkId=93dc064602ee165e168ec57b8417cddf&language=de_DE&ref_=as_li_ss_tl"
  >Kaninchen-Spielzueg</a
>
