import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'totalFilteredProducts',
})
export class TotalFilteredProductsPipe implements PipeTransform {
  transform(value: number, format: 'i18n' | 'short'): string {
    if (format === 'i18n') {
      if (value === 0) {
        return '0';
      } else if (value === 1) {
        return '1';
      } else {
        return 'many';
      }
    } else if (format === 'short') {
      if (value <= 500) {
        return value.toString();
      } else {
        return '500+';
      }
    }
  }
}
