import { createAction, props } from '@ngrx/store';
import { ExtendedDesign } from '../interfaces/design.interface';

const actionType = '[My Designs]';

export const loadMyDesignsAction = createAction(`${actionType} Load my designs`);
export const loadMyDesignsSucceededAction = createAction(
  `${actionType} Load my designs succeeded`,
  props<{ designs: ExtendedDesign[] }>()
);
export const loadMyDesignsFailedAction = createAction(`${actionType} Load my designs failed`, props<{ error: any }>());
