import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { loginAction, loginSucceededAction } from '../actions/authentication.actions';

export interface AuthenticationState {
  originalUrl: string | null;
  authenticated: boolean;
}

export const initialState: AuthenticationState = {
  originalUrl: null,
  authenticated: false,
};

export const authenticationReducer = createReducer(
  initialState,
  on(loginAction, (state, action) => {
    return { ...state, originalUrl: action.originalUrl ?? null };
  }),
  on(loginSucceededAction, (state) => {
    return { ...state, authenticated: true };
  })
);

export function reducer(state: AuthenticationState = initialState, action: Action): AuthenticationState {
  return authenticationReducer(state, action);
}

export const authenticationState = createFeatureSelector<AuthenticationState>('authentication');
export const getOriginalUrl = createSelector(authenticationState, (state: AuthenticationState) => state.originalUrl);
export const isAuthenticated = createSelector(authenticationState, (state: AuthenticationState) => state.authenticated);
