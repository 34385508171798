import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { ProductFilterComponent } from '../../shared/product-filter/product-filter.component';
import { ProductDetailsComponent } from '../../shared/product-details/product-details.component';
import { loadItemsFailedAction } from '../actions/admin.actions';
import { chooseItemAction, leaveItemPickerAction } from '../actions/shelf-designer.actions';
import {
  leaveItemDetailsAction,
  leaveProductFilterAction,
  loadItemsAction,
  setSortCriterionAction,
  toggleTypeAction,
  toggleThemeAction,
  toggleAffiliateAction,
  setPriceRangeAction,
  toggleShowOnlySaleProductsAction,
  toggleColorAction,
  toggleMaterialAction,
  loadItemsSucceededAction,
  showItemDetailsAction,
  enterProductFilterAction,
} from '../actions/shelf-item-picker.actions';
import { addItemToCartAction } from '../actions/shopping-cart.actions';
import { AnalyticLogs } from '../enums/analytic-logs.enum';
import { ProductCategories } from '../enums/product-categories.enum';
import { FilteredItems, Item } from '../interfaces/item.interface';
import { ProductFilterOptions } from '../interfaces/product-filter-options.interface';
import { getCurrentDesignId } from '../reducers/my-designs.reducer';
import { getFilterOptions, hasMoreItems } from '../reducers/shelf-item-picker.reducer';
import { AnalyticsService } from '../services/analytics.service';
import { ItemService } from '../services/item.service';
import { ShelfDesignerService } from '../services/shelf-designer.service';
import { ContextCategories } from '../enums/context-categories.enum';

@Injectable()
export class ShelfItemPickerEffects {
  constructor(
    private actions$: Actions,
    private itemService: ItemService,
    private store: Store,
    private analyticsService: AnalyticsService,
    private shelfDesignerService: ShelfDesignerService
  ) { }

  loadItems$ = createEffect(
    (): Observable<Action> =>
      this.actions$.pipe(
        ofType(
          loadItemsAction,
          setSortCriterionAction,
          toggleTypeAction,
          toggleThemeAction,
          toggleAffiliateAction,
          setPriceRangeAction,
          toggleShowOnlySaleProductsAction,
          toggleColorAction,
          toggleMaterialAction
        ),
        withLatestFrom(this.store.select(hasMoreItems), this.store.select(getFilterOptions)),
        filter(
          ([action, hasItemsToLoad, filterOptions]: [any, boolean, ProductFilterOptions]) =>
            hasItemsToLoad || action.configuration?.reset || this.itemService.hasFilterChanged(filterOptions)
        ),
        switchMap(([action, hasItemsToLoad, filterOptions]: [any, boolean, ProductFilterOptions]) => {
          return this.itemService.getFilteredItems$(filterOptions);
        }),
        map((filteredItems: FilteredItems) => loadItemsSucceededAction({ filteredItems })),
        catchError((error, caught) => {
          console.error('>>> load items error', error);
          this.store.dispatch(loadItemsFailedAction({ error }));
          return caught;
        })
      )
  );

  chooseItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chooseItemAction),
        tap((action) =>
          this.analyticsService.log(AnalyticLogs.SHELF_ITEM_PICKER_COMPONENT_CHOOSE_ITEM, {
            item: action.item.id,
            price: action.item?.affiliates[action.item.activeAffiliates[0]]?.price,
          })
        ),
        switchMap((action) => this.itemService.incrementUsageCount$(action.item).pipe(map(() => action))),
        withLatestFrom(this.store.select(getCurrentDesignId)),
        switchMap(([action, configId]) => [addItemToCartAction({ item: action.item, designId: configId }), leaveItemPickerAction()])
      ),
    { dispatch: true }
  );

  // TODO: log itemId and name and price
  showItemDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showItemDetailsAction),
        tap(() => this.analyticsService.log(AnalyticLogs.SHELF_ITEM_PICKER_COMPONENT_SHOW_ITEM_DETAILS)),
        tap((action) => {
          const nav: HTMLIonNavElement = this.shelfDesignerService.getIonNav(ProductCategories.Item);
          nav.push(ProductDetailsComponent, {
            product: action.item,
            productCategory: ProductCategories.Item,
            context: ContextCategories.Picker,
          });
        })
      ),
    { dispatch: false }
  );

  hideItemDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(leaveItemDetailsAction),
        tap(() => {
          const nav: HTMLIonNavElement = this.shelfDesignerService.getIonNav(ProductCategories.Item);
          nav.pop();
        })
      ),
    { dispatch: false }
  );

  enterProductFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(enterProductFilterAction),
        tap(() => this.analyticsService.log(AnalyticLogs.SHELF_ITEM_PICKER_COMPONENT_SHOW_PRODUCT_FILTER)),
        tap(() => {
          const nav: HTMLIonNavElement = this.shelfDesignerService.getIonNav(ProductCategories.Item);
          nav.push(ProductFilterComponent);
        })
      ),
    { dispatch: false }
  );

  hideProductFilter$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(leaveProductFilterAction),
        tap(() => {
          const nav: HTMLIonNavElement = this.shelfDesignerService.getIonNav(ProductCategories.Item);
          nav.pop();
        })
      ),
    { dispatch: false }
  );
}
