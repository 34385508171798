<ion-header>
  <ion-title>Card Picker Test</ion-title>
</ion-header>

<ion-content>
  <app-card-picker
    [cards]="cards"
    [orientation]="orientation"
    [cardContentTemplateRef]="cardContent"
    (onSelectCard)="handleSelectCard($event)"
  >
    <!-- CARD CONTENT -->
    <ng-template #cardContent let-card="card">
      <app-card
        *ngIf="!card.skeletonItem"
        [card]="card"
        (onSelect)="handleSelect($event)"
        (onButtonClick)="handleButtonClick($event)"
        (onRemove)="handleRemove($event)"
      >
        <!-- Card Images -->
        <app-card-images slot="images">
          <ng-container slot="images-content">
            <img [src]="card.imageUrls[0]" />
          </ng-container>
          <ng-container slot="images-badges">
            <app-product-badge
              *ngFor="let badge of card.badges"
              [text]="badge.text | transloco"
              [priority]="badge.priority"
            ></app-product-badge>
          </ng-container>
        </app-card-images>

        <!-- Card Label Rows -->
        <app-card-labels slot="labels">
          <ng-container slot="left">
            <label class="bold primary">{{ card.labels.left[0] }}</label>
            <label class="bold">{{ card.labels.left[1] }}</label>
          </ng-container>
          <ng-container slot="right">
            <label class="price-strike-through" *ngIf="card.labels.right.length > 1" [innerHTML]="card.labels.right[0]"></label>
            <label
              class="bold"
              [ngClass]="{ secondary: card.labels.right.length > 1 }"
              [innerHTML]="card.labels.right[card.labels.right.length === 1 ? 0 : 1]"
            ></label>
          </ng-container>
        </app-card-labels>

        <!-- Card Buttons -->
        <app-card-buttons slot="buttons">
          <ng-container slot="buttons-content">
            <ion-button fill="outline" color="primary" size="small" (click)="handleButtonClick('select', card)">
              {{ 'general.choose' | transloco }}
            </ion-button>
            <ion-button fill="outline" color="dark" size="small" (click)="handleButtonClick('details', card)">
              {{ 'general.details' | transloco }}
            </ion-button>
          </ng-container>
        </app-card-buttons>
      </app-card>
    </ng-template>
  </app-card-picker>
</ion-content>
