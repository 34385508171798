import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { map, Observable, of, tap } from 'rxjs';
import { AuthService } from '../../login/services/auth.service';
import { loadLastModifiedDesignOrCreateNewDesignAction, loadMyDesignAction } from '../actions/shelf-designer.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  private readonly MAINTENANCE_MODE = false;

  constructor(public authService: AuthService, public router: Router, private store: Store) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.MAINTENANCE_MODE) {
      console.log('>>> Redirect to maintenance site');
      return of(null).pipe(
        tap(() => {
          window.location.href = 'https://blog.hello-shelfy.com/maintenance/';
        }),
        map(() => false)
      );
    }

    if (route.url.length === 0) {
      // keep #admin fragment in case it exists
      this.router.navigate(['/shelfdesigner'], { fragment: route.fragment });
    }




    if (this.authService.isLoggedIn !== true) {
      this.router.navigate(['sign-in-a'], {
        state: { originalUrl: route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/') },
      });
      return false;
    }

    // shelf designer: only perform routing with id
    // console.log(">>> this.authService.userData ", this.authService.userData );

    /*
    alert('>>> window.location.href:' + window.location.href);

    alert('>>> route.toString:' + route.toString());
    alert('>>> route.url:' + route.url);
    alert('>>> route.length:' + route.url.length);
    alert('>>> route.url[0]:' + route.url[0]?.path); //shelfdesigner,
    alert('>>> route.url[1]:' + route.url[1]?.path)+"|"; // empty
    alert('>>> route.url[1].length:' + route.url[1]?.path.trim().length)+"|"; // empty
    alert('>>> route.url[2]:' + route.url[2]?.path); // undefined
*/
    const urlLength = route.url[1]?.path.trim().length == 0 ? 1 : route.url?.length;

    // shelfdesign, comes if the user clicks on "open" button in safari to open the native app
    if (route.url[0].path === 'shelfdesigner' || route.url[0].path === 'shelfdesigner,') {
      if (urlLength == 1) {
        // TODO: regard scene index with fallback for older versions
        this.store.dispatch(loadLastModifiedDesignOrCreateNewDesignAction({ user: this.authService.userData }));
        return false;
      } else {
        const payload: { designId: string; sceneIndex?: number } = {
          designId: route.url[1].path,
        };
        if (route.url.length > 2) {
          payload.sceneIndex = Number(route.url[2].path);
        }

        this.store.dispatch(loadMyDesignAction(payload));
      }
    }

    // hide admin routes for non-admins
    if (route.url[0].path === 'admin' && !this.authService.isAdmin()) {
      this.router.navigate(['/shelfdesigner']);
      return false;
    }

    return true;
  }
}
