import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IonContent } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { BehaviorSubject, of, Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { concatMap, delay, filter, map, take, tap, withLatestFrom } from 'rxjs/operators';
import * as MarketLocations from '../../../assets/json/market-locations.json';
import { environment } from '../../../environments/environment';
import { chooseItemAction, editItemAction, leaveItemPickerAction } from '../../core/actions/shelf-designer.actions';
import {
  enterProductFilterAction,
  loadItemsAction,
  setAffiliatesAction,
  showItemDetailsAction,
  toggleThemeAction,
  toggleTypeAction,
} from '../../core/actions/shelf-item-picker.actions';
import { removeItemAction } from '../../core/actions/shopping-cart.actions';
import { AffiliatesEnum } from '../../core/enums/affiliates.enum';
import { AnalyticLogs } from '../../core/enums/analytic-logs.enum';
import { ThemesEnum } from '../../core/enums/themes.enum';
import { TypesEnum } from '../../core/enums/types.enum';
import { getTypesWithoutHackTypes } from '../../core/functions/hacks';
import { Item } from '../../core/interfaces/item.interface';
import { MarketLocation } from '../../core/interfaces/market-location.interface';
import { SlotScenes } from '../../core/interfaces/scenes-slot.interface';
import { ExtendedShoppingCartItem } from '../../core/interfaces/shopping-cart-item.interface';
import { getExtendedShoppingCartItemByIdForCurrentDesign } from '../../core/reducers/my-designs.reducer';
import {
  getActiveTheme,
  getActiveTypes,
  getFilteredItems,
  hasMoreItems,
  isLoadingItems,
} from '../../core/reducers/shelf-item-picker.reducer';
import { getSelectedSlot } from '../../core/reducers/shelf-item.reducer';
import { AffiliateService } from '../../core/services/affiliate.service';
import { AnalyticsService } from '../../core/services/analytics.service';

@Component({
  selector: 'app-shelf-item-picker',
  templateUrl: './shelf-item-picker.component.html',
  styleUrls: ['./shelf-item-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShelfItemPickerComponent implements OnInit, OnDestroy {
  @Input('slot') slot: SlotScenes = null;
  @ViewChild(IonContent, { read: IonContent }) ionContent: IonContent;

  private destroy$ = new Subject();

  // UI bindings
  hasMoreItems$: Observable<boolean> = this.store.select(hasMoreItems);
  isLoadingItems$: Observable<boolean> = this.store.select(isLoadingItems);
  activeTheme$: Observable<ThemesEnum> = this.store.select(getActiveTheme);
  activeTypes$: Observable<Array<TypesEnum>> = this.store.select(getActiveTypes);

  items$: Observable<Item[]> = this.store.select(getFilteredItems).pipe(delay(0));
  themes: ThemesEnum[] = Object.values(ThemesEnum);
  types: TypesEnum[] = Object.values(TypesEnum);

  // virtual scroller
  private _virtualScroller: VirtualScrollerComponent;

  skeletonItems$: BehaviorSubject<Item[]> = new BehaviorSubject(this.getSkeletonItems());
  @ViewChild('scroll')
  set virtualScroller(virtualScroller: VirtualScrollerComponent) {
    const firstInit = virtualScroller !== undefined && (this._virtualScroller == undefined || this._virtualScroller == null);

    this._virtualScroller = virtualScroller;

    if (firstInit) {
      this.recalculateVirtualScroller();
    }
  }
  get virtualScroller(): VirtualScrollerComponent {
    return this._virtualScroller;
  }

  constructor(
    private store: Store,
    private analyticsService: AnalyticsService,
    private affiliateService: AffiliateService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.setAffiliatesForMarketLocation();
    this.handleLazyLoadItems();

    // Remove hack-types
    this.types = getTypesWithoutHackTypes();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  handleItemClick(item: Item): void {
    // this.store.dispatch(setItemInShelfAction({ slot: this.slot, item }));
    // current slot is saved in redux now... refactor...
    this.store.dispatch(chooseItemAction({ item }));
  }

  handleEditItem(item: Item): void {
    this.store.dispatch(editItemAction({ item }));
  }

  handleEditItemForNotAdmin(item: Item): void {
    this.store.dispatch(chooseItemAction({ item }));
  }

  handleClear(): void {
    // check if current slot is not empty and dispatch remove action if necessary
    this.store
      .select(getSelectedSlot)
      .pipe(
        take(1),
        filter((slot: SlotScenes) => slot?.itemId != null),
        concatMap((slot: SlotScenes) =>
          of(slot).pipe(withLatestFrom(this.store.select(getExtendedShoppingCartItemByIdForCurrentDesign(slot?.itemId)).pipe(take(1))))
        ),
        tap(([slot, item]: [SlotScenes, ExtendedShoppingCartItem]) => this.store.dispatch(removeItemAction({ item, slotNumber: slot?.id })))
      )
      .subscribe();
  }

  handleClose(): void {
    this.store.dispatch(leaveItemPickerAction());
  }

  handleShowItemDetails(item: Item): void {
    this.store.dispatch(showItemDetailsAction({ item }));
  }

  handleLazyLoadItems(): void {
    this.store.dispatch(
      loadItemsAction({
        configuration: {
          onlyActiveItems: true,
          lazyLoading: true,
          reset: false,
        },
      })
    );
  }

  handleShowMore(): void {
    this.analyticsService.log(AnalyticLogs.SHELF_ITEM_PICKER_COMPONENT_SHOW_MORE);
  }

  handleToggleTheme(theme: ThemesEnum) {
    console.log(theme);
    this.store.dispatch(toggleThemeAction({ theme }));
  }

  handleToggleType(type: TypesEnum) {
    this.store.dispatch(toggleTypeAction({ itemType: type }));
  }

  isThemeActive$(themeToCheck: ThemesEnum): Observable<boolean> {
    return this.activeTheme$.pipe(map((theme: ThemesEnum) => theme === themeToCheck));
  }

  isTypeActive$(type: TypesEnum): Observable<boolean> {
    return this.activeTypes$.pipe(map((types: Array<TypesEnum>) => types.indexOf(type) >= 0));
  }

  trackByItem(index, item: Item) {
    return item.id;
  }

  handleScrollToTop() {
    this.virtualScroller.scrollToIndex(0);
  }

  handleShowFilter(): void {
    this.store.dispatch(enterProductFilterAction());
  }

  // virtual scroller stuff
  private recalculateVirtualScroller() {
    if (this.virtualScroller) {
      this.virtualScroller.invalidateAllCachedMeasurements();
    }
  }

  onVirtualScrollerUpdate(): void {
    this.changeDetectorRef.detectChanges();
  }

  private getSkeletonItems(): Item[] {
    const frames = new Array<Item>();
    for (let i = 0; i < 50; i++) {
      const frame = { skeletonItem: true } as Item;
      frames.push(frame);
    }

    return frames;
  }

  // set affiliates for market location (depending on user country)
  setAffiliatesForMarketLocation(): void {
    const marketLocations: { [key: string]: MarketLocation } = MarketLocations;
    const euCountries: string[] = marketLocations.eu.countries;
    const userCountry: string = localStorage.getItem('userCountry');
    if (environment.allowedAffiliates[0] !== AffiliatesEnum.ALL) {
      this.store.dispatch(setAffiliatesAction({ affiliates: environment.allowedAffiliates }));
    }
    else {
      if (euCountries.find((locationCode: string) => locationCode === userCountry)) {
        this.store.dispatch(setAffiliatesAction({ affiliates: this.affiliateService.convertFromStringArray(marketLocations.eu.affiliates) }));
      } else {
        this.store.dispatch(setAffiliatesAction({ affiliates: this.affiliateService.convertFromStringArray(marketLocations.us.affiliates) }));
      }
    }
  }
}
