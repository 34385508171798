export enum ProductFilterEnum {
  Sort = 'sort',
  Types = 'types',
  Theme = 'theme',
  Affiliates = 'affiliates',
  Prices = 'prices',
  Colors = 'colors',
  Materials = 'materials',
  Sale = 'sale',
}
