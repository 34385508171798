<div class="item" [ngClass]="{ selected: selected }">
  <div class="badge-wrapper">
    <app-product-badge *ngIf="shelf | isNew" [text]="'general.new' | transloco" priority="primary"> </app-product-badge>
    <app-product-badge *ngIf="shelf | affiliate: 'hasSalePrice'" [text]="'general.sale' | transloco"
      priority="secondary"></app-product-badge>
  </div>

  <div class="image" (click)="handleShelfClick(shelf)">
    <img src="{{ shelf.scenes[0].imageUrl | imageSize: '250x250':'webp' }}" loading="lazy" sizes="30vw"
      srcset="{{ shelf.scenes[0].imageUrl | imageSize: '250x250':'webp' }}" />
  </div>

  <!-- <app-item-image class="shelf-image" [src]="shelf?.image3dViewPath" size="250x250"> </app-item-image> -->

  <div class="info-box">
    <div class="line1">
      <div class="name">
        {{ shelf?.name }}
      </div>
      <!--If it is a SALE-->
      <div *ngIf="shelf | affiliate: 'hasSalePrice'" class="higher-price"
        [innerHTML]="shelf | affiliate: 'higherPrice' | currencyFormat: 'EUR':'de'"></div>
    </div>
    <div class="line2">
      <div class="manufacturer">
        {{ shelf?.manufacturer }}
      </div>
      <!--If it is NOT a SALE-->
      <div *ngIf="!(shelf | affiliate: 'hasSalePrice')" class="higher-price-clear">&nbsp;</div>
      <div *ngIf="!(shelf | affiliate: 'hasSalePrice')" class="price"
        [innerHTML]="shelf | affiliate: 'price' | currencyFormat: 'EUR':'de'"></div>
      <!--If it is a SALE-->
      <div *ngIf="shelf | affiliate: 'hasSalePrice'" class="price info-box-second-line"
        [innerHTML]="shelf | affiliate: 'price' | currencyFormat: 'EUR':'de'"></div>
    </div>
  </div>

  <div class="actions">
    <ion-button fill="outline" size="small" class="shelfy-primary-btn actions-buttons"
      [ngClass]="{ 'split-mode': splitMode$ | async }" (click)="handleShelfClick(shelf)">
      {{ 'general.choose' | transloco }}
    </ion-button>
    <!-- ! Keep as reference (click)="handleDetailsClick(shelf)" -->
    <ng-container [ngSwitch]="environment.allowedAffiliates[0]">
      <ion-button *ngSwitchCase="affiliatesEnum.ALL" fill="outline" size="small" [href]="shelf | affiliate: 'url'"
        target="_blank" class="actions-buttons details" [ngClass]="{ 'split-mode': splitMode$ | async }">
        {{ 'general.details' | transloco }}
      </ion-button>

      <ion-button *ngSwitchCase="affiliatesEnum.OTTO_DE" fill="clear"
        class="shelfy-secondary-btn actions-buttons details-otto" [ngClass]="{ 'split-mode': splitMode$ | async }"
        size="small" [href]="shelf | affiliate: 'url'" target="_blank">
        {{ 'general.details' | transloco }}
      </ion-button>
    </ng-container>
  </div>
</div>
