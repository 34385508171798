import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ShelfScenes } from '../../../../core/interfaces/scenes-shelf.interface';
import { environment } from '../../../../../environments/environment';
import { AffiliatesEnum } from '../../../../core/enums/affiliates.enum';
import { Observable } from 'rxjs';
import { isSplitMode } from '../../../../core/reducers/general.reducer';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-shelf-picker-item',
  templateUrl: './shelf-picker-item.component.html',
  styleUrls: ['./shelf-picker-item.component.scss'],
})
export class ShelfPickerItemComponent implements OnInit {
  @Input() shelf: ShelfScenes;
  @Input() selected = false;
  @Output() shelfClicked: EventEmitter<ShelfScenes> = new EventEmitter();
  @Output() detailsClicked: EventEmitter<ShelfScenes> = new EventEmitter();

  splitMode$: Observable<boolean> = this.store.select(isSplitMode);

  environment = environment;
  affiliatesEnum = AffiliatesEnum;

  constructor(private store: Store) { }

  ngOnInit(): void { }

  handleShelfClick(shelf: ShelfScenes): void {
    this.shelfClicked.emit(shelf);
  }

  handleDetailsClick(shelf: ShelfScenes): void {
    this.detailsClicked.emit(shelf);
  }
}
