import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, iif, Observable, of } from 'rxjs';
import { filter, mergeMap } from 'rxjs/operators';
import { getAffiliateData } from '../functions/affiliates';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { AffiliatePipe } from '../pipes/affiliate.pipe';
import { getSumAffiliatePriceOfItemsFromDesign, isBuyingCurrentShelf } from '../reducers/my-designs.reducer';
import { getCurrentShelf } from '../reducers/shelf-designer.reducer';

@Injectable({
  providedIn: 'root',
})
export class ProductListService {
  /* Observables */
  private isBuyingShelf$: Observable<boolean> = this.store.select(isBuyingCurrentShelf);
  private shelfData$: Observable<ShelfScenes> = this.store.select(getCurrentShelf);

  constructor(private store: Store, private affiliatePricePipe: AffiliatePipe) {}

  getTotalPrice$(): Observable<number> {
    return combineLatest([this.isBuyingShelf$, this.shelfData$, this.store.select(getSumAffiliatePriceOfItemsFromDesign)]).pipe(
      filter(([isBuyingShelf, shelf, sumPriceOfItems]) => shelf != null && isBuyingShelf != null && sumPriceOfItems != null),
      mergeMap(([isBuyingShelf, shelf, sumPriceOfItems]: [boolean, ShelfScenes, number]) => {
        return iif(
          () => isBuyingShelf && getAffiliateData(shelf) !== null,
          of(sumPriceOfItems + Number(this.affiliatePricePipe.transform(shelf, 'price'))),
          of(sumPriceOfItems)
        );
      })
    );
  }
}
