import { Injectable, NgZone, Optional } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  FacebookAuthProvider,
  getAuth,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  TwitterAuthProvider,
  User,
  UserCredential,
} from '@angular/fire/auth';
import { doc, DocumentReference, Firestore, setDoc } from '@angular/fire/firestore';
import { ActivatedRoute, Router } from '@angular/router';
import { authState } from 'rxfire/auth';
import { from, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AppUser } from '../entities/user.interface';
import mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userData: User; // Save logged in user data
  readonly homeRoute = '/shelfdesigner';
  // private auth: Auth;

  constructor(
    public firestore: Firestore,
    @Optional() private auth: Auth,

    // public readonly afAuth: AngularFireAuth, // Inject Firebase auth service
    public route: ActivatedRoute,
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    //  this.afAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
    this.userData = JSON.parse(localStorage.getItem('user'));

    /* Saving user data in localstorage when
    logged in and setting up null when logged out */

    if (auth) {
      authState(this.auth).subscribe((user: User) => {
        console.log('>>> authState: user', user);
        if (user) {
          this.updateUserInStorage(user);
          this.initTracking();

          console.log('>>> this.authService.isLoggedIn', user);
        }
        if (user) {
          //      this.router.navigate(['/home'], { fragment: this.route.snapshot.fragment });
        } else {
          //      this.router.navigate(['/sign-in'], { fragment: this.route.snapshot.fragment });
        }
      });
    }

    /*
    this.afAuth.authState.subscribe((user: firebase.User) => {
      this.updateUserInStorage(user);
      if (user) {
        //      this.router.navigate(['/home'], { fragment: this.route.snapshot.fragment });
      } else {
        //      this.router.navigate(['/sign-in'], { fragment: this.route.snapshot.fragment });
      }
    });
    */
  }

  initTracking() {
    // or use this version...
    console.log('>>> auth', this.auth);
    console.log('>>> userData', this.userData);

    if (this.userData?.uid) {
      mixpanel.identify(this.userData.uid);
    }
  }

  initAuth$(): Observable<void> {
    return of(null);
    /*
    return of(null).pipe(
      tap(() => console.log('>>> initAuth$()')),
      switchMap(() => this.afAuth.app),
      tap((app) => {
        this.auth = this.whichAuth(app);
        console.log('>>> auth', this.auth);
      }),
      map(() => null)
    );
    */
  }

  private updateUserInStorage(user: User): void {
    if (user) {
      this.userData = user;
      localStorage.setItem('user', JSON.stringify(this.userData));
    } else {
      localStorage.setItem('user', null);
    }
  }

  // Sign in with email/password
  signIn$(email: string, password: string): Observable<void> {
    /*
    return of(null).pipe(
      switchMap(() =>
        from(this.afAuth.signInWithEmailAndPassword(email, password)).pipe(
          switchMap((userCredentials) => this.updateUserData$(userCredentials.user)),
          tap(() => this.router.navigate([this.homeRoute], { fragment: this.route.snapshot.fragment })),
          catchError((error) => {
            window.alert(error.message);
            throw error;
          })
        )
      )
    );
    */
    return of(null).pipe(
      switchMap(() =>
        from(signInWithEmailAndPassword(this.auth, email, password)).pipe(
          switchMap((userCredentials: UserCredential) => this.updateUserData$(userCredentials.user)),
          tap(() => this.router.navigate([this.homeRoute], { fragment: this.route.snapshot.fragment })),
          catchError((error) => {
            window.alert(error.message);
            throw error;
          })
        )
      )
    );
  }

  private whichAuth(app) {
    let auth = getAuth();
    return auth;
    /*
    if (Capacitor.isNativePlatform()) {
      auth = initializeAuth(app, {
        persistence: indexedDBLocalPersistence,
      });
    } else {
      auth = getAuth();
    }
    return auth;
    */
  }

  signInAnonymously$(): Observable<User> {
    return of(null).pipe(
      switchMap(() =>
        from(signInAnonymously(this.auth)).pipe(
          switchMap((userCredentials) => this.updateUserData$(userCredentials.user)),
          tap(() => this.router.navigate([this.homeRoute], { fragment: this.route.snapshot.fragment })),
          map(() => this.userData),
          catchError((error) => {
            window.alert(error.message);
            throw error;
          })
        )
      )
    );
  }

  // Sign up with email/password
  signUp(email: string, password: string): void {
    createUserWithEmailAndPassword(this.auth, email, password)
      .then((result: UserCredential) => {
        // Call the SendVerificaitonMail() function when new user sign
        // up and returns promise
        this.sendVerificationMail();
        this.updateUserData$(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
    /*
    return this.afAuth
      .createUserWithEmailAndPassword(email, password)
      .then((result: firebase.auth.UserCredential) => {
        // Call the SendVerificaitonMail() function when new user sign
        // up and returns promise
        this.sendVerificationMail();
        this.updateUserData$(result.user);
      })
      .catch((error) => {
        window.alert(error.message);
      });
      */
  }

  // Send email verfificaiton when new user sign up
  async sendVerificationMail(): Promise<void> {
    /*
    return (await this.afAuth.currentUser).sendEmailVerification().then(() => {
      this.router.navigate(['verify-email-address'], { fragment: this.route.snapshot.fragment });
    });
    */

    return await sendEmailVerification(this.userData).then(() => {
      this.router.navigate(['verify-email-address'], { fragment: this.route.snapshot.fragment });
    });
  }

  // Reset Forggot password
  forgotPassword(passwordResetEmail: string): Promise<void> {
    /*
    return this.afAuth
      .sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      })
      .catch((error) => {
        window.alert(error);
      });
      */
    const p = sendPasswordResetEmail(this.auth, passwordResetEmail);

    p.then(() => {
      window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      window.alert(error);
    });
    return p;
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    // const user = JSON.parse(localStorage.getItem('user'));
    //return user !== null && user.emailVerified !== false ? true : false;
    // return this.userData !== null && this.userData.emailVerified !== false ? true : false;

    return this.userData !== null && this.userData.uid !== null;
  }

  isAdmin(): boolean {
    let developers = [
      'behrendt@onexip.com',
      'conrad.beck@onexip.com',
      'starick@onexip.com',
      'hausmann@onexip.com',
      'tobi@starbooker.de',
      'miersch@onexip.com',
      'tobidd.b@googlemail.com',
      'aurora-zoe.weigelt@onexip.com',
    ];

    console.log('>>> isAdmin: ', this.userData?.email);
    return developers.indexOf(this.userData?.email) > -1;
  }

  // Sign in with Google

  googleAuth$(): Observable<void | UserCredential> {
    const provider = new GoogleAuthProvider();
    return this.authLogin$(provider);
  }

  twitterAuth$(): Observable<void | UserCredential> {
    const provider = new TwitterAuthProvider();
    return this.authLogin$(provider);
  }

  facebookAuth$(): Observable<void | UserCredential> {
    const provider = new FacebookAuthProvider();
    return this.authLogin$(provider);
  }

  // Auth logic to run auth providers
  authLogin$(provider): Observable<void | UserCredential> {
    /*
    return of(null).pipe(
      switchMap(() => from(this.afAuth.signInWithPopup(provider))),
      switchMap((userCredentials: firebase.auth.UserCredential) => this.updateUserData$(userCredentials.user)),
      tap((userCredentials) => {
        this.ngZone.run(() => {
          this.router.navigate([this.homeRoute], { fragment: this.route.snapshot.fragment });
        });
      }),
      catchError((error) => {
        window.alert(error);
        throw error;
      })
    );
    */
    return of(null).pipe(
      switchMap(() => from(signInWithPopup(this.auth, provider))),
      switchMap((userCredentials: UserCredential) => this.updateUserData$(userCredentials.user)),
      tap((userCredentials) => {
        this.ngZone.run(() => {
          this.router.navigate([this.homeRoute], { fragment: this.route.snapshot.fragment });
        });
      }),
      catchError((error) => {
        window.alert(error);
        throw error;
      })
    );
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  updateUserData$(user: User): Observable<void> {
    const userRef: DocumentReference<any> = doc(this.firestore, 'users', user.uid);
    const userData: AppUser = {
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
    };

    return of(null).pipe(
      switchMap(() =>
        from(
          setDoc(userRef, userData, {
            merge: true,
          })
        )
      ),
      map(() => null),
      catchError((err) => {
        console.error('>>> err', err);
        throw err;
      })
    );
  }

  // Sign out
  signOut$(): Observable<void> {
    return of(null).pipe(
      switchMap(() => signOut(this.auth)),
      tap(() => {
        localStorage.removeItem('user');
        this.ngZone.run(() => {
          this.router.navigate(['../sign-in'], { fragment: this.route.snapshot?.fragment });
        });
      })
    );
  }

  getUserId(): string {
    return this.userData.uid;
  }
}
