<ion-header>
  <ion-toolbar class="header-toolbar">
    <ion-buttons slot="start">
      <ion-button (click)="handleClose()" *ngIf="hasActiveAffiliates()">
        <ion-icon name="arrow-back-outline" class="grey-icon"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title class="product-title">{{ 'filter.product.title' | transloco }}</ion-title>
  </ion-toolbar>
  <div class="actions">
    <ion-button (click)="handleResetFilter()" fill="clear">
      {{ 'filter.product.reset' | transloco }}
    </ion-button>
  </div>
</ion-header>

<ion-content>
  <div class="filter-container">
    <form [formGroup]="productFilterFormGroup" role="form">
      <ion-accordion-group value="sort">
        <!-- Sort Option -->
        <ion-accordion value="sort">
          <ion-item slot="header">
            <ion-label>
              {{ 'filter.product.sort' | transloco }}
            </ion-label>
          </ion-item>

          <ion-list slot="content">
            <ion-radio-group formControlName="sortCriterion" appUiChanges behavior="click"
              (uiChange)="handleSortChanged($event)">
              <ion-item *ngFor="let sort of sortCriteria" lines="none">
                <ion-label>{{ 'filter.sort.' + sort | transloco }}</ion-label>
                <ion-radio slot="end" [value]="sort"></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-list>
        </ion-accordion>

        <!-- Types Options -->
        <ion-accordion value="types">
          <ion-item slot="header">
            <ion-label>
              {{ 'filter.product.types' | transloco }}
            </ion-label>
          </ion-item>

          <ion-item slot="content">
            <div class="types-filter">
              <app-chip *ngFor="let type of types" [text]="'types.' + type | transloco"
                [active]="type | inArray: activeTypesFormControl.value" (click)="handleToggleType(type)"></app-chip>
            </div>
          </ion-item>
        </ion-accordion>

        <!-- Theme Option -->
        <ion-accordion value="theme">
          <ion-item slot="header">
            <ion-label>
              {{ 'filter.product.theme' | transloco }}
            </ion-label>
          </ion-item>

          <ion-item slot="content">
            <div class="themes-filter v1">
              <div *ngFor="let theme of themes"
                [ngClass]="{ theme: true, active: theme | inArray: activeThemeFormControl.value }"
                (click)="handleToggleTheme(theme)">
                <div class="title">{{ 'themes.' + theme | transloco }}</div>
              </div>
            </div>
          </ion-item>
        </ion-accordion>

        <!-- Affiliates Options -->
        <ion-accordion value="affiliates"
          *ngIf="environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] === affiliatesEnum.ALL">
          <ion-item slot="header">
            <ion-label>
              {{ 'filter.product.affiliates' | transloco }}
            </ion-label>
          </ion-item>

          <ion-list slot="content">
            <div class="warning" [ngStyle]="{ display: !hasActiveAffiliates() ? 'block' : 'none' }">
              {{ 'filter.product.please-select-affiliates' | transloco }}
            </div>

            <ion-item *ngFor="let affiliate of affiliates" lines="none" class="affiliate-item">
              <ion-label class="affiliate" *ngIf="affiliate !== affiliatesEnum.ALL">
                {{ 'affiliates.' + affiliate | transloco }}
              </ion-label>
              <ion-toggle appUiChanges behavior="click" (uiChange)="handleToggleAffiliate(affiliate)"
                [formControlName]="'affiliate.' + affiliate" slot="end" name="{{ affiliate }}"
                *ngIf="affiliate !== affiliatesEnum.ALL"></ion-toggle>
            </ion-item>
          </ion-list>
        </ion-accordion>

        <!-- Price Options -->
        <div class="price filter-option-not-accordion"
          *ngIf="minPriceFormControl.value !== undefined && maxPriceFormControl.value !== undefined">
          <div class="header">
            <div class="header-title">{{ 'filter.product.prices' | transloco }}</div>
            <div class="header-prices">{{ minPriceFormControl.value }} € - {{ maxPriceFormControl.value }} €</div>
          </div>

          <div>
            <ion-item lines="none">
              <ion-range *ngLet="{ lowest: lowestItemPrice$ | async, highest: highestItemPrice$ | async } as priceObj"
                appUiChanges behavior="drag" (uiChange)="handleSetPriceRange($event)" [min]="priceObj.lowest"
                [max]="priceObj.highest"
                [value]="{ lower: minPriceFormControl.value, upper: maxPriceFormControl.value }" step="1"
                dualKnobs="true"
                [disabled]="(totalFilteredItems$ | async) === 0 && (priceObj.lowest === NaN || priceObj.highest === NaN)">
                <ion-label slot="start">{{ priceObj.lowest }}</ion-label>
                <ion-label slot="end">{{ priceObj.highest }}</ion-label>
              </ion-range>
            </ion-item>
            <div class="sale">
              <div>
                <div class="sale-text">{{ 'filter.product.sale' | transloco }}</div>
              </div>
              <ion-toggle appUiChanges behavior="click" slot="end" name="sale" formControlName="sale"
                (uiChange)="handleToggleShowOnlySaleProducts()"></ion-toggle>
            </div>
          </div>
        </div>

        <!-- Color Options -->
        <div class="colors filter-option-not-accordion">
          <div class="header">
            <div class="header-title">{{ 'filter.product.colors.title' | transloco }}</div>
          </div>

          <div class="color-choice">
            <div class="color-palette-row">
              <app-color-circle [color]="color" [active]="color | inArray: activeColorsFormControl.value"
                *ngFor="let color of allColors | slice: 0:8"
                (click)="handleToggleColorOption(color)"></app-color-circle>
            </div>
            <div class="color-palette-row">
              <app-color-circle [color]="color" [active]="color | inArray: activeColorsFormControl.value"
                *ngFor="let color of allColors | slice: 8" (click)="handleToggleColorOption(color)"></app-color-circle>
            </div>
          </div>
        </div>

        <!-- Material Options -->
        <ion-accordion value="materials">
          <ion-item slot="header">
            <ion-label>
              {{ 'filter.product.materials' | transloco }}
            </ion-label>
          </ion-item>

          <ion-item slot="content">
            <div class="materials-filter">
              <app-chip *ngFor="let material of materials" [text]="'materials.' + material | transloco"
                [active]="material | inArray: activeMaterialsFormControl.value"
                (click)="handleToggleMaterial(material)"></app-chip>
            </div>
          </ion-item>
        </ion-accordion>
      </ion-accordion-group>
    </form>
  </div>
</ion-content>

<ion-footer class="footer" *ngLet="totalFilteredItems$ | async as totalFilteredItems">
  <ion-button [disabled]="totalFilteredItems === 0" [ngClass]="{ pointer: totalFilteredItems > 0 }"
    (click)="handleApplyFilter()">
    {{
    'filter.product.useFilterResults.' + (totalFilteredItems | totalFilteredProducts: 'i18n')
    | transloco: { totalNumberOfProducts: totalFilteredItems | totalFilteredProducts: 'short' }
    }}
  </ion-button>
</ion-footer>
