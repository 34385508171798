<div front class="item" (click)="handleItemClick(sceneIndex)">
  <div class="image" [ngClass]="{selected: selected}">
    <img src="{{ scene?.imageUrl | imageSize: '250x250':'webp' }}" loading="lazy"
      [ngClass]="{ cover: scene?.type === 'custom', selected: selected }" />
  </div>
  <div class="info-box">
    <div class="name">{{ scene?.name }}</div>
  </div>
  <div class="actions">
    <ng-container [ngSwitch]="environment.allowedAffiliates[0]">
      <ion-button *ngSwitchCase="affiliatesEnum.ALL" fill="outline" size="small" class="actions-buttons details"
        [ngClass]="{ 'split-mode': splitMode$ | async }">
        {{ 'general.choose' | transloco }}
      </ion-button>

      <ion-button *ngSwitchCase="affiliatesEnum.OTTO_DE" fill="clear"
        class="shelfy-secondary-btn actions-buttons details-otto" [ngClass]="{ 'split-mode': splitMode$ | async }"
        size="small">
        {{ 'general.choose' | transloco }}
      </ion-button>
    </ng-container>
  </div>
</div>
