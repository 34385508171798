import { Component, OnInit } from '@angular/core';
import { Card } from '../core/classes/card.class';
import { OrientationEnum } from '../core/enums/orientation.enum';
import { getAffiliateData } from '../core/functions/affiliates';
import { MetaAffiliate } from '../core/interfaces/affiliate.interface';
import { Badge } from '../core/interfaces/badge.interface';
import { Item } from '../core/interfaces/item.interface';
import { CurrencyFormatPipe } from '../core/pipes/currency-format.pipe';
import { IsNewPipe } from '../core/pipes/is-new.pipe';
import { ItemService } from '../core/services/item.service';

@Component({
  selector: 'app-card-picker-test',
  templateUrl: './card-picker-test.component.html',
  styleUrls: ['./card-picker-test.component.scss'],
  providers: [CurrencyFormatPipe, IsNewPipe],
})
export class CardPickerTestComponent implements OnInit {
  cards!: Array<Card>;
  orientation!: OrientationEnum;

  constructor(private itemService: ItemService, private currencyFormat: CurrencyFormatPipe, private isNew: IsNewPipe) {}

  ngOnInit(): void {
    // Orientation
    this.orientation = OrientationEnum.Vertical;

    // Cards
    this.cards = [];

    this.itemService.getItemsForAdmin$({ reset: true, lazyLoading: true, onlyActiveItems: true }, []).subscribe((items: Item[]) => {
      console.log(`>>> retrieved ${items.length} items`);
      items.forEach((item: Item, index: number) => {
        const affiliateData: MetaAffiliate = getAffiliateData(item);
        const priceNormal: number = affiliateData.data.price;
        const priceSale: number = affiliateData.data.priceSale;

        let badges: Badge[] = [];
        // new
        if (this.isNew.transform(item)) {
          badges.push({
            text: 'general.new',
            priority: 'primary',
          });
        }

        // sale
        if (priceSale) {
          badges.push({
            text: 'general.sale',
            priority: 'secondary',
          });
        }

        const card: Card = {
          id: item.id,
          badges,
          imageUrls: [item.image180RemotePath],
          labels: {
            left: [item.name, item.manufacturer],
            right: priceSale
              ? [this.currencyFormat.transform(priceNormal, 'EUR', 'de'), this.currencyFormat.transform(priceSale, 'EUR', 'de')]
              : [this.currencyFormat.transform(priceNormal, 'EUR', 'de')],
          },
        };

        this.cards.push(card);
      });
    });
  }

  handleSelectCard($event: Card): void {
    console.log('>>> handleSelectCard', $event);
  }

  handleButtonClick(label: string, card: Card): void {
    console.log(`>>> handleButtonClick: [${label}]`, card);
  }
}
