import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Item } from '../interfaces/item.interface';
import { ExtendedShoppingCartItem, ProductListProduct, ShoppingCartItem } from '../interfaces/shopping-cart-item.interface';
import { TypesEnum } from '../enums/types.enum';
import { SlotScenes } from '../interfaces/scenes-slot.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';

@Injectable({
  providedIn: 'root',
})
export class ShoppingcartService {
  items: Array<Item> = [];
  itemList: ExtendedShoppingCartItem[] = [];
  total: number;
  total$: Observable<number>;

  constructor() {}

  searchSlotNumber(slotNumberArray: Array<number>, slot: SlotScenes): boolean {
    let result: boolean;
    let index: number;
    index = slotNumberArray.indexOf(slot.id);
    return index != -1;
  }

  checkSlotNumber(slotIndex: number, slot: SlotScenes) {
    if (slotIndex != -1) {
      let quantity: number = this.itemList[slotIndex].slotnumber.length - 1;
      if (quantity > 0) {
        let item = this.itemList[slotIndex].item;
        // update slotnumber array
        let reducedSlotNumberArray: Array<number> = this.itemList[slotIndex].slotnumber.slice();
        reducedSlotNumberArray.splice(reducedSlotNumberArray.indexOf(slot.id), 1);
        // update slotToBuy array if element is marked to buy
        let reducedSlotsToBuyArray: Array<number> = this.itemList[slotIndex].slotsToBuy.slice();
        if (reducedSlotsToBuyArray.includes(slot.id)) {
          reducedSlotsToBuyArray.splice(reducedSlotsToBuyArray.indexOf(slot.id), 1);
        }
        this.itemList.splice(slotIndex, 1, { slotnumber: reducedSlotNumberArray, item: item, slotsToBuy: reducedSlotsToBuyArray });
      } else {
        this.itemList.splice(slotIndex, 1);
      }
    }
    return of(this.itemList);
  }

  public addItemToList(items: ExtendedShoppingCartItem[], slot: SlotScenes, item: Item): ExtendedShoppingCartItem[] {
    //shoppingcard not empty
    if (items != null) {
      this.itemList = items.slice();
      let slotIndex = this.itemList
        .map((item: ExtendedShoppingCartItem) => {
          return item.slotnumber;
        })
        .findIndex((slotNumberArray: Array<number>) => this.searchSlotNumber(slotNumberArray, slot));

      //if slotNumber already in list, remove it
      this.checkSlotNumber(slotIndex, slot).subscribe((itemList: ExtendedShoppingCartItem[]) => {
        let itemIndex = this.itemList
          .map((item: ExtendedShoppingCartItem) => {
            return item.item.id;
          })
          .indexOf(item.id);
        // item not in list yet
        if (itemIndex == -1) {
          let newSlotNumberArray: Array<number> = [];
          let newSlotsToBuyArray: Array<number> = [];
          newSlotNumberArray.push(slot.id);
          newSlotsToBuyArray.push(slot.id);
          this.itemList.push({ slotnumber: newSlotNumberArray, item: item, slotsToBuy: newSlotsToBuyArray });

          //list has entry for this ItemID
        } else {
          let newSlotNumberArray: Array<number> = this.itemList[itemIndex].slotnumber.slice();
          let newSlotsToBuyArray: Array<number> = this.itemList[itemIndex].slotsToBuy.slice();
          newSlotNumberArray.push(slot.id);
          newSlotsToBuyArray.push(slot.id);
          this.itemList.splice(itemIndex, 1, { slotnumber: newSlotNumberArray, item: item, slotsToBuy: newSlotsToBuyArray });
        }
      });

      //shoppingcard empty
    } else {
      this.itemList = [];
      let slotNumberArray: Array<number> = [];
      slotNumberArray.push(slot.id);
      this.itemList.push({ slotnumber: slotNumberArray, item: item, slotsToBuy: slotNumberArray });
    }

    return this.itemList;
  }

  public toggleHacksItemInList(items: ExtendedShoppingCartItem[], slot: SlotScenes, item: Item): ExtendedShoppingCartItem[] {
    let itemList: ExtendedShoppingCartItem[] = [];
    // Shoppingcard not empty
    if (items != null) {
      itemList = JSON.parse(JSON.stringify(items));
      let index: number = itemList.findIndex((checkItem) => {
        return checkItem.item.id === item.id;
      });
      // Check if HacksItem is included (then remove it)
      if (index !== -1) {
        itemList.splice(index, 1);
      } else {
        // Add the new HacksItem
        let newHacksItem: ExtendedShoppingCartItem = {
          item,
          slotnumber: [slot.id],
          slotsToBuy: [slot.id],
        } as ExtendedShoppingCartItem;

        itemList.push(newHacksItem);
      }

      // Shoppingcard empty
    } else {
      itemList = [];
      let slotNumberArray: Array<number> = [];
      slotNumberArray.push(slot.id);
      itemList.push({ slotnumber: slotNumberArray, item: item, slotsToBuy: slotNumberArray });
    }

    return itemList;
  }

  public removeItemGroupFromList(items: ExtendedShoppingCartItem[], item: ExtendedShoppingCartItem): ExtendedShoppingCartItem[] {
    this.itemList = items.slice();
    this.itemList.splice(this.itemList.indexOf(item), 1);

    return this.itemList;
  }

  public removeItemFromList(
    items: ExtendedShoppingCartItem[],
    item: ExtendedShoppingCartItem,
    slotNumber: number
  ): ExtendedShoppingCartItem[] {
    // remove extended item completly
    if (item.slotnumber.length === 1) {
      return this.removeItemGroupFromList(items, item);
      // remove just for selected slot
    } else {
      this.itemList = items.slice();
      const index = this.itemList.indexOf(item);
      let reducedSlotNumberArray: Array<number> = this.itemList[index].slotnumber.slice();
      reducedSlotNumberArray.splice(reducedSlotNumberArray.indexOf(slotNumber), 1);
      let reducedSlotsToBuyArray: Array<number> = this.itemList[index].slotsToBuy.slice();
      if (reducedSlotsToBuyArray.includes(slotNumber)) {
        reducedSlotsToBuyArray.splice(reducedSlotsToBuyArray.indexOf(slotNumber), 1);
      }
      this.itemList[index].slotnumber = reducedSlotNumberArray;
      this.itemList[index].slotsToBuy = reducedSlotsToBuyArray;
      return this.itemList;
    }
  }

  public updateSlotsToBuyForItem(items: ExtendedShoppingCartItem[], item: ProductListProduct): ExtendedShoppingCartItem[] {
    this.itemList = items.slice();
    const index = items.findIndex((cartItem: ExtendedShoppingCartItem) => cartItem.item.id === item.item.id);
    const updatedSlotsToBuyArray = [...items[index].slotsToBuy];
    if (updatedSlotsToBuyArray.indexOf(item.slotnumber) === -1) {
      updatedSlotsToBuyArray.push(item.slotnumber);
    } else {
      updatedSlotsToBuyArray.splice(updatedSlotsToBuyArray.indexOf(item.slotnumber), 1);
    }
    this.itemList[index].slotsToBuy = updatedSlotsToBuyArray;
    return this.itemList;
  }

  checkUpdateChanges(shoppingCardItems: ExtendedShoppingCartItem[], types: TypesEnum[]): ExtendedShoppingCartItem[] {
    let newShoppingCardItems: Array<ExtendedShoppingCartItem> = shoppingCardItems.slice();
    let found: boolean = false;
    let newSlotNumberArray: Array<number>;
    let newItem: Item;
    let newQuantity: number;

    // TODO:
    // newShoppingCardItems.forEach((shoppingCardItem: ShoppingCartItem) => {
    //   let shoppingCardItemIndex = newShoppingCardItems.indexOf(shoppingCardItem);
    //   types.forEach((type: TypesEnum) => {
    //     let findIndex: number = type.items.findIndex((item: Item) => {
    //       return item.id == shoppingCardItem.item.id;
    //     });
    //     if (findIndex != -1) {
    //       found = true;
    //       newItem = type.items[findIndex];
    //       newSlotNumberArray = shoppingCardItem.slotnumber;
    //       newQuantity = shoppingCardItem.quantity;
    //     }
    //   });
    //   if (found == false) {
    //     newShoppingCardItems.splice(shoppingCardItemIndex, 1);
    //   } else {
    //     found = false;
    //     newShoppingCardItems.splice(shoppingCardItemIndex, 1, { slotnumber: newSlotNumberArray, item: newItem, quantity: newQuantity });
    //   }
    // });

    return newShoppingCardItems;
  }

  /**
   *
   * @param cartItems current cartItems before shelf change
   * @param newShelf new shelf with mapped items
   * @returns
   */
  updateItemQuantityAfterShelfChange(cartItems: ExtendedShoppingCartItem[], newShelf: ShelfScenes): ExtendedShoppingCartItem[] {
    let newShoppingCartItems = [...cartItems];
    cartItems.forEach((cartItem: ExtendedShoppingCartItem) => {
      const transferedItemsWithCartItemId: Array<SlotScenes> = newShelf.scenes[0].slots.filter(
        (slot: SlotScenes) => slot.itemId === cartItem.item.id
      );
      const noItemInShelf = transferedItemsWithCartItemId.length;
      const slotnumbersOfTransferedItems: Array<number> = transferedItemsWithCartItemId.map((slot) => slot.id);

      // shoppingCartItem does no longer exist in shelf after transfer
      if (noItemInShelf === 0) {
        newShoppingCartItems = this.removeItemGroupFromList(newShoppingCartItems, cartItem);
        // shoppingCartItem still exist in shelf but less quantity
      } else if (noItemInShelf < cartItem.slotnumber.length) {
        let index = newShoppingCartItems.findIndex((newItem: ExtendedShoppingCartItem) => newItem.item.id === cartItem.item.id);
        // newShoppingCartItems[index].quantity = noItemInShelf;
        newShoppingCartItems[index].slotnumber = slotnumbersOfTransferedItems;
        newShoppingCartItems[index].slotsToBuy = slotnumbersOfTransferedItems;
        // shoppingCartItem still exist with same quantity but internal slotnumber must be adjusted
      } else if (noItemInShelf === cartItem.slotnumber.length) {
        let index = newShoppingCartItems.findIndex((newItem: ExtendedShoppingCartItem) => newItem.item.id === cartItem.item.id);
        newShoppingCartItems[index].slotnumber = slotnumbersOfTransferedItems;
        newShoppingCartItems[index].slotsToBuy = slotnumbersOfTransferedItems;
      }
    });

    return newShoppingCartItems;
  }
}
