<div front class="item" (click)="handleItemClick()">
  <div class="badge-wrapper">
    <app-product-badge *ngIf="item | isNew" [text]="'general.new' | transloco" priority="primary"></app-product-badge>
    <app-product-badge *ngIf="item | affiliate: 'hasSalePrice'" [text]="'general.sale' | transloco"
      priority="secondary"></app-product-badge>
  </div>

  <div class="image">
    <!-- src="{{ item.image180RemotePath }}" -->
    <!--<img  loading="lazy" srcset="{{ item.image180RemotePath | imageSrcSet }}" sizes="30vw" />//-->
    <!-- loading="lazy" -->
    <!--
    <img srcset="{{ item?.image180RemotePath | imageSrcSet }}" sizes="30vw" />
     <ion-img src="{{ item?.image180RemotePath | imageSize: '250x250':'png' }}"></ion-img>
    //-->

    <img src="{{ item?.image180RemotePath | imageSize: '250x250':'png' }}" loading="lazy" />
    <!--<picture size="250x250" [appPicture]="item?.image180RemotePath"> </picture>//-->
  </div>
  <div class="info-box">
    <div class="line1">
      <div class="name">{{ item?.name }}</div>
      <!--If it is a SALE-->
      <div *ngIf="item | affiliate: 'hasSalePrice'" class="higher-price"
        [innerHTML]="item | affiliate: 'higherPrice' | currencyFormat: 'EUR':'de'"></div>
    </div>
    <div class="line2">
      <div class="manufacturer">{{ item?.manufacturer }}</div>
      <!--If it is NOT a SALE-->
      <div *ngIf="!(item | affiliate: 'hasSalePrice')" class="higher-price-clear">&nbsp;</div>
      <div *ngIf="!(item | affiliate: 'hasSalePrice')" class="price"
        [innerHTML]="item | affiliate: 'price' | currencyFormat: 'EUR':'de'"></div>
      <!--If it is a SALE-->
      <div *ngIf="(item | affiliate: 'hasSalePrice')" class="price info-box-second-line"
        [innerHTML]="item | affiliate: 'price' | currencyFormat: 'EUR':'de'"></div>
    </div>
  </div>
  <div class="actions">
    <ion-button *ngIf="!isAdmin" fill="outline" size="small" class="shelfy-primary-btn actions-buttons"
      [ngClass]="{ 'split-mode': splitMode$ | async }">
      {{ 'general.choose' | transloco }}
    </ion-button>
    <ion-button *ngIf="isAdmin" (click)="handleEditItem($event)" fill="outline" size="small"
      class="shelfy-primary-btn actions-buttons" [ngClass]="{ 'split-mode': splitMode$ | async }">
      {{ 'general.edit' | transloco }}
    </ion-button>

    <!-- Only for Debug -->
    <!-- <ion-button fill="outline" size="small" (click)="handleShowItemDetails($event, item)">{{ 'general.details' | transloco }}</ion-button> -->
    <!-- Only for Real-Use -->
    <!-- [href]="item | affiliate: 'url'" target="_blank" -->
    <ng-container [ngSwitch]="environment.allowedAffiliates[0]">
      <ion-button *ngSwitchCase="affiliatesEnum.ALL" fill="outline" size="small"
        (click)="handleShowItemDetails($event, item)" class="actions-buttons details"
        [ngClass]="{ 'split-mode': splitMode$ | async }">
        {{ 'general.details' | transloco }}
      </ion-button>

      <ion-button *ngSwitchCase="affiliatesEnum.OTTO_DE" fill="clear" size="small"
        (click)="handleShowItemDetails($event, item)" class="shelfy-secondary-btn actions-buttons details-otto"
        [ngClass]="{ 'split-mode': splitMode$ | async }">
        {{ 'general.details' | transloco }}
      </ion-button>
    </ng-container>
  </div>
</div>
