import { Component, OnInit } from '@angular/core';
import { AffiliateInformation } from '../../core/interfaces/affiliate.interface';
import * as Affiliates from '../../../assets/json/affiliate-informations.json';

@Component({
  selector: 'app-amazon-linker',
  templateUrl: './amazon-linker.component.html',
  styleUrls: ['./amazon-linker.component.scss'],
})
export class AmazonLinkerComponent implements OnInit {
  affiliateLink: string = null;
  affiliateInformations: { [key: string]: AffiliateInformation } = Affiliates;

  constructor() {}

  ngOnInit(): void {}

  handleGotoAmazon(link: string) {
    this.affiliateLink = this.getAffiliateLinkForAmazonDE(link);
  }

  private getAffiliateLinkForAmazonDE(link: any): any {
    link = this.parseLink(link);

    if (!link || link.length <= 0) {
      link = this.affiliateInformations.amazonDE.productFallbackUrl;
    }
    return `${link}?tag=${this.affiliateInformations.amazonDE.affiliateId}`;
  }

  private parseLink(content: string) {
    return content.substring(content.indexOf('https://'));
  }
}
