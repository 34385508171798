import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import {
  chooseItemAction,
  chooseShelfAction,
  chooseShelfColorAction,
  chooseShelfColorSucceededAction,
  chooseShelfFailedAction,
  chooseShelfSceneAction,
  shelfImageLoadedAction,
  chooseShelfSucceededAction,
  cloneDesignAction,
  cloneDesignFailedAction,
  cloneDesignSucceededAction,
  enterItemPickerAction,
  enterShelfPickerAction,
  hideBottomPickerAction,
  hideIntroductionAnimationAction,
  leaveItemPickerAction,
  leaveShelfPickerAction,
  loadMyDesignSucceededAction,
  resetSavedConfigStatusAction,
  resetShelfDataAction,
  showBottomPickerAction,
  showIntroductionAnimationAction,
  chooseTabAction,
  toggleShelfPickerDesktopAction,
  toggleScenePickerDesktopAction,
  setShelfScenesHacksPickerSizeMobileAction,
  toggleHacksPickerDesktopAction,
} from '../actions/shelf-designer.actions';
import * as ShelfItemActions from '../actions/shelf-item.actions';
import { removeItemGroupSucceededAction, removeItemSucceededAction } from '../actions/shopping-cart.actions';
import { PickerTypes } from '../enums/picker-types.enum';
import { ShelfSceneHacksPickerTabEnum } from '../enums/shelf-scenes-hacks-picker-tab.enum';
import { Scene } from '../interfaces/scenes-scene.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';
import { ExtendedShoppingCartItem } from '../interfaces/shopping-cart-item.interface';

// default shelf: 2x2 white kallax
export const DEFAULT_SHELF_ID = environment.shelfId;

export interface ShelfDesignerState {
  currentShelf: ShelfScenes;
  selectedSlot: SlotScenes;
  shelfLoading: boolean;
  colorsLoading: boolean;
  slotLoading: boolean;
  shelfDataLoadingCounter: number;
  changingShelf: boolean;
  cloningDesignInProgress: boolean;
  introductionAnimationVisible: boolean;
  lastModalPicker: PickerTypes | null; // TODO: refactor later (should not be held in state)
  bottomPickerVisible: boolean;
  splitMode: boolean;
  currentSceneIndex: number;
  shelvesScenesHacksPickerCurrentTab: ShelfSceneHacksPickerTabEnum;
  isShelfPickerOpen: boolean;
  isScenesPickerOpen: boolean;
  isHacksPickerOpen: boolean;
  addedFirstItem: boolean;
}

export const initialState: ShelfDesignerState = {
  currentShelf: null,
  selectedSlot: null,
  shelfLoading: false,
  colorsLoading: false,
  slotLoading: false,
  shelfDataLoadingCounter: 0,
  changingShelf: false,
  cloningDesignInProgress: false,
  introductionAnimationVisible: false,
  lastModalPicker: null,
  bottomPickerVisible: true,
  splitMode: false,
  currentSceneIndex: 0,
  shelvesScenesHacksPickerCurrentTab: ShelfSceneHacksPickerTabEnum.SCENE,
  isShelfPickerOpen: false,
  isScenesPickerOpen: false,
  isHacksPickerOpen: false,
  addedFirstItem: false,
};

export const shelfDesignerReducer = createReducer(
  initialState,
  on(loadMyDesignSucceededAction, (state, action) => {
    let currentShelf = { ...action.shelf };
    action.extendedShoppingCartItems.forEach((shoppingCartItem) => {
      shoppingCartItem.slotnumber.forEach((slotnumber) => {
        if (slotnumber < currentShelf.scenes[state.currentSceneIndex].slots.length) {
          for (let scene of currentShelf.scenes) {
            let slot = scene.slots[slotnumber];
            if (slot) {
              slot.slotImage = shoppingCartItem.item?.imageFrontalPath;
              slot.itemId = shoppingCartItem.item?.id;
            }
          }
        } else {
          console.error(
            `Couldn't set image of slot ${slotnumber}. There are only ${
              currentShelf.scenes[state.currentSceneIndex].slots.length
            } available`
          );
        }
      });
    });

    return {
      ...state,
      currentShelf,
      currentSceneIndex: action.sceneIndex,
    };
  }),
  on(resetSavedConfigStatusAction, (state) => {
    return { ...state, shelfDataLoadingCounter: 0 };
  }),
  on(ShelfItemActions.setSelectedSlotAction, (state, action) => {
    return { ...state, selectedSlot: action.slot };
  }),
  on(resetShelfDataAction, (state) => {
    return { ...state, currentShelf: null };
  }),
  on(chooseItemAction, (state, action) => {
    const currentShelfNew = JSON.parse(JSON.stringify(state.currentShelf));
    let slotNumber = getCurrentSlotIndex(null, state);

    for (let i: number = 0; i < state.currentShelf.scenes.length; i++) {
      if (state.currentShelf.scenes[i].slots) {
        const slots = state.currentShelf.scenes[i].slots.slice();
        const slot: SlotScenes = {
          ...slots[slotNumber],
        };
        // console.log('>>> slot', slot);
        slot.slotImage = `${action.item.imageFrontalPath}`;
        slot.itemId = action.item.id;
        slots[slotNumber] = slot;

        currentShelfNew.scenes[i].slots = slots;
      }
    }

    return { ...state, currentShelf: currentShelfNew, lastModalPicker: PickerTypes.Item, addedFirstItem: true };
  }),
  on(removeItemGroupSucceededAction, (state, action) => {
    // remove no longer existing shoppingCartItems from designer
    const slots = state.currentShelf.scenes[state.currentSceneIndex].slots.slice();
    slots
      .filter((slot) => slot.itemId != null)
      .forEach((slot: SlotScenes) => {
        if (action.shoppingCartItems.findIndex((cartItem: ExtendedShoppingCartItem) => slot.itemId === cartItem.item.id) === -1) {
          const newSlot: SlotScenes = { ...slot };

          newSlot.slotImage = null;
          delete newSlot.itemId;

          slots[slot.id] = newSlot;
        }
      });

    const currentShelfNew = { ...state.currentShelf } as ShelfScenes;
    currentShelfNew.scenes[0].slots = slots;

    return { ...state, currentShelf: currentShelfNew, lastModalPicker: PickerTypes.Item };
  }),
  on(removeItemSucceededAction, (state, action) => {
    // clone shelf
    const currentShelfNew = { ...state.currentShelf } as ShelfScenes;

    // remove no longer existing shoppingCartItems from designer
    state.currentShelf.scenes.forEach((scene: Scene, sceneIndex: number) => {
      const slots = scene.slots.slice();
      slots
        .filter((slot: SlotScenes) => slot.itemId != null)
        .forEach((slot: SlotScenes) => {
          if (action.shoppingCartItems.length > 0) {
            action.shoppingCartItems.forEach((cartItem: ExtendedShoppingCartItem) => {
              if (
                action.shoppingCartItems.findIndex((cartItem: ExtendedShoppingCartItem) => slot.itemId === cartItem.item.id) === -1 ||
                (slot.itemId === cartItem.item.id && !cartItem.slotnumber.includes(slot.id))
              ) {
                const newSlot: SlotScenes = { ...slot };

                newSlot.slotImage = null;
                delete newSlot.itemId;

                slots[slot.id] = newSlot;
              }
            });
          } else {
            const newSlot: SlotScenes = { ...slot };

            newSlot.slotImage = null;
            delete newSlot.itemId;

            slots[slot.id] = newSlot;
          }
        });

      // assign new slots
      currentShelfNew.scenes[sceneIndex].slots = slots;
    });

    return { ...state, currentShelf: currentShelfNew, lastModalPicker: PickerTypes.Item };
  }),
  on(chooseShelfAction, (state: ShelfDesignerState) => {
    return { ...state, shelfLoading: true, lastModalPicker: PickerTypes.ShelfType, currentSceneIndex: 0 };
  }),
  on(chooseShelfColorAction, (state) => {
    return { ...state, shelfLoading: true, lastModalPicker: PickerTypes.ShelfColor };
  }),
  on(chooseShelfSucceededAction, (state, action) => {
    const noActivesScenes = action.shelf.scenes.filter((scene) => scene.active).length;

    return {
      ...state,
      currentShelf: action.shelf,
      shelfDataLoadingCounter: state.shelfDataLoadingCounter + 1,
      changingShelf: false,
      currentSceneIndex: 0, // reset scene to default scene
      shelvesScenesHacksPickerCurrentTab:
        noActivesScenes > 1 ? ShelfSceneHacksPickerTabEnum.SCENE : state.shelvesScenesHacksPickerCurrentTab,
    };
  }),
  on(shelfImageLoadedAction, (state, action) => {
    return {
      ...state,
      shelfLoading: false,
    };
  }),
  on(chooseShelfColorSucceededAction, (state, action) => {
    return {
      ...state,
      currentShelf: action.shelf,
      shelfLoading: false,
      shelfDataLoadingCounter: state.shelfDataLoadingCounter + 1,
      changingShelf: false,
    };
  }),
  on(chooseShelfFailedAction, (state: ShelfDesignerState) => {
    return { ...state, shelfLoading: false, changingShelf: false };
  }),
  on(enterShelfPickerAction, (state: ShelfDesignerState) => {
    return { ...state, changingShelf: true };
  }),
  on(leaveShelfPickerAction, (state: ShelfDesignerState) => {
    return { ...state, changingShelf: false };
  }),
  on(enterItemPickerAction, (state: ShelfDesignerState, action) => {
    return { ...state, selectedSlot: action.slot };
  }),
  on(cloneDesignAction, (state: ShelfDesignerState) => {
    return { ...state, cloningDesignInProgress: true };
  }),
  on(cloneDesignSucceededAction, (state: ShelfDesignerState) => {
    return { ...state, cloningDesignInProgress: false };
  }),
  on(cloneDesignFailedAction, (state: ShelfDesignerState) => {
    return { ...state, cloningDesignInProgress: false };
  }),
  on(showIntroductionAnimationAction, (state: ShelfDesignerState) => {
    return { ...state, introductionAnimationVisible: true };
  }),
  on(hideIntroductionAnimationAction, (state: ShelfDesignerState) => {
    return { ...state, introductionAnimationVisible: false };
  }),
  on(leaveItemPickerAction, (state: ShelfDesignerState) => {
    return { ...state, lastModalPicker: PickerTypes.Item };
  }),
  on(showBottomPickerAction, (state: ShelfDesignerState) => {
    return { ...state, bottomPickerVisible: true };
  }),
  on(hideBottomPickerAction, (state: ShelfDesignerState) => {
    return { ...state, bottomPickerVisible: false };
  }),
  on(chooseShelfSceneAction, (state: ShelfDesignerState, action) => {
    return { ...state, currentSceneIndex: action.sceneIndex };
  }),
  on(chooseTabAction, (state: ShelfDesignerState, action) => {
    return { ...state, shelvesScenesHacksPickerCurrentTab: action.tab };
  }),
  on(toggleShelfPickerDesktopAction, (state: ShelfDesignerState) => {
    return { ...state, isShelfPickerOpen: !state.isShelfPickerOpen };
  }),
  on(toggleScenePickerDesktopAction, (state: ShelfDesignerState) => {
    return { ...state, isScenesPickerOpen: !state.isScenesPickerOpen };
  }),
  on(toggleHacksPickerDesktopAction, (state: ShelfDesignerState) => {
    return { ...state, isHacksPickerOpen: !state.isHacksPickerOpen };
  }),
  on(setShelfScenesHacksPickerSizeMobileAction, (state: ShelfDesignerState, action) => {
    return { ...state, isShelfPickerOpen: action.open, isScenesPickerOpen: action.open, isHacksPickerOpen: action.open };
  })
);

function getCurrentSlotIndex(slot: SlotScenes, state: ShelfDesignerState) {
  let slotNumber = slot?.id;
  if (!slotNumber) {
    slotNumber = state.selectedSlot?.id;
  }
  return slotNumber;
}

export function reducer(state: ShelfDesignerState = initialState, action: Action): ShelfDesignerState {
  return shelfDesignerReducer(state, action);
}

export const shelfDesignerState = createFeatureSelector<ShelfDesignerState>('shelfdesigner');
export const getCurrentShelf = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.currentShelf);
export const isShelfLoading = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.shelfLoading);
export const isColorsLoading = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.colorsLoading);
export const isSlotLoading = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.slotLoading);
export const getShelfDataLoadingCounter = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.shelfDataLoadingCounter);
export const isChangingShelfType = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.changingShelf);
export const isCloningDesign = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.cloningDesignInProgress);
export const isIntroductionAnimationVisible = createSelector(
  shelfDesignerState,
  (state: ShelfDesignerState) => state.introductionAnimationVisible
);
export const getLastModalPicker = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.lastModalPicker);
export const isBottomPickerVisible = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.bottomPickerVisible);
export const getCurrentSceneIndex = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.currentSceneIndex);
export const getShelvesScenesHacksPickerCurrentTab = createSelector(
  shelfDesignerState,
  (state: ShelfDesignerState) => state.shelvesScenesHacksPickerCurrentTab
);
export const isShelfPickerOpen = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.isShelfPickerOpen);
export const isHacksPickerOpen = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.isHacksPickerOpen);
export const isScenesPickerOpen = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.isScenesPickerOpen);
export const isAddedFirstItem = createSelector(shelfDesignerState, (state: ShelfDesignerState) => state.addedFirstItem);
