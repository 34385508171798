/**
 * This code has been published by hovado on StackOverflow.
 *
 * https://stackoverflow.com/a/51460047/2764486
 */
import { formatDate } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';

@Pipe({
  name: 'firestoreDate',
})
export class FirestoreDatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(timestamp: Timestamp, format?: string): string {
    if (!timestamp?.toDate) {
      return;
    }
    return formatDate(timestamp.toDate(), format || 'medium', this.locale);
  }
}
