import { registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AnalyticsModule } from '@angular/fire/analytics';
import { provideFirebaseApp } from '@angular/fire/app';
import { browserLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IonicModule } from '@ionic/angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { getApp, initializeApp } from 'firebase/app';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { environment } from '../environments/environment';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CardPickerTestComponent } from './card-picker-test/card-picker-test.component';
import { CoreUIModule } from './core-ui/core-ui.module';
import { CoreModule } from './core/core.module';
import { AdminEffects } from './core/effects/admin.effects';
import { AuthenticationEffects } from './core/effects/authentication.effects';
import { CheckoutEffects } from './core/effects/checkout.effects';
import { GeneralEffects } from './core/effects/general.effects';
import { HacksPickerEffects } from './core/effects/hacks-picker.effects';
import { MyDesignsEffects } from './core/effects/my-designs.effects';
import { ProductDetailsEffects } from './core/effects/product-details.effects';
import { ShelfConfiguratorEffects } from './core/effects/shelf-designer.effects';
import { ShelfItemPickerEffects } from './core/effects/shelf-item-picker.effects';
import { ShelfitemEffects } from './core/effects/shelf-item.effects';
import { ShelfpickerEffects } from './core/effects/shelf-picker.effects';
import { ShoppingcartEffects } from './core/effects/shoppingcart.effects';
import { LocaleProvider } from './core/providers/locale-provider';
import * as adminState from './core/reducers/admin.reducer';
import * as authenticationState from './core/reducers/authentication.reducer';
import * as generalState from './core/reducers/general.reducer';
import * as hacksState from './core/reducers/hacks-picker.reducer';
import { metaReducers } from './core/reducers/meta.reducer';
import * as myDesignsState from './core/reducers/my-designs.reducer';
import * as productDetailsState from './core/reducers/product-details.reducer';
import * as shelfDesignerState from './core/reducers/shelf-designer.reducer';
import * as shelfItemPickerState from './core/reducers/shelf-item-picker.reducer';
import * as shelfItemState from './core/reducers/shelf-item.reducer';
import * as shelfPickerState from './core/reducers/shelf-picker.reducer';
import * as shoppingCartState from './core/reducers/shoppingcart.reducer';
import { AppInitializerService } from './core/services/app-initializer.service';
import { LandingPageComponent } from './landingpage/landing-page/landing-page.component';
import { LoginModule } from './login/login.module';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { MyDesignsPickerComponent } from './my-designs-picker/my-designs-picker.component';
import { MyDesignComponent } from './my-designs/my-design/my-design.component';
import { MyDesignsComponent } from './my-designs/my-designs.component';
import { ProductDetailContainerComponent } from './product-detail-container/product-detail-container.component';
import { ProductListComponent } from './product-list/product-list/product-list.component';
import { CardButtonsComponent } from './shared/card-picker/card-buttons/card-buttons.component';
import { CardImagesComponent } from './shared/card-picker/card-images/card-images.component';
import { CardLabelsComponent } from './shared/card-picker/card-labels/card-labels.component';
import { CardPickerComponent } from './shared/card-picker/card-picker.component';
import { CardComponent } from './shared/card-picker/card/card.component';
import { ModalNavContainerComponent } from './shared/modal-nav-container/modal-nav-container.component';
import { ProductDetailsComponent } from './shared/product-details/product-details.component';
import { ProductFilterComponent } from './shared/product-filter/product-filter.component';
import { ProductRatingComponent } from './shared/product-rating/product-rating.component';
import { DesignerOptionsBarComponent } from './shelf-designer/designer-options-bar/designer-options-bar.component';
import { ShelfDesignerComponent } from './shelf-designer/shelf-designer-component/shelf-designer.component';
import { ShelfItemPickerItemComponent } from './shelf-designer/shelf-item-picker/shelf-item-picker-item/shelf-item-picker-item.component';
import { ShelfItemPickerComponent } from './shelf-designer/shelf-item-picker/shelf-item-picker.component';
import { ShelfItemComponent } from './shelf-designer/shelf-item/shelf-item.component';
import { ShelvesScenesHacksPickerModule } from './shelf-designer/shelves-scenes-hacks-picker/shelves-scenes-hacks-picker.module';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AmazonLinkerComponent } from './stuff/amazon-linker/amazon-linker.component';
import { TranslocoRootModule } from './transloco/transloco-root.module';

export function initApp(appInitializerService: AppInitializerService) {
  return () => appInitializerService.initApp$().toPromise();
}

import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import { SocialShareButtonsComponent } from './shelf-designer/social-share-buttons/social-share-buttons.component';
import { NgLetModule } from 'ng-let';
registerLocaleData(localeDe, 'de');
registerLocaleData(localeEn, 'en');
@NgModule({
  declarations: [
    AppComponent,
    ShelfDesignerComponent,
    ShelfItemComponent,
    ShelfItemPickerComponent,
    AppMenuComponent,
    ProductListComponent,
    MyDesignsComponent,
    ShelfItemPickerItemComponent,
    MyDesignsPickerComponent,
    LandingPageComponent,
    ProductDetailsComponent,
    ProductRatingComponent,
    ModalNavContainerComponent,
    ProductRatingComponent,
    MyDesignComponent,
    ProductFilterComponent,
    ProductDetailContainerComponent,
    AmazonLinkerComponent,
    DesignerOptionsBarComponent,
    CardPickerComponent,
    CardComponent,
    MaintenancePageComponent,
    SidebarComponent,
    CardPickerTestComponent,
    CardButtonsComponent,
    CardLabelsComponent,
    CardImagesComponent,
    SocialShareButtonsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    VirtualScrollerModule,
    NgLetModule,

    CoreModule,
    CoreUIModule,

    IonicModule.forRoot({ mode: 'ios' }),

    LoginModule,
    ShelvesScenesHacksPickerModule,

    // TODO: use it for deploy:release
    provideFirebaseApp(() => initializeApp(environment.firebase)),

    // TODO: use it if you develop on dev
    // provideFirebaseApp(() => initializeApp(environment.firebaseDev)),

    // TODO: use it for mirating data from origin / live to dev
    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideFirebaseApp(() => initializeApp(environment.firebaseDev, 'dev')),
    provideAuth(() => {
      const auth = initializeAuth(getApp(), {
        persistence: browserLocalPersistence,
        // popupRedirectResolver: browserPopupRedirectResolver,
        popupRedirectResolver: null,
      });
      //connectAuthEmulatorInDevMode(auth);

      return auth;
    }),
    provideFirestore(() => {
      const firestore = getFirestore();
      // connectEmulator(firestore, 'localhost', 8080);
      // enableIndexedDbPersistence(firestore);
      return firestore;
    }),
    provideStorage(() => {
      const storage = getStorage();
      return storage;
    }),

    AnalyticsModule,

    EffectsModule.forRoot([
      ShelfpickerEffects,
      ShelfConfiguratorEffects,
      ShelfItemPickerEffects,
      HacksPickerEffects,
      ShoppingcartEffects,
      ShelfitemEffects,
      CheckoutEffects,
      GeneralEffects,
      AdminEffects,
      AuthenticationEffects,
      MyDesignsEffects,
      ProductDetailsEffects,
    ]),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
        metaReducers,
      }
    ),
    StoreModule.forFeature('admin', adminState.reducer),
    StoreModule.forFeature('authentication', authenticationState.reducer),
    StoreModule.forFeature('general', generalState.reducer),
    StoreModule.forFeature('mydesigns', myDesignsState.reducer),
    StoreModule.forFeature('shelfdesigner', shelfDesignerState.reducer),
    StoreModule.forFeature('shelfitempicker', shelfItemPickerState.reducer),
    StoreModule.forFeature('shelfItem', shelfItemState.reducer),
    StoreModule.forFeature('shelfpicker', shelfPickerState.reducer),
    StoreModule.forFeature('shoppingcart', shoppingCartState.reducer),
    StoreModule.forFeature('hacks-picker', hacksState.reducer),
    StoreModule.forFeature('product-details', productDetailsState.reducer),

    // !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 250, serialize: true }) : [],
    StoreDevtoolsModule.instrument({ maxAge: 250, serialize: true }),

    TranslocoRootModule,
    VirtualScrollerModule,
    ShelvesScenesHacksPickerModule,
  ],
  providers: [
    LocaleProvider,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppInitializerService],
      multi: true,
    },
    //   { provide: PERSISTENCE, useValue: 'local' },
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
