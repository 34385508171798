import { ChangeDetectionStrategy, Component, } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { shareAction, shareOnFacebookAction, shareOnPinterestAction, shareOnTwitterAction } from '../../core/actions/general.actions';
import { isDesktopPlatform } from '../../core/reducers/general.reducer';

@Component({
  selector: 'app-social-share-buttons',
  templateUrl: './social-share-buttons.component.html',
  styleUrls: ['./social-share-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialShareButtonsComponent {
  desktopPlatform$: Observable<boolean> = this.store.select(isDesktopPlatform);

  constructor(private store: Store) {}

  handleShare() {
    this.store.dispatch(shareAction());
  }

  handleShareOnPinterest() {
    this.store.dispatch(shareOnPinterestAction());
  }

  handleShareOnTwitter() {
    this.store.dispatch(shareOnTwitterAction());
  }

  handleShareOnFacebook() {
    this.store.dispatch(shareOnFacebookAction());
  }
}
