<ion-header>
  <ion-toolbar>
    <ion-title>{{ 'my-designs.title' | transloco }}</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="handleClose()">{{ 'general.close' | transloco }}</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <div class="root">
    <!--<div>UserID: {{ userId }}</div>//-->
    <ng-container [ngSwitch]="loadingMyDesigns$ | async">
      <ion-spinner *ngSwitchCase="true"></ion-spinner>

      <ion-list lines="full" *ngSwitchCase="false">
        <ion-item class="item" *ngFor="let myDesign of myDesigns$ | async" (click)="handleLoadDesign(myDesign)">
          <ion-thumbnail slot="start">
            <img height="100%" src="{{myDesign.thumbnailUrl}}" />
          </ion-thumbnail>
          <ion-label>
            <h2 class="name">{{ myDesign.date | firestoreDate}}</h2>
            <!--<p>ID: {{ myDesign.id }}, Items: {{ myDesign.shoppingCartItems?.length }}</p>//-->
            <h3>{{'my-designs.items' | transloco }}: {{ myDesign.shoppingCartItems?.length }}</h3>
            <!--
          <h3 class="description">{{ item.item.articleNumber }}</h3>
          <div class="price" [innerHTML]="item.item.price | currencyFormat: 'EUR':'de'"></div>
          -->
          </ion-label>


          <!--
          <ion-button fill="clear" size="default" class="remove-item-button" (click)="handleRemoveDesign(myDesign)">
            <ion-icon icon="close-circle-outline"></ion-icon>
          </ion-button>
          //-->

          <ion-button slot="end" fill="outline" class="open-design-button">
            {{'my-designs.openDesignButton' |transloco}}
          </ion-button>


        </ion-item>
      </ion-list>
    </ng-container>
  </div>
</ion-content>