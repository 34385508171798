import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { AnonymousLoginComponent } from './anonymous-login/anonymous-login.component';
import { NgLetModule } from 'ng-let';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  declarations: [SignInComponent, SignUpComponent, ForgotPasswordComponent, VerifyEmailComponent, AnonymousLoginComponent],
  imports: [CommonModule, RouterModule, FormsModule, IonicModule, NgLetModule, TranslocoModule],
})
export class LoginModule {}
