<div class="displayTable">
  <div class="displayTableCell">
    <div class="authBlock">
      <h3>{{ 'general.login.register.title' | transloco }}</h3>

      <div class="formGroup" *ngLet="authService.userData as user">
        <p class="text-center">
          {{ 'general.login.register.send-confirm' | transloco }} <strong>{{ user.email }}</strong>.
        </p>
        <p class="text-center">
          {{ 'general.login.register.please-check' | transloco }}
        </p>
      </div>

      <!-- Calling SendVerificationMail() method using authService Api -->
      <div class="formGroup">
        <button type="button" class="btn btnPrimary" (click)="authService.sendVerificationMail()">
          <ion-icon name="arrow-redo-circle-outline"></ion-icon>
          {{ 'general.login.register.verification-resend' | transloco }}
        </button>
      </div>
    </div>

    <div class="redirectToLogin">
      <span>
        {{ 'general.login.register.back' | transloco }}
        <span class="redirect" routerLink="/sign-in"> {{ 'general.login.register.sign-in' | transloco }}</span>
      </span>
    </div>
  </div>
</div>
