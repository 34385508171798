<div class="container" id="container" [ngClass]="{ 'right-panel-active': rightPanelActive }">
  <!-- Reset password -->
  <div class="form-container forgot-password-container">
    <form name="signUpForm" action="#" onSubmit="return false;">
      <h1>
        {{ 'general.login.pwd-reset.title' | transloco }}
      </h1>
      <!--
      <div class="social-container">
        <a href="#" class="social"><ion-icon name="logo-facebook"></ion-icon></a>
        <a href="#" class="social"><ion-icon name="logo-google"></ion-icon></a>
        <a href="#" class="social"><ion-icon name="logo-apple"></ion-icon></a>
        <a href="#" class="social"><ion-icon name="logo-twitter"></ion-icon></a>
        <a href="#" class="social"><ion-icon name="logo-linkedin"></ion-icon></a>
      </div>
      //-->
      <span>
        {{ 'general.login.general.or-use-mail' | transloco }}
      </span>
      <!--<input type="text" placeholder="Name" />//-->
      <input type="email" [placeholder]="'general.login.general.placeholder.mail' | transloco" #userEmail required />
      <input type="password" [placeholder]="'general.login.general.placeholder.pwd' | transloco" #userPassword
        required />
      <button (click)="handleSignUp(userEmail, userPassword)">
        {{ 'general.login.general.sign-up' | transloco }}
      </button>
    </form>
  </div>

  <!-- Create account -->
  <div class="form-container sign-up-container">
    <form name="signUpForm" action="#" onSubmit="return false;">
      <h1>
        {{ 'general.login.create-account.title' | transloco }}
      </h1>
      <!--
      <div class="social-container">
        <a href="#" class="social"><ion-icon name="logo-facebook"></ion-icon></a>
        <a href="#" class="social"><ion-icon name="logo-google"></ion-icon></a>
        <a href="#" class="social"><ion-icon name="logo-apple"></ion-icon></a>
        <a href="#" class="social"><ion-icon name="logo-twitter"></ion-icon></a>
        <a href="#" class="social"><ion-icon name="logo-linkedin"></ion-icon></a>
      </div>
      -->
      <span>
        {{ 'general.login.general.or-use-mail' | transloco }}
      </span>
      <!--<input type="text" placeholder="Name" />//-->
      <input type="email" [placeholder]="'general.login.general.placeholder.mail' | transloco" #userEmail required />
      <input type="password" [placeholder]="'general.login.general.placeholder.pwd' | transloco" #userPassword
        required />
      <button (click)="handleSignUp(userEmail, userPassword)">
        {{ 'general.login.general.sign-up' | transloco }}
      </button>
    </form>
  </div>

  <!-- Login-tile -->
  <div class="form-container sign-in-container">
    <form name="signInForm" #signInForm="ngForm">
      <h1>
        {{ 'general.login.sign-in.title' | transloco }}
      </h1>
      <!--
      <div class="social-container">
        <div class="social" (click)="handleSignInWithFacebook()"><ion-icon name="logo-facebook"></ion-icon></div>
        <div class="social" (click)="handleSignInWithGoogle()"><ion-icon name="logo-google"></ion-icon></div>
        <div class="social" (click)="handleSignInWithApple()"><ion-icon name="logo-apple"></ion-icon></div>
        <div class="social" (click)="handleSignInWithTwitter()"><ion-icon name="logo-twitter"></ion-icon></div>
        <div class="social" (click)="handleSignInWithLinkedIn()"><ion-icon name="logo-linkedin"></ion-icon></div>
      </div>
      <span>or use your account</span>
      -->
      <input type="email" id="name" #name="ngModel" [(ngModel)]="signInModel.userName" name="userName"
        [placeholder]="'general.login.general.placeholder.mail' | transloco" required />
      <input type="password" id="password" #userPassword="ngModel" [(ngModel)]="signInModel.userPassword"
        name="userPassword" [placeholder]="'general.login.general.placeholder.pwd' | transloco" required />
      <a routerLink="/forgot-password">
        {{ 'general.login.sign-in.forgot-pwd' | transloco }}
      </a>
      <!--
      <app-button
        (buttonClick)="onSubmit()"
        cssClass="signInButton"
        type="submit"
        [highlight]="false"
        text="Sign In"
        [progress]="signingIn"
      ></app-button>
      //-->
      <button (click)="onSubmit()" class="signInButton" type="submit">
        {{ 'general.login.general.sign-in' | transloco }}
      </button>
    </form>
  </div>

  <!-- Overlay pages (Left & Right sides) -->
  <div class="overlay-container">
    <div class="overlay">
      <div class="overlay-panel overlay-left">
        <h1>
          {{ 'general.login.overlay.wellcome-back.title' | transloco }}
        </h1>
        <p>
          {{ 'general.login.overlay.wellcome-back.message' | transloco }}
        </p>
        <button class="ghost" (click)="handleSignIn()">
          {{ 'general.login.general.sign-in' | transloco }}
        </button>
      </div>
      <div class="overlay-panel overlay-right">
        <h1>
          {{ 'general.login.overlay.hi.title' | transloco }}
        </h1>
        <p>
          {{ 'general.login.overlay.hi.message' | transloco }}
        </p>
        <button class="ghost" (click)="handleShowSignUp()">
          {{ 'general.login.general.sign-up' | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>
