import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { delay, filter, from, map, Observable, of, switchMap, tap } from 'rxjs';
import { ExtendedDesign } from './../interfaces/design.interface';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  constructor(private transloco: TranslocoService, private route: ActivatedRoute, private modalController: ModalController) {}

  init$(): Observable<void> {
    // return of(null).pipe(switchMap(() => this.authService.initAuth$()));

    return of(null).pipe(tap(() => {}));
  }

  shareOnPinterest$(design: ExtendedDesign): Observable<any> {
    return of(null).pipe(
      delay(1),
      tap(() => {
        const url = encodeURIComponent(window.location.href);
        const description = encodeURIComponent('Awesome Kallax design by Shelfy');
        const media = encodeURIComponent(design.thumbnailUrl);
        const shareUrl = `https://pinterest.com/pin/create/button/?url=${url}&description=${description}&media=${media}`;
        window.open(shareUrl, '_blank');
      })
    );
  }

  shareOnTwitter$(design: ExtendedDesign): Observable<any> {
    return of(null).pipe(
      delay(1),
      tap(() => {
        const url = encodeURIComponent(this.getSharingLink());
        const description = encodeURIComponent(this.getSharingDescription() + ' ' + design.thumbnailUrl);
        const shareUrl = `https://twitter.com/share?text=${description}&url=${url}`;
        window.open(shareUrl, '_blank');
      })
    );
  }

  shareOnFacebook$(design: ExtendedDesign): Observable<any> {
    return of(null).pipe(
      delay(1),
      tap(() => {
        const url = encodeURIComponent(this.getSharingLink());
        const description = encodeURIComponent(this.getSharingDescription() + ' ' + design.thumbnailUrl);
        const shareUrl = `https://www.facebook.com/sharer.php?u=${url}&p[title]=${description}`;
        window.open(shareUrl, '_blank');
      })
    );
  }

  private getSharingTitle(): string {
    return 'Shelfy Design';
  }

  private getSharingLink(): string {
    return window.location.href.replace('capacitor://localhost/', 'https://app.hello-shelfy.com/');
  }

  private getSharingDescription(): string {
    return this.transloco.translate('general.sharing.share');
  }

  public share$(design: ExtendedDesign): Observable<any> {
    let sharingUrl = this.getSharingLink();

    return of(null).pipe(
      switchMap(() => fetch(design.thumbnailUrl)),
      switchMap((response) => response.blob()),
      map((blob: Blob) => {
        return [
          new File([blob], 'thumbnail.png', {
            type: 'image/png',
            lastModified: new Date().getTime(),
          }),
        ];
      }),
      switchMap((files) =>
        navigator.share({
          title: this.getSharingTitle(),
          url: sharingUrl,
          text: this.getSharingDescription(),
          files,
        })
      )
      /*
      switchMap(() =>
        from(
          Share.share({
            title: 'Shelfy',
            text: this.transloco.translate('general.sharing.share'),
            url: sharingUrl,
            // files?: string[];
            dialogTitle: 'Shelfy',
          })
        ).pipe(
          catchError((error) => {
            if (error.toString().includes('AbortError')) {
            } else {
              const elem = document.createElement('textarea');
              elem.value = sharingUrl;
              document.body.appendChild(elem);
              elem.select();
              document.execCommand('copy');
              document.body.removeChild(elem);
              alert(`${this.transloco.translate('general.sharing.error')}`);
            }
            console.error(error);
            return of(null);
          })
        )
      )
      */
    );
  }

  closeModal$(): Observable<boolean> {
    return from(this.modalController.getTop()).pipe(
      filter((top) => top != null),
      switchMap((top: HTMLIonModalElement) => from(top.dismiss()))
    );
  }
}
