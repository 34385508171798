import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, Platform } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Timestamp } from 'firebase/firestore';
import { combineLatest, iif, of } from 'rxjs';
import { catchError, delay, filter, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { AuthService } from '../../login/services/auth.service';
import { hideAppMenuAction, openReviewPopupAction } from '../actions/general.actions';
import { loadChooseHacksItems } from '../actions/hacks-picker.actions';
import {
  chooseShelfAction,
  chooseShelfColorAction,
  chooseShelfColorSucceededAction,
  chooseShelfSceneAction,
  chooseShelfSucceededAction,
  cloneDesignAction,
  cloneDesignFailedAction,
  cloneDesignSucceededAction,
  createNewDesignAction,
  createNewDesignByUserAction,
  createNewDesignSucceededAction,
  editCurrentShelfAction,
  editItemAction,
  enterItemPickerAction,
  enterShelfColorPickerAction,
  enterShelfColorPickerSucceededAction,
  enterShelfPickerAction,
  enterShelfPickerFailedAction,
  enterShelfPickerSucceededAction,
  hideIntroductionAnimationAction,
  hideMyDesignsAction,
  leaveItemPickerAction,
  leaveShelfPickerAction,
  loadLastModifiedDesignFailedAction,
  loadLastModifiedDesignOrCreateNewDesignAction,
  loadLastModifiedDesignSucceededAction,
  loadMyDesignAction,
  loadMyDesignFailedAction,
  loadMyDesignSucceededAction,
  loadSettingsAndDesignForUserSucceededAction,
  openDesignCloneDialogAction,
  openEmptyShelfDesignerAction,
  saveDesignSucceededAction,
  shelfImageLoadedAction,
  showIntroductionAnimationAction,
  showMyDesignAction,
} from '../actions/shelf-designer.actions';
import { removeItemAction, shoppingcardLoadSavedConfSucceededAction } from '../actions/shopping-cart.actions';
import { AnalyticLogs } from '../enums/analytic-logs.enum';
import { HacksSlotsIdEnum } from '../enums/hacks-types.enum';
import { PickerTypes } from '../enums/picker-types.enum';
import { ProductCategories } from '../enums/product-categories.enum';
import { Design, ExtendedDesign } from '../interfaces/design.interface';
import { Scene } from '../interfaces/scenes-scene.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { ExtendedShoppingCartItem, ProductListProduct, ShoppingCartItem } from '../interfaces/shopping-cart-item.interface';
import { getCurrentDesign, getCurrentDesignId, getCurrentExtendedShoppingCartItemsAsListOfSingleItems } from '../reducers/my-designs.reducer';
import { DEFAULT_SHELF_ID, isAddedFirstItem, getCurrentSceneIndex, getCurrentShelf } from '../reducers/shelf-designer.reducer';
import { AnalyticsService } from '../services/analytics.service';
import { DesignService } from '../services/design.service';
import { ItemService } from '../services/item.service';
import { SettingsService } from '../services/settings.service';
import { ShelfDesignerService } from '../services/shelf-designer.service';
import { ShelvesService } from '../services/shelf.service';
import { ShoppingcartService } from '../services/shoppingcart.service';
import { showMyDesignsAction } from './../actions/shelf-designer.actions';
import { Item } from '../interfaces/item.interface';
import { isSplitMode } from '../reducers/general.reducer';

@Injectable()
export class ShelfConfiguratorEffects {
  constructor(
    private actions$: Actions,
    private shelvesService: ShelvesService,
    private designService: DesignService,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
    private store: Store,
    private shelfDesignerService: ShelfDesignerService,
    private route: ActivatedRoute,
    private router: Router,
    private itemService: ItemService,
    private alertCtrl: AlertController,
    private transloco: TranslocoService,
    private settingsService: SettingsService,
    private shoppingcartService: ShoppingcartService,
    private platform: Platform
  ) { }
  openDesignCloneDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openDesignCloneDialogAction),
        tap(() => {
          this.alertCtrl
            .create({
              header: this.transloco.translate('shelfdesigner.clone-design.modal.title'),
              message: this.transloco.translate('shelfdesigner.clone-design.modal.message'),
              buttons: [
                {
                  text: this.transloco.translate('shelfdesigner.clone-design.modal.confirm'),
                  handler: () => this.store.dispatch(cloneDesignAction()),
                  cssClass: 'primary-button',
                },
                {
                  text: this.transloco.translate('general.cancel'),
                  role: 'cancel',
                  cssClass: 'secondary-button',
                },
              ],
            })
            .then((alert) => alert.present());
        })
      ),
    { dispatch: false }
  );

  chooseShelf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chooseShelfAction),
      map((action) => ({
        action: action,
        shelfImageLoadedCounter: this.shelfDesignerService.shelfImageLoaded$.getValue(),
      })),
      switchMap((data: ChooseShelfData) =>
        this.shelvesService.getShelf$(data.action.shelfId).pipe(
          map((shelf: ShelfScenes) => {
            data.newShelf = shelf;
            return data;
          })
        )
      ),
      switchMap((data: ChooseShelfData) =>
        this.store.select(getCurrentDesignId).pipe(
          take(1),
          filter((configId: string | null) => configId !== null),
          map((configId: string) => {
            data.currentDesignId = configId;
            return data;
          })
        )
      ),
      switchMap((data: ChooseShelfData) => {
        const fieldsToUpdate = {
          shelfColor: data.newShelf.color,
          shelfId: data.newShelf.id,
          date: Timestamp.fromDate(new Date()),
          shoppingCartItems: [], // TODO: remove again with #227
        } as unknown as Design;
        return this.designService.updateMyDesign$(data.currentDesignId, fieldsToUpdate).pipe(map(() => data));
      }),
      withLatestFrom(this.store.select(getCurrentShelf), this.store.select(getCurrentDesign)),
      mergeMap(([data, currentShelf, currentDesign]: [ChooseShelfData, ShelfScenes, ExtendedDesign]) =>
        iif(
          () => currentShelf != null,
          of(this.shelvesService.transferSelectedItems(currentShelf, data.newShelf)).pipe(
            map((newShelf: ShelfScenes) => [
              newShelf,
              this.shoppingcartService.updateItemQuantityAfterShelfChange(currentDesign.shoppingCartItems, newShelf),
            ]),
            switchMap(([newShelf, newExtendedCartItems]: [ShelfScenes, ExtendedShoppingCartItem[]]) =>
              this.itemService.getShoppingCartItems$(newExtendedCartItems).pipe(
                switchMap((newCartItems: ShoppingCartItem[]) =>
                  this.designService.updateMyDesign$(currentDesign.id, { shoppingCartItems: newCartItems } as Design)
                ),
                map(() => {
                  data.newShelf = newShelf;
                  data.newExtendedCartItems = newExtendedCartItems;
                  return data;
                })
              )
            )
          ),
          of(data)
        )
      ),
      tap((data: ChooseShelfData) =>
        this.shelfDesignerService.shelfImageLoaded$
          .pipe(
            filter((shelfImageLoadedCounterNew) => shelfImageLoadedCounterNew > data.shelfImageLoadedCounter),
            tap(() => this.store.dispatch(shelfImageLoadedAction({ shelf: data.newShelf })))
          )
          .subscribe()
      ),
      switchMap((data: ChooseShelfData) => [
        chooseShelfSucceededAction({ shelf: data.newShelf, newCartItems: data.newExtendedCartItems }),
        leaveShelfPickerAction(),
      ])
    )
  );

  chooseShelfColor$ = createEffect(() =>
    this.actions$.pipe(
      ofType(chooseShelfColorAction),
      switchMap((action) => this.shelvesService.getShelf$(action.shelfId)),
      withLatestFrom(this.store.select(getCurrentShelf)),
      withLatestFrom(this.store.select(getCurrentDesign)),
      switchMap(([[newShelf, currentShelf], currentDesign]) =>
        this.designService
          .updateMyDesign$(currentDesign.id, { shelfId: newShelf.id, shelfColor: newShelf.color } as Design)
          .pipe(map(() => this.shelvesService.transferSelectedItems(currentShelf, newShelf)))
      ),
      map((shelf: ShelfScenes) => chooseShelfColorSucceededAction({ shelf }))
    )
  );

  /*
  TODO: If you want to use this, refactor 'loadSlots$' in 'slot.services.ts'
  loadSlots$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSlotsAction),
      switchMap((action, shelf) => this.slotService.loadSlots$(action.shelf)),
      map((slotData) => loadSlotsSuccessAction({ slotData }))
    )
  );
  */

  saveDesignSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(saveDesignSucceededAction),
        map(() => hideAppMenuAction())
      ),
    { dispatch: true }
  );

  showMyDesign$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showMyDesignAction),
        tap((action) => {
          this.store.dispatch(hideMyDesignsAction());
          this.store.dispatch(hideAppMenuAction());
          // wenn man das hier nimmt, ist das BottomPanel weg :( TODO: fix!
          //this.router.navigate([`/shelfdesigner/${action.designId}`], { fragment: this.route.snapshot.fragment });
          if (this.route.snapshot.fragment) {
            window.location.href = `/shelfdesigner/${action.designId}#${this.route.snapshot.fragment}`;
          }
          else {
            window.location.href = `/shelfdesigner/${action.designId}`;
          }
        })
      ),
    { dispatch: false }
  );

  loadLastModifiedDesignOrCreateNewDesign$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadLastModifiedDesignOrCreateNewDesignAction),
        switchMap((action) => this.designService.loadLastModifiedDesignIfExists$(action.user)),
        map((designId: string | null) => {
          if (designId) {
            return loadLastModifiedDesignSucceededAction({ designId: designId });
          } else {
            return createNewDesignAction();
          }
        }),
        catchError((error, caught) => {
          console.error('Error while loading last modified design: ', error);
          this.store.dispatch(loadLastModifiedDesignFailedAction({ error }));
          return caught;
        })
      ),
    { dispatch: true }
  );

  loadLastModifiedDesignSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadLastModifiedDesignSucceededAction),
        tap((action) => this.router.navigate([`/shelfdesigner/${action.designId}`], { fragment: this.route.snapshot.fragment }))
      ),
    { dispatch: false }
  );

  loadMyDesign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMyDesignAction),

      switchMap((action) => this.designService.loadMyDesign$(action.designId).pipe(map((design: Design) => [design, action.sceneIndex]))),
      // TODO: move to service

      // mergeMap(([design, shelves, currentShelf]: [SavedShelfConfig, Shelf[], Shelf]) =>
      //   iif(
      //     () => shelves?.length === 0 || !currentShelf,
      //     of(design).pipe(tap(() => this.store.dispatch(loadShelfTypesAction({ color: design.shelfColor })))),
      //     of(design)
      //   )
      // ),
      switchMap(([design, sceneIndex]: [Design, number | undefined]) =>
        combineLatest([
          this.itemService.getExtendedSavedConfiguration$(design),
          of(this.authService.getUserId() === design.userId),
          this.shelvesService.getShelf$(design.shelfId).pipe(
            filter((shelf) => shelf !== undefined),
            take(1),
            map((shelf: ShelfScenes) => {
              // clean up slotImages from possible previously loaded config
              const cleanedShelf: ShelfScenes = { ...shelf };
              for (let scene of cleanedShelf.scenes) {
                scene.slots.forEach((slot) => {
                  slot.slotImage = null;
                });
              }

              return cleanedShelf;
            })
          ),
          // extendedShoppingCartItems
          iif(() => design.shoppingCartItems.length > 0, this.itemService.getExtendedShoppingCartItems$(design.shoppingCartItems), of([])),
          of(sceneIndex),
        ])
      ),
      //  tap(([extendedConfig, shelf, extendedShoppingCartItems]) => console.log('>>> extendedConfig', extendedConfig)),
      switchMap(
        ([extendedConfig, currentDesignIsOwnDesign, shelf, extendedShoppingCartItems, sceneIndex]: [
          ExtendedDesign,
          boolean,
          ShelfScenes,
          ExtendedShoppingCartItem[],
          number | undefined
        ]) => {
          // check scene index and reset to 0 if not existent or scene inactive
          let checkedSceneIndex: number = 0;
          const scene: Scene = shelf.scenes.find((scene: Scene, index: number) => index === sceneIndex && scene.active);
          if (scene) {
            checkedSceneIndex = sceneIndex;
          }

          // Add HacksItem in Desing for Buy (Only for UI)
          let hacksItems: string[] = [];
          extendedShoppingCartItems.forEach((item: ExtendedShoppingCartItem) => {
            switch (item.slotnumber[0]) {
              case HacksSlotsIdEnum.Feet:
              case HacksSlotsIdEnum.Plates:
              case HacksSlotsIdEnum.Sticker:
              case HacksSlotsIdEnum.AdditionalHacks:
                hacksItems.push(item.item.id);
                break;
            }
          });

          return [
            loadMyDesignSucceededAction({
              design: extendedConfig,
              currentDesignIsOwnDesign,
              shelf,
              extendedShoppingCartItems: extendedShoppingCartItems,
              sceneIndex: checkedSceneIndex,
            }),
            shoppingcardLoadSavedConfSucceededAction({ config: extendedConfig, shelf }),
            loadChooseHacksItems({ items: hacksItems }),
          ];
        }
      ),
      catchError((error: Error, caught) => {
        console.error('Error while loading my design: ', error);
        this.store.dispatch(loadMyDesignFailedAction({ error }));
        return caught;
      })
    )
  );

  loadMyDesignFailed$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadMyDesignFailedAction),
      tap(() => {
        this.alertCtrl
          .create({
            header: this.transloco.translate('my-designs.error.header'),
            message: this.transloco.translate('my-designs.error.message'),
            buttons: [this.transloco.translate('my-designs.error.to-landing-page')],
          })
          .then((alert) => alert.present());
        this.router.navigate(['/']);
      }),
      map(() => hideMyDesignsAction())
    )
  );

  enterShelfPicker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enterShelfPickerAction),
      switchMap(() => this.shelfDesignerService.showProductPicker$(PickerTypes.ShelfType)),
      switchMap(() => this.shelvesService.getShelves$()),
      map((shelves: ShelfScenes[]) => enterShelfPickerSucceededAction({ shelves })),
      catchError((error, caught) => {
        console.error('Error while entering shelf picker: ', error);
        this.store.dispatch(enterShelfPickerFailedAction());
        return caught;
      })
    )
  );

  enterShelfColorPicker$ = createEffect(() =>
    this.actions$.pipe(
      ofType(enterShelfColorPickerAction),
      switchMap(() => this.shelfDesignerService.showProductPicker$(PickerTypes.ShelfColor)),
      withLatestFrom(this.store.select(getCurrentShelf)),
      switchMap(([picker, currentShelf]: [any, ShelfScenes]) => this.shelvesService.getShelvesForType$(currentShelf.type)),
      map((shelves: ShelfScenes[]) => enterShelfColorPickerSucceededAction({ shelves }))
    )
  );

  selectShelfSlot$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(enterItemPickerAction),
        switchMap(() => this.shelfDesignerService.showProductPicker$(PickerTypes.Item))
      ),
    { dispatch: false }
  );

  leaveItemPicker$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(leaveItemPickerAction),
        switchMap(() => this.shelfDesignerService.hideModalNavCtrl$(ProductCategories.Item)),
        delay(500),
        withLatestFrom(
          this.store.select(getCurrentExtendedShoppingCartItemsAsListOfSingleItems),
          this.store.select(isAddedFirstItem),
        ),
        filter(([action, items, addedFirstItem]: [any, ProductListProduct[], boolean]) => addedFirstItem && items.length >= 2),
        filter(() => this.platform.is('ios') || this.platform.is('android')),
        map(() => openReviewPopupAction())
      ),
    { dispatch: true }
  );

  removeItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(removeItemAction),
        tap(() => this.analyticsService.log(AnalyticLogs.SHELF_ITEM_PICKER_COMPONENT_CLEAR_ITEM)),
        switchMap(() => this.shelfDesignerService.hideModalNavCtrl$(ProductCategories.Item))
      ),
    { dispatch: false }
  );

  createNewDesign$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewDesignAction),
        tap(() => {
          this.analyticsService.log(AnalyticLogs.SHELF_DESIGNER_COMPONENT_CREATE_NEW_DESIGN);
        }),
        switchMap(() => {
          const newEmptyConfig: Design = {
            buyingShelf: false,
            shelfId: DEFAULT_SHELF_ID,
            shoppingCartItems: [],
            userId: this.authService.userData.uid,
            date: Timestamp.fromDate(new Date()),
          };
          return this.designService.saveMyDesign$(newEmptyConfig).pipe(
            map((designId: string) => {
              const newExtendedConfig: ExtendedDesign = {
                ...newEmptyConfig,
                id: designId,
                shoppingCartItems: [],
                shelfColor: newEmptyConfig.shelfColor ?? '',
              };
              return newExtendedConfig;
            })
          );
        }),
        map((newDesign: ExtendedDesign) => createNewDesignSucceededAction({ design: newDesign }))
      ),
    { dispatch: true }
  );

  // TODO: remove code when #422 is finally fixed

  createNewDesignByUserWorkaround$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewDesignByUserAction),
        tap(() => console.log('>>> create new design by user')),
        tap(() => {
          // this.analyticsService.log(AnalyticLogs.SHELF_DESIGNER_COMPONENT_CREATE_NEW_DESIGN);
        }),
        tap(() => {
          // clear localstorage to get rid of old design id
          localStorage.clear();

          // get full URL
          const fullUrl = window.location.href.split('/');
          // remove scene index
          fullUrl.pop();
          // remove design id
          fullUrl.pop();

          // create reload url and trigger it
          const reloadUrl = fullUrl.join('/');
          window.location.href = reloadUrl;
        })
      ),
    { dispatch: false }
  );

  createNewDesignSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(createNewDesignSucceededAction),
        tap((action) => {
          // Be sure that you use a validate URL (base+/id/sceneIndex)
          // this.router.navigateByUrl(`/shelfdesigner/${action.design.id}/${0}`);
          window.location.href = `/shelfdesigner/${action.design.id}/${0}`; // as replacement for the workround createNewDesignByUserWorkaround
        })
      ),
    { dispatch: false }
  );

  openEmptyShelfDesignerAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openEmptyShelfDesignerAction),
      tap(() => this.router.navigate(['/shelfdesigner'], { fragment: this.route.snapshot.fragment })),
      map(() => hideAppMenuAction())
    )
  );

  editCurrentShelf$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editCurrentShelfAction),
        switchMap((action) => of(action).pipe(withLatestFrom(this.store.select(getCurrentShelf)))),
        switchMap(([action, currentShelf]) =>
          this.router.navigate([`/admin/shelves/details/${currentShelf.id}`], { fragment: this.route.snapshot.fragment })
        ),
        map(() => hideAppMenuAction())
      ),
    { dispatch: true }
  );

  loadSettingsAndDesignForUserSucceeded$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadSettingsAndDesignForUserSucceededAction),
      filter((action) => action.settings.firstUse === true),
      map(() => showIntroductionAnimationAction())
    )
  );

  /*
  showIntroductionAlert$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showIntroductionAlertAction),
        switchMap(() => this.transloco.selectTranslation()),
        switchMap(() =>
          from(
            this.alertCtrl.create({
              header: this.transloco.translate('shelfdesigner.introduction.title'),
              subHeader: this.transloco.translate('shelfdesigner.introduction.message'),
              buttons: [
                {
                  text: this.transloco.translate('shelfdesigner.introduction.okay'),
                  cssClass: ['introduction-alert-button', 'continue-button'],
                  handler: () => {
                    this.store.dispatch(closeIntroductionAlertAction());
                  },
                },
              ],
              cssClass: 'introduction-alert',
            })
          )
        ),
        switchMap((alert: HTMLIonAlertElement) => from(alert.present())),
        tap(() => {
          const continueButton: HTMLButtonElement = document.querySelector('.continue-button');
          continueButton.focus();
        })
      ),
    { dispatch: false }
  );
  */

  showIntroductionAlert$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showIntroductionAnimationAction),
        tap(() => { })
      ),
    { dispatch: false }
  );

  hideIntroductionAnimation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hideIntroductionAnimationAction),
        switchMap(() => this.settingsService.unsetFirstUse$()),
        catchError((error, caught) => {
          console.error('Error while unsetting first use flag: ', error);
          return caught;
        })
      ),
    { dispatch: false }
  );

  cloneDesign$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cloneDesignAction),
      withLatestFrom(this.store.select(getCurrentDesignId), this.store.select(getCurrentSceneIndex)),
      switchMap(([action, designId, currentSceneIndex]: [any, string, number]) =>
        this.designService.cloneDesign$(designId).pipe(
          map((newDesignId: string) => ({
            newDesignId: newDesignId,
            currentSceneIndex: currentSceneIndex,
          }))
        )
      ),
      map((data: { newDesignId: string; currentSceneIndex: number }) =>
        cloneDesignSucceededAction({ newDesignId: data.newDesignId, sceneIndex: data.currentSceneIndex })
      ),
      catchError((error, caught) => {
        console.error('Error while cloning design: ', error);
        this.store.dispatch(cloneDesignFailedAction({ error }));
        return caught;
      })
    )
  );

  cloneDesignSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(cloneDesignSucceededAction),
        tap((action) => {
          this.router.navigateByUrl(`/shelfdesigner/${action.newDesignId}/${action.sceneIndex}`);
        })
      ),
    { dispatch: false }
  );

  editItem$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(editItemAction),
        switchMap((action) => this.router.navigate([`/admin/items/details/${action.item.id}`], { fragment: this.route.snapshot.fragment })),
        map(() => leaveItemPickerAction())
      ),
    { dispatch: true }
  );

  /*
  chooseTabOrTogglePickerMode$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chooseTabOrTogglePickerModeAction),
        withLatestFrom(this.store.select(getShelvesScenesHacksPickerCurrentTab)),
        tap(([action, tab]: [any, string]) => {
          if (action.tab == tab) {
            this.shelfDesignerService.handleOpenOrCloseSheet();
          }
        }),
        filter(([action, tab]: [any, string]) => action.tab != tab),
        map(([action, tab]: [any, string]) => chooseShelvesScenesHacksPickerTabAction({ tab: action.tab }))
      ),
    { dispatch: true }
  );
  */

  showMyDesigns$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showMyDesignsAction),
        switchMap(() => this.shelfDesignerService.showMyDesignsPicker$())
      ),
    { dispatch: false }
  );

  hideMyDesigns$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hideMyDesignsAction),
        switchMap(() => this.shelfDesignerService.hideMyDesignsPicker$())
      ),
    { dispatch: false }
  );

  chooseShelfScene$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(chooseShelfSceneAction),
        withLatestFrom(this.store.select(isSplitMode)),
        switchMap(([, splitMode])=>
          this.shelfDesignerService.setBottomSheetPositionToMiddle$(splitMode)
        )
      ),
    { dispatch: false }
  );
}

interface ChooseShelfData {
  newExtendedCartItems: ExtendedShoppingCartItem[];
  currentDesignId: string;
  action: any;
  shelfImageLoadedCounter: number;
  newShelf?: ShelfScenes;
}
