import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { VirtualScrollerComponent } from 'ngx-virtual-scroller';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { BottomPanePositionsEnum } from '../../../core/enums/bottom-pane-positions.enum';
import { environment } from '../../../../environments/environment';
import { setBottomPanelPositionAction } from '../../../core/actions/shelf-picker.actions';
import { PickerTypes } from '../../../core/enums/picker-types.enum';
import { Scene } from '../../../core/interfaces/scenes-scene.interface';
import { ShelfScenes } from '../../../core/interfaces/scenes-shelf.interface';
import { getCurrentSceneIndex, getCurrentShelf, isScenesPickerOpen } from '../../../core/reducers/shelf-designer.reducer';
import { getBottomPanelPosition, isShelvesLoading } from '../../../core/reducers/shelf-picker.reducer';

@Component({
  selector: 'app-shelf-scenes-picker',
  templateUrl: './shelf-scenes-picker.component.html',
  styleUrls: ['./shelf-scenes-picker.component.scss'],
})
export class ShelfScenesPickerComponent implements OnInit, AfterViewInit {
  environment = environment;

  // UI binding
  shelfScenes$: Observable<Scene[]>;
  currentShelf$: Observable<ShelfScenes> = this.store.select(getCurrentShelf);
  isLoadingShelves$: Observable<boolean> = this.store.select(isShelvesLoading);
  bottomPanelPosition$: Observable<string> = this.store.select(getBottomPanelPosition);
  skeletonItems$: BehaviorSubject<ShelfScenes[]> = new BehaviorSubject(this.getSkeletonShelves());
  scrollLeftButtonVisible$ = new BehaviorSubject<boolean>(false);
  scrollRightButtonVisible$ = new BehaviorSubject<boolean>(true);
  isScenesPickerOpen$: Observable<boolean> = this.store.select(isScenesPickerOpen);
  sceneIndex$: Observable<number> = this.store.select(getCurrentSceneIndex);

  // Inputs
  @Input('sidebar') sidebar = true;
  @Input() pickerType: PickerTypes = PickerTypes.ShelfType;

  // References
  @ViewChild('scrollingContainer') scrollingContainer: ElementRef;

  private _virtualScroller: VirtualScrollerComponent;

  @ViewChild('scroll')
  set virtualScroller(virtualScroller: VirtualScrollerComponent) {
    const firstInit = virtualScroller !== undefined && (this._virtualScroller == undefined || this._virtualScroller == null);

    this._virtualScroller = virtualScroller;

    if (firstInit) {
      this.recalculateVirtualScroller();
    }
  }

  get virtualScroller(): VirtualScrollerComponent {
    return this._virtualScroller;
  }

  constructor(private store: Store, private changeDetectorRef: ChangeDetectorRef) {
    // TODO: move to reducer
    this.shelfScenes$ = this.store.select(getCurrentShelf).pipe(
      map((shelfScenes: ShelfScenes) => {
        return shelfScenes?.scenes.map((scene, index: number) => ({ ...scene, index })).filter((scene) => scene.active);
      })
    );
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {}

  handleClose(): void {
    this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.MIDDLE }));
  }

  handleOpen(): void {
    this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.TOP }));
  }

  private recalculateVirtualScroller() {
    if (this.virtualScroller) {
      this.virtualScroller.invalidateAllCachedMeasurements();
    }
  }

  private getSkeletonShelves(): ShelfScenes[] {
    const shelves = new Array<ShelfScenes>();
    for (let i = 0; i < 50; i++) {
      const shelf = { skeleton: true } as ShelfScenes;
      shelves.push(shelf);
    }

    return shelves;
  }

  onVirtualScrollerUpdate(): void {
    this.scrollLeftButtonVisible$.next(this.virtualScroller?.viewPortInfo?.scrollStartPosition > 0);
    this.scrollRightButtonVisible$.next(
      this.virtualScroller?.viewPortInfo?.scrollStartPosition + 10 < this.virtualScroller?.viewPortInfo?.maxScrollPosition
    );
    this.changeDetectorRef.detectChanges();
  }

  handleScrollForward(): void {
    this.virtualScroller.scrollToPosition(this.virtualScroller.viewPortInfo.scrollEndPosition);
  }

  handleScrollBackward(): void {
    this.virtualScroller.scrollToPosition(
      this.virtualScroller.viewPortInfo.scrollStartPosition - this.scrollingContainer.nativeElement.offsetWidth
    );
  }

  debug(value: any): void {
    console.log('>>> DEBUG:', value);
  }
}
