<ion-content>
  <div class="root">
    <div class="content">
      <section class="header">
        <div class="logo"><img src="./assets/imgs/shelfy-logo.png" height="40" /></div>
      </section>

      <section class="hero-image-container">
        <div class="hero-image"></div>
        <div class="mockup"></div>
        <div class="mockup-dreieck"></div>
        <div class="claim">
          <div class="claim-headline" [innerHTML]="'landing-page.claim.headline' | transloco"></div>
          <div class="claim-description" [innerHTML]="'landing-page.claim.description' | transloco"></div>
          <a class="cta" [routerLink]="'shelfdesigner'">{{ 'landing-page.design-now' | transloco }}</a>
        </div>
      </section>

      <section class="facts">
        <div class="fact">
          <ion-icon name="phone-portrait-sharp"></ion-icon>
          <div>{{ 'landing-page.facts.1' | transloco }}</div>
        </div>
        <div class="fact">
          <ion-icon name="basket"></ion-icon>
          <div>{{ 'landing-page.facts.2' | transloco }}</div>
        </div>
        <div class="fact">
          <ion-icon name="heart"></ion-icon>
          <div>{{ 'landing-page.facts.3' | transloco }}</div>
        </div>
        <div class="fact">
          <ion-icon name="hammer"></ion-icon>
          <div>{{ 'landing-page.facts.4' | transloco }}</div>
        </div>
      </section>

      <section class="a-shelf-for-you">
        <div class="block1">
          <h1>{{ 'landing-page.a-shelf-for-you.title' | transloco }}</h1>
          <p>
            {{ 'landing-page.a-shelf-for-you.description' | transloco }}
          </p>
        </div>
        <div class="living-images">
          <img src="../../../assets/imgs/living-scene1.png" class="image1" />
          <div class="image23-block">
            <img src="../../../assets/imgs/living-scene2.png" class="image23" />
            <img src="../../../assets/imgs/living-scene3.png" class="image23" />
          </div>
        </div>
      </section>

      <section class="let-you-inspire">
        <h1>{{ 'landing-page.inspiration.title' | transloco }}</h1>
        <p>{{ 'landing-page.inspiration.description' | transloco }}</p>
        <div class="shelves">
          <div class="sliding-background"></div>
        </div>
      </section>

      <section class="pimp-your-kallax desktop">
        <div class="container">
          <img src="../../../assets/imgs/rooms.png" />
          <div class="content">
            <h1>{{ 'landing-page.pimp-your-kallax.title' | transloco }}</h1>
            <p>{{ 'landing-page.pimp-your-kallax.description' | transloco }}</p>
            <!--<a [href]="'landing-page.pimp-your-kallax.to-blog.link' | transloco" target="_blank">{{
              'landing-page.pimp-your-kallax.to-blog.text' | transloco
            }}</a>
            //-->
          </div>
        </div>
      </section>

      <section class="be-creative">
        <div class="container">
          <div class="content">
            <h1>{{ 'landing-page.be-creative.title' | transloco }}</h1>
            <p>{{ 'landing-page.be-creative.description' | transloco }}</p>
            <img class="app-mockups" src="../../../assets/imgs/shelfy-mockups.png" />
            <a class="cta" [routerLink]="'shelfdesigner'">{{ 'landing-page.design-now' | transloco }}</a>
          </div>
        </div>
      </section>

      <section class="testimonials">
        <img class="testimonial-image" src="../../../assets/imgs/testimonial-jenny.png" />
        <p class="opinion">"{{ 'landing-page.testimonials.1.opinion' | transloco }}"</p>
        <div class="name">{{ 'landing-page.testimonials.1.name' | transloco }}</div>
      </section>

      <section class="platforms">
        <div class="container">
          <h1>{{ 'landing-page.platforms.title' | transloco }}</h1>
          <div class="descriptions">
            <div class="description1">{{ 'landing-page.platforms.description1' | transloco }}</div>
            <div class="description2">{{ 'landing-page.platforms.description2' | transloco }}</div>
          </div>
          <div class="platforms-logos"
            *ngIf="affiliateInformations.ikeaDE !== undefined || affiliateInformations.amazonDE !== undefined || affiliateInformations.pocoDE !== undefined">
            <a *ngIf="affiliateInformations.ikeaDE !== undefined"
              href="{{ affiliateInformations.ikeaDE.productFallbackUrl }}?{{ affiliateInformations.ikeaDE.affiliateId }}"
              target="_blank"><img class="ikea" src="../../../assets/imgs/ikea-logo.png" /></a>
            <a href="https://etsy.com" target="_blank"><img class="etsy" src="../../../assets/imgs/etsy-logo.png" /></a>
            <a *ngIf="affiliateInformations.amazonDE !== undefined"
              href="{{ affiliateInformations.amazonDE.productFallbackUrl }}?tag={{ affiliateInformations.amazonDE.affiliateId }}"
              target="_blank"><img class="amazon" src="../../../assets/imgs/amazon-logo.png" /></a>
            <a *ngIf="affiliateInformations.pocoDE !== undefined"
              href="{{ affiliateInformations.pocoDE.productFallbackUrl }}?tag={{ affiliateInformations.pocoDE.affiliateId }}"
              target="_blank"><img class="poco" src="../../../assets/imgs/poco-logo.png" /></a>
          </div>
        </div>
      </section>

      <section class="cta-section">
        <a class="cta" [routerLink]="'shelfdesigner'">{{ 'landing-page.design-now' | transloco }}</a>
      </section>

      <section class="social-media">
        <div class="circle"></div>

        <div class="logos">
          <a href="https://www.facebook.com/MyShelfy" target="_blank"><ion-icon name="logo-facebook"></ion-icon></a>
          <a href="https://www.instagram.com/my.shelfy/" target="_blank"><ion-icon name="logo-instagram"></ion-icon></a>
          <a href="https://twitter.com/myshelfy" target="_blank"><ion-icon name="logo-twitter"></ion-icon></a>
          <a href="https://www.pinterest.de/myshelfy" target="_blank"><ion-icon name="logo-pinterest"></ion-icon></a>
        </div>
      </section>

      <section class="footer">
        <a [attr.href]="'app-menu.imprint-link' | transloco" target="_blank">{{ 'app-menu.imprint' | transloco }}</a>
        <div><a href="https://onexip.com" target="_blank">© 2022 onexip GmbH</a></div>
        <a [attr.href]="'app-menu.privacy-pocility' | transloco" target="_blank">{{ 'landing-page.privacy-policy' |
          transloco }}</a>
      </section>
    </div>
  </div>
</ion-content>
