import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { clearItemImagesAction } from '../actions/shelf-item.actions';
import {
  addItemToCartAction,
  addItemToCartSucceededAction,
  checkUpdateChangesAction,
  checkUpdateChangesSucceededAction,
  clearItemListAction,
  hideProductListAction,
  removeItemAction,
  removeItemGroupAction,
  removeItemGroupSucceededAction,
  removeItemSucceededAction,
  setBuyingItemAction,
  setBuyingItemFailedAction,
  setBuyingItemSucceededAction,
  setBuyingShelfAction,
  setBuyingShelfFailedAction,
  setBuyingShelfSucceededAction,
  showProductListAction,
} from '../actions/shopping-cart.actions';
import { AnalyticLogs } from '../enums/analytic-logs.enum';
import { Design } from '../interfaces/design.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';
import { ExtendedShoppingCartItem, ShoppingCartItem } from '../interfaces/shopping-cart-item.interface';
import {
  getCurrentDesign,
  getCurrentDesignId,
  getExtendedShoppingCartItemsForCurrentDesign,
  getExtendedShoppingCartItemsFromCurrentDesign,
  getExtendedShoppingCartItemsFromDesign,
} from '../reducers/my-designs.reducer';
import { getSelectedSlot } from '../reducers/shelf-item.reducer';
import { AnalyticsService } from '../services/analytics.service';
import { DesignService } from '../services/design.service';
import { ItemService } from '../services/item.service';
import { ShelfDesignerService } from '../services/shelf-designer.service';
import { ShoppingcartService } from '../services/shoppingcart.service';

@Injectable()
export class ShoppingcartEffects {
  constructor(
    private actions$: Actions,
    private shoppingCardService: ShoppingcartService,
    private store: Store,
    private modalController: ModalController,
    private userDataService: DesignService,
    private analyticsService: AnalyticsService,
    private shelfDesignerService: ShelfDesignerService,
    private itemService: ItemService
  ) {}

  addItemToCart$ = createEffect(() =>
    this.actions$.pipe(
      ofType(addItemToCartAction),
      concatMap((action) =>
        of(action).pipe(
          withLatestFrom(this.store.select(getExtendedShoppingCartItemsFromCurrentDesign), this.store.select(getSelectedSlot))
        )
      ),
      // update shoppingCartItem array
      map(([action, shoppingCardItems, selectedSlot]: [any, ExtendedShoppingCartItem[], SlotScenes]) =>
        this.shoppingCardService.addItemToList(shoppingCardItems, selectedSlot, action.item)
      ),
      // get neccessary data from store and map new shoppingCartItem array to flat object for firestore
      switchMap((extendedShoppingCartItems: ExtendedShoppingCartItem[]) =>
        combineLatest([
          of(extendedShoppingCartItems),
          this.store.select(getCurrentDesignId).pipe(take(1)),
          this.itemService.getShoppingCartItems$(extendedShoppingCartItems),
        ])
      ),
      // safe changes to firestore
      switchMap(([extendedShoppingCartItems, designId, shoppingCartItems]: [ExtendedShoppingCartItem[], string, ShoppingCartItem[]]) =>
        this.userDataService
          .updateMyDesign$(designId, { shoppingCartItems: shoppingCartItems } as Design)
          .pipe(map(() => ({ shoppingCartItems, extendedShoppingCartItems, designId })))
      ),
      map(({ designId, shoppingCartItems, extendedShoppingCartItems }) =>
        addItemToCartSucceededAction({ designId: designId, shoppingCartItems, extendedShoppingCartItems })
      )
    )
  );

  removeItemGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeItemGroupAction),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(getCurrentDesign)))),
      // update shoppingCartItem array
      map(([action, currentSavedConfig]) =>
        this.shoppingCardService.removeItemGroupFromList(currentSavedConfig.shoppingCartItems, action.item)
      ),
      // get neccessary data from store and map new shoppingCartItem array to flat object for firestore
      switchMap((newExtendedShoppingCardItems: ExtendedShoppingCartItem[]) =>
        combineLatest([
          of(newExtendedShoppingCardItems),
          this.itemService.getShoppingCartItems$(newExtendedShoppingCardItems),
          this.store.select(getCurrentDesignId).pipe(take(1)),
        ])
      ),
      // save changes to firestore
      switchMap(
        ([newExtendedShoppingCardItems, newShoppingCartItems, configId]: [ExtendedShoppingCartItem[], ShoppingCartItem[], string]) =>
          this.userDataService
            .updateMyDesign$(configId, { shoppingCartItems: newShoppingCartItems } as Design)
            .pipe(map(() => newExtendedShoppingCardItems))
      ),
      // update changes in state (shelf designer and my-designs)
      map((items: ExtendedShoppingCartItem[]) => removeItemGroupSucceededAction({ shoppingCartItems: items }))
    )
  );

  removeItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(removeItemAction),
      tap(() => this.analyticsService.log(AnalyticLogs.PRODUCT_LIST_COMPONENT_REMOVE_ITEM)),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(getCurrentDesign)))),
      // update shoppingCartItem array
      map(([action, curentDesign]) =>
        this.shoppingCardService.removeItemFromList(curentDesign.shoppingCartItems, action.item, action.slotNumber)
      ),
      // get neccessary data from store and map new shoppingCartItem array to flat object for firestore
      switchMap((newExtendedShoppingCardItems: ExtendedShoppingCartItem[]) =>
        combineLatest([
          of(newExtendedShoppingCardItems),
          this.itemService.getShoppingCartItems$(newExtendedShoppingCardItems),
          this.store.select(getCurrentDesignId).pipe(take(1)),
        ])
      ),
      // save changes to firestore
      switchMap(
        ([newExtendedShoppingCardItems, newShoppingCartItems, configId]: [ExtendedShoppingCartItem[], ShoppingCartItem[], string]) =>
          this.userDataService
            .updateMyDesign$(configId, { shoppingCartItems: newShoppingCartItems } as Design)
            .pipe(map(() => newExtendedShoppingCardItems))
      ),
      // update changes in state (shelf designer and my-designs)
      map((items: ExtendedShoppingCartItem[]) => removeItemSucceededAction({ shoppingCartItems: items }))
    )
  );

  clearItemImages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(clearItemListAction),
      map((action: any) => clearItemImagesAction({ goBack: action.back }))
    )
  );

  checkUpdateChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(checkUpdateChangesAction),
      concatMap((action) => of(action).pipe(withLatestFrom(this.store.select(getExtendedShoppingCartItemsFromDesign(action.configId))))),
      map(([action, extendedShoppingCardItems]: [any, ExtendedShoppingCartItem[]]) =>
        this.shoppingCardService.checkUpdateChanges(extendedShoppingCardItems, action.types)
      ),
      map((extendedShoppingCartItems: ExtendedShoppingCartItem[]) => checkUpdateChangesSucceededAction({ extendedShoppingCartItems }))
    )
  );

  showProductList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(showProductListAction),
        tap(() => this.analyticsService.log(AnalyticLogs.SHELF_DESIGNER_COMPONENT_SHOW_PRODUCT_LIST)),
        switchMap(() => this.shelfDesignerService.showProductListSheet$())
      ),
    { dispatch: false }
  );

  hideProductList$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(hideProductListAction),
        tap(() => this.analyticsService.log(AnalyticLogs.SHELF_DESIGNER_COMPONENT_HIDE_PRODUCT_LIST)),
        switchMap(() => this.shelfDesignerService.hideProductListSheet$())
      ),
    { dispatch: false }
  );

  // TODO: re-enable and fix with #227
  // updateShoppingCartOnShelfChange$ = createEffect(
  //   () => () =>
  //     this.actions$.pipe(
  //       ofType(chooseShelfSucceededAction),
  //       withLatestFrom(this.store.select(getShoppingCardItems)),
  //       filter(([action, cartItems]) => cartItems != null),
  //       map(([action, cartItems]: [any, ExtendedShoppingCartItem[]]) =>
  //         this.shoppingCardService.updateItemQuantityAfterShelfChange(cartItems, action.shelf)
  //       ),
  //       switchMap((newExtendedShoppingCardItems: ExtendedShoppingCartItem[]) =>
  //         combineLatest([
  //           of(newExtendedShoppingCardItems),
  //           this.store.select(getCurrentDesignId).pipe(take(1)),
  //           this.itemService.getShoppingCartItems$(newExtendedShoppingCardItems),
  //         ])
  //       ),
  //       switchMap(
  //         ([newExtendedShoppingCardItems, configId, newShoppingCardItems]: [ExtendedShoppingCartItem[], string, ShoppingCartItem[]]) =>
  //           this.userDataService
  //             .updateMyDesign$(configId, { shoppingCartItems: newShoppingCardItems } as SavedShelfConfig)
  //             .pipe(map(() => newExtendedShoppingCardItems))
  //       ),
  //       map((newShoppingCardItems: ExtendedShoppingCartItem[]) => {
  //         return updateShoppingCartItemsAfterShelfChangeAction({ shoppingCartItems: newShoppingCardItems });
  //       })
  //     )
  // );

  setBuyingShelf$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBuyingShelfAction),
      tap(() => this.analyticsService.log(AnalyticLogs.PRODUCT_LIST_COMPONENT_TOGGLE_SHELF_CHECKED)),
      withLatestFrom(this.store.select(getCurrentDesignId)),
      switchMap(([action, configId]) =>
        this.userDataService.updateMyDesign$(configId, { buyingShelf: action.buyShelf } as Design).pipe(map(() => action.buyShelf))
      ),
      map((isBuyingShelf: boolean) => setBuyingShelfSucceededAction({ isBuyingShelf })),
      catchError((error, caught) => {
        this.store.dispatch(setBuyingShelfFailedAction({ err: error }));
        return caught;
      })
    )
  );

  setBuyingItem$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setBuyingItemAction),
      tap(() => this.analyticsService.log(AnalyticLogs.PRODUCT_LIST_COMPONENT_TOGGLE_ITEM_CHECKED)),
      withLatestFrom(this.store.select(getExtendedShoppingCartItemsForCurrentDesign)),
      map(([action, extendedShoppingCartItems]) =>
        this.shoppingCardService.updateSlotsToBuyForItem(extendedShoppingCartItems, action.item)
      ),
      switchMap((extendedShoppingCartItems: ExtendedShoppingCartItem[]) =>
        combineLatest([
          of(extendedShoppingCartItems),
          this.store.select(getCurrentDesignId).pipe(take(1)),
          this.itemService.getShoppingCartItems$(extendedShoppingCartItems),
        ])
      ),
      switchMap(([newExtendedShoppingCartItems, configId, newShoppingCartItems]) =>
        this.userDataService
          .updateMyDesign$(configId, { shoppingCartItems: newShoppingCartItems } as Design)
          .pipe(map(() => newExtendedShoppingCartItems))
      ),
      map((newExtendedShoppingCartItems: ExtendedShoppingCartItem[]) =>
        setBuyingItemSucceededAction({ shoppingCartItems: newExtendedShoppingCartItems })
      ),
      catchError((error, caught) => {
        this.store.dispatch(setBuyingItemFailedAction({ error }));
        return caught;
      })
    )
  );

  addItemToCartSucceeded$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(addItemToCartSucceededAction),
        switchMap((action) =>
          this.userDataService.updateMyDesignThumbnail$(action.designId, { shoppingCartItems: action.shoppingCartItems } as Design)
        )
      ),
    { dispatch: false }
  );
}
