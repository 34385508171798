import { createAction, props } from '@ngrx/store';
import { Product } from '../interfaces/product.interface';

const actionType = '[Checkout]';

export const startCheckoutAllAction = createAction(`${actionType} Start checkout all`, props<{ affiliate: string }>());

export const startCheckoutAllSucceededAction = createAction(`${actionType} Succeeded to start checkout all`, props<{ affiliateUrl: string }>());

export const startCheckoutAction = createAction(`${actionType} Start checkout`, props<{ affiliate: string, product: Product }>());

export const startCheckoutSucceededAction = createAction(`${actionType} Succeeded to start checkout`, props<{ affiliateUrl: string }>());
