import { Item } from '../interfaces/item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';

export const newProductRange = 1209600000; // 14 days in seconds

export function isNewProduct(product: ShelfScenes | Item) {
  let dateNow = Date.now();
  let dateAdded = product.dateAdded.seconds * 1000;

  if (dateNow - dateAdded <= newProductRange) {
    return true;
  }
}
