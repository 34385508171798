import { environment } from './../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { loadItemAction, loadShelfAction } from '../core/actions/product-details.actions';
import { Observable } from 'rxjs';
import { getProduct } from '../core/reducers/product-details.reducer';
import { Item } from '../core/interfaces/item.interface';
import { ProductCategories } from '../core/enums/product-categories.enum';
import { showAppMenuAction } from '../core/actions/general.actions';
import { ContextCategories } from '../core/enums/context-categories.enum';
import { ShelfScenes } from '../core/interfaces/scenes-shelf.interface';
import { AffiliatesEnum } from '../core/enums/affiliates.enum';

@Component({
  selector: 'app-product-detail-container',
  templateUrl: './product-detail-container.component.html',
  styleUrls: ['./product-detail-container.component.scss'],
})
export class ProductDetailContainerComponent implements OnInit {
  product$: Observable<ShelfScenes | Item> = this.store.select(getProduct);
  productCategory!: ProductCategories;

  logoPath: string = '../../assets/imgs/shelfy-logo.png';

  affiliatesEnum = AffiliatesEnum;

  ContextCategories = ContextCategories;

  constructor(private store: Store, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] !== this.affiliatesEnum.ALL) {
      switch (environment.allowedAffiliates[0]) {
        case AffiliatesEnum.OTTO_DE:
          this.logoPath = '../../assets/imgs/otto-logo.svg';
          break;
      }
    }

    const id: string = this.route.snapshot.params['id'];
    const category: string = this.route.snapshot.params['category'];

    if (category === ProductCategories.Item) {
      this.productCategory = ProductCategories.Item;
      this.store.dispatch(loadItemAction({ id }));
    } else {
      this.productCategory = ProductCategories.Shelf;
      this.store.dispatch(loadShelfAction({ id }));
    }
  }

  handleShowAppMenu(): void {
    this.store.dispatch(showAppMenuAction());
  }
}
