export enum ThemesEnum {
  Children = 'children',
  Office = 'office',
  Handicrafts = 'handicrafts',
  Animals = 'animals',
  Patterns = 'patterns',
  Colorful = 'colorful',
  Plain = 'plain',
  Music = 'music',
  Christmas = 'christmas',
  ValentinesDay = 'valentines-day',
  Easter = 'easter',
}
