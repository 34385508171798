import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { ModalOptions, NavOptions } from '@ionic/core';
import { Store } from '@ngrx/store';
import { CupertinoPane, CupertinoSettings } from 'cupertino-pane';
import { BehaviorSubject, from, iif, Observable, of } from 'rxjs';
import { delay, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppAnimationsService } from '../../core-ui/app-animations.service';
import { ProductListComponent } from '../../product-list/product-list/product-list.component';
import { ModalNavContainerComponent } from '../../shared/modal-nav-container/modal-nav-container.component';
import { bottomPanelPositionChangedAction, setBottomPanelPositionAction } from '../actions/shelf-picker.actions';
import { BottomPanePositionsEnum } from '../enums/bottom-pane-positions.enum';
import { PickerTypes } from '../enums/picker-types.enum';
import { ProductCategories } from '../enums/product-categories.enum';
import { ShelfSceneHacksPickerTabEnum } from '../enums/shelf-scenes-hacks-picker-tab.enum';
import { LooseObject } from '../interfaces/loose-object.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';
import { isSplitMode } from '../reducers/general.reducer';
import { getLastModalPicker } from '../reducers/shelf-designer.reducer';
import { MyDesignsComponent } from './../../my-designs/my-designs.component';

import { BackdropModule, InverseModule } from 'cupertino-pane/dist/modules';
import { openReviewPopupAction } from '../actions/general.actions';

@Injectable({
  providedIn: 'root',
})
export class ShelfDesignerService {
  private renderer: Renderer2;
  private modal: HTMLIonModalElement;

  bottomPane: CupertinoPane;
  productListSheet: CupertinoPane;

  public shelfImageLoaded$ = new BehaviorSubject<number>(0);

  constructor(
    private modalController: ModalController,
    private appAnimationsService: AppAnimationsService,
    private rendererFactory: RendererFactory2,
    private platform: Platform,
    private store: Store
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  showProductPicker$(pickerType: PickerTypes, slot?: SlotScenes): Observable<void> {
    return of(null).pipe(
      withLatestFrom(this.store.select(getLastModalPicker)),
      switchMap(([nothing, lastShelfPicker]) => {
        let productCategory: ProductCategories;
        let componentProps: LooseObject = { pickerType };
        switch (pickerType) {
          case PickerTypes.ShelfColor:
          case PickerTypes.ShelfType:
            productCategory = ProductCategories.Shelf;
            break;
          case PickerTypes.Item:
            productCategory = ProductCategories.Item;
            componentProps.slot = slot;
        }

        const modal: HTMLIonModalElement = document.querySelector(`ion-modal.modal-nav-ctrl-${productCategory}`);

        if (modal) {
          // reuse cached modal
          this.renderer.setStyle(modal, 'display', 'flex');
          const ionNav = this.getIonNav(pickerType === PickerTypes.Item ? ProductCategories.Item : ProductCategories.Shelf);
          ionNav.animated = false;
          ionNav.canGoBack().then(() => ionNav.popToRoot({} as NavOptions)); // go back to list from details

          let animation = this.appAnimationsService.iosEnterAnimation(modal);

          animation.onFinish(() => {
            ionNav.animated = true;
          });
          animation.play();

          return of(modal);
        }

        return from(
          this.modalController.create({
            component: ModalNavContainerComponent,
            // presentingElement: this.getIonRouterOutlet(), // cool native animation, but not possible here because of our modal caching approach...
            cssClass: `picker-modal modal-nav-ctrl-${productCategory}`,
            componentProps,
            backdropDismiss: false, // can later be re-activated, but regard redux workflow!
          })
        ).pipe(
          tap(() => (this.modal = modal)),
          switchMap((modal) => modal.present())
        );
      }),
      map(() => null)
    );
  }

  hideModalNavCtrl$(productCategory: ProductCategories): Observable<boolean> {
    return this.hideOpenModal$(`modal-nav-ctrl-${productCategory}`);
  }

  private hideOpenModal$(modalClassName: string): Observable<boolean> {
    return of(null).pipe(
      switchMap(() => {
        const modal = document.querySelector(`ion-modal.${modalClassName}`);
        if (modal) {
          return of(modal);
        } else {
          return from(this.modalController.getTop());
        }
      }),
      tap((modal: HTMLIonModalElement) => {
        let animation = this.appAnimationsService.iosLeaveAnimation(modal);

        animation.onFinish(() => {
          this.renderer.setStyle(modal, 'display', 'none');
        });
        animation.play();
      }),
      map(() => true)
    );
  }

  getIonNav(productCategory: ProductCategories): HTMLIonNavElement | undefined {
    return document.querySelector(`ion-nav.${productCategory}`) as HTMLIonNavElement;
  }

  getIonRouterOutlet(): HTMLIonRouterOutletElement {
    return document.querySelector('ion-router-outlet') as HTMLIonRouterOutletElement;
  }

  getBottomSheetMiddlePosition(): number {
    return this.platform.is('desktop') ? 190 : 168;
  }

  getBottomSheetBottomPosition(): number {
    return 40;
  }

  showBottomSheet$(): Observable<CupertinoPane> {
    if (!this.bottomPane && document.querySelector('#cupertino-pane')) {
      //if (true) {
      let settings: CupertinoSettings = {
        modules: [BackdropModule],
        parentElement: 'app-shelf-designer',
        breaks: {
          top: { enabled: true, height: window.innerHeight * 0.9, bounce: true },
          middle: { enabled: true, height: this.getBottomSheetMiddlePosition() },
          bottom: { enabled: true, height: this.getBottomSheetBottomPosition() },
        },
        backdrop: true,
        fitHeight: false,
        buttonDestroy: false,
        cssClass: 'shelves-scenes-hacks-sheet',
        initialBreak: BottomPanePositionsEnum.MIDDLE,
        simulateTouch: false,
        showDraggable: !this.platform.is('desktop'),
        dragBy: [
          `.pane ion-segment-button[value='${ShelfSceneHacksPickerTabEnum.SCENE}']`,
          `.pane ion-segment-button[value='${ShelfSceneHacksPickerTabEnum.SHELF}']`,
          `.pane ion-segment-button[value='${ShelfSceneHacksPickerTabEnum.HACKS}']`,
          '.pane .draggable',
        ],
        // onDrag: () => console.log('Drag event'),
        events: {
          onTransitionEnd: () => {
            if (this.bottomPane.isPanePresented()) {
              this.store.dispatch(
                bottomPanelPositionChangedAction({ position: this.bottomPane.currentBreak() as BottomPanePositionsEnum })
              );
            }
          },
        },
      };

      this.bottomPane = new CupertinoPane(
        '#cupertino-pane', // Pane container selector
        settings
      );
    }

    if (this.bottomPane.isPanePresented()) {
      return of(this.bottomPane);
    } else
      return of(null).pipe(
        switchMap(() => from(this.bottomPane.present({ animate: false }))),
        tap(() => {
          if (this.bottomPane.isPanePresented()) {
            this.store.dispatch(bottomPanelPositionChangedAction({ position: this.bottomPane.currentBreak() as BottomPanePositionsEnum }));
          }
        })
      );
  }

  public hideBottomSheet$(): Observable<boolean> {
    if (this.bottomPane) return from(this.bottomPane.destroy());
    return of(true);
  }

  public handleOpenOrCloseSheet() {
    switch (this.bottomPane.currentBreak()) {
      case BottomPanePositionsEnum.TOP: {
        this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.MIDDLE }));
        break;
      }
      case BottomPanePositionsEnum.MIDDLE: {
        this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.TOP }));
        break;
      }
      case BottomPanePositionsEnum.BOTTOM: {
        this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.MIDDLE }));
        break;
      }
    }
  }

  public setBottomSheetPosition$(position: BottomPanePositionsEnum): Observable<boolean> {
    if (this.bottomPane != undefined) {
      return of(null).pipe(switchMap(() => from(this.bottomPane.moveToBreak(position))));
    } else {
      return of(null);
    }
  }

  public setBottomSheetPositionToMiddle$(splitMode: boolean): Observable<void> {
    let isNative: boolean = (this.platform.is('ios') || this.platform.is('android'));
    return of(null).pipe(
      switchMap(() =>
        iif(() => (!splitMode && this.getBottomSheetPosition() === BottomPanePositionsEnum.TOP),
          this.setBottomSheetPosition$(BottomPanePositionsEnum.MIDDLE).pipe(
            // tap(()=>{
            //   console.log('>>> KLICH');
            // }),
            map(() => this.store.dispatch(bottomPanelPositionChangedAction({ position: BottomPanePositionsEnum.MIDDLE })))
          ),
          of(null))
      ),
      // delay(100),
      tap(() => {
        if (isNative)
          this.store.dispatch(openReviewPopupAction());
      })
    )
  }

  public getBottomSheetPosition(): string {
    return this.bottomPane.currentBreak();
  }

  public checkBottomSheetBackDrop(): void {
    if (this.bottomPane.currentBreak() == BottomPanePositionsEnum.TOP) {
      this.bottomPane.backdrop({ show: true });
      (document.getElementsByClassName('backdrop')[0] as HTMLDivElement).style.display = 'block';
    } else {
      // hint: 'as any' is needed because 'show' has not the type boolean, it's of type true ;) (Stand: 09/2022)
      this.bottomPane.backdrop({ show: false as any });
      (document.getElementsByClassName('backdrop')[0] as HTMLDivElement).style.display = 'none';
    }
  }

  public setToMiddleWhenIsAtBottom(): void {
    if (this.bottomPane != undefined) {
      this.store.dispatch(setBottomPanelPositionAction({ position: BottomPanePositionsEnum.MIDDLE }));
    }
  }

  public hideProductListSheet$(): Observable<boolean> {
    return of(null).pipe(
      withLatestFrom(this.store.select(isSplitMode)),
      switchMap(([_, splitMode]) => iif(() => splitMode, this.hideProductListOnDesktop$(), this.hideProductListOnMobile$()))
    );
  }

  private hideProductListOnDesktop$(): Observable<boolean> {
    if (this.productListSheet) {
      return of(null).pipe(switchMap(() => from(this.productListSheet.destroy({ animate: true }))));
    }
    return of(true);
  }

  private hideProductListOnMobile$(): Observable<boolean> {
    return of(null).pipe(switchMap(() => from(this.modalController.dismiss())));
  }

  public showProductListSheet$(): Observable<void> {
    return of(null).pipe(
      withLatestFrom(this.store.select(isSplitMode)),
      mergeMap(([_, splitMode]) => iif(() => splitMode, this.showProductListOnDesktop$(), this.showProductListOnMobile$()))
    );
  }

  private showProductListOnDesktop$(): Observable<void> {
    return of(null).pipe(
      map(() => {
        let settings: CupertinoSettings = {
          modules: [BackdropModule, InverseModule],
          parentElement: 'app-sidebar',
          inverse: true,
          breaks: {
            bottom: { enabled: true, height: 50, bounce: true }, // window.innerHeight * 0.9
            //  middle: { enabled: true, height: 100, bounce: true }, // window.innerHeight * 0.9
            top: { enabled: true, height: window.innerHeight * 1, bounce: false },
          },
          backdrop: true,
          fitHeight: false,
          buttonDestroy: false,
          bottomOffset: 0,
          cssClass: 'product-list-sheet',
          initialBreak: BottomPanePositionsEnum.TOP,
          simulateTouch: false,
          showDraggable: false, // !this.platform.is('desktop'),
          dragBy: ['.pane ion-segment-button', '.pane .draggable'],
          // onDrag: () => console.log('Drag event'),
          events: {
            onTransitionEnd: () => {
              this.store.dispatch(
                bottomPanelPositionChangedAction({ position: this.bottomPane.currentBreak() as BottomPanePositionsEnum })
              );
            },
          },
        };
        this.productListSheet = new CupertinoPane(
          '#product-list-cupertino-pane', // Pane container selector
          settings
        );
        return this.productListSheet;
      }),
      switchMap((productListSheet) =>
        from(productListSheet.present({ animate: true })).pipe(
          tap((pane) => {
            // document.querySelector('.cupertino-pane-wrapper .header').addEventListener('click', () => this.handleOpenOrCloseSheet());
          })
        )
      ),
      map(() => null)
    );
  }

  private showProductListOnMobile$(): Observable<void> {
    return from(this.modalController.getTop()).pipe(
      switchMap((topModal: HTMLElement) =>
        from(
          this.modalController.create({
            component: ProductListComponent,
            cssClass: 'product-list-dialog',
            // presentingEl: topModal
          } as ModalOptions)
        ).pipe(switchMap((modal) => modal.present()))
      )
    );
  }

  notifyShelfImageLoaded() {
    this.shelfImageLoaded$.next(this.shelfImageLoaded$.getValue() + 1);
    // console.log('>>> ShelfDesignerService.notifyShelfImageLoaded()', this.shelfImageLoaded$.getValue());
  }

  public showMyDesignsPicker$(): Observable<void> {
    return of(null).pipe(
      switchMap(() =>
        from(
          this.modalController.create({
            component: MyDesignsComponent,
            cssClass: 'my-designs-modal',
          } as ModalOptions)
        )
      ),
      switchMap((modal) => modal.present())
    );
  }

  public hideMyDesignsPicker$(): Observable<any> {
    return of(null).pipe(switchMap(() => this.modalController.dismiss()));
  }
}
