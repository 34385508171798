import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, of, tap, withLatestFrom } from 'rxjs';
import { hideProductListAction, showProductListAction } from '../core/actions/shopping-cart.actions';
import { isProductListOpen } from '../core/reducers/shoppingcart.reducer';
import { ProductListService } from '../core/services/product-list.service';
import { ShelfDesignerService } from '../core/services/shelf-designer.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  // TODO: Warum steht das nicht im Redux?
  totalPrice$: Observable<number> = this.productListService.getTotalPrice$();
  isProductListOpen$: Observable<boolean> = this.store.select(isProductListOpen);

  constructor(private productListService: ProductListService, private store: Store, private shelfDesignerService: ShelfDesignerService) {}

  ngOnInit(): void {}

  // TODO: komplett in Redux legen
  public handleCheckout(): void {
    /*
    this.totalPrice$.pipe(
      take(1),
      tap((price: number) => {
        if (price > 0) {
          this.store.dispatch(showShoppingCartAction());
        } else {
          window.open('https://www.amazon.de/?tag=hello-shelfy-21', '_blank');
        }
      })
    );
    */
  }

  handleToggleProductList() {
    of(null)
      .pipe(
        withLatestFrom(this.isProductListOpen$),
        tap(([input, isProductListOpen]) => {
          if (isProductListOpen) {
            this.store.dispatch(hideProductListAction());
          } else {
            this.store.dispatch(showProductListAction());
          }
        })
      )
      .subscribe();
  }
}
