import { createAction, props } from '@ngrx/store';
import { ItemImage } from '../interfaces/item-image.interface';
import { ShoppingCartItem } from '../interfaces/shopping-cart-item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { SlotScenes } from '../interfaces/scenes-slot.interface';

const actionType = '[Shelf-Item]';

export const setSelectedShelfAction = createAction(`${actionType} Set selected shelf`, props<{ shelf: ShelfScenes }>());

export const setSelectedSlotAction = createAction(`${actionType} Set selected slot`, props<{ slot: SlotScenes }>());

export const buildItemImageAction = createAction(`${actionType} Build item image`, props<{ slot: SlotScenes }>());

export const buildItemImageSucceededAction = createAction(
  `${actionType} Succeeded to build item image`,
  props<{ itemImages: ItemImage[] }>()
);

export const clearItemImagesAction = createAction(`${actionType} Clear item images`, props<{ goBack?: boolean }>());

export const removeItemImageAction = createAction(`${actionType} Remove item image`, props<{ shoppingCardItem: ShoppingCartItem }>());
