<ion-header>
  <ion-toolbar>
    <ion-label slot="start">
      <h1><strong>Slot Editor</strong></h1>
    </ion-label>
    <ion-buttons slot="end">
      <ion-button (click)="onClose()">
        {{ 'general.close' | transloco }}
      </ion-button>
      <ion-button class="save" fill="solid" shape="round" [disabled]="isUploadDisable()" (click)="onUpdate()">
        {{ 'general.save' | transloco }}
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="slot-informations">
    <div class="slot-configutartor">
      <div class="shelf-configurator">
        <div class="container">
          <div #shelfElement class="shelf" [style.zoom]="zoom">
            <!--
            <img
              default-image="{{ environment.basePath }}{{ currentShelf.imagePlaceholder }}"
              img-preloader="{{ currentShelf.image3dViewPath }}"
              srcset="{{currentShelf.image3dViewPath | imageSrcSet}}"
              sizes="80vw"
            />
            //-->

            <!--<div #shelfImage class="shelf-image">//-->
            <!--          <img [src]="currentShelf?.image3dViewPath" />//-->

            <!--  -->

            <!--
            <lib-ngx-image-zoom
            enableLens="true"
            circularLens="true"
            [magnification]="2"
            [style.zoom]="1/(zoomFactor$ | async)"
            [style.width.px]="currentShelf?.slotsData?.width*(zoomFactor$ | async)"
            [style.height.px]="currentShelf?.slotsData?.height*(zoomFactor$ | async)"
            [thumbImage]="currentShelf?.image3dViewPath"
          ></lib-ngx-image-zoom>
          //-->

            <div
              #slots
              class="slots"
              *ngIf="zoomFactor$ | async as zoomFactor"
              [style.width.px]="currentShelf?.scenes[currentSceneIndex]?.imageSize?.width"
              [style.height.px]="currentShelf?.scenes[currentSceneIndex]?.imageSize?.height"
            >
              <!--
                <div
                  class="shelf-item"
                  [style.left.px]="slot.ulx"
                  [style.top.px]="slot.uly"
                  [style.width.px]="slot.width"
                  [style.height.px]="slot.height"
                >
                  <div class="dnd" cdkDrag [cdkDragFreeDragPosition]="{ x: slot.ulx, y: slot.uly }"></div>
                  <div class="dnd" cdkDrag [cdkDragFreeDragPosition]="{ x: slot.urx, y: slot.ury }"></div>
                  <div class="dnd" cdkDrag [cdkDragFreeDragPosition]="{ x: slot.llx, y: slot.lly }"></div>
                  <div class="dnd" cdkDrag [cdkDragFreeDragPosition]="{ x: slot.lrx, y: slot.lry }"></div>
                </div>
                //-->

              <!--
              <div
                class="image-zoom"
                #imageZoom
                [style.backgroundImage]="'url(' + currentShelf?.image3dViewPath + ')'"
                [style.backgroundPosition]="magnifierPosition"
              ></div>
              //-->

              <!--
              <lib-ngx-image-zoom
                enableLens="true"
                circularLens="true"
                [magnification]="2"
                [style.width.px]="currentShelf?.slotsData?.width * (zoomFactor$ | async)"
                [style.height.px]="currentShelf?.slotsData?.height * (zoomFactor$ | async)"
                [thumbImage]="currentShelf?.image3dViewPath"
              ></lib-ngx-image-zoom>
              //-->

              <svg-container
                containerId="svg-element"
                [width]="currentShelf?.scenes[currentSceneIndex]?.imageSize?.width"
                [height]="currentShelf?.scenes[currentSceneIndex]?.imageSize?.height"
                [style.zoom]="zoomFactor"
              >
                <svg-image
                  [imageUrl]="currentShelf?.scenes[currentSceneIndex]?.imageUrl"
                  [width]="currentShelf?.scenes[currentSceneIndex]?.imageSize?.width"
                  [height]="currentShelf?.scenes[currentSceneIndex]?.imageSize?.height"
                ></svg-image>
                <ng-container
                  class="slot-visualization"
                  *ngFor="let slot of currentShelf?.scenes[currentSceneIndex]?.slots; let indexOfSlot = index"
                >
                  <svg-polyline
                    (onInitialize)="onInitializeSlotShape($event, slot)"
                    class="slot-area"
                    [points]="getPoints(slot)"
                    borderColor="none"
                    fill="rgba(255, 0, 0, 0.2)"
                  ></svg-polyline>
                  <svg-circle
                    (onInitialize)="onInitialize($event, slot.slotData.ul)"
                    [x]="slot.slotData.ul?.x"
                    [y]="slot.slotData.ul?.y"
                    [diameter]="getCircleDiameter()"
                  ></svg-circle>
                  <svg-circle
                    (onInitialize)="onInitialize($event, slot.slotData.ur)"
                    [x]="slot.slotData.ur?.x"
                    [y]="slot.slotData.ur?.y"
                    [diameter]="getCircleDiameter()"
                  ></svg-circle>
                  <svg-circle
                    (onInitialize)="onInitialize($event, slot.slotData.ll)"
                    [x]="slot.slotData.ll?.x"
                    [y]="slot.slotData.ll?.y"
                    [diameter]="getCircleDiameter()"
                  ></svg-circle>
                  <svg-circle
                    (onInitialize)="onInitialize($event, slot.slotData.lr)"
                    [x]="slot.slotData.lr?.x"
                    [y]="slot.slotData.lr?.y"
                    [diameter]="getCircleDiameter()"
                  ></svg-circle>
                  <svg-text
                    [x]="(slot.slotData.ur?.x - slot.slotData.ul?.x) / 2 + slot.slotData.ul?.x"
                    [y]="(slot.slotData.ll?.y - slot.slotData.ul?.y) / 2 + slot.slotData.ul?.y"
                    [text]="indexOfSlot + 1"
                    [size]="(slot.slotData.ll?.y - slot.slotData.ul?.y) / 10"
                    color="rgb(190, 29, 83)"
                    [classes]="['no-cursor']"
                  ></svg-text>
                </ng-container>
              </svg-container>

              <!--
                <svg *ngIf="slotData" [attr.viewBox]="getViewBox(slotData)" xmlns="http://www.w3.org/2000/svg" version="1.1" id="myShape">
                  <g class="slot-visualization" *ngFor="let slot of slotData?.slots">
                    <polyline class="slot-area" cdkDrag [attr.points]="getPoints(slot)" />
                    <circle #circleUl cdkDrag (cdkDragMoved)="onDragMoved($event, slot.ul,circleUl)" [attr.cx]="slot.ul?.x" [attr.cy]="slot.ul?.y" r="10"></circle>
                    <circle cdkDrag (cdkDragMoved)="onDragMoved($event, slot.ur)" [attr.cx]="slot.ur?.x" [attr.cy]="slot.ur?.y" r="10"></circle>
                    <circle cdkDrag (cdkDragMoved)="onDragMoved($event, slot.lr)" [attr.cx]="slot.lr?.x" [attr.cy]="slot.lr?.y" r="10"></circle>
                    <circle cdkDrag (cdkDragMoved)="onDragMoved($event, slot.ll)" [attr.cx]="slot.ll?.x" [attr.cy]="slot.ll?.y" r="10"></circle>
                  </g>
                </svg>
                //-->
            </div>

            <!--
            <img src="{{ currentShelf.image3dViewPath }}" />
            -->

            <!-- -->
          </div>
        </div>
      </div>
    </div>
    <div class="slot-details">
      <div>
        <div class="name-line">
          <span class="heading">{{ 'admin-tool.views.name' | transloco }}</span>
          <ion-textarea placeholder="Erforderlich" [(ngModel)]="nameOfThisScene"></ion-textarea>
        </div>

        <div class="type-line">
          <span class="heading">{{ 'admin-tool.views.type.title' | transloco }}</span>
          <ion-select [(ngModel)]="sceneType" [okText]="'general.okay' | transloco" [cancelText]="'general.cancel' | transloco">
            <ion-select-option value="perspective">{{ 'admin-tool.views.type.perspective' | transloco }}</ion-select-option>
            <ion-select-option value="front">{{ 'admin-tool.views.type.frontal' | transloco }}</ion-select-option>
            <ion-select-option value="custom">{{ 'admin-tool.views.type.custom' | transloco }}</ion-select-option>
          </ion-select>
        </div>

        <div class="active-line">
          <span class="heading">{{ 'admin-tool.views.active' | transloco }}</span>
          <ion-toggle #activeToggle></ion-toggle>
        </div>

        <div class="image-details">
          <ion-label class="heading">
            {{ 'admin-tool.views.picture' | transloco }}
          </ion-label>
          <div class="image-data">
            <div class="imageUpload color" [ngClass]="{ disabled: isUploadDisable() }" (click)="handleUploadImage()">
              <ion-icon name="cloud-upload-outline"></ion-icon>
              <input
                type="file"
                #uploadImageInput
                style="display: none"
                (change)="handleUploadFileInput($event.target.files[0])"
                autocomplete="off"
              />
            </div>
            <div class="image-sizes">
              <div class="box">
                <ion-icon src="assets/imgs/arrows-alt-h.svg"></ion-icon>
                <ion-input
                  class="image-dimensions"
                  type="string"
                  #imagePixelWidth
                  [value]="currentShelf.scenes[currentSceneIndex]?.imageSize?.width"
                ></ion-input>
                <span>px</span>
              </div>
              <div class="box">
                <ion-icon src="assets/imgs/arrows-alt-v.svg"></ion-icon>
                <ion-input
                  class="image-dimensions"
                  type="string"
                  #imagePixelHeight
                  [value]="currentShelf.scenes[currentSceneIndex]?.imageSize?.height"
                ></ion-input>
                <span>px</span>
              </div>
            </div>
          </div>
        </div>
        <!-- Sltos -->
        <!-- TODO: implement type-editing -->
        <div class="slots-details">
          <div class="slots-header">
            <div class="slots-heading-options">
              <ion-label class="heading"> SLOT </ion-label>
              <div class="boxes">
                <div class="box" (click)="onImportSlots()">
                  <ion-icon name="color-wand-outline"></ion-icon>
                  <span>
                    {{ 'admin-tool.views.import' | transloco }}
                  </span>
                </div>
                <div class="box" (click)="onAddSlot()">
                  <ion-icon name="add-circle-outline"></ion-icon>
                  <span>
                    {{ 'admin-tool.views.new' | transloco }}
                  </span>
                </div>
              </div>
            </div>

            <div class="slots-advice">
              <span>
                {{ 'admin-tool.views.advice' | transloco }}
              </span>
            </div>
          </div>

          <div class="slots-list">
            <ion-list>
              <div class="add-lessons-form" [formGroup]="slotsFormGroup">
                <ng-container formArrayName="slotsArray" *ngFor="let slotsItem of slotsArray.controls; let i = index">
                  <ion-item-sliding [formGroup]="slotsItem">
                    <ion-item-options side="end">
                      <ion-item-option (click)="onCloneSlot(i)">
                        <div>
                          <ion-icon name="copy-sharp"></ion-icon>
                          <span>{{ 'admin-tool.views.duplicate' | transloco }}</span>
                        </div>
                      </ion-item-option>
                      <ion-item-option (click)="onDeleteSlot(i)">
                        <div>
                          <ion-icon name="trash"></ion-icon>
                          <span>{{ 'admin-tool.views.delete' | transloco }}</span>
                        </div>
                      </ion-item-option>
                    </ion-item-options>

                    <ion-item detail="true">
                      <span class="slot-index">Slot {{ i + 1 }}</span>
                      <div class="slot-sizes">
                        <ion-icon src="assets/imgs/arrows-alt-h.svg"></ion-icon>
                        <ion-input class="slot-sizes" type="string" formControlName="width"></ion-input>
                        <ion-icon src="assets/imgs/arrows-alt-v.svg"></ion-icon>
                        <ion-input class="slot-sizes" type="string" formControlName="height"></ion-input>
                        <ion-icon src="assets/imgs/arrows-alt-d.svg"></ion-icon>
                        <ion-input class="slot-sizes" type="string" formControlName="depth"></ion-input>
                      </div>
                    </ion-item>
                  </ion-item-sliding>
                </ng-container>
              </div>
            </ion-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
