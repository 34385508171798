import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import {
  leaveItemDetailsAction,
  leaveProductFilterAction,
  loadItemsAction,
  loadItemsFailedAction,
  loadItemsOfCategoryFailedAction,
  loadItemsSucceededAction,
  setSortCriterionAction,
  showItemDetailsAction,
  toggleThemeAction,
  toggleTypeAction,
  toggleAffiliateAction,
  setPriceRangeAction,
  toggleShowOnlySaleProductsAction,
  toggleColorAction,
  toggleMaterialAction,
  resetProductFilterAtion,
  enterProductFilterAction,
  setAffiliatesAction,
} from '../actions/shelf-item-picker.actions';
import { AffiliatesEnum } from '../enums/affiliates.enum';
import { MaterialsEnum } from '../enums/materials.enum';
import { SortCriteriaEnum } from '../enums/sort.enum';
import { ThemesEnum } from '../enums/themes.enum';
import { TypesEnum } from '../enums/types.enum';
import { Color } from '../interfaces/color.interface';
import { FilteredItems } from '../interfaces/item.interface';
import { ProductFilterOptions } from '../interfaces/product-filter-options.interface';

export interface ShelfItemPickerState {
  loadingItems: boolean;
  filteredItems: FilteredItems;
  filteredItemsOnEnterFilter: FilteredItems;
  chunkSize: number;
  hasMoreItems: boolean;
  selectedType: TypesEnum;
  itemDetailsVisible: string | null;
  // filter criteria
  sortCriterion: SortCriteriaEnum;
  sortCriterionOnEnterFilter: SortCriteriaEnum;
  activeTypes: TypesEnum[];
  activeTypesOnEnterFilter: TypesEnum[];
  activeTheme: ThemesEnum;
  activeThemeOnEnterFilter: ThemesEnum;
  activeAffiliates: AffiliatesEnum[];
  activeAffiliatesOnEnterFilter: AffiliatesEnum[];
  minPrice: number;
  minPriceOnEnterFilter: number;
  maxPrice: number;
  maxPriceOnEnterFilter: number;
  onlySaleProducts: boolean;
  onlySaleProductsOnEnterFilter: boolean;
  activeColors: Color[];
  activeColorsOnEnterFilter: Color[];
  activeMaterials: MaterialsEnum[];
  activeMaterialsOnEnterFilter: MaterialsEnum[];
}

export const initialState: ShelfItemPickerState = {
  loadingItems: false,
  filteredItems: {
    items: [],
    total: 0,
    lowestPrice: undefined,
    highestPrice: undefined,
  },
  filteredItemsOnEnterFilter: {
    items: [],
    total: 0,
    lowestPrice: undefined,
    highestPrice: undefined,
  },
  chunkSize: -1,
  hasMoreItems: true,
  selectedType: null,
  itemDetailsVisible: null,
  sortCriterion: SortCriteriaEnum.Popularity,
  sortCriterionOnEnterFilter: SortCriteriaEnum.Popularity,
  activeTypes: [],
  activeTypesOnEnterFilter: [],
  activeTheme: null,
  activeThemeOnEnterFilter: null,
  activeAffiliates: [...Object.values(AffiliatesEnum)],
  activeAffiliatesOnEnterFilter: [...Object.values(AffiliatesEnum)],
  minPrice: undefined,
  minPriceOnEnterFilter: undefined,
  maxPrice: undefined,
  maxPriceOnEnterFilter: undefined,
  onlySaleProducts: false,
  onlySaleProductsOnEnterFilter: false,
  activeColors: [],
  activeColorsOnEnterFilter: [],
  activeMaterials: [],
  activeMaterialsOnEnterFilter: [],
};

export const shelfItemPickerReducer = createReducer(
  initialState,

  on(loadItemsAction, (state: ShelfItemPickerState, action) => {
    return {
      ...state,
      loadingItems: true,
      filteredItems: action.configuration.reset
        ? {
          items: [],
          total: 0,
          lowestPrice: undefined,
          highestPrice: undefined,
        }
        : { ...state.filteredItems },
      hasMoreItems: action.configuration.reset ? true : state.hasMoreItems,
      chunkSize: action.configuration.lazyLoading ? environment.itemLimitPerLoad : -1,
    };
  }),
  on(loadItemsSucceededAction, (state: ShelfItemPickerState, action) => {
    return {
      ...state,
      filteredItems: action.filteredItems,
      hasMoreItems: action.filteredItems.items.length === state.chunkSize,
      loadingItems: false,
      minPrice: state.minPrice === undefined ? action.filteredItems.lowestPrice : state.minPrice,
      minPriceOnEnterFilter: state.minPriceOnEnterFilter === undefined ? action.filteredItems.lowestPrice : state.minPriceOnEnterFilter,
      maxPrice: state.maxPrice === undefined ? action.filteredItems.highestPrice : state.maxPrice,
      maxPriceOnEnterFilter: state.maxPriceOnEnterFilter === undefined ? action.filteredItems.highestPrice : state.maxPriceOnEnterFilter,
    };
  }),
  on(loadItemsFailedAction, (state: ShelfItemPickerState) => {
    return { ...state, loadingItems: false, items: [] };
  }),
  on(loadItemsOfCategoryFailedAction, (state) => {
    return { ...state, loadingItems: false, items: [] };
  }),
  on(showItemDetailsAction, (state, action) => {
    return { ...state, itemDetailsVisible: action.item.id };
  }),
  on(leaveItemDetailsAction, (state) => {
    return { ...state, itemDetailsVisible: null };
  }),
  on(enterProductFilterAction, (state) => {
    return {
      ...state,
      filteredItemsOnEnterFilter: JSON.parse(JSON.stringify(state.filteredItems)),
      sortCriterionOnEnterFilter: state.sortCriterion,
      activeTypesOnEnterFilter: [...state.activeTypes],
      activeThemeOnEnterFilter: state.activeTheme,
      activeAffiliatesOnEnterFilter: [...state.activeAffiliates],
      minPriceOnEnterFilter: state.minPrice,
      maxPriceOnEnterFilter: state.maxPrice,
      onlySaleProductsOnEnterFilter: state.onlySaleProducts,
      activeColorsOnEnterFilter: [...state.activeColors],
      activeMaterialsOnEnterFilter: [...state.activeMaterials],
    };
  }),
  on(setSortCriterionAction, (state, action) => {
    return {
      ...state,
      sortCriterion: action.sortCriterion,
    };
  }),
  on(toggleTypeAction, (state, action) => {
    const activeTypes = [...state.activeTypes];
    if (!activeTypes.includes(action.itemType)) {
      activeTypes.push(action.itemType);
    } else {
      activeTypes.splice(activeTypes.indexOf(action.itemType), 1);
    }
    return {
      ...state,
      activeTypes,
    };
  }),
  on(toggleThemeAction, (state, action) => {
    // toggle theme if it is already selected
    const newActiveTheme = action.theme === state.activeTheme ? null : action.theme;

    return {
      ...state,
      activeTheme: newActiveTheme,
    };
  }),
  on(toggleAffiliateAction, (state, action) => {
    const activeAffiliates = [...state.activeAffiliates];
    if (!activeAffiliates.includes(action.affiliate)) {
      activeAffiliates.push(action.affiliate);
    } else {
      activeAffiliates.splice(activeAffiliates.indexOf(action.affiliate), 1);
    }
    return {
      ...state,
      activeAffiliates,
    };
  }),
  on(setPriceRangeAction, (state, action) => {
    // sometimes, the ion-range element fires "NaN" values - these must be caught
    if (action.minPrice === NaN) {
      action.minPrice = state.filteredItems.lowestPrice;
    }
    if (action.maxPrice === NaN) {
      action.maxPrice = state.filteredItems.highestPrice;
    }

    return {
      ...state,
      minPrice: action.minPrice,
      maxPrice: action.maxPrice,
    };
  }),
  on(toggleShowOnlySaleProductsAction, (state) => {
    return {
      ...state,
      onlySaleProducts: !state.onlySaleProducts,
    };
  }),
  on(toggleColorAction, (state, action) => {
    const activeColors = [...state.activeColors];
    if (!activeColors.find((activeColor: Color) => activeColor.key === action.color.key)) {
      activeColors.push(action.color);
    } else {
      activeColors.splice(activeColors.indexOf(action.color), 1);
    }
    return {
      ...state,
      activeColors,
    };
  }),
  on(toggleMaterialAction, (state, action) => {
    const activeMaterials = [...state.activeMaterials];
    if (!activeMaterials.includes(action.material)) {
      activeMaterials.push(action.material);
    } else {
      activeMaterials.splice(activeMaterials.indexOf(action.material), 1);
    }
    return {
      ...state,
      activeMaterials,
    };
  }),
  on(leaveProductFilterAction, (state, action) => {
    let newState: ShelfItemPickerState;
    if (action.reset) {
      newState = {
        ...state,
        filteredItems: JSON.parse(JSON.stringify(state.filteredItemsOnEnterFilter)),
        sortCriterion: state.sortCriterionOnEnterFilter,
        activeTypes: state.activeTypesOnEnterFilter,
        activeTheme: state.activeThemeOnEnterFilter,
        activeAffiliates: state.activeAffiliatesOnEnterFilter,
        minPrice: state.minPriceOnEnterFilter,
        maxPrice: state.maxPriceOnEnterFilter,
        activeColors: state.activeColorsOnEnterFilter,
        activeMaterials: state.activeMaterialsOnEnterFilter,
      };
    } else {
      newState = { ...state };
    }

    return newState;
  }),
  on(resetProductFilterAtion, (state) => {
    return {
      ...state,
      sortCriterion: SortCriteriaEnum.New,
      activeTypes: [],
      activeTheme: null,
      activeAffiliates: [...Object.values(AffiliatesEnum)],
      minPrice: state.filteredItems.lowestPrice,
      maxPrice: state.filteredItems.highestPrice,
      onlySaleProducts: false,
      activeColors: [],
      activeMaterials: [],
    };
  }),
  on(setAffiliatesAction, (state, action) => {
    return {
      ...state,
      activeAffiliates: action.affiliates,
      activeAffiliatesOnEnterFilter: action.affiliates,
      filteredItems: { items: [], total: 0, lowestPrice: undefined, highestPrice: undefined },
      filteredItemsOnEnterFilter: { items: [], total: 0, lowestPrice: undefined, highestPrice: undefined },
    };
  })
);

export function reducer(state: ShelfItemPickerState = initialState, action: Action): ShelfItemPickerState {
  return shelfItemPickerReducer(state, action);
}

export const shelfItemPickerState = createFeatureSelector<ShelfItemPickerState>('shelfitempicker');
export const hasMoreItems = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.hasMoreItems);
export const isLoadingItems = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.loadingItems);
export const getItems = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.filteredItems.items);
export const getFilteredItems = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.filteredItems.items);
export const getTotalFilteredItems = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.filteredItems.total);
export const getSelectedType = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.selectedType);
export const getItemDetailsVisible = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.itemDetailsVisible);

// product filter selectors
export const getSortCriterion = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.sortCriterion);
export const getActiveTypes = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.activeTypes);
export const getActiveTheme = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.activeTheme);
export const getActiveAffiliates = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.activeAffiliates);
export const getLowestItemPrice = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.filteredItems.lowestPrice);
export const getMinPrice = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.minPrice);
export const getHighestItemPrice = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.filteredItems.highestPrice);
export const getMaxPrice = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.maxPrice);
export const showOnlySaleProducts = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.onlySaleProducts);
export const getActiveColors = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.activeColors);
export const getActiveMaterials = createSelector(shelfItemPickerState, (state: ShelfItemPickerState) => state.activeMaterials);
export const getFilterOptions = createSelector(
  shelfItemPickerState,
  (state: ShelfItemPickerState) =>
  ({
    sortCriterion: state.sortCriterion,
    types: state.activeTypes,
    theme: state.activeTheme,
    affiliates: state.activeAffiliates,
    prices: [state.minPrice, state.maxPrice],
    sale: state.onlySaleProducts,
    colors: state.activeColors,
    materials: state.activeMaterials,
  } as ProductFilterOptions)
);
