import { Injectable } from '@angular/core';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  DocumentReference,
  Firestore,
  getDocs,
  query,
  QuerySnapshot,
  setDoc,
  Timestamp,
  writeBatch
} from '@angular/fire/firestore';
import { forkJoin, from, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { Product } from '../../core/interfaces/product.interface';
import { Item } from '../../core/interfaces/item.interface';
import { ShelfScenes } from '../../core/interfaces/scenes-shelf.interface';
import { Scene } from '../../core/interfaces/scenes-scene.interface';
import { ImageSize } from '../../core/interfaces/scenes-image-sizes.interface';
import { ShelfScenesEnum } from '../../core/enums/scenes-shelf.enum';
import { SlotScenes } from '../../core/interfaces/scenes-slot.interface';
import { DesignDebug } from '../../core/services/debug.service';

@Injectable({
  providedIn: 'root',
})
export class AdminToolService {
  constructor(private firestore: Firestore) { }

  addItem$(item: Item): Observable<DocumentReference<any | Item>> {
    item.lastModified = Timestamp.fromDate(new Date());

    this.checkRequiredFields(item);

    //return from(this.afs.collection<Item>('items').add(item));
    return from(addDoc(collection(this.firestore, 'items'), item));
  }

  public checkRequiredFields(product: Product) {
    let updated = false;

    if (product.usageCount === null) {
      product.usageCount = 0;
      updated = true;
    }
    if (product.dateAdded === null) {
      product.dateAdded = Timestamp.fromDate(new Date());
      updated = true;
    }

    if (updated) {
      console.log('>>> missing required fields... product', product);
    }
    return updated;
  }

  addShelf$(shelf: ShelfScenes): Observable<DocumentReference<any | ShelfScenes>> {
    this.checkRequiredFields(shelf);
    return from(addDoc(collection(this.firestore, 'shelfs'), shelf));
  }

  updateItem$(item: Item): Observable<Item> {
    item.lastModified = Timestamp.fromDate(new Date());
    this.checkRequiredFields(item);
    return from(setDoc(doc(this.firestore, `items/${item.id}`), item)).pipe(map(() => item));
  }

  updateShelf$(shelf: ShelfScenes): Observable<any> {
    this.checkRequiredFields(shelf);
    return from(setDoc(doc(this.firestore, `shelfs/${shelf.id}`), shelf));
  }

  /*
  migrateItems$(): Observable<void> {
    return this.getOldItems$().pipe(
      mergeMap((oldItems) => oldItems),
      map(
        (oldItem: OldItem) =>
          ({
            id: oldItem.id,
            name: oldItem.name,
            imageFrontalPath: oldItem.imageFrontalPath,
            image180RemotePath: oldItem.image180RemotePath,
            color: oldItem.colorFilter || '',
            colorManufacturer: oldItem.colorOriginal || '',
            manufacturer: oldItem.manufacturer || '',
            sizes: oldItem.sizes || '',
            handle: oldItem.handle ? true : false,
            cover: oldItem.cover ? true : false,
            type: oldItem.type,
            affiliates: {
              ikeaDE: {
                articleNumber: oldItem.articleNumber,
                price: oldItem.price,
                priceSale: oldItem.price,
                priceSaleDescription: oldItem.priceSaleDescription || '',
                sellerUrl: oldItem.urlManufacturer || '',
                active: oldItem.active,
                rating: oldItem.rating || 0,
                ratingNumber: oldItem.ratingNumber || 0,
              },
            },
          } as Item)
      ),
      switchMap((newItem: Item) => {
        console.log('>>> newItem', newItem);
        return this.addItem$(newItem);
        // return of(newItem);
      }),
      map(() => null)
    );
  }
  */

  // TODO: erste Scene erweitert initialisieren
  createNewShelf$(): Observable<DocumentReference<ShelfScenes>> {
    return of(null).pipe(
      map(
        () =>
        ({
          scenes: [
            {
              name: 'Perspektive',
              active: false,
              imageUrl: '',
              imageSize: {
                width: 2000,
                height: 2000,
              } as ImageSize,
              type: ShelfScenesEnum.PERSPECTIVE,
              slots: [],
            } as Scene,
            {
              name: 'Frontalansicht',
              active: false,
              imageUrl: '',
              imageSize: {
                width: 2000,
                height: 2000,
              } as ImageSize,
              type: ShelfScenesEnum.FRONTAL,
              slots: [],
            } as Scene,
          ],
          type: null, // 4x4, 3x3

          active: false,
          description: '',
          name: '',
          height: 0,
          width: 0,
          depth: 0,
          color: null,
          manufacturer: '',
          colorManufacturer: '',
          dateAdded: null,
          lastModified: null,
          affiliates: {},
          activeAffiliates: [''],
          tags: [''],
          usageCount: 0,
        } as any)
      ),
      switchMap((shelf) => this.addShelf$(shelf))
    );
  }

  createNewItem$(): Observable<DocumentReference<Item>> {
    return of(null).pipe(
      map(
        () =>
        ({
          imageFrontalPath: '',
          image180RemotePath: '',
          handle: false,
          cover: false,
          themes: [],
          type: null,
          materials: [],

          active: false,
          description: '',
          name: '',
          height: 0,
          width: 0,
          depth: 0,
          show: {
            height: 0,
            width: 0,
          },
          color: null,
          manufacturer: '',
          colorManufacturer: '',
          dateAdded: null,
          lastModified: null,
          affiliates: {},
          activeAffiliates: [],
          tags: [],
          usageCount: 0,
        } as any)
      ),
      switchMap((item) => this.addItem$(item))
    );
  }

  cloneShelf$(shelf: ShelfScenes): Observable<DocumentReference<ShelfScenes>> {
    return of(shelf).pipe(
      map((shelf) => {
        let clonedShelf = { ...shelf };
        clonedShelf.id = null;
        return clonedShelf;
      }),
      switchMap((clonedShelf) => this.addShelf$(clonedShelf))
    );
  }

  cloneItem$(item: Item): Observable<DocumentReference<Item>> {
    return of(item).pipe(
      map((item) => {
        let clonedItem = { ...item };
        clonedItem.id = null;
        return clonedItem;
      }),
      switchMap((clonedItem) => this.addItem$(clonedItem))
    );
  }

  deleteShelf$(shelf: ShelfScenes): Observable<void> {
    return from(deleteDoc(doc(this.firestore, `shelfs/${shelf.id}`)));
  }

  deleteItem$(item: Item): Observable<void> {
    return from(deleteDoc(doc(this.firestore, `items/${item.id}`)));
  }

  /*
  fixPrices$(): Observable<void> {
    return (
      this.afs
        .collection<Item>('items', (ref) => ref.orderBy('name'))
        // .valueChanges({ idField: 'id' })
        .get()
        .pipe(
          map((querySnapshot) => querySnapshot.docs.map((doc) => doc.data())),
          switchMap((items: Item[]) => {
            const updateObs = [];
            items.map((item) => {
              let succ = true;

              // console.log('item', item.affiliates[0]);
              if (item.affiliates) {
                const amazonDEPrice = item.affiliates.amazonDE.price.toString();
                const ikeaDEPrice = item.affiliates.ikeaDE.price.toString();

                if (amazonDEPrice.indexOf(',') > -1) {
                  console.log('>>> amazonDEPrice', amazonDEPrice, this.getNumberPrice(amazonDEPrice));
                  item.affiliates.amazonDE.price = this.getNumberPrice(amazonDEPrice);
                  //updateObs.push(this.updateItem$(item));
                  //this.updateItem$(item).subscribe();
                  console.log('>>> item.affiliates.amazonDE.price', item.affiliates.amazonDE.price);
                }

                if (ikeaDEPrice.indexOf(',') > -1) {
                  console.log('>>> ikeaDEPrice', item.id, ikeaDEPrice, this.getNumberPrice(ikeaDEPrice));
                  item.affiliates.ikeaDE.price = this.getNumberPrice(ikeaDEPrice);
                  // updateObs.push(this.updateItem$(item));
                  // this.updateItem$(item).subscribe();
                }
              }

              return succ;
            });

            return forkJoin(updateObs);
          }),
          map(() => null)
        )
    );
  }
*/
  private getNumberPrice(price: string): number {
    return parseFloat(price.trim().replace(/,/g, '.'));
  }

  addNewScene(shelf: ShelfScenes): ShelfScenes {
    let newSlots: SlotScenes[] = JSON.parse(JSON.stringify(shelf.scenes[0].slots));
    // add scene
    let newScene: Scene = {
      active: false,
      name: 'Neue Ansicht',
      imageUrl: '',
      imageSize: {
        width: 2000,
        height: 2000,
      } as ImageSize,
      type: ShelfScenesEnum.CUSTOM,
      slots: newSlots,
    } as Scene;
    shelf.scenes.push(newScene);

    return shelf;
  }

  deleteScene(shelf: ShelfScenes, sceneIndexToDelete: number): ShelfScenes {
    shelf.scenes.splice(sceneIndexToDelete, 1);
    return shelf;
  }

  /**
   * ! DO NOT refactor this :) !!!
   */
  deleteUnnecessaryDesigns$(): Observable<any> {
    return from(getDocs(query(collection(this.firestore, 'designs')))).pipe(
      map((querySnapshot: QuerySnapshot) => {
        return querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      }),
      switchMap((designs: DesignDebug[]) => {
        let updateDocObs: Observable<any>[] = [];
        let checkIfBatchCanCommited: boolean = false;
        let batch: any;
        designs.forEach((design: DesignDebug, index: number) => {
          if (index % 500 == 0) {
            if (checkIfBatchCanCommited) {
              updateDocObs.push(from(batch.commit()));
            }
            batch = writeBatch(this.firestore);
          }
          const ref = doc(this.firestore, 'designs', design.id);

          // must be / are a field in this document
          checkIfBatchCanCommited = true;
          const dateAddedPlain = new Date(design.date.seconds * 1000 + 60 * 60 * 1000);

          const now = new Date();
          const deleteBorder = new Date(now.getTime() - (1000 * 60 * 60 * 24 * 7));

          if (design.shoppingCartItems.length === 0) {
            // ! Delete only shelves (Designs) with a date since one week ago
            if (dateAddedPlain < deleteBorder) {
              batch.delete(ref);
            }
          }

          if (index == designs.length - 1) {
            updateDocObs.push(from(batch.commit()));
          }
        });

        return forkJoin(updateDocObs);
      }),
      tap(() => console.log('>>> DELETE DONE <<<'))
    );
  }
}
