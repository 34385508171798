import { createAction, props } from '@ngrx/store';
import { Item } from '../interfaces/item.interface';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';

const actionType = '[Product Details]';

export const loadItemAction = createAction(`${actionType} Load item`, props<{ id: string }>());
export const loadItemSucceededAction = createAction(`${actionType} Load item succeeded`, props<{ item: Item }>());
export const loadItemFailedAction = createAction(`${actionType} Load item failed`, props<{ error: Error }>());

export const loadShelfAction = createAction(`${actionType} Load shelf`, props<{ id: string }>());
export const loadShelfSucceededAction = createAction(`${actionType} Load shelf succeeded`, props<{ shelf: ShelfScenes }>());
export const loadShelfFailedAction = createAction(`${actionType} Load shelf failed`, props<{ error: Error }>());
