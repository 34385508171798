import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CoreModule } from '../core/core.module';
import { AffiliateLogoComponent } from './affiliate-logo/affiliate-logo.component';
import { AppButtonComponent } from './app-button/app-button.component';
import { FlipComponent } from './flip/flip.component';
import { ItemImageComponent } from './item-image/item-image.component';
import { LoadingImageComponent } from './loading-image/loading-image.component';

@NgModule({
  declarations: [AppButtonComponent, FlipComponent, ItemImageComponent, AffiliateLogoComponent, LoadingImageComponent],
  exports: [AppButtonComponent, FlipComponent, ItemImageComponent, AffiliateLogoComponent, LoadingImageComponent],
  imports: [CommonModule, IonicModule, CoreModule],
})
export class CoreUIModule {}
