import { Action, createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';
import * as ShoppingCardActions from '../actions/shopping-cart.actions';
import { ShelfScenes } from '../interfaces/scenes-shelf.interface';
import { ExtendedShoppingCartItem } from '../interfaces/shopping-cart-item.interface';

export interface ShoppingCartState {
  shelfData: ShelfScenes;
  extendedShoppingCartItems: ExtendedShoppingCartItem[];
  addingItem: boolean;
  removingItem: boolean;
  totalPrice: number;
  calculatePrice: boolean;
  loadConfigSuccess: boolean;
  checkUpdates: boolean;
  productListOpen: boolean;
  currentAccordionStateOnProductList: string;
}

export const initialState: ShoppingCartState = {
  shelfData: null,
  extendedShoppingCartItems: null,
  addingItem: false,
  removingItem: false,
  totalPrice: 0.0,
  calculatePrice: false,
  loadConfigSuccess: false,
  checkUpdates: false,
  productListOpen: false,
  currentAccordionStateOnProductList: '',
};

export const shoppingCartReducer = createReducer(
  initialState,
  on(ShoppingCardActions.addShelfAction, (state, action) => {
    return { ...state, shelfData: action.shelf };
  }),
  on(ShoppingCardActions.addItemToCartAction, (state) => {
    return { ...state, addingItem: true };
  }),
  on(ShoppingCardActions.addItemToCartSucceededAction, (state, action): ShoppingCartState => {
    return { ...state, extendedShoppingCartItems: action.extendedShoppingCartItems, addingItem: false };
  }),
  on(ShoppingCardActions.removeItemGroupAction, (state, action) => {
    return { ...state, removingItem: true };
  }),
  on(ShoppingCardActions.removeItemGroupSucceededAction, (state, action) => {
    return { ...state, removingItem: false, extendedShoppingCartItems: action.shoppingCartItems };
  }),
  on(ShoppingCardActions.removeItemAction, (state, action) => {
    return { ...state, removingItem: true };
  }),
  on(ShoppingCardActions.removeItemSucceededAction, (state, action) => {
    return { ...state, removingItem: false, extendedShoppingCartItems: action.shoppingCartItems };
  }),
  on(ShoppingCardActions.calculatePriceAction, (state) => {
    return { ...state, calculatePrice: true };
  }),
  on(ShoppingCardActions.calculatePriceSucceededAction, (state, action) => {
    return { ...state, totalPrice: action.totalPrice, calculatePrice: false };
  }),
  on(ShoppingCardActions.clearItemListAction, (state) => {
    return { ...state, extendedShoppingCartItems: null };
  }),
  on(ShoppingCardActions.shoppingcardLoadSavedConfSucceededAction, (state, action) => {
    return {
      ...state,
      shelfData: action.shelf,
      extendedShoppingCartItems: action.config.shoppingCartItems,
      loadConfigSuccess: true,
    };
  }),
  on(ShoppingCardActions.checkUpdateChangesAction, (state) => {
    return { ...state, checkUpdates: true };
  }),
  on(ShoppingCardActions.checkUpdateChangesSucceededAction, (state, action) => {
    return { ...state, checkUpdates: false, extendedShoppingCartItems: action.extendedShoppingCartItems };
  }),
  on(ShoppingCardActions.updateShoppingCartItemsAfterShelfChangeAction, (state, action) => {
    return { ...state, extendedShoppingCartItems: action.shoppingCartItems };
  }),
  on(ShoppingCardActions.showProductListAction, (state, action) => {
    return { ...state, productListOpen: true };
  }),
  on(ShoppingCardActions.hideProductListAction, (state, action) => {
    return { ...state, productListOpen: false };
  }),
  on(ShoppingCardActions.setCurrentAccordionStateAction, (state, action) => {
    return { ...state, currentAccordionStateOnProductList: action.id };
  })
);

export function reducer(state: ShoppingCartState = initialState, action: Action): ShoppingCartState {
  return shoppingCartReducer(state, action);
}

export const shoppingCartState = createFeatureSelector<ShoppingCartState>('shoppingcart');
export const getShelfData = createSelector(shoppingCartState, (state: ShoppingCartState) => state.shelfData);
export const getShoppingCardItems = createSelector(shoppingCartState, (state: ShoppingCartState) => state.extendedShoppingCartItems);
export const isAddingItem = createSelector(shoppingCartState, (state: ShoppingCartState) => state.addingItem);
export const isRemovingItem = createSelector(shoppingCartState, (state: ShoppingCartState) => state.removingItem);
export const getTotalPrice = createSelector(shoppingCartState, (state: ShoppingCartState) => state.totalPrice);
export const isCalculatePrice = createSelector(shoppingCartState, (state: ShoppingCartState) => state.calculatePrice);
export const isLoadConfigSuccess = createSelector(shoppingCartState, (state: ShoppingCartState) => state.loadConfigSuccess);
export const isCheckUpdates = createSelector(shoppingCartState, (state: ShoppingCartState) => state.checkUpdates);
// Needed in prerender image at shelf-item.component.ts
export const getShoppingCardItemById = (id: string) =>
  createSelector(shoppingCartState, (state: ShoppingCartState) =>
    state.extendedShoppingCartItems.find((item: ExtendedShoppingCartItem) => item?.item?.id === id)
  );
export const isProductListOpen = createSelector(shoppingCartState, (state: ShoppingCartState) => state.productListOpen);

export const getCurrentAccordionStateOnProductList = createSelector(shoppingCartState, (state: ShoppingCartState) => {
  return state.currentAccordionStateOnProductList;
});
