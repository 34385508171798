<ion-header [ngClass]="{ 'side-bar': sideBar }">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button (click)="handleClose()" class="close-button" *ngIf="sideBar">
        <ion-icon name="arrow-back-outline" class="grey-icon"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title (click)="debugWithValue(shoppingCartItems)">
      {{ 'shopping-cart.title' | transloco }}
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="handleClose()" class="close-button">
        <ion-icon name="close-circle-outline" class="grey-icon"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content #root>
  <div class="root">
    <ng-container *ngLet="{
        isOwnDesign: currentDesignIsOwnDesign$ | async,
        isBuyingShelf: isBuyingShelf$ | async
      } as obsA">
      <!-- Active products -->
      <ion-list lines="full">
        <ion-accordion-group #accordionList
          *ngLet="{ currentAccordionStateOnProductList: currentAccordionStateOnProductList$ | async } as obsACG"
          [value]="obsACG.currentAccordionStateOnProductList" (ionChange)="toggleAccordionState()">
          <div class="affiliate" *ngFor="let affiliate of shoppingCartItems | keyvalue; trackBy: trackByAffiliate">
            <div class="header">
              <img [src]="affiliate.key | affiliateLogoUrl" class="affiliate-thumbnail" [ngClass]="affiliate.key" />
              <div class="price" [innerHTML]="affiliate.value.totalPrice | currencyFormat: 'EUR':'de'"></div>
            </div>
            <ng-container *ngFor="let listEntry of affiliate.value.list; trackBy: trackByItemGroup">
              <!-- Multiple items are shown in an ion-accordion -->
              <ng-container *ngIf="listEntry.products.length > 1; else singleProduct">
                <ion-accordion [value]="listEntry.products[0].item.id">
                  <ion-item slot="header">
                    <ion-thumbnail slot="start">
                      <img [src]="listEntry.products[0].item?.image180RemotePath | imageSize: '125x125'" />
                    </ion-thumbnail>
                    <ion-label>
                      <div class="accordion-name">
                        {{ listEntry.products[0].item.name }}
                      </div>
                      <div class="pieces">{{ listEntry.products.length }} {{ 'shopping-cart.pieces' | transloco }}</div>
                      <div class="prices">
                        <div class="to-buy" *ngIf="listEntry?.sumToBuy > 0">
                          <span>{{ listEntry.sumToBuy }} x </span>
                          <span
                            [innerHTML]="listEntry.products[0].item | affiliate: 'price' | currencyFormat: 'EUR':'de'"></span>
                        </div>
                        <div class="not-to-buy spacing" *ngIf="listEntry?.sumToBuy > 0 && listEntry?.sumNotToBuy > 0">
                          <span> + </span>
                        </div>
                        <div class="not-to-buy" *ngIf="listEntry?.sumNotToBuy > 0">
                          <span>{{ listEntry.sumNotToBuy }} x </span>
                          <span
                            [innerHTML]="listEntry.products[0].item | affiliate: 'price' | currencyFormat: 'EUR':'de'"></span>
                        </div>
                      </div>
                      <div class="item-fitting" [ngClass]="{
                        'item-fit': listEntry.products[0].itemFit === 1,
                        'item-fit-warning': listEntry.products[0].itemFit === -1,
                        'item-not-fit': listEntry.products[0].itemFit === 0
                      }" *ngIf="listEntry.products[0].itemFit <= 0">
                        <ion-icon name="warning"></ion-icon>
                        <ng-container [ngSwitch]="listEntry.products[0].itemFit">
                          <span *ngSwitchCase="-1">
                            {{ 'shelfdesigner.item-does-not-fit-slot-warning' | transloco }}
                          </span>
                          <span *ngSwitchCase="0">
                            {{ 'shelfdesigner.item-does-not-fit-slot' | transloco }}
                          </span>
                        </ng-container>
                      </div>
                    </ion-label>
                    <ion-label class="open-close-label" slot="end"
                      *ngLet="{ isAccordionState: obsACG.currentAccordionStateOnProductList == listEntry.products[0].item.id } as obsIL">
                      <div *ngIf="obsIL.isAccordionState">{{ 'shopping-cart.close' | transloco }}</div>
                      <div *ngIf="!obsIL.isAccordionState">{{ 'shopping-cart.open' | transloco }}</div>
                    </ion-label>
                  </ion-item>

                  <div slot="content">
                    <ion-list lines="full">
                      <ion-item class="item" *ngFor="let productListProduct of listEntry.products">
                        <ng-container *ngLet="productListProduct.item | affiliate: 'url' as url">
                          <ion-thumbnail slot="start">
                            <img [src]="productListProduct.item?.image180RemotePath | imageSize: '125x125'" />
                          </ion-thumbnail>
                          <ion-label>
                            <div class="name-link" (click)="openUrl(url)">
                              <h2 class="name">{{ productListProduct.item.name }}</h2>
                            </div>
                            <div class="actions-line">
                              <ion-button class="button" fill="outline" size="small" (click)="openUrl(url)">
                                {{ 'general.details' | transloco }}
                              </ion-button>
                              <ion-toggle tooltip="{{ 'product-list.i-already-have-it' | transloco }}"
                                [checked]="productListProduct.buyItem"
                                (click)="obsA.isOwnDesign ? handleItemToggle(productListProduct) : handleCloneDialog($event)"
                                class="toggle"></ion-toggle>
                              <label class="buy-it" *ngIf="productListProduct.buyItem; else haveIt">
                                {{ 'shopping-cart.buy-it' | transloco }}
                              </label>
                              <ng-template #haveIt>
                                <label class="have-it">
                                  {{ 'shopping-cart.have-it' | transloco }}
                                </label>
                              </ng-template>
                            </div>
                            <div class="price-line">
                              <div class="price" [ngClass]="{ 'do-not-buy': !productListProduct.buyItem }"
                                [innerHTML]="productListProduct.item | affiliate: 'price' | currencyFormat: 'EUR':'de'">
                              </div>
                            </div>
                          </ion-label>
                          <ion-label slot="end" class="go-to-label">
                            <div class="go-to" (click)="goToAffiliate(productListProduct.item, url, affiliate.key)">
                              <ion-icon name="cart"></ion-icon>
                              <div>
                                {{ 'shopping-cart.go-to' | transloco: { affiliate: 'affiliates.' + affiliate.key |
                                transloco } }}
                              </div>
                            </div>
                          </ion-label>
                        </ng-container>
                      </ion-item>
                    </ion-list>
                  </div>
                </ion-accordion>
              </ng-container>
              <!-- A single item shows in an ion-item -->
              <ng-template #singleProduct>
                <!-- Shelf -->
                <ng-container *ngIf="listEntry.products[0].item?.scenes; else singleItem">
                  <ng-container *ngIf="listEntry.products[0].item as shelf">
                    <ion-item class="item shelf" *ngLet="shelf | affiliate: 'url' as url">
                      <ion-thumbnail slot="start">
                        <img [src]="shelf.scenes[0].imageUrl" />
                      </ion-thumbnail>
                      <ion-label>
                        <div class="name-link" (click)="openUrl(url)">
                          <h2 class="name">{{ shelf.name }}</h2>
                        </div>
                        <div class="actions-line">
                          <ion-button class="button" fill="outline" size="small" (click)="openUrl(url)">
                            {{ 'general.details' | transloco }}
                          </ion-button>
                          <div
                            *ngIf="environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] === affiliatesEnum.ALL">
                            <ion-toggle tooltip="{{ 'product-list.i-already-have-it' | transloco }}"
                              [checked]="obsA.isBuyingShelf"
                              (click)="obsA.isOwnDesign ? handleShelfToggle(!obsA.isBuyingShelf) : handleCloneDialog($event)"
                              class="toggle"></ion-toggle>
                            <div class="buy-it" *ngIf="obsA.isBuyingShelf; else haveIt">
                              {{ 'shopping-cart.buy-it' | transloco }}
                            </div>
                            <ng-template #haveIt>
                              <div class="have-it">
                                {{ 'shopping-cart.have-it' | transloco }}
                              </div>
                            </ng-template>
                          </div>
                        </div>
                        <div class="price-line">
                          <div class="price" [ngClass]="{ 'do-not-buy': !obsA.isBuyingShelf }"
                            [innerHTML]="shelf | affiliate: 'price' | currencyFormat: 'EUR':'de'"></div>
                        </div>
                      </ion-label>
                      <ion-label slot="end" class="go-to-label">
                        <div class="go-to" (click)="goToAffiliate(shelf, url, affiliate.key)">
                          <ion-icon name="cart"></ion-icon>
                          <div>
                            {{ 'shopping-cart.go-to' | transloco: { affiliate: 'affiliates.' + affiliate.key | transloco
                            } }}
                          </div>
                        </div>
                      </ion-label>
                    </ion-item>
                  </ng-container>
                </ng-container>
                <!-- Item -->
                <ng-template #singleItem>
                  <ng-container *ngIf="listEntry.products[0] as product">
                    <ion-item class="item" *ngLet="product.item | affiliate: 'url' as url">
                      <ion-thumbnail slot="start">
                        <img [src]="product.item.image180RemotePath | imageSize: '125x125'" />
                      </ion-thumbnail>
                      <ion-label>
                        <div class="name-link" (click)="openUrl(url)">
                          <h2 class="name">{{ product.item.name }}</h2>
                        </div>
                        <div class="actions-line">
                          <ion-button class="button" fill="outline" size="small" (click)="openUrl(url)">
                            {{ 'general.details' | transloco }}
                          </ion-button>
                          <div
                            *ngIf="environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] === affiliatesEnum.ALL">
                            <ion-toggle tooltip="{{ 'product-list.i-already-have-it' | transloco }}"
                              [checked]="product.buyItem"
                              (click)="obsA.isOwnDesign ? handleItemToggle(product) : handleCloneDialog($event)"
                              class="toggle"></ion-toggle>
                            <label class="buy-it" *ngIf="product.buyItem; else haveIt">
                              {{ 'shopping-cart.buy-it' | transloco }}
                            </label>
                            <ng-template #haveIt>
                              <label class="have-it">
                                {{ 'shopping-cart.have-it' | transloco }}
                              </label>
                            </ng-template>
                          </div>
                        </div>
                        <div class="price-line">
                          <div class="price" [ngClass]="{ 'do-not-buy': !product.buyItem }"
                            [innerHTML]="product.item | affiliate: 'price' | currencyFormat: 'EUR':'de'"></div>
                        </div>
                        <!-- Item-not-fit warning -->
                        <div class="item-fitting" [ngClass]="{
                          'item-fit': product.itemFit === 1,
                          'item-fit-warning': product.itemFit === -1,
                          'item-not-fit': product.itemFit === 0
                        }" *ngIf="product.itemFit <= 0 && !product.hacksItem">
                          <ion-icon name="warning-outline"></ion-icon>
                          <ng-container [ngSwitch]="product.itemFit">
                            <span *ngSwitchCase="-1">
                              {{ 'shelfdesigner.item-does-not-fit-slot-warning' | transloco }}
                            </span>
                            <span *ngSwitchCase="0">
                              {{ 'shelfdesigner.item-does-not-fit-slot' | transloco }}
                            </span>
                          </ng-container>
                        </div>
                      </ion-label>
                      <ion-label slot="end" class="go-to-label">
                        <div class="go-to" (click)="goToAffiliate(product.item, url, affiliate.key)">
                          <ion-icon name="cart"></ion-icon>
                          <div>
                            {{ 'shopping-cart.go-to' | transloco: { affiliate: 'affiliates.' + affiliate.key | transloco
                            } }}
                          </div>
                        </div>
                      </ion-label>
                    </ion-item>
                  </ng-container>
                </ng-template>
              </ng-template>
            </ng-container>

            <!-- Close the affiliate-tile -->
            <div class="ending-affiliate">
              <div class="to-shoppingdart" *ngIf="hasShoppingCartFunctionality(affiliate.key); else noShoppingCart"
                (click)="handleShoppingCart(affiliate.key)">
                <label>
                  {{ 'shopping-cart.go-to' | transloco: { affiliate: 'affiliates.' + affiliate.key | transloco } }}
                </label>
                <ion-icon name="cart"></ion-icon>
              </div>
              <ng-template #noShoppingCart>
                <div class="to-shoppingdart not-allowed"></div>
              </ng-template>
            </div>
          </div>
        </ion-accordion-group>
      </ion-list>

      <!-- Inactive products -->
      <div *ngIf="shoppingCartItemsInactive.list.length > 0" class="not-available">
        <div class="texts">
          <label>
            {{ 'shopping-cart.not-available.label' | transloco }}
          </label>
          <label> {{ shoppingCartItemsInactive.list.length }} {{ 'shopping-cart.not-available.products' | transloco }}
          </label>
        </div>
        <!-- List of products -->
        <ion-list lines="full">
          <ng-container *ngFor="let product of shoppingCartItemsInactive.list; let i = index">
            <ion-item class="item-inactive">
              <ion-thumbnail slot="start">
                <!-- Item -->
                <img [src]="product?.image180RemotePath | imageSize: '125x125'" *ngIf="!product.scenes; else noShelf" />
                <ng-template #noShelf>
                  <!-- Shelf -->
                  <img [src]="product?.scenes[0].imageUrl | imageSize: '125x125'" />
                </ng-template>
              </ion-thumbnail>
              <ion-label>
                <div class="name-link">
                  <h2 class="name">{{ product.name }}</h2>
                </div>
              </ion-label>
            </ion-item>
          </ng-container>
        </ion-list>
      </div>
    </ng-container>
  </div>
</ion-content>
