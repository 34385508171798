import { Injectable, NgZone } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { GeneralService } from './general.service';
import mixpanel from 'mixpanel-browser';
import { AuthService } from '../../login/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppInitializerService {
  constructor(
    private meta: Meta,
    private generalService: GeneralService,
    private zone: NgZone,
    private router: Router,
    private authService: AuthService
  ) {}

  initApp$(): Observable<void> {
    return of(null).pipe(
      tap(() => this.initAppListener()),
      switchMap(() => this.generalService.init$()),
      tap(() => this.initTracking())
    );
  }
  initTracking(): void {
    // Replace YOUR_TOKEN with your Project Token
    console.log('>>> init tracking');
    mixpanel.init('7f2a290cfc819b359aff01d31f9dc481', { debug: true, api_host: 'https://api-eu.mixpanel.com' });

    this.authService.initTracking();
  }

  private initAppListener() {
    // update apple app link so that we can directly link into the native app to a special design
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.meta.updateTag({
          name: 'apple-itunes-app',
          content: `app-id=1593391156, app-argument=${window.location.href}`,
        } as MetaDefinition);

        // TODO: do it later dynamically
        /*
        // facebook
        this.meta.updateTag({
          name: 'og:image',
          content: `https://firebasestorage.googleapis.com/v0/b/shelfdesigner-bbbcb.appspot.com/o/designs%2Fthumbnails%2Fulo0rKIBzS3OgrxP2Z1y-thumbmail.png?alt=media&token=f68fe771-502e-4010-a17e-f5110f5056e5`,
        } as MetaDefinition);

         // twitter
         this.meta.updateTag({
          name: 'twitter:image',
          content: `https://firebasestorage.googleapis.com/v0/b/shelfdesigner-bbbcb.appspot.com/o/designs%2Fthumbnails%2Fulo0rKIBzS3OgrxP2Z1y-thumbmail.png?alt=media&token=f68fe771-502e-4010-a17e-f5110f5056e5`,
        } as MetaDefinition);
*/
      }
    });

    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split('.com').pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });
  }
}
