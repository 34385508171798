export enum TypesEnum {
  Boxes = 'box',
  Drawers = 'drawer',
  Doors = 'door',
  SlotSeparators = 'slot-separators',
  Decoration = 'decoration',
  // Tops = 'tops',
  Other = 'other',
  Feet = 'feet',
  Sticker = 'sticker',
  Plates = 'plates',
  AdditionalHacks = 'additional-hacks',
}
