import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Item } from '../../../core/interfaces/item.interface';
import { AuthService } from '../../../login/services/auth.service';
import { AffiliatesEnum } from '../../../core/enums/affiliates.enum';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { isSplitMode } from '../../../core/reducers/general.reducer';

@Component({
  selector: 'app-shelf-item-picker-item',
  templateUrl: './shelf-item-picker-item.component.html',
  styleUrls: ['./shelf-item-picker-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShelfItemPickerItemComponent implements OnInit {
  @Output('onItemClick') onItemClick: EventEmitter<Item> = new EventEmitter();
  @Output('onDetailsClick') onDetailsClick: EventEmitter<Item> = new EventEmitter();
  @Output('onEditClick') onEditClick: EventEmitter<Item> = new EventEmitter();

  @Input('item') item: Item;

  splitMode$: Observable<boolean> = this.store.select(isSplitMode);

  // UI bindings
  environment = environment;
  affiliatesEnum = AffiliatesEnum;
  isAdmin = this.authService.isAdmin();

  constructor(private authService: AuthService, private store: Store) { }

  ngOnInit(): void { }

  handleItemClick(): void {
    this.onItemClick.emit(this.item);
  }

  handleShowItemDetails(event: CustomEvent): void {
    event.stopPropagation();
    this.onDetailsClick.emit(this.item);
  }

  handleEditItem(event: CustomEvent): void {
    event.stopPropagation();
    this.onEditClick.emit(this.item);
  }
}
