import { Component, OnInit } from '@angular/core';
import { App, AppInfo } from '@capacitor/app';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { showShelfsListAction } from '../core/actions/admin.actions';
import { logoutAction } from '../core/actions/authentication.actions';
import { gotoSignInAction, hideAppMenuAction } from '../core/actions/general.actions';
import { createNewDesignAction, editCurrentShelfAction, showMyDesignsAction } from '../core/actions/shelf-designer.actions';
import { AnalyticLogs } from '../core/enums/analytic-logs.enum';
import { AnalyticsService } from '../core/services/analytics.service';
import { AuthService } from '../login/services/auth.service';
import { AffiliatesEnum } from '../core/enums/affiliates.enum';

const APP_MENU = 'app-menu';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
})
export class AppMenuComponent implements OnInit {
  isAdmin!: boolean;
  currentAppVersion!: string;
  isLoggedIn = false;
  logoPath: string = '../../assets/imgs/shelfy-logo.png';
  environment = environment;
  affiliatesEnum = AffiliatesEnum;

  constructor(
    private authService: AuthService,
    private store: Store,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    if (environment.allowedAffiliates.length === 1 && environment.allowedAffiliates[0] !== this.affiliatesEnum.ALL) {
      switch (environment.allowedAffiliates[0]) {
        case AffiliatesEnum.OTTO_DE:
          this.logoPath = '../../assets/imgs/otto-logo.svg';
          break;
      }
    }

    this.isAdmin = this.authService.isAdmin();
    this.isLoggedIn = this.authService.isLoggedIn;

    App.getInfo()
      .then((appInfo: AppInfo) => (this.currentAppVersion = appInfo.version))
      .catch(() => (this.currentAppVersion = environment.appVersion));
  }

  handleClose(): void {
    this.store.dispatch(hideAppMenuAction());
  }

  handleShowMyDesigns(): void {
    this.store.dispatch(showMyDesignsAction());
  }

  handleNewDesign(): void {
    this.store.dispatch(createNewDesignAction());
  }

  handleAdminTool(): void {
    this.store.dispatch(showShelfsListAction());
  }

  handleSignIn(): void {
    this.store.dispatch(gotoSignInAction());
  }

  handleLogout(): void {
    this.store.dispatch(logoutAction());
  }

  handleEditShelf(): void {
    this.store.dispatch(editCurrentShelfAction());
  }

  handleShowContactInfo(): void {
    this.analyticsService.log(AnalyticLogs.APP_MENU_SHOW_CONTACT_INFO);
  }

  handleShowImprint(): void {
    this.analyticsService.log(AnalyticLogs.APP_MENU_SHOW_IMPRINT);
  }

  handleShowPrivacyPolicy(): void {
    this.analyticsService.log(AnalyticLogs.APP_MENU_SHOW_PRIVACY_POLICY);
  }

  handleCatClick(event: MouseEvent | TouchEvent): void {
    let showAdmintool = false;
    if (event instanceof MouseEvent) {
      showAdmintool = event.altKey && event.shiftKey;
    }
    if (event instanceof TouchEvent) {
      showAdmintool = (event as TouchEvent).touches.length === 2;
    }
    if (showAdmintool) {
      const url = window.location.protocol + '//' + window.location.host + '/admin/shelves#admin';
      window.location.replace(url);
    }
  }
}
